import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Cotacao } from 'app/main/cotacao/shared/interfaces/cotacao.interface';
import { Os } from '../../shareds/interfaces/os.interface';

@Component({
    selector: 'os-cadastro-page',
    templateUrl: './os-cadastro-page.component.html',
    styleUrls: ['./os-cadastro-page.component.scss']
})
export class OsCadastroPageComponent implements OnInit {
    os: Os;

    constructor(private _route: ActivatedRoute) {
        this.os = {};
    }

    ngOnInit() {
        this.os = {};
        this._route.data.subscribe((data) => {
            this.os = data.os;
        });
    }

}
