import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class ProjetoGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [
            { path: '/projeto', role: Role.PROJETO_BUSCA },
            { path: '/projeto/visualizar/[0-9]+', role: Role.PROJETO_EXIBIR },
            { path: '/projeto/cadastro/[0-9]+', role: Role.PROJETO_ALTERAR },
            { path: '/projeto/abertura/cadastro/[0-9]+', role: Role.PROJETO_ABERTURA_ALTERAR },
            { path: '/projeto/abertura/visualizar/[0-9]+', role: Role.PROJETO_ABERTURA_EXIBIR },
        ];
    }
}
