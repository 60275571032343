import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FaturamentoHistorico } from '../interfaces/faturamento-historico.interface';
import { FiltroFaturamentoHistorico } from '../interfaces/filtro-faturamento-historico.interface';

@Injectable({
    providedIn: 'root',
})
export class FaturamentoHistoricoService extends ServiceGridBase<
    FaturamentoHistorico,
    FiltroFaturamentoHistorico
> {
    private path: string = `${environment.server}/faturamento/historico`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroFaturamentoHistorico
    ): Observable<Response<Array<FaturamentoHistorico>>> {
        const params: string = filtro
            ? this.toGetParams(filtro, ['idFaturamento'])
            : '';
        const url = `${this.path}/${filtro.idFaturamento}${params}`;
        return this._http.get<Response<Array<FaturamentoHistorico>>>(url);
    }
}
