import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { ProfileGuard } from './profile.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [ProfileGuard],
        canLoad: [ProfileGuard],
        component: ProfilePageComponent,
    },
    { path: '', redirectTo: '/profile', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ProfileRoutingModule {}
