import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { StatusFinanceiro } from '../../shared/interfaces/status-financeiro.interface';
import { TipoCobranca } from '../../shared/interfaces/tipo-cobranca.interface';
import { CotacaoStatusService } from '../../shared/services/cotacao-status.service';
import { StatusFinanceiroService } from '../../shared/services/status-financeiro.service';
import { TipoCobrancaService } from '../../shared/services/tipo-cobranca.service';
import { CotacaoStatus } from '../../shared/interfaces/cotacao-status.interface';
import { CotacaoEditar } from '../../shared/interfaces/cotacao-editar.interface';
import { CotacaoEdicaoService } from '../../shared/services/cotacao-edicao.service';
import { UsuarioService } from 'app/main/usuario/shared/services/usuario.service';
import { FormControl } from '@angular/forms';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';

@Component({
    selector: 'editar-cotacao',
    templateUrl: './editar-cotacao.component.html',
    styleUrls: ['./editar-cotacao.component.scss'],
})
export class EditarCotacaoComponent extends FormBase implements OnInit {
    private _cotacaoEditar: CotacaoEditar;
    isView: boolean = false;
    listaCotacaoStatus: Array<CotacaoStatus> = [];
    listaStatusFinanceiro: Array<StatusFinanceiro> = [];
    listaTipoCobranca: Array<TipoCobranca> = [];

    checkCotacaoStatus: boolean = false;
    checkStatusFinanceiro: boolean = false;
    checkTipoCobranca: boolean = false;
    checkSquad: boolean = false;
    todosFull: boolean = false;
    checkResponsavel: boolean = false;

    @Output() atualizado = new EventEmitter<boolean>();
    listaUsuarios: Usuario[];
    _listaUsuarios: Usuario[];

    listaResponsavel: Usuario[];
    _listaResponsavel: Usuario[];

    mySelections: string[];

    public squadFilterCtrl: FormControl = new FormControl();
    public filterResponsavel: FormControl = new FormControl();

    get idsCotacao() {
        return this._cotacaoEditar;
    }

    @Input() set idsCotacao(cotacaoEditar: CotacaoEditar) {
        const idsCotacoes = cotacaoEditar.idCotacoes;
        this._cotacaoEditar = cotacaoEditar;
        if (idsCotacoes && idsCotacoes.length > 0) {
            this.isView = true;
        }
    }

    constructor(
        private _injector: Injector,
        private _statusService: CotacaoStatusService,
        private _statusFinanceiroService: StatusFinanceiroService,
        private _tipoCobrancaService: TipoCobrancaService,
        private _usuarioService: UsuarioService,
        private _service: CotacaoEdicaoService,
    ) {
        super(_injector);
    }

    ngOnInit() {
        this._spinner.show();
        this._statusService.find({}).subscribe(
            (result) => {
                this.listaCotacaoStatus = result.data;
                this._spinner.hide();
            },
            (error) => {
                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
        this._statusFinanceiroService.find({}).subscribe(
            (result) => {
                this.listaStatusFinanceiro = result.data;
                this._spinner.hide();
            },
            (error) => {
                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
        this._tipoCobrancaService.find({}).subscribe(
            (result) => {
                this.listaTipoCobranca = result.data;
                this._spinner.hide();
            },
            (error) => {
                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
        this._usuarioService.find({ativo: 1,  tipo: 1}).subscribe(
            (result) => {
                this.listaUsuarios = result.data;
                this._listaUsuarios = result.data;

                this.listaResponsavel =  result.data;
                this._listaResponsavel = result.data;
                this._spinner.hide();
            },
            (error) => {
                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
    }

    filterSquadOptions(search: string) {        
        if (search === '' || search == undefined) {
            this.listaUsuarios = this._listaUsuarios;
        } else {
            this.listaUsuarios = [];
            this._listaUsuarios.forEach(x => {                
                if (x.nome.includes(search)) {
                    this.listaUsuarios.push(x);
                }

                if (x.nome.toLowerCase().includes(search)) {
                    this.listaUsuarios.push(x);
                }
            });            
        }
    }

    filterResponsavelCotacao(search: string) {
        if (search === '' || search == undefined) {
            this.listaResponsavel = this._listaResponsavel;
        } else {
            this.listaResponsavel = [];
            this._listaResponsavel.forEach(x => {                
                if (x.nome.includes(search)) {
                    this.listaResponsavel.push(x);
                }

                if (x.nome.toLowerCase().includes(search)) {
                    this.listaResponsavel.push(x);
                }
            });            
        }
    }


    selecionaCheckCotacaoStatus(check) {
        this.checkCotacaoStatus = check;
        if (!check) {
            this.form.get('statusCotacao').setValue('');
        }
    }
    selecionaCheckStatusFinanceiro(check) {
        this.checkStatusFinanceiro = check;
        if (!check) {
            this.form.get('statusFinanceiro').setValue('');
        }
    }
    selecionaCheckTipoCobranca(check) {
        this.checkTipoCobranca = check;
        if (!check) {
            this.form.get('tipoCobranca').setValue('');
        }
    }
    selecionaCheckSquad(check) {
        this.checkSquad = check;
        if (!check) {
            this.form.get('squad').setValue('');
        }
    }

    selecionaCheckResponsavel(check) {
        this.checkResponsavel = check;
        if (!check) {
            this.form.get('idResponsavel').setValue('');
        }
    }

    selecionaTodos(check) {
        if (!check) {
            this.form.reset();
        }
        this.checkCotacaoStatus = check;
        this.checkStatusFinanceiro = check;
        this.checkTipoCobranca = check;
        this.checkSquad = check;
        this.checkResponsavel = check;
    }

    isDesabled(chave: string) {
        switch (chave) {
            case 'checkCotacaoStatus':
                return !this.checkCotacaoStatus;
                break;
            case 'checkStatusFinanceiro':
                return !this.checkStatusFinanceiro;
                break;
            case 'checkTipoCobranca':
                return !this.checkTipoCobranca;
                break;
            case 'checkTipoSquad':
                return !this.checkSquad;
                break;
            case 'checkResponsavel':
                return !this.checkResponsavel;
                break;
        }
    }

    alterar() {
        const responsavel = this.form.get('idResponsavel').value;
        this._cotacaoEditar = Object.assign({}, this._cotacaoEditar, {
            idCotacaoStatus: this.form.get('statusCotacao').value,
            idStatusFinanceiro: this.form.get('statusFinanceiro').value,
            idTipoCobranca: this.form.get('tipoCobranca').value,
            idUsuariosSquad: this.form.get('squad').value,
            idUsuarioResponsavel: (responsavel != null) ? responsavel.id: responsavel
        });        
        if (this._cotacaoEditar.idCotacaoStatus) {
            this._confirmation
                .confirm({
                    titulo:
                        'Tem certeza que deseja continuar com a alteração do Status da Cotação?',
                    mensagem:
                        'Esta alteração impacta nos Projetos relacionados.',
                })
                .subscribe((result) => {
                    if (result) {
                        if (this._cotacaoEditar.idUsuariosSquad) {
                            this._confirmation
                            .confirm({
                                titulo:
                                    'Tem certeza que deseja continuar com a alteração do Squad?',
                                mensagem:
                                    'Ao selecionar usuários na edição em lote, os dados dos usuários previamente cadastrados na(s) cotação(ões) serão substituídos.',
                            })
                            .subscribe((result) => {
                                if (result) {
                                    this.alterarExec();
                                }
                            });
                        } else {
                            this.alterarExec();
                        }
                    }
                });
        } else if (this._cotacaoEditar.idUsuariosSquad) {
            this._confirmation
                .confirm({
                    titulo:
                        'Tem certeza que deseja continuar com a alteração do Squad?',                   
                    mensagem:
                        'Ao selecionar usuários na edição em lote, os dados dos usuários previamente cadastrados na(s) cotação(ões) serão substituídos.',
                })
                .subscribe((result) => {
                    if (result) {
                        this.alterarExec();
                    }
                });
        } else {
            this.alterarExec();
        }
    }

    private alterarExec() {
        this._spinner.show();
        this._service.update(this._cotacaoEditar).subscribe(
            () => {
                this._spinner.hide();
                this._notification.sucess('Cotações alteradas com sucesso!');
                this.atualizado.emit(true);
            },
            (error) => {
                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
    }

    protected crateForm(): { [key: string]: any } {
        return {
            statusCotacao: [],
            statusFinanceiro: [],
            tipoCobranca: [],
            squad: [],
            idResponsavel: [],
        };
    }

    changed() {        
        let toppings = this.form.get('squad').value
        if (toppings.length <= 10) {
            this.mySelections = toppings;
        } else {
            this.form.get('squad').setValue(this.mySelections);
            this._notification.error('você não pode selecionar mais de 10 pessoas para Squad.');
        }
    }
}
