import { Time } from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnInit,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { OsAbertura } from 'app/main/os/shareds/interfaces/os-abertura.interface';
import { TabelaDominio } from 'app/main/tabela-dominio/shareds/interfaces/tabela-dominio.interface';
import { FiltroGmudConfiguracaoAprovacao } from '../../shared/interfaces/filtro-gmud-configuracao-aprovacao.interface';
import { GmudAmbiente } from '../../shared/interfaces/gmud-ambiente.interface';
import { GmudConfiguracaoAprovacao, GmudConfiguracaoAprovacaoRequest } from '../../shared/interfaces/gmud-configuracao-aprovacao.interface';
import { GmudProduto } from '../../shared/interfaces/gmud-produto.interface';
import { GmudConfiguracaoAprovacaoService } from '../../shared/services/gmud-configuracao-aprovacao.service';

@Component({
    selector: 'gmud-configurar-aprovacao',
    templateUrl: './gmud-configurar-aprovacao.component.html',
    styleUrls: ['./gmud-configurar-aprovacao.component.scss']
})
export class GmudConfigurarAprovacaoComponent extends GridBase<GmudConfiguracaoAprovacao, FiltroGmudConfiguracaoAprovacao, GmudConfiguracaoAprovacaoService> implements OnInit {
    listaGmudProdutos: Array<GmudProduto> = [];
    listaGmudAmbiente: Array<GmudAmbiente> = [];
    listaGmudQuantidadeAprovacao: Array<number> = [1, 2, 3];
    listaGmudTime: Array<Time> = [];

    public isEdit: boolean = false;
    public form: FormGroup;

    constructor(
        protected _gmudConfiguracaoAprovacaoService: GmudConfiguracaoAprovacaoService,
        protected _injector: Injector,
        protected _changeDetectorRef: ChangeDetectorRef,
    ) {
        super(_gmudConfiguracaoAprovacaoService, _injector, _changeDetectorRef);
        this.form = this._formBuilder.group(this.crateForm() || null);
    }

    ngOnInit() {
        this.initGrid(this.filtro || {})
        this._route.data.subscribe(data => {
            this.listaGmudTime = data.times;
            this.listaGmudAmbiente = data.ambientes;
            this.listaGmudProdutos = data.produtos;
        })
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'produto', label: '' },
            { chave: 'ambiente', label: '' },
            { chave: 'qtdeImp', label: '' },
            { chave: 'timeImp', label: '' },
            { chave: 'qtdeGmud', label: '' },
            { chave: 'timeGmud', label: '' },
        ];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            id: [],
            produto: ['', Validators.required],
            ambiente: ['', Validators.required],
            qtdeAprovacaoImp: ['', Validators.required],
            timeAprovadorImp: ['', Validators.required],
            qtdeAprovacaoGmud: ['', Validators.required],
            timeAprovadorGmud: ['', Validators.required],
        };
    }
    private getForm(): GmudConfiguracaoAprovacaoRequest {
        const id = this.form.get('id').value;
        const impQtdeAprovacao = this.form.get('qtdeAprovacaoImp').value;
        const gmudQtdeAprovacao = this.form.get('qtdeAprovacaoGmud').value;
        const gmudProduto = this.form.get('produto').value;
        const gmudAmbiente = this.form.get('ambiente').value;
        const timeImp = this.form.get('timeAprovadorImp').value;
        const timeGmud = this.form.get('timeAprovadorGmud').value;

        return {
            id,
            impQtdeAprovacao,
            gmudQtdeAprovacao,
            gmudProduto: gmudProduto.id || gmudProduto,
            gmudAmbiente: gmudAmbiente.id || gmudAmbiente,
            timeImp: timeImp.id || timeImp,
            timeGmud: timeGmud.id || timeGmud,
        };
    }

    limparForm() {
        this.isEdit = false;
        this.form.reset();
    }

    adicionar() {
        this._spinner.show();
        this._gmudConfiguracaoAprovacaoService.save(this.getForm()).subscribe(
            () => {
                this.sucess('Configuração criada com sucesso');
                this.atualizarGrid();
                this.limparForm();
            },
            (error) => this.error(error)
        );
    }

    atualizar() {
        this._spinner.show();
        const gmudConfiguracaoAprovacao = this.getForm();
        this._gmudConfiguracaoAprovacaoService.update(gmudConfiguracaoAprovacao).subscribe(
            () => {
                this.sucess('Configuração atualizada com sucesso');
                this.atualizarGrid();
                this.limparForm();
            },
            (error) => this.error(error)
        );
    }

    remover(gmudConfiguracaoAprovacao: GmudConfiguracaoAprovacao) {
        this._confirmation
            .confirm({
                titulo: 'Deseja excluir a configuração?',
                mensagem: 'Ao remover a configuração o mesmo não ficará mais disponível',
            })
            .subscribe((result) => {
                if (result) {
                    this._gmudConfiguracaoAprovacaoService.delete(gmudConfiguracaoAprovacao).subscribe(
                        () => {
                            this.atualizarGrid();
                            this.sucess('Configuração removida com sucesso!');
                            this.limparForm();
                        },
                        (error) => {
                            this.error(error);
                        }
                    );
                }
            });
    }

    editar(gmudConfiguracaoAprovacao: GmudConfiguracaoAprovacao) {
        this.isEdit = true;

        setTimeout(() => {
            const gmudProduto = gmudConfiguracaoAprovacao.gmudProduto ? gmudConfiguracaoAprovacao.gmudProduto : {};
            const gmudAmbiente = gmudConfiguracaoAprovacao.gmudAmbiente ? gmudConfiguracaoAprovacao.gmudAmbiente : {};
            const timeImp = gmudConfiguracaoAprovacao.timeImp ? gmudConfiguracaoAprovacao.timeImp : {};
            const timeGmud = gmudConfiguracaoAprovacao.timeGmud ? gmudConfiguracaoAprovacao.timeGmud : {};

            this.form.patchValue({
                id: gmudConfiguracaoAprovacao.id,
                produto: gmudProduto,
                ambiente: gmudAmbiente,
                qtdeAprovacaoImp: gmudConfiguracaoAprovacao.impQtdeAprovacao,
                timeAprovadorImp: timeImp,
                qtdeAprovacaoGmud: gmudConfiguracaoAprovacao.gmudQtdeAprovacao,
                timeAprovadorGmud: timeGmud,
            });
        }, 300);
    }

    selectedOption(o1: any, o2: any) {
        if (o2) {
            return o1 == o2.id;
        }
    }

    selectedOptionValue(o1: any, o2: any) {
        if (o2) {
            return o1 == o2;
        }
    }
}
