import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FaturamentoProduto } from '../interfaces/faturamento-produto.interface';
import { FaturamentoProdutoService } from '../services/faturamento-produto.service';

@Injectable()
export class FaturamentoProdutoListaResolver implements Resolve<FaturamentoProduto> {
    constructor(private _service: FaturamentoProdutoService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let id = route.params['id']
        return this._service.find({ idFaturamento: id }).pipe(map((result) => result.data));
    }
}
