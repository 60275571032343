import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Injector,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Faturamento } from '../../shared/interfaces/faturamento.interface';
import { FaturamentoService } from '../../shared/services/faturamento.service';

@Component({
    selector: 'faturamento-visualizar-detalhe',
    templateUrl: './faturamento-visualizar-detalhe.component.html',
    styleUrls: ['./faturamento-visualizar-detalhe.component.scss'],
})
export class FaturamentoVisualizarDetalheComponent extends GridBase<Faturamento, FiltroBase, FaturamentoService> implements OnInit {

    constructor(
        protected _injector: Injector,
        protected _faturamentoService: FaturamentoService,
        protected _changeDetectorRefs: ChangeDetectorRef,
    ) {
        super(_faturamentoService, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void {
    }

    protected crateForm(): { [key: string]: any } {
        return {
        };
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return []
    }
}

