import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalChecklistConfiguracaoArquivoComponent } from '../../modal/modal-checklist-configuracao-arquivo/modal-checklist-configuracao-arquivo.component';
import { ChecklistConfiguracaoArquivo } from '../interfaces/checklist-configuracao-arquivo.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistConfiguracaoArquivoModalService {
    constructor(public dialog: MatDialog) {}

    checklistArquivo(arquivo: ChecklistConfiguracaoArquivo, isView?: boolean) {
        isView = isView ? isView : false;
        const dialogRef = this.dialog.open(
            ModalChecklistConfiguracaoArquivoComponent,
            {
                width: '90%',
                data: {
                    arquivo,
                    isView,
                },
            }
        );

        return dialogRef.afterClosed();
    }
}
