import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChecklistDadosEmpresa } from '../interfaces/checklist-dados-empresa.interface';
import { ChecklistDadosEmpresaService } from '../services/checklist-dados-empresa.service';

@Injectable()
export class ChecklistDadosEmpresaListaResolver
    implements Resolve<ChecklistDadosEmpresa> {
    constructor(
        private _checklistDadosEmpresaService: ChecklistDadosEmpresaService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        return this._checklistDadosEmpresaService
            .findByChecklist(id)
            .pipe(map((result) => result.data));
    }
}
