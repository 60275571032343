import { EventEmitter, Injector, Input, Output } from "@angular/core";
import { FormBase } from "@utils/base/form-base/form-base";
import { ModelBase } from "@utils/base/model-base/model-base";

export abstract class ChecklistConectividadeBase<T extends ModelBase> extends FormBase {

    @Input() isView: boolean = false;
    @Output() values: EventEmitter<T> = new EventEmitter<T>()

    public _data: T;
    get data(): T {
        return this._data;
    }

    @Input()
    set data(val: T) {
        if (val) {
            this._data = val;
            this.setForm(val);
        }
    }

    constructor(
        protected _injector: Injector
    ) {
        super(_injector)
    }

    protected abstract setForm(data: T)
}