import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cpfcnpj',
})
export class CpfcnpjPipe implements PipeTransform {
    transform(value: string, zenkaku: boolean = true): string {
        if (!value) {
            return '';
        }
        if (value.length < 10) {
            return value;
        }
        if (value.length == 11) {
            return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(
                6,
                3
            )}-${value.substr(-2)}`;
        } else if (value.length == 14) {
            return `${value.substr(0, 2)}.${value.substr(2, 3)}.${value.substr(
                5,
                3
            )}/${value.substr(8, 4)}-${value.substr(12)}`;
        }
    }
}
