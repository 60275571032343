import {
    ChangeDetectorRef,
    Component,
    Injector,
    EventEmitter,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Projeto } from 'app/main/projeto/shareds/interfaces/projeto.interface';
import { ProjetoService } from 'app/main/projeto/shareds/services/projeto.service';
import { ProjetoPesquisa } from '../../shareds/interfaces/projeto-pesquisa.interface';
import { ProjetoPesuisaService } from '../../shareds/services/projeto-pesquisa.service';

@Component({
    selector: 'pesquisa-projeto',
    templateUrl: './pesquisa-projeto.component.html',
    styleUrls: ['./pesquisa-projeto.component.scss'],
})
export class PesquisaProjetoComponent
    extends GridBase<ProjetoPesquisa, FiltroBase, ProjetoPesuisaService>
    implements OnChanges, OnInit {
    @Output() selecionado = new EventEmitter();

    constructor(
        protected _service: ProjetoPesuisaService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {});
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'numeroCotacao', label: '' },
            { chave: 'checklist', label: '' },
            { chave: 'projeto', label: '' },
            { chave: 'empresa', label: '' },
            { chave: 'inscricao', label: '' },
            { chave: 'produto', label: '' },
            { chave: 'modulo', label: '' },
            { chave: 'seleciona', label: '' },
        ];
    }

    selecionar(projeto: Projeto) {
        this.selecionado.emit(projeto);
    }
}
