import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { Os } from '../interfaces/os.interface';
import { FiltroOs } from '../interfaces/filtro-os.interface';
import { Cotacao } from 'app/main/cotacao/shared/interfaces/cotacao.interface';
import { OsContato } from '../interfaces/os-contato.interface';
import { OsAlerta } from '../interfaces/os-alerta.interface';

@Injectable({
    providedIn: 'root',
})
export class OsService extends ServiceGridBase<Os, FiltroOs> {
    private path: string = `${environment.server}/os`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroOs): Observable<Response<Array<Os>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/busca${params}`;
        return this._http.get<Response<Array<Os>>>(url);
    }

    findById(id: number | string): Observable<Response<Os>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Os>>(url);
    }

    findCotacao(idOs: number): Observable<Response<Cotacao>> {
        const url = `${this.path}/${idOs}/cotacao`;
        return this._http.get<Response<Cotacao>>(url);
    }

    save(obj: Os): Observable<Response<Os>> {
        if (obj.id) {
            return this.update(obj.id, obj);
        } else {
            return this.create(obj);
        }
    }

    create(obj: Os): Observable<Response<Os>> {
        const url = `${this.path}/manual`;
        return this._http.post<Response<Os>>(url, obj);
    }

    update(idOs: number, obj: Os): Observable<Response<Os>> {
        const url = `${this.path}/${idOs}`;
        return this._http.put<Response<Os>>(url, obj);
    }

    delete(idOs: number): Observable<void> {
        const url = `${this.path}/${idOs}`;
        return this._http.delete<void>(url);
    }

    clonar(obj: Os): Observable<Response<Os>> {
        const url = `${environment.server}/os-clonar/${obj.id}`;
        return this._http.post<Response<Os>>(url, obj);
    }

    gerarRelatorio(filtro: FiltroOs) {
        console.log(filtro);
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${environment.server}/relatorio/os${params}`;
        return this._http.get<Response<any>>(url);
    }

    findNoficao(idOs: number) {
        const url = `${this.path}/${idOs}/notificacao`;
        return this._http.get<Response<any>>(url);
    }

    resendNotifications(idOs: number, body: { enviarParaOBanco: boolean, enviarParaOCliente: boolean, notificacoes: number[] }) {
        const url = `${this.path}/${idOs}/notificacao`;
        return this._http.patch<Response<any>>(url, body); 
    }
    getContatos(os: Os) {
        const url = `${environment.server}/os/${os.id}/contato`;
        return this._http.get<Response<OsContato[]>>(url);
    }

    excluirContato(os: Os, id: number) {
        const url = `${environment.server}/os/${os.id}/contato/${id}`;
        return this._http.delete<Response<void>>(url);
    }

    criarContato(os: Os, contato: OsContato) {
        const url = `${environment.server}/os/${os.id}/contato`;
        return this._http.post<Response<OsContato>>(url, contato)
    }

    atualizarContato(os: Os, contato: OsContato) {
        const url = `${environment.server}/os/${os.id}/contato/${contato.id}`;
        return this._http.put<Response<OsContato>>(url, contato)
    }

    getListaAlerta(id: number): Observable<Response<Array<OsAlerta>>> {
        const url = `${environment.server}/os-lista-alerta-email?idOs=${id}`;
        return this._http.get<Response<Array<OsAlerta>>>(url);
    }
}
