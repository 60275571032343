import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Permissao } from '../shareds/interfaces/permissao.interface';
import { PermissaoService } from '../shareds/services/permissao.service';

@Injectable()
export class PermissaoListaResolver implements Resolve<Permissao> {
    constructor(private _permissaoService: PermissaoService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._permissaoService.find().pipe(map((result) => result.data));
    }
}
