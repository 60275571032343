import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FiltroContrato } from '../interfaces/filtro-contrato.interface';
import { Contrato } from '../interfaces/contrato.interface';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { TabelaDominio } from 'app/main/tabela-dominio/shareds/interfaces/tabela-dominio.interface';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';

@Injectable({
    providedIn: 'root',
})
export class ContratoDominioService extends ServiceGridBase<
    TabelaDominio,
    FiltroBase
> {
    private path = `${environment.server}/tabelaDominio`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroBase): Observable<Response<Array<TabelaDominio>>> {
        const params: string = this.toGetParams(filtro);
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<TabelaDominio>>>(url);
    }

    findById(id: number | string): Observable<Response<TabelaDominio>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<TabelaDominio>>(url);
    }

    findFinnetCNPJ(): Observable<Response<TabelaDominio[]>> {
        const url = `${this.path}/FinnetCNPJ`;
        return this._http.get<Response<TabelaDominio[]>>(url);
    }

    findIndiceReajuste(): Observable<Response<TabelaDominio[]>> {
        const url = `${environment.server}/tabelareajuste`;
        return this._http.get<Response<TabelaDominio[]>>(url);
    }

    findAutorizacaoUsoMarca(): Observable<Response<TabelaDominio[]>> {
        const url = `${this.path}/autorizacaousomarca`;
        return this._http.get<Response<TabelaDominio[]>>(url);
    }

    findAutorizacaoBloqueio(): Observable<Response<TabelaDominio[]>> {
        const url = `${this.path}/autorizacaobloqueio`;
        return this._http.get<Response<TabelaDominio[]>>(url);
    }

    findAutorizaProtesto(): Observable<Response<TabelaDominio[]>> {
        const url = `${this.path}/autorizacaoprotesto`;
        return this._http.get<Response<TabelaDominio[]>>(url);
    }

    findTipoCobranca(): Observable<Response<TabelaDominio[]>> {
        const url = `${this.path}/tipocobranca`;
        return this._http.get<Response<TabelaDominio[]>>(url);
    }

    findMultaRescisoria(): Observable<Response<TabelaDominio[]>> {
        const url = `${this.path}/contratomultarescisoria`;
        return this._http.get<Response<TabelaDominio[]>>(url);
    }

    findLimitacaoResponsabilidade(): Observable<Response<TabelaDominio[]>> {
        const url = `${this.path}/contratolimitacaoresponsabilidade`;
        return this._http.get<Response<TabelaDominio[]>>(url);
    }
}
