import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    ActivatedRoute,
    Router,
} from '@angular/router';
import { ChecklistService } from './shared/services/checklist.service';

@Injectable()
export class ChecklistDetalheGuard implements CanActivate {
    constructor(
        private _checklistService: ChecklistService,
        private _router: Router
    ) {}

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        const idChecklist = route.params.id;

        const checklistData = await this._checklistService
            .findById(idChecklist)
            .toPromise();
        const checklist = checklistData.data;

        if (!checklist.finalizadoProdutos) {
            this._router.navigate(['checklist']);
            return false;
        }

        return true;
    }
}
