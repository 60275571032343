import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { BancoHistorico } from '../../shareds/interfaces/banco-historico.interface';
import { Banco } from '../../shareds/interfaces/banco.interface';
import { BancoHistoricoService } from '../../shareds/services/banco-historico.service';
import { BancoService } from '../../shareds/services/banco.service';

@Component({
  selector: 'banco-historico-detalhe',
  templateUrl: './banco-historico-detalhe.component.html',
  styleUrls: ['./banco-historico-detalhe.component.scss']
})
export class BancoHistoricoDetalheComponent implements OnInit {

  @Input() historico: BancoHistorico = {};

  constructor(
    protected _service: BancoHistoricoService,
    protected _injector: Injector,
    protected _changeDetectorRefs: ChangeDetectorRef
  ) { }

  ngOnInit(): void { }

  ngOnChanges(): void { }

}
