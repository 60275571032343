import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { ChecklistHistorico } from '../../shared/interfaces/checklist-historico.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { FiltroChecklistHistorico } from '../../shared/interfaces/filtro-checklist-historico.interface';
import { ChecklistHistoricoService } from '../../shared/services/checklist-historico.service';
import { ChecklistModalService } from '../../shared/services/checklist-modal.service';

@Component({
    selector: 'checklist-historico',
    templateUrl: './checklist-historico.component.html',
    styleUrls: ['./checklist-historico.component.scss'],
})
export class ChecklistHistoricoComponent
    extends GridBase<
    ChecklistHistorico,
    FiltroChecklistHistorico,
    ChecklistHistoricoService
    >
    implements OnChanges, OnInit {
    private _checklist: Checklist;
    get checklist(): Checklist {
        return this._checklist;
    }
    @Input() set checklist(checklist: Checklist) {
        this._checklist = checklist;
        this.filtro.idChecklist = checklist.id;
        this.initGrid(this.filtro || {});
    }

    constructor(
        protected _service: ChecklistHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _modalChecklistHistoricoService: ChecklistModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void { }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'data', label: '' },
            { chave: 'item', label: '' },
            { chave: 'acao', label: '' },
            { chave: 'usuario', label: '' },
        ];
    }

    selecionar(checklistHistorico: ChecklistHistorico) {
        this._modalChecklistHistoricoService
            .historicoDetalhe(checklistHistorico)
            .subscribe(() => { });
    }
}
