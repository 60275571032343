import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfiguracaoRoutingModule } from './configuracao-routing.module';
import { ConfiguracaoPageComponent } from './pages/configuracao-page/configuracao-page.component';
import { ConfiguracaoComponent } from './components/configuracao/configuracao.component';
import { ConfiguracaoGuard } from './configuracao.guard';
import { UtilsModule } from '@utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatRadioModule, MatSelectModule, MatSlideToggleModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ConfiguracaoDetalheResolver } from './shareds/resolvers/configuracao-detalhe.resolver';
import { ConfiguracaoService } from './shareds/services/configuracao.service';


@NgModule({
  declarations: [ConfiguracaoPageComponent, ConfiguracaoComponent],
  imports: [
    CommonModule,
    ConfiguracaoRoutingModule,
    UtilsModule,

    // FORMULARIO
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatRadioModule,
    NgxMatSelectSearchModule,
  ],
  providers: [
    ConfiguracaoGuard,
    ConfiguracaoDetalheResolver,
    ConfiguracaoService
  ]
})
export class ConfiguracaoModule { }
