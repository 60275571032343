import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { GmudHistoricoAprovacao } from '../../shared/interfaces/gmud-historico-aprovacao.interface';
import { GmudHistoricoAprovacaoService } from '../../shared/services/gmud-historico-aprovacao.service';

@Component({
  selector: 'gmud-aba-aprovacao',
  templateUrl: './gmud-aba-aprovacao.component.html',
  styleUrls: ['./gmud-aba-aprovacao.component.scss']
})
export class GmudAbaAprovacaoComponent implements OnInit {
  gmudHistoricoAprovacao: GmudHistoricoAprovacao = {}
  constructor(
    private _route: ActivatedRoute,
    private _spinner: SpinnerService,
    private _gudHistoricoAprovacaoService: GmudHistoricoAprovacaoService
  ) { }

  ngOnInit() {
    this._route.data.subscribe(data => {
      if (data.gmud) {
        this._spinner.show();
        this._gudHistoricoAprovacaoService.findByGmud(data.gmud.id).subscribe(result => {
          this.gmudHistoricoAprovacao = result
          this._spinner.hide();
        })
      }
    })
  }

}
