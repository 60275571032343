import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { CotacaoModulo } from '../interfaces/cotacao-modulo.interface';
import { FiltroCotacaoModulo } from '../interfaces/filtro-cotacao-modulo.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';

@Injectable({
    providedIn: 'root',
})
export class CotacaoModuloService extends ServiceBase<CotacaoModulo> {
    private path = `${environment.server}/cotacao`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro: FiltroCotacaoModulo
    ): Observable<Response<Array<CotacaoModulo>>> {
        const params: string = this.toGetParams(filtro);
        const url = `${this.path}/${filtro.idCotacao}/modulo${params}`;
        return this._http.get<Response<Array<CotacaoModulo>>>(url);
    }
}
