import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupEnterpriseGuard extends BaseGuard {
  protected configPermissions(): GuardConfigPermission[] {
    return [
      { path: '/grupo-empresas', role: Role.GRUPO_EMPRESA_LISTAR },
      { path: '/grupo-empresas/cadastro', role: Role.GRUPO_EMPRESA_CADASTRAR },
      { path: '/grupo-empresas/cadastro/[0-9]+/visualizar', role: Role.GRUPO_EMPRESA_EXIBIR },
      { path: '/grupo-empresas/cadastro/[0-9]+/editar', role: Role.GRUPO_EMPRESA_ALTERAR },
      { path: '/grupo-empresas/cadastro/[0-9]+/associar', role: Role.GRUPO_EMPRESA_ASSOCIAR },
    ];
  }
}
