import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { Faturamento } from '../interfaces/faturamento.interface';
import { FiltroFaturamento } from '../interfaces/filtro-faturamento.interface';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { FaturamentoEditar } from '../interfaces/faturamento-editar.interface';

@Injectable({
    providedIn: 'root',
})
export class FaturamentoParcelaService extends ServiceGridBase<Faturamento, FiltroFaturamento> {
    private path = `${environment.server}/faturamento/parcelas`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(id: any): Observable<Response<Array<Faturamento>>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Array<Faturamento>>>(url);
    }

}
