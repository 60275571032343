import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Time } from '../../shareds/interfaces/time.interface';

@Component({
  selector: 'modal-time-pesquisa',
  templateUrl: './modal-time-pesquisa.component.html',
  styleUrls: ['./modal-time-pesquisa.component.scss']
})
export class ModalTimePesquisaComponent implements OnInit {
  tituloModal: string = 'Selecione o time';

  constructor(
      public dialogRef: MatDialogRef<ModalTimePesquisaComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {}

  selecionado(time: Time) {
      this.dialogRef.close(time);
  }

  fechar(): void {
      this.dialogRef.close();
  }
}
