import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChecklistConectividade } from '../interfaces/checklist-conectividade.interface';
import { ChecklistConectividadeService } from '../services/checklist-conectividade.service';

@Injectable()
export class ChecklistConectividadeListaResolver
    implements Resolve<ChecklistConectividade> {
    constructor(
        private _service: ChecklistConectividadeService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        return this._service
            .find({ idCotacao: id })
            .pipe(map((result) => result.data));
    }
}
