import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Faturamento } from '../interfaces/faturamento.interface';
import { ModalHistoricoFaturamentoComponent } from '../../modal/modal-historico-faturamento/modal-historico-faturamento.component';
import { FaturamentoHistorico } from '../interfaces/faturamento-historico.interface';
import { ModalHistoricoDetalheFaturamentoComponent } from '../../modal/modal-historico-detalhe-faturamento/modal-historico-detalhe-faturamento.component';
import { FaturamentoEditar } from '../interfaces/faturamento-editar.interface';
import { ModalEditarFaturamentoComponent } from '../../modal/modal-editar-faturamento/modal-editar-faturamento.component';

@Injectable({
    providedIn: 'root',
})
export class FaturamentoModalService {
    constructor(public dialog: MatDialog) {}

    faturamentoEditar(data: FaturamentoEditar) {
        const dialogRef = this.dialog.open(ModalEditarFaturamentoComponent, {
            width: '50%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    historico(data: Faturamento) {
        const dialogRef = this.dialog.open(ModalHistoricoFaturamentoComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    historicoDetalhe(data: FaturamentoHistorico) {
        const dialogRef = this.dialog.open(
            ModalHistoricoDetalheFaturamentoComponent,
            {
                width: '90%',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }
    
}
