import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Empresa } from '../../shareds/interfaces/empresa.interface';

@Component({
    selector: 'modal-empresa-detalhe',
    templateUrl: './modal-empresa-detalhe.component.html',
    styleUrls: ['./modal-empresa-detalhe.component.scss'],
})
export class ModalEmpresaDetalheComponent implements OnInit {
    tituloModal: string = 'Empresa';

    constructor(
        public dialogRef: MatDialogRef<ModalEmpresaDetalheComponent>,
        @Inject(MAT_DIALOG_DATA) public empresa: Empresa
    ) {}
    ngOnInit() {}

    fechar(): void {
        this.dialogRef.close();
    }
}
