import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'faturamento-recorrente-cadastro',
  templateUrl: './faturamento-recorrente-cadastro.component.html',
  styleUrls: ['./faturamento-recorrente-cadastro.component.scss']
})
export class FaturamentoRecorrenteCadastroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
