import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Gmud } from '../../shared/interfaces/gmud.interface';

@Component({
  selector: 'gmud-cadastro-page',
  templateUrl: './gmud-cadastro-page.component.html',
  styleUrls: ['./gmud-cadastro-page.component.scss']
})
export class GmudCadastroPageComponent implements OnInit {

  gmud: Gmud

  constructor(private _route: ActivatedRoute) {
  }

  ngOnInit() { }
}
