import {
    Component,
    Injector,
    OnInit,
    Output,
    EventEmitter,
} from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { Banco } from 'app/main/banco/shareds/interfaces/banco.interface';
import { Configurador } from 'app/main/configurador/shareds/interfaces/configurador.interface';
import { FiltroProjetoAbertura } from '../../shareds/interfaces/filtro-projeto-abertura.interface';
import { ProjetoStatus } from '../../shareds/interfaces/projeto-status.interface';

@Component({
    selector: 'projeto-abertura-grid-filtro',
    templateUrl: './projeto-abertura-grid-filtro.component.html',
    styleUrls: ['./projeto-abertura-grid-filtro.component.scss'],
})
export class ProjetoAberturaGridFiltroComponent
    extends FormFiltroBase<FiltroProjetoAbertura>
    implements OnInit {
    @Output() filtrar = new EventEmitter();
    _bancos: Array<Banco> = [];
    bancos: Array<Banco> = [];
    statuss: Array<ProjetoStatus> = [];
    configuracoes: Array<Configurador> = [];

    constructor(injector: Injector) {
        super('ProjetoAberturaGrid', injector, []);
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this._bancos = data.bancos;
            this.bancos = this._bancos;
            this.statuss = data.statuss;
            this.configuracoes = data.configurador;
        });
    }

    camposFiltro(): string[] {
        return [
            'status',
            'banco',
            'inscricao',
            'os',
            'agencia',
            'empresa',
            'conta',
            'responsavelOs',
            'convenio',
            'configurador'
        ];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            status: [],
            banco: [],
            inscricao: [],
            os: [],
            agencia: [],
            empresa: [],
            conta: [],
            responsavelOs: [],
            convenio: [],
            configurador: [],
            filterBancos: []
        };
    }

    filtrarExec(): void {
        const filtro: FiltroProjetoAbertura = {};
        filtro.idOsAberturaStatus = this.form.get('status').value
            ? this.form.get('status').value.id
            : '';
        filtro.idOs = this.form.get('os').value;
        filtro.idBanco = this.form.get('banco').value
            ? this.form.get('banco').value.id
            : '';
        filtro.agencia = this.form.get('agencia').value;
        filtro.conta = this.form.get('conta').value;
        filtro.convenio = this.form.get('convenio').value;
        filtro.empresaInscricao = this.form.get('inscricao').value;
        filtro.empresaNome = this.form.get('empresa').value;
        filtro.usuarioNome = this.form.get('responsavelOs').value;
        filtro.configurador = this.form.get('configurador').value;
        this.filtrar.emit(filtro);
    }

    filterBancos() {
        let search = this.form.get('filterBancos').value;
        this.bancos = this._bancos.filter(result => result['nome'].toLowerCase().indexOf(search) > -1);
        if (this.bancos.length == 0) {
            this.bancos = this._bancos;
        }
    }
}