import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { FiltroImportacaoOs } from '../interfaces/filtro-importacao-os.interfaces';
import { ImportacaoOs } from '../interfaces/importacao-os.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ImportacaoOsService extends ServiceGridBase<
ImportacaoOs,
FiltroImportacaoOs
> {

  private path = `${environment.server}/os-importar`;

  constructor(private _http: HttpClient) {
    super();
  }

  find(filtro: FiltroImportacaoOs): Observable<Response<ImportacaoOs[]>> {
    const params: string = this.toGetParams(filtro);
    const url = `${this.path}${params}`;
    return this._http.get<Response<Array<ImportacaoOs>>>(url);
  }

  findById(id: number | string): Observable<ImportacaoOs> {
    const url = `${this.path}/${id}`;
    return this._http.get<ImportacaoOs>(url);
  }

  upload(file: File): Observable<any> {
    const url = `${this.path}`;
    const formData = new FormData();
    formData.set('arquivo', file, file.name);

    return this._http.post(url, formData, {
      observe: 'events',
      reportProgress: true,
    });
  }

  download(importacaoOs: ImportacaoOs): Observable<any> {
    const url = `${this.path}/download/${importacaoOs.id}`;
    return this._http.get(url);
  }

  modeloDocumentoArquivo(): Observable<any> {
    const url = `${environment.server}/moduloDocumentoArquivo/download/3`;
    return this._http.get(url);
  }
}
