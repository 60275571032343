import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Perfil } from '../shareds/interfaces/perfil.interface';
import { PerfilService } from '../shareds/services/perfil.service';

@Injectable()
export class PerfilDetalheResolver implements Resolve<Perfil> {
    constructor(private _perfilService: PerfilService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        return this._perfilService
            .findById(id)
            .pipe(map((result) => result.data));
    }
}
