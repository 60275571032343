import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ProjetoAberturaHistorico } from '../../shareds/interfaces/projeto-abertura-historico.interface';
import { Projeto } from '../../shareds/interfaces/projeto.interface';

@Component({
    selector: 'modal-historico-detalhe-projeto-abertura',
    templateUrl: './modal-historico-detalhe-projeto-abertura.component.html',
    styleUrls: ['./modal-historico-detalhe-projeto-abertura.component.scss'],
})
export class ModalHistoricoDetalheProjetoAberturaComponent implements OnInit {
    tituloModal: string = 'Histórico';

    constructor(
        public dialogRef: MatDialogRef<ModalHistoricoDetalheProjetoAberturaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProjetoAberturaHistorico
    ) { }
    ngOnInit() { }

    selecionado(checklist: Projeto) {
        this.dialogRef.close(checklist);
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
