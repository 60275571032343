import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { FaturamentoGrid } from '../../components/faturamento-grid/faturamento-grid.component';
import { Faturamento } from '../interfaces/faturamento.interface';
import { FiltroFaturamento } from '../interfaces/filtro-faturamento.interface';

@Injectable({
  providedIn: 'root'
})
export class FaturamentoSetupRelatorioService extends ServiceGridBase<FaturamentoGrid, FiltroFaturamento> {

  private path = `${environment.server}/relatorio/faturamento-setup`;

  constructor(private _http: HttpClient) {
    super();
  }

  gerarRelatorio(filtro: FiltroBase) {
    const params: string = filtro ? this.toGetParams(filtro) : '';
    const url = `${this.path}${params}`;
    return this._http.get<Response<any>>(url);
  }

  find(filtro: FiltroFaturamento): Observable<Response<Faturamento[]>> {
    const params: string = this.toGetParams(filtro);
    const url = `${this.path}${params}`;
    return this._http.get<Response<any>>(url);
  }
}
