import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CotacaoRoutingModule } from './cotacao-routing.module';
import { CotacaoGridPageComponent } from './pages/cotacao-grid-page/cotacao-grid-page.component';
import { CotacaoDetalhePageComponent } from './pages/cotacao-detalhe-page/cotacao-detalhe-page.component';
import { CotacaoGridComponent } from './components/cotacao-grid/cotacao-grid.component';
import { CotacaoDetalheComponent } from './components/cotacao-detalhe/cotacao-detalhe.component';
import { CotacaoGuard } from './cotacao.guard';
import {
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
} from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@utils/utils.module';
import { CotacaoDetalheResolver } from './shared/resolvers/cotacao-detalhe.resolver';
import { CotacaoService } from './shared/services/cotacao.service';
import { CotacaoListaResolver } from './shared/resolvers/cotacao-lista.resolver';
import { CotacaoStatusListaResolver } from './shared/resolvers/cotacao-status-lista.resolver';
import { CotacaoModuloService } from './shared/services/cotacao-modulo.service';
import { CotacaoModuloChecklistListaResolver } from './shared/resolvers/cotacao-modulo-checklist-lista.resolver';
import { PesquisaCotacaoComponent } from './components/pesquisa-cotacao/pesquisa-cotacao.component';
import { ModalPesquisaCotacaoComponent } from './modal/modal-pesquisa-cotacao/modal-pesquisa-cotacao.component';
import { CotacaoGridFiltroComponent } from './components/cotacao-grid-filtro/cotacao-grid-filtro.component';
import { ModalEditarCotacaoComponent } from './modal/modal-editar-cotacao/modal-editar-cotacao.component';
import { StatusFinanceiroService } from './shared/services/status-financeiro.service';
import { TipoCobrancaService } from './shared/services/tipo-cobranca.service';
import { CotacaoModalService } from './shared/services/cotacao-modal.service';
import { EditarCotacaoComponent } from './components/editar-cotacao/editar-cotacao.component';
import { CotacaoStatusService } from './shared/services/cotacao-status.service';
import { StatusFinanceiroListaResolver } from './shared/resolvers/status-financeiro-lista.resolver';
import { TipoCobrancaListaResolver } from './shared/resolvers/tipo-cobranca-lista.resolver';
import { CotacaoEdicaoService } from './shared/services/cotacao-edicao.service';
import { CotacaoClienteService } from './shared/services/cotacao-cliente.service';
import { CotacaoClienteContarResolver } from './shared/resolvers/cotacao-cliente-contar.resolver';
import { ModalHistoricoCotacaoComponent } from './modal/modal-historico-cotacao/modal-historico-cotacao.component';
import { CotacaoHistoricoDetalheComponent } from './components/cotacao-historico-detalhe/cotacao-historico-detalhe.component';
import { CotacaoHistoricoComponent } from './components/cotacao-historico/cotacao-historico.component';
import { CotacaoHistoricoService } from './shared/services/cotacao-historico.service';
import { ModalHistoricoDetalheCotacaoComponent } from './modal/modal-historico-detalhe-cotacao/modal-historico-detalhe-cotacao.component';
import { CotacaoContratoVincularComponent } from './components/cotacao-vincular-contrato/cotacao-vincular-contrato.component';
import { ModalContratoVincularComponent } from './modal/modal-contrato-vincular/modal-contrato-vincular.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ModalCotacaoBancoContratadoComponent } from './modal/modal-cotacao-banco-contratado/modal-cotacao-banco-contratado.component';
import { CotacaoEmpresaContratadaComponent } from './components/cotacao-banco-contratado/cotacao-banco-contratado.component';
import { ModalSquadCotacaoComponent } from './modal/modal-squad-cotacao/modal-squad-cotacao.component';
import { CotacaoSquadGridComponent } from './components/cotacao-squad-grid/cotacao-squad-grid.component';
import { CotacaoSquadService } from './shared/services/cotacao-squad.service';
import { CotacaoSquadResolver } from './shared/resolvers/cotacao-squad.resolver';

@NgModule({
    declarations: [
        // COMPONENTES
        CotacaoGridPageComponent,
        CotacaoDetalhePageComponent,
        CotacaoGridComponent,
        CotacaoDetalheComponent,
        PesquisaCotacaoComponent,
        CotacaoGridFiltroComponent,
        EditarCotacaoComponent,
        CotacaoHistoricoComponent,
        CotacaoHistoricoDetalheComponent,
        CotacaoContratoVincularComponent,
        CotacaoEmpresaContratadaComponent,

        // MODAIS
        ModalEditarCotacaoComponent,
        ModalPesquisaCotacaoComponent,
        ModalHistoricoCotacaoComponent,
        ModalHistoricoDetalheCotacaoComponent,
        ModalContratoVincularComponent,
        ModalPesquisaCotacaoComponent,
        ModalCotacaoBancoContratadoComponent,
        ModalSquadCotacaoComponent,
        CotacaoSquadGridComponent,
    ],
    imports: [
        CommonModule,
        CotacaoRoutingModule,


        UtilsModule,

        // TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,

        // FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatRadioModule,
        NgxMatSelectSearchModule,        
    ],
    providers: [
        CotacaoGuard,
        CotacaoDetalheResolver,
        CotacaoListaResolver,
        CotacaoStatusListaResolver,
        CotacaoService,
        CotacaoModuloService,
        CotacaoModuloChecklistListaResolver,
        StatusFinanceiroService,
        TipoCobrancaService,
        CotacaoModalService,
        CotacaoStatusService,
        StatusFinanceiroListaResolver,
        TipoCobrancaListaResolver,
        CotacaoEdicaoService,
        CotacaoClienteService,
        CotacaoClienteContarResolver,
        CotacaoHistoricoService,
        CotacaoSquadService,
        CotacaoSquadResolver,

    ],
    entryComponents: [
        ModalEditarCotacaoComponent,
        ModalPesquisaCotacaoComponent,
        ModalHistoricoCotacaoComponent,
        ModalHistoricoDetalheCotacaoComponent,
        ModalContratoVincularComponent,
        ModalCotacaoBancoContratadoComponent,
        ModalSquadCotacaoComponent,
    ],
})
export class CotacaoModule { }
