import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Projeto } from '../interfaces/projeto.interface';
import { ProjetoService } from '../services/projeto.service';

@Injectable()
export class ProjetoDetalheResolver implements Resolve<Projeto> {
    constructor(private _projetoService: ProjetoService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let id = route.params['id'];
        return this._projetoService
            .findById(id)
            .pipe(map((result) => result.data));
    }
}
