import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { TabelaDominio } from '../interfaces/tabela-dominio.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';

@Injectable({
    providedIn: 'root',
})
export class TabelaDominioService extends ServiceBase<TabelaDominio> {
    private path = `${environment.server}/tabelaDominio`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(chave: string): Observable<Response<Array<TabelaDominio>>> {
        const url = `${this.path}/${chave}`;
        return this._http.get<Response<Array<TabelaDominio>>>(url);
    }

}
