import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[FieldLetter]',
})
export class FieldLetter {
    constructor(private el: ElementRef) { }

    @Input() OnlyLetter: boolean = true;

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        let e = <KeyboardEvent>event;
        if (this.OnlyLetter) {
            var charCode = (e.charCode) ? e.charCode : ((e.keyCode) ? e.keyCode :
                ((e.which) ? e.which : 0));
            if (
                [32, 46].indexOf(e.keyCode) !== -1 ||
                // Allow: Ctrl+A
                (e.keyCode == 65 && e.ctrlKey === true) ||
                // Allow: Ctrl+C
                (e.keyCode == 67 && e.ctrlKey === true) ||
                // Allow: Ctrl+X
                (e.keyCode == 88 && e.ctrlKey === true) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)
            ) {
                return;
            }
            if (charCode > 31 && (charCode < 65 || charCode > 90)) {
                e.preventDefault();
            }
        }
    }
}
