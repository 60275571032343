import { Component, OnInit } from '@angular/core';
import { FiltroOs } from '../../shareds/interfaces/filtro-os.interface';

@Component({
  selector: 'os-grid-page',
  templateUrl: './os-grid-page.component.html',
  styleUrls: ['./os-grid-page.component.scss']
})
export class OsGridPageComponent implements OnInit {

  filtro: FiltroOs

  constructor() { }

  ngOnInit() {
  }

  filtrar(filtro: FiltroOs){
    this.filtro = Object.assign({}, filtro) 
  }
}
