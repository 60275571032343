import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { OsVincularRequest } from '../interfaces/os-vincularRequest.interface';
import { FiltroOsVincular } from '../interfaces/filtro-os-vincular.interface';
import { OsVincular } from '../interfaces/os-vincular.interface';

@Injectable({
    providedIn: 'root',
})
export class OsVincularService extends ServiceBase<OsVincular> {
    private path = `${environment.server}/projeto`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroOsVincular): Observable<Response<Array<OsVincular>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${environment.server}/os/buscar-vinculo${params}`;
        return this._http.get<Response<Array<OsVincular>>>(url);
    }

    findByIdProjeto(idProjeto: number) {
        const url = `${this.path}/${idProjeto}/oss`;
        return this._http.get<Response<Array<OsVincular>>>(url);
    }

    save(
        osVincular: OsVincularRequest
    ): Observable<Response<OsVincularRequest>> {
        const url = `${this.path}/${osVincular.projeto.id}/vincular-oss`;
        return this._http.put<Response<OsVincularRequest>>(url, osVincular);
    }
}
