export class CotacaoUtil {
    public static trataValorFaturado(valorFaturado: number | string) {
        let valor = valorFaturado + '';
        valor = valor.replace('.', ',');

        if (/[0-9]+\,[0-9]{2}/g.test(valor)) {
            return valor;
        } else if (/[0-9]+\,[0-9]{1}/g.test(valor)) {
            return valor + '0';
        } else if (/[0-9]+/g.test(valor)) {
            return valor + ',00';
        }
    }
}