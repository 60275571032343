import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Projeto } from '../../shareds/interfaces/projeto.interface';

@Component({
    selector: 'modal-historico-projeto',
    templateUrl: './modal-historico-projeto.component.html',
    styleUrls: ['./modal-historico-projeto.component.scss'],
})
export class ModalHistoricoProjetoComponent implements OnInit {
    tituloModal: string = 'Histórico';

    constructor(
        public dialogRef: MatDialogRef<ModalHistoricoProjetoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Projeto
    ) {}
    ngOnInit() {}

    selecionado(checklist: Projeto) {
        this.dialogRef.close(checklist);
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
