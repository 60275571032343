import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuracao } from '../interfaces/configuracao.interface';
import { ConfiguracaoService } from '../services/configuracao.service';

@Injectable()
export class ConfiguracaoDetalheResolver implements Resolve<Array<Configuracao>> {
    constructor(private _cotacaoService: ConfiguracaoService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        return this._cotacaoService
            .findConfiguracao()
            .pipe(map((result) => result.data));
    }
}
