import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';

@Component({
    selector: 'modal-squad-cotacao',
    templateUrl: './modal-squad-cotacao.component.html',
    styleUrls: ['./modal-squad-cotacao.component.scss'],
})
export class ModalSquadCotacaoComponent implements OnInit {
    tituloModal: string = 'Lista da Squad';

    constructor(
        public dialogRef: MatDialogRef<ModalSquadCotacaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any[]
    ) { }
    ngOnInit() { }

    fechar(): void {
        this.dialogRef.close();
    }

    atualizado(result) {
        this.dialogRef.close(result);
    }
}
