import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChecklistConectividade } from '../../shared/interfaces/checklist-conectividade.interface';
import { ChecklistContatoEmpresa } from '../../shared/interfaces/checklist-contato-empresa.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistDetalhe } from '../../shared/interfaces/checklist-detalhe.interface';
import { Direcao } from '../../shared/interfaces/direcao.interface';
import { ChecklistConfiguracaoArquivoModalService } from '../../shared/services/checklist-configuracao-arquivo-modal.service';
import { environment } from 'environments/environment';

@Component({
    selector: 'checklist-detalhe',
    templateUrl: './checklist-detalhe.component.html',
    styleUrls: ['./checklist-detalhe.component.scss'],
})
export class ChecklistDetalheComponent implements OnInit {
    checklist: Checklist;

    contatos: Array<ChecklistContatoEmpresa> = [];
    conectividade: ChecklistConectividade;
    configuracoes: Array<ChecklistDetalhe> = [];
    direcoes: Array<Direcao> = [];

    constructor(
        private _route: ActivatedRoute,
        private _modalArquivo: ChecklistConfiguracaoArquivoModalService,
        private _router: Router
    ) {}

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.checklist = data.checklist;
            this.contatos = data.contatos ? data.contatos.contatos : [];
            this.conectividade = data.conectividades.find((c) => c.selected);
            this.configuracoes = data.detalhe;
            this.direcoes = data.direcoes;
        });
    }

    getNomeDirecao(idDirecao): string {
        const direcao = this.direcoes.find((d) => d.id === idDirecao);
        return direcao ? direcao.nome : '';
    }

    getNomeDsname(dsnames: Array<any>): string {
        let nome = '';
        dsnames.forEach((d) => (nome += d.dsnameCampo.exemplo));
        return nome;
    }

    modalArquivo(idConfiguracao) {
        this._modalArquivo
            .checklistArquivo(
                {
                    idChecklist: this.checklist.id,
                    idChecklistConfiguracao: idConfiguracao,
                },
                true
            )
            .subscribe(() => {});
    }

    getConfiguracoes(config) {
        return config && config.configuracao
            ? config.configuracao.checklistConfiguracaoDsname
            : [];
    }

    abrirProjeto(idProjeto: number) {
        const link = `${environment.host}/projeto/visualizar/${idProjeto}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }
}
