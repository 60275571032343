import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { FiltroService } from '../../filtro.service';
import { FiltroSalvarDados } from '../../shareds/interfaces/fintro.interface';

@Component({
    selector: 'modal-salvar-configuracao-grid',
    templateUrl: './modal-salvar-configuracao-grid.component.html',
    styleUrls: ['./modal-salvar-configuracao-grid.component.scss']
})
export class ModalSalvarConfiguracaoGridComponent implements OnInit {
    tituloModal: string = 'Configurar visibilidade das colunas da grid';
    fields: any = {}

    constructor(
        public dialogRef: MatDialogRef<ModalSalvarConfiguracaoGridComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private filtroService: FiltroService,
        private _spinner: SpinnerService,
        private _notification: NotificationService
    ) { }

    ngOnInit() {
        this.filtroService.findByChave(this.data.chave).subscribe(result => {
            const filtroSalvarDados = result.data
            const campos = filtroSalvarDados.grid ? JSON.parse(filtroSalvarDados.grid) : undefined;
            const listaCampos: Array<any> = this.data.campos;

            if (campos) {
                Object.keys(campos).forEach((chave) => {
                    if (campos[chave] && listaCampos.findIndex(c => c.chave === chave) > -1) {
                        this.fields[chave] = true;
                    }
                });
            }
            else {
                listaCampos.forEach(campo => {
                    if (campo.chave && campo.label) {
                        this.fields[campo.chave] = true;
                    }
                })
            }
        })
    }

    salvar() {
        let i = Object.keys(this.fields).length
        if (i > 0) {
            i = 0
            Object.keys(this.fields).forEach(field => {
                this.fields[field] ? i++ : ''
            });
        }

        if (i == 0) {
            this._notification.warning("Selecione ao menos um campo para visualizar");
            return
        }

        const filtroSalvarDados: FiltroSalvarDados = {
            tela: this.data.chave,
            grid: this.fields ? JSON.stringify(this.fields) : ''
        }

        this._spinner.show();
        this.filtroService.save(filtroSalvarDados).subscribe(
            result => {
                this._notification.sucess('Dados salvos com sucesso!')
                this._spinner.hide();
                this.dialogRef.close(true);
            },
            error => {
                this._notification.error(error.error.message)
            })
    }

    fechar(): void {
        this.dialogRef.close();
    }

    selecionarTodos(): void {
        this.data.campos.forEach(campo => {
            if (campo.chave && campo.label) {
                this.fields[campo.chave] = true;
            }
        });
    }

    limparSelecao(): void {
        this.data.campos.forEach(campo => {
            if (campo.chave && campo.label) {
                this.fields[campo.chave] = false;
            }
        });
    }
}