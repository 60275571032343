import { ChangeDetectorRef, Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { BancoHistorico } from '../../shareds/interfaces/banco-historico.interface';
import { Banco } from '../../shareds/interfaces/banco.interface';
import { FiltroBancoHistorico } from '../../shareds/interfaces/filtro-banco-historico.interface';
import { BancoHistoricoService } from '../../shareds/services/banco-historico.service';
import { BancoModalService } from '../../shareds/services/banco-modal.service';

@Component({
  selector: 'banco-historico',
  templateUrl: './banco-historico.component.html',
  styleUrls: ['./banco-historico.component.scss']
})
export class BancoHistoricoComponent extends GridBase<
BancoHistorico,
FiltroBancoHistorico,
BancoHistoricoService
>
  implements OnChanges, OnInit {

  private _banco: Banco;
  get banco(): Banco {
    return this._banco;
  }

  @Input() set banco(banco: Banco) {
    this._banco = banco;
    this.filtro.idBanco = banco.id;
    this.initGrid(this.filtro || {});
  }

  constructor(
    protected _service: BancoHistoricoService,
    protected _injector: Injector,
    protected _changeDetectorRefs: ChangeDetectorRef,
    protected _modalBancoHistoricoService: BancoModalService
  ) {
    super(_service, _injector, _changeDetectorRefs);
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void { }

  configDisplayedColumns(): Array<GridCollumn> {
    return [
      { chave: 'seleciona' },
      { chave: 'data', label: '' },
      { chave: 'item', label: '' },
      { chave: 'acao', label: '' },
      { chave: 'usuario', label: '' },
    ];
  }

  selecionar(bancoHistorico: BancoHistorico) {
    this._modalBancoHistoricoService
      .historicoDetalhe(bancoHistorico)
      .subscribe(() => { });
  }

}
