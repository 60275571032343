import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { ContratoHistorico } from '../../shared/interfaces/contrato-historico.interface';
import { Contrato } from '../../shared/interfaces/contrato.interface';
import { FiltroContratoHistorico } from '../../shared/interfaces/filtro-contrato-historico.interface';
import { ContratoHistoricoService } from '../../shared/services/contrato-historico.service';
import { ContratoModalService } from '../../shared/services/contrato-modal.service';

@Component({
    selector: 'contrato-historico',
    templateUrl: './contrato-historico.component.html',
    styleUrls: ['./contrato-historico.component.scss'],
})
export class ContratoHistoricoComponent
    extends GridBase<
    ContratoHistorico,
    FiltroContratoHistorico,
    ContratoHistoricoService
    >
    implements OnChanges, OnInit {
    private _contrato: Contrato;
    get contrato(): Contrato {
        return this._contrato;
    }
    @Input() set contrato(contrato: Contrato) {
        this._contrato = contrato;
        this.filtro.idContrato = contrato.id;
        this.initGrid(this.filtro || {});
    }

    constructor(
        protected _service: ContratoHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _modalContratoHistoricoService: ContratoModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void { }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'data', label: '' },
            { chave: 'item', label: '' },
            { chave: 'acao', label: '' },
            { chave: 'usuario', label: '' },
        ];
    }

    selecionar(contratoHistorico: ContratoHistorico) {
        this._modalContratoHistoricoService
            .historicoDetalhe(contratoHistorico)
            .subscribe(() => { });
    }
}
