import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { ContratoVigencia } from '../interfaces/contrato-vigencia.interface';

@Injectable({
    providedIn: 'root',
})
export class ContratoAtualizarVigenciaService extends ServiceBase<ContratoVigencia> {
    private path = `${environment.server}/contratoatualizacaovigencia`;

    constructor(private _http: HttpClient) {
        super();
    }

    update(contratoVigencia: ContratoVigencia): Observable<Response<ContratoVigencia>> {
        const url = `${this.path}`;
        return this._http.put<Response<ContratoVigencia>>(url, contratoVigencia);
    }

}

