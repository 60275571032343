import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import { Role } from 'app/fuse-config/role';
import { EnumUtil } from './enum.util';

export class PermissaoUtil {
    public static isAccess(
        usuarioLogado: UsuarioLogado,
        roleStr: string | Role
    ): boolean {
        const role = this.verificaPermissao(roleStr);
        usuarioLogado = usuarioLogado ? usuarioLogado : {};
        usuarioLogado.permissoes = usuarioLogado.permissoes
            ? usuarioLogado.permissoes
            : [];
        return (
            usuarioLogado.permissoes.findIndex(
                (permission) => permission.nome === role
            ) >= 0
        );
    }

    private static verificaPermissao(role: string | Role): string {
        let val = EnumUtil.toArray(Role).find((r) => r === role);
        if (!val) {
            if (!Role[role]) {
                throw Error('Permissão não encontrada');
            }
            val = Role[role];
        }
        return val;
    }
}
