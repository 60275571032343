import { Component, Injector, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ModalBase } from '@utils/base/modal-base/modal-base';

@Component({
    selector: 'importacao-cotacao-upload-modal',
    templateUrl: './importacao-cotacao-upload-modal.component.html',
    styleUrls: ['./importacao-cotacao-upload-modal.component.scss'],
})
export class ImportacaoCotacaoUploadModalComponent
    extends ModalBase
    implements OnInit {
    tituloModal = 'Importar cotações';

    constructor(
        public dialogRef: MatDialogRef<ImportacaoCotacaoUploadModalComponent>,
        private _injector: Injector
    ) {
        super(_injector);
    }

    ngOnInit(): void {}

    fechar(): void {
        this.dialogRef.close();
    }
}
