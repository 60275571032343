import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Checklist } from '../interfaces/checklist.interface';
import { ChecklistDetalheService } from '../services/checklist-detalhe.service';

@Injectable()
export class ChecklistVisualizacaoDetalheResolver
    implements Resolve<Checklist> {
    constructor(private _checklistDetalheService: ChecklistDetalheService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        return this._checklistDetalheService
            .find(id)
            .pipe(map((result) => result.data));
    }
}
