import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Contrato } from '../../shared/interfaces/contrato.interface';

@Component({
    selector: 'modal-documento-contrato',
    templateUrl: './modal-documento-contrato.component.html',
    styleUrls: ['./modal-documento-contrato.component.scss'],
})
export class ModalDocumentoContratoComponent implements OnInit {
    tituloModal: string = 'Documentação';

    constructor(
        public dialogRef: MatDialogRef<ModalDocumentoContratoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Contrato
    ) { }
    ngOnInit() { }


    fechar(): void {
        this.dialogRef.close();
    }
}
