import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { Gmud } from '../interfaces/gmud.interface';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { GmudProduto } from '../interfaces/gmud-produto.interface';

@Injectable({
    providedIn: 'root',
})
export class GmudProdutoService extends ServiceGridBase<GmudProduto, FiltroBase> {
    private path = `${environment.server}/gmudprodutos`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroBase): Observable<Response<Array<GmudProduto>>> {
        const params: string = this.toGetParams(filtro);
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<GmudProduto>>>(url);
    }


}
