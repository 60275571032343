import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalEditarLoteFaturamentoRecorrenteComponent } from '../../modal/modal-editar-lote-faturamento-recorrente/modal-editar-lote-faturamento-recorrente.component';
import { FaturamentoRecorrenteEditarLote } from '../interfaces/faturamento-recorrente-editar-lote.interface';

@Injectable({
  providedIn: 'root'
})
export class FaturamentoModalRecorrenteService {

  constructor(public dialog: MatDialog) { }

  faturamentoEditar(data: FaturamentoRecorrenteEditarLote) {
    const dialogRef = this.dialog.open(ModalEditarLoteFaturamentoRecorrenteComponent, {
      width: '50%',
      data: data,
    });

    return dialogRef.afterClosed();
  }
}
