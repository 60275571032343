import { ChangeDetectorRef, Component, Injector, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FaturamentoRecorrenteItemProduto } from '../../shared/interfaces/faturamento-recorrente-item-produto.interface';
import { FaturamentoRecorrente } from '../../shared/interfaces/faturamento-recorrente.interface';
import { Faturamento } from '../../shared/interfaces/faturamento.interface';
import { FiltroFaturamentoRecorrenteItemProduto } from '../../shared/interfaces/filtro-faturamento-recorrente-item-produto.interface';
import { FaturamentoRecorrenteItemProdutoService } from '../../shared/services/faturamento-recorrente-item-produto.service';

@Component({
    selector: 'faturamento-recorrente-produto-grid',
    templateUrl: './faturamento-recorrente-produto-grid.component.html',
    styleUrls: ['./faturamento-recorrente-produto-grid.component.scss']
})
export class FaturamentoRecorrenteProdutoGridComponent extends GridBase<FaturamentoRecorrenteItemProduto, FiltroFaturamentoRecorrenteItemProduto, FaturamentoRecorrenteItemProdutoService> implements OnInit, OnChanges {
    public faturamentoRecorrente: FaturamentoRecorrente = {}

    constructor(
        protected _faturamentoRecorrenteItemProdutoService: FaturamentoRecorrenteItemProdutoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
    ) {
        super(_faturamentoRecorrenteItemProdutoService, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.faturamentoRecorrente = data.faturamento;

            if (this.faturamentoRecorrente.id) {
                this.filtro.idFaturamentoRecorrente = this.faturamentoRecorrente.id;
                this.initGrid(this.filtro);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {});
    }


    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'produto', label: '' },
            { chave: 'itemLinha', label: '' },
            { chave: 'valorItem', label: '' },
            { chave: 'quantidade', label: '' },
            { chave: 'valorTotal', label: '' },
            { chave: 'tipoItem', label: '' },
        ];
    }

}
