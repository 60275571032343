import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bi-bu',
  templateUrl: './bi-bu.component.html',
  styleUrls: ['./bi-bu.component.scss']
})
export class BiBuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
