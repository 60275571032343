import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { ChecklistContatoEmpresa } from '../../shared/interfaces/checklist-contato-empresa.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistContatoEmpresaService } from '../../shared/services/checklist-contato-empresa.service';
import { ChecklistNovoUsuarioService } from '../../shared/services/checklist-novo-usuario.service';

@Component({
    selector: 'checklist-contato-empresa',
    templateUrl: './checklist-contato-empresa.component.html',
    styleUrls: ['./checklist-contato-empresa.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChecklistContatoEmpresaComponent
    extends FormBase
    implements OnInit {
    @Input() checklist: Checklist;
    @Output() gravando = new EventEmitter();
    isVisualizacao: boolean = false;
    isEdit = false;

    CELLPHONE = '(00) 0 0000-0000';
    LANDLINE = '(00) 0000-0000';

    phoneMask = this.LANDLINE;
    phoneNumber = '';
    previusLength = 0;

    selectedChecklistIds: Array<number> = [];
    contatos: Array<ChecklistContatoEmpresa> = [];

    constructor(
        private _injector: Injector,
        private _checklistContatoEmpresaService: ChecklistContatoEmpresaService,
        private _checklistNovoUsuarioService: ChecklistNovoUsuarioService
    ) {
        super(_injector);
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.contatos = data.contatos.contatos;
            this.isVisualizacao = data.isVisualizacao;
        })
    }

    protected crateForm(): { [key: string]: any } {
        return {
            nome: ['', Validators.required],
            telefone: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            cargo: ['', Validators.required],
            principal: [],
            index: [],
        };
    }

    limparForm(): void {
        this.isEdit = false;
        this.form.get('telefone').setValue(' ');
        this.form.reset();
    }

    adicionar(): void {
        const contato: ChecklistContatoEmpresa = this.form.value;
        if (this.contatos.length === 0 && !contato.principal) {
            contato.principal = true;
        }
        this.contatos.push(contato);
        this.limparForm();
    }

    atualizar(): void {
        const index = this.form.get('index').value;
        this.contatos[index] = this.form.value;
        this.limparForm();
    }

    remover(contato: ChecklistContatoEmpresa, index: number): void {
        this._confirmation
            .confirm({ titulo: `Deseja remover o contato?` })
            .subscribe((result) => {
                if (result) {
                    this.contatos.splice(index, 1);
                }
            });
    }

    editar(contato: ChecklistContatoEmpresa, index: number): void {
        this.isEdit = true;
        if (contato.telefone && contato.telefone.length === 11) {
            this.phoneMask = this.CELLPHONE;
        }
        this.form.patchValue(contato);
        this.form.get('index').setValue(index);
    }

    atualizarPrincipal(index: number): void {
        this.contatos.forEach((c) => (c.principal = false));
        this.contatos[index].principal = true;
    }

    gravar(): void {
        if (!this.validaContatos()) {
            this._notification.error('Selecione um contato como principal');
            return;
        }

        this._spinner.show();
        this._checklistContatoEmpresaService
            .save(this.checklist.id, this.contatos)
            .subscribe(
                (data) => {
                    this.contatos = (data.data as any).contatos ;
                    this.gravando.emit(true);
                    this.sucess('Contatos salvos com sucesso!');
                },
                (error) => this.error(error));
    }

    validaContatos(): boolean {
        let isValid = false;

        this.contatos.forEach((contato) => {
            if (!contato.principal) {
                contato.principal = false;
            } else {
                isValid = true;
            }
        });
        return isValid;
    }

    onPhoneChanged() {
        this.phoneMask = this.LANDLINE;
        if (
            this.phoneNumber &&
            this.phoneNumber.length >= 10 &&
            this.phoneMask >= this.LANDLINE &&
            this.previusLength >= 10
        ) {
            this.phoneMask = this.CELLPHONE;
        }

        this.previusLength = this.phoneNumber ? this.phoneNumber.length : 0;
    }

    sendChecklist() {
        this._checklistNovoUsuarioService.createNewUser(this.checklist.id, this.selectedChecklistIds)
            .subscribe(() => {
                this.sucess('acesso ao checklist enviado com sucesso!');
            },
                (error) => this.error(error));
    }

    atualizarCheckSendContato(checklistId: number, $event: any) {
        let i = this.selectedChecklistIds.indexOf(checklistId);
        if (i !== -1) {
            this.selectedChecklistIds.splice(i, 1);
        }
        if ($event.checked) {
            this.selectedChecklistIds.push(checklistId)
        }
    }
}
