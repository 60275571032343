import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import {
    ChecklistConfiguracaoArquivo,
    ChecklistConfiguracaoArquivoDownload,
    ChecklistConfiguracaoArquivoAnexo,
} from '../interfaces/checklist-configuracao-arquivo.interface';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistConfiguracaoArquivoService extends ServiceBase<ChecklistConfiguracaoArquivoAnexo> {
    private path: string = `${environment.server}/checklist`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroBase
    ): Observable<Response<Array<ChecklistConfiguracaoArquivoAnexo>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<
            Response<Array<ChecklistConfiguracaoArquivoAnexo>>
        >(url);
    }

    upload(arquivo: ChecklistConfiguracaoArquivo, file: File): Observable<any> {
        const url = `${this.path}/${arquivo.idChecklist}/checklistConfiguracao/${arquivo.idChecklistConfiguracao}/anexo`;
        const formData = new FormData();
        formData.set('arquivo', file, file.name);

        return this._http.post(url, formData, {
            observe: 'events',
            reportProgress: true,
        });
    }

    delete(
        arquivo: ChecklistConfiguracaoArquivo,
        idChecklistConfiguracaoAnexo: number
    ): Observable<any> {
        const url = `${this.path}/${arquivo.idChecklist}/checklistConfiguracao/${arquivo.idChecklistConfiguracao}/anexo/${idChecklistConfiguracaoAnexo}`;
        return this._http.delete(url);
    }

    download(
        arquivo: ChecklistConfiguracaoArquivo,
        idChecklistConfiguracaoAnexo: number
    ): Observable<any> {
        const url = `${this.path}/${arquivo.idChecklist}/checklistConfiguracao/${arquivo.idChecklistConfiguracao}/anexo/${idChecklistConfiguracaoAnexo}/download`;
        return this._http.get<Response<ChecklistConfiguracaoArquivoDownload>>(
            url
        );
    }
}
