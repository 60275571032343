import { AfterViewInit, Injector, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ComponentBase } from '../component-base/component-base';
import { OptionsConfig } from './options-config.interface';

export abstract class FormBase extends ComponentBase implements OnDestroy, AfterViewInit {
    protected _formBuilder: FormBuilder;

    public form: FormGroup;
    public optionList: any = {};
    private optionsConfig: Array<OptionsConfig> = [];
    public filteredList: Array<ReplaySubject<Array<Object>>> = [];
    protected _onDestroy = new Subject<void>();

    configEditorHTML: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '15rem',
        minHeight: '5rem',
        placeholder: '',
        translate: 'no',
        defaultParagraphSeparator: 'p',
        defaultFontName: 'Arial',
        toolbarHiddenButtons: [
            [
                'link',
                'unlink',
                'insertImage',
                'insertVideo',
                'toggleEditorMode',
                'backgroundColorPicker',
                'foregroundColorPicker'
            ]
        ],
        customClasses: [
            {
                name: "quote",
                class: "quote",
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: "titleText",
                class: "titleText",
                tag: "h1",
            },
        ]
    };

    constructor(injector: Injector, optionsConfig?: Array<OptionsConfig>) {
        super(injector)
        this._formBuilder = injector.get(FormBuilder);

        this.initOptionsConfig(optionsConfig);
        this.form = this._formBuilder.group(this.generateForm() || null);
        this.optionList;
    }

    ngAfterViewInit() {
        this.setInitialValue();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    selectedOption(o1: any, o2: any) {
        return o1.id == o2.id;
    }

    selectedOptionId(o1: any, o2: any) {
        return o1.id == o2;
    }

    selectedOptionCodigo(o1: any, o2: any) {
        if (!o1 || !o2) return;
        return o1.codigo == o2.codigo;
    }

    selectedOptionObject(o1: any, o2: any) {
        return o1 === o2;
    }

    protected abstract crateForm(): {
        [key: string]: any;
    };

    private initOptionsConfig(optionsConfig: Array<OptionsConfig>) {
        if (optionsConfig) {
            this.optionsConfig = optionsConfig;
            this.optionsConfig.forEach((options: OptionsConfig) => {
                this.optionList[options.listName] = options.data || [];
                this.filteredList[options.listName] = new ReplaySubject<any[]>(1);
            });
        }
    }

    protected refreshOptionsConfig() {
        this.optionsConfig.forEach((options: OptionsConfig) => {
            if (this.optionList[options.listName]) {
                this.filteredList[options.listName].next(this.optionList[options.listName].slice());
            }
            if (options.filterName) {
                this.form.get(options.filterName).valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                        this.filterOptions(options);
                    });
            }
        });
    }

    protected filterOptions(options: OptionsConfig) {
        if (!this.optionList[options.listName]) {
            return;
        }
        // get the search keyword
        let search = this.form.get(options.filterName).value;
        if (!search) {
            this.filteredList[options.listName].next(this.optionList[options.listName].slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the options
        this.filteredList[options.listName].next(
            this.optionList[options.listName].filter(result => result[options.fieldValue].toLowerCase().indexOf(search) > -1)
        );
    }
    protected generateForm(): any {
        const form = this.crateForm();
        this.optionsConfig.forEach((options: OptionsConfig) => {
            if (options.filterName) {
                form[options.filterName] = [];
            }
        });
        return form;
    }

    protected setInitialValue() {
        this.optionsConfig.forEach((options: OptionsConfig) => {
            this.filteredList[options.listName]
                .pipe(takeUntil(this._onDestroy))
                .subscribe(() => {
                    // setting the compareWith property to a comparison function
                    // triggers initializing the selection according to the initial value of
                    // the form control (i.e. _initializeSelection())
                    // this needs to be done after the filteredBanks are loaded initially
                    // and after the mat-option elements are available
                    //  this.filteredList[options.listName].compareWith = (a: Bank, b: Bank) => a && b && a.id === b.id;
                });
        });
    }

}
