import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { RelacionamentoAberturaHistorico } from '../../shared/interfaces/relacionamento-abertura-historico.interface';
import { RelacionamentoAberturaHistoricoService } from '../../shared/services/relacionamento-abertura-historico.service';

@Component({
    selector: 'relacionamento-abertura-historico-detalhe',
    templateUrl: './relacionamento-abertura-historico-detalhe.component.html',
    styleUrls: ['./relacionamento-abertura-historico-detalhe.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class RelacionamentoAberturaHistoricoDetalheComponent implements OnChanges, OnInit {
    @Input() historico: RelacionamentoAberturaHistorico = {};

    constructor(
        protected _service: RelacionamentoAberturaHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) { }

    ngOnInit(): void { }

    ngOnChanges(): void { }
}
