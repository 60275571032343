import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PermissaoRoutingModule } from './permissao-routing.module';
import { PermissaoGuard } from './permissao.guard';
import { PermissaoListaResolver } from './resolvers/permissao-lista.resolver';
import { PermissaoGrupoListaResolver } from './resolvers/permissao-grupo-lista.resolver';
import { PermissaoGrupoService } from './shareds/services/permissao-grupo.service';
import { PermissaoService } from './shareds/services/permissao.service';

@NgModule({
    declarations: [],
    imports: [CommonModule, PermissaoRoutingModule],
    providers: [
        PermissaoGuard,
        PermissaoListaResolver,
        PermissaoGrupoListaResolver,
        PermissaoGrupoService,
        PermissaoService,
    ],
})
export class PermissaoModule {}
