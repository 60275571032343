import { ChangeDetectorRef, Component, Injector, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Contrato } from '../../shared/interfaces/contrato.interface';
import { ContratoService } from '../../shared/services/contrato.service';

@Component({
  selector: 'pre-contrato-add-aditivo',
  templateUrl: './pre-contrato-add-aditivo.component.html',
  styleUrls: ['./pre-contrato-add-aditivo.component.scss']
})
export class PreContratoAddAditivoComponent
  extends GridBase<Contrato, FiltroBase, ContratoService>
  implements OnChanges, OnInit {

  @Output() selecionado = new EventEmitter();
  selected: Array<any> = [];

  constructor(
    protected _service: ContratoService,
    protected _injector: Injector,
    protected _changeDetectorRefs: ChangeDetectorRef
  ) {
    super(_service, _injector, _changeDetectorRefs);
  }

  ngOnInit() {
    this.initGrid(this.filtro || {});
    for (let i = 0; i < this.listaDataSource.data.length; i++) {
      const contrato = this.listaDataSource.data[i];
      contrato['selecionado'] = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.atualizarGrid(this.filtro || {});
    for (let i = 0; i < this.listaDataSource.data.length; i++) {
      const contrato = this.listaDataSource.data[i];
      contrato['selecionado'] = false;
    }
  }

  configDisplayedColumns(): GridCollumn[] {
    return [
      { chave: 'seleciona' },
      { chave: 'empresa', label: 'Empresa' },
      { chave: 'inscricao', label: 'Inscrição' },
      { chave: 'contrato', label: 'Contrato' },
      { chave: 'situacao', label: 'Situação' }
    ];
  }

  updateAllComplete(contrato) {
    this.listaDataSource.data.forEach(t => (t['selecionado'] = false));
    contrato['selecionado'] = true;
  }

  selecionar() {
    for (let i = 0; i < this.listaDataSource.data.length; i++) {
      const element = this.listaDataSource.data[i];
      if (element['selecionado'] == true) {
        this.selected.push(element);
        break;
      }
    }
    this.selecionado.emit(this.selected);
  }

}
