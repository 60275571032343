import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BiGuard } from './bi.guard';
import { BiBuComponent } from './components/bi-bu/bi-bu.component';
import { BiDiretoriaComponent } from './components/bi-diretoria/bi-diretoria.component';
import { BiSuporteComponent } from './components/bi-suporte/bi-suporte.component';
import { BiRelatorioComponent } from './components/bi-relatorio/bi-relatorio.component';
import { BiJBSComponent } from './components/bi-jbs/bi-jbs.component';
import { BiProdutoComponent } from './components/bi-produto/bi-produto.component';
import { BiPainelSuporteComponent } from './components/bi-painel-suporte/bi-painel-suporte.component';

const routes: Routes = [
    {
        path: 'suporte',
        canActivate: [BiGuard],
        canLoad: [BiGuard],
        component: BiSuporteComponent
    },
    {
        path: 'bu',
        canActivate: [BiGuard],
        canLoad: [BiGuard],
        component: BiBuComponent
    },
    {
        path: 'painel-suporte',
        canActivate: [BiGuard],
        canLoad: [BiGuard],
        component: BiPainelSuporteComponent
    },
    {
        path: 'csc',
        canActivate: [BiGuard],
        canLoad: [BiGuard],
        component: BiDiretoriaComponent
    },
    {
        path: 'relatorio',
        canActivate: [BiGuard],
        canLoad: [BiGuard],
        component: BiRelatorioComponent
    },
    {
        path: 'jbs',
        canActivate: [BiGuard],
        canLoad: [BiGuard],
        component: BiJBSComponent
    },
    {
        path: 'produto',
        canActivate: [BiGuard],
        canLoad: [BiGuard],
        component: BiProdutoComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BiRoutingModule { }
