import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthModule } from './main/public/auth/auth.module';
import { HomeModule } from './main/home/home.module';
import { UsuarioModule } from './main/usuario/usuario.module';
import { AppRoutingModule } from './app-routing.module';

import { UtilsModule } from '@utils/utils.module';
import { ToastrModule } from 'ngx-toastr';
import { AclModule } from './main/acl/acl.module';
import { CotacaoModule } from './main/cotacao/cotacao.module';
import { ImportacaoModule } from './main/importacao/importacao.module';
import { ProfileModule } from './main/profile/profile.module';
import { ChecklistModule } from './main/checklist/checklist.module';
import { MatDialogModule } from '@angular/material';
import { BancoModule } from './main/banco/banco.module';
import { ProjetoModule } from './main/projeto/projeto.module';
import { OsModule } from './main/os/os.module';
import { ModuloModule } from './main/modulo/modulo.module';
import { ProdutoModule } from './main/produto/produto.module';
import { EmpresaModule } from './main/empresa/empresa.module';
import { TimeModule } from './main/time/time.module';
import { ContratoModule } from './main/contrato/contrato.module';
import { ConfiguradorModule } from './main/configurador/configurador.module';
import { FaturamentoModule } from './main/faturamento/faturamento.module';
import { GmudModule } from './main/gmud/gmud.module';
import { TabelaDominioModule } from './main/tabela-dominio/tabela-dominio.module';
import { RelatorioModule } from './main/relatorio/relatorio.module';
// import { NotificacaoModule } from './main/notificacao/notificacao.module';
import { ConfiguracaoModule } from './main/configuracao/configuracao.module';
import { RelacionamentoAberturaModule } from './main/relacionamento-abertura/relacionamento-abertura.module';
import { DocumentImportModule } from './main/document-import/document-import.module';
import { ValidacaoModule } from './main/validacao/validacao.module';
import { BiModule } from './main/bi/bi.module';
// import { CartaAberturaModule } from './main/carta-abertura/carta-abertura.module';
// import { PlanoProjetoModule } from './main/plano-projeto/plano-projeto.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,

        UtilsModule,

        TranslateModule.forRoot(),
        ToastrModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatDialogModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        // Produtos
        AclModule,
        AuthModule,
        ChecklistModule,
        CotacaoModule,
        HomeModule,
        ImportacaoModule,
        ProfileModule,
        ChecklistModule,
        ProjetoModule,
        OsModule,
        EmpresaModule,
        UsuarioModule,
        BancoModule,
        ProjetoModule,
        ModuloModule,
        TimeModule,
        ProdutoModule,
        ContratoModule,
        ConfiguradorModule,
        FaturamentoModule,
        GmudModule,
        TabelaDominioModule,
        RelatorioModule,
        // NotificacaoModule,
        ConfiguracaoModule,
        RelacionamentoAberturaModule,
        DocumentImportModule,
        ValidacaoModule,
        BiModule,
        // CartaAberturaModule,
        // PlanoProjetoModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
