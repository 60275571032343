import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Checklist } from 'app/main/checklist/shared/interfaces/checklist.interface';
import { FiltroEmpresa } from '../../shareds/interfaces/filtro-empresa.interface';

@Component({
    selector: 'modal-empresa-grid',
    templateUrl: './modal-empresa-grid.component.html',
    styleUrls: ['./modal-empresa-grid.component.scss'],
})
export class ModalEmpresaGridComponent implements OnInit {
    filtro: FiltroEmpresa;
    tituloModal: string = 'Gestão de empresa';

    constructor(
        public dialogRef: MatDialogRef<ModalEmpresaGridComponent>,
        @Inject(MAT_DIALOG_DATA) public checklist: Checklist
    ) {}
    ngOnInit() {}

    fechar(): void {
        this.dialogRef.close();
    }

    filtrar(filtro: FiltroEmpresa) {
        this.filtro = Object.assign({}, filtro);
    }
}
