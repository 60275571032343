import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { Faturamento } from '../interfaces/faturamento.interface';
import { FiltroFaturamento } from '../interfaces/filtro-faturamento.interface';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { FaturamentoGridRecorrente } from '../interfaces/faturamento-recorrente-grid-interface';
import { FaturamentoRecorrente } from '../interfaces/faturamento-recorrente.interface';
import { FaturamentoRecorrenteEditarLote } from '../interfaces/faturamento-recorrente-editar-lote.interface';
import { FaturamentoRecorrenteEmpresa } from '../interfaces/faturamento-recorrente-empresa.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';

@Injectable({
    providedIn: 'root',
})
export class FaturamentoRecorrenteEmpresaService extends ServiceBase<FaturamentoRecorrenteEmpresa> {
    private path = `${environment.server}/faturamento-recorrente`;

    constructor(private _http: HttpClient) {
        super();
    }

    findById(id: number | string): Observable<Response<FaturamentoRecorrenteEmpresa>> {
        const url = `${this.path}/${id}/empresa`;
        return this._http.get<Response<FaturamentoRecorrenteEmpresa>>(url);
    }

    updateContato(idFaturamentoRecorrente: number, contatoNome: string, contatoTelefone: string, contatoEmail: string, emailsCobranca: string): Observable<Response<void>> {
        const url = `${environment.server}/faturamento-recorrente/${idFaturamentoRecorrente}/empresa/dadosContatos`;
        return this._http.put<Response<void>>(url, {
            contatoNome,
            contatoTelefone,
            contatoEmail,
            emailsCobranca,
        });
    }


}
