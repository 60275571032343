import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { ModalCaixaPostalProjetoAberturaComponent } from '../../modal/modal-caixa-postal-projeto-abertura/modal-caixa-postal-projeto-abertura.component';
import { ModalAlteracaoLoteProjetoComponent } from '../../modal/modal-alteracao-lote-projeto/modal-alteracao-lote-projeto.component';
import { ModalHistoricoDetalheProjetoComponent } from '../../modal/modal-historico-detalhe-projeto/modal-historico-detalhe-projeto.component';
import { ModalHistoricoProjetoAberturaComponent } from '../../modal/modal-historico-projeto-abertura/modal-historico-projeto.component';
import { ModalHistoricoProjetoComponent } from '../../modal/modal-historico-projeto/modal-historico-projeto.component';
import { ModalPesquisaProjetoComponent } from '../../modal/modal-pesquisa-projeto/modal-pesquisa-projeto.component';
import { ProjetoAberturaConfiguracaoCore } from '../interfaces/projeto-abertura-configuracao-core.interface';
import { ProjetoAberturaConfiguracao } from '../interfaces/projeto-abertura-configuracao.interface';
import { ProjetoAberturaHistorico } from '../interfaces/projeto-abertura-historico.interface';
import { ProjetoAbertura } from '../interfaces/projeto-abertura.interface';
import { ProjetoHistorico } from '../interfaces/projeto-historico.interface';
import { Projeto } from '../interfaces/projeto.interface';
import { ModalDownloadProjetoComponent } from '../../modal/modal-download-projeto/modal-download-projeto.component';
import { ModalHistoricoStatusProjetoAberturaComponent } from '../../modal/modal-historico-status-projeto-abertura/modal-historico-status-projeto-abertura.component';

@Injectable({
    providedIn: 'root',
})
export class ProjetoModalService {
    constructor(public dialog: MatDialog) { }

    projetoPesquisa(data: Projeto) {
        const dialogRef = this.dialog.open(ModalPesquisaProjetoComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    historico(data: Projeto) {
        const dialogRef = this.dialog.open(ModalHistoricoProjetoComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    historicoDetalhe(data: ProjetoHistorico) {
        const dialogRef = this.dialog.open(
            ModalHistoricoDetalheProjetoComponent,
            {
                width: '90%',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }

    historicoProjetoAbertura(data: ProjetoAbertura) {
        const dialogRef = this.dialog.open(ModalHistoricoProjetoAberturaComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    alteracaoEmLote(data: any) {
        const dialogRef = this.dialog.open(
            ModalAlteracaoLoteProjetoComponent,
            {
                width: '50%',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }

    caixaPostaProjetoAbertura(data: any) {
        const dialogRef = this.dialog.open(
            ModalCaixaPostalProjetoAberturaComponent,
            {
                width: '90%',
                data: data,
            }
        );
        return dialogRef.afterClosed();
    }

    statusProjetoAbertura(data: any) {
        const dialogRef = this.dialog.open(
            ModalHistoricoStatusProjetoAberturaComponent,
            {
                width: '90%',
                data: data,
            }
        )
        return dialogRef.afterClosed();
    }

    download(data: Projeto) {
        const dialogRef = this.dialog.open(
            ModalDownloadProjetoComponent,
            {
                width: '90%',
                data: data,
            }
        );
        return dialogRef.afterClosed();
    }
}
