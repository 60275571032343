import { ChangeDetectorRef, Component, EventEmitter, Injector, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { ChecklistPesquisa } from '../../shared/interfaces/checklist-pesquisa.interface';
import { FiltroChecklist } from '../../shared/interfaces/filtro-checklist.interface';
import { ChecklistService } from '../../shared/services/checklist.service';

@Component({
    selector: 'checklist-pesquisa',
    templateUrl: './checklist-pesquisa.component.html',
    styleUrls: ['./checklist-pesquisa.component.scss'],
})
export class ChecklistPesquisaComponent
    extends GridBase<ChecklistPesquisa, FiltroChecklist, ChecklistService>
    implements OnChanges, OnInit {
    @Output() selecionado = new EventEmitter();

    constructor(
        protected _service: ChecklistService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {});
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'checklist', label: '' },
            { chave: 'numeroCotacao', label: '' },
            { chave: 'empresa', label: '' },
            { chave: 'quantidadeProjeto', label: '' },
        ];
    }

    selecionar(checklist: ChecklistPesquisa) {
        this.selecionado.emit(checklist);
    }
}
