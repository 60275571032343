import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configurador } from '../interfaces/configurador.interface';
import { ConfiguradorService } from '../services/configurador.service';

@Injectable()
export class ConfiguradorListaResolver implements Resolve<Configurador> {
    constructor(private _configuradorService: ConfiguradorService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._configuradorService.find({}).pipe(map((result) => result.data));
    }
}
