import { Component, OnInit } from '@angular/core';
import { FiltroGmud } from '../../shared/interfaces/filtro-gmud.interface';

@Component({
    selector: 'gmud-grid-page',
    templateUrl: './gmud-grid-page.component.html',
    styleUrls: ['./gmud-grid-page.component.scss'],
})
export class GmudGridPageComponent implements OnInit {
    filtro: FiltroGmud;

    constructor() {}

    ngOnInit() {}

    filtrar(filtro: FiltroGmud) {
        this.filtro = Object.assign({}, filtro);
    }
}
