import { ServiceBase } from "@utils/base/service-base/service-base";
import { CotacaoSquad } from "../interfaces/cotacao.squad.interface";
import { environment } from "environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FiltroBase } from "@utils/base/form-filtro-base/interfaces/filtro-base.interface";
import { Response } from "@utils/interfaces/http/response.interface";
import { FiltroSquad } from "../interfaces/filtro-squad.interface";
import { ActivatedRouteSnapshot } from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class CotacaoSquadService extends ServiceBase<CotacaoSquad> {
    private path: string = `${environment.server}/cotacao`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroSquad): Observable<Response<Array<CotacaoSquad>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/${filtro.id}/squad${params}`;
        return this._http.get<Response<Array<CotacaoSquad>>>(url);
    }
}

