import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthRedefinirSenhaCliente } from '../interfaces/auth-redefinir-senha-cliente.interface';
import { AuthService } from '../services/auth.service';

@Injectable()
export class RedefinirSenhaClienteValidaDadosResolver
    implements Resolve<AuthRedefinirSenhaCliente> {
    constructor(private _authService: AuthService, private _router: Router) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const esqueceuSenha: AuthRedefinirSenhaCliente = {
            email: route.queryParams['email'],
            token: route.queryParams['token'],
        };
        this._authService
            .validaTokenEsqueceuSenhaCliente(esqueceuSenha)
            .pipe(map((result) => result.data))
            .subscribe((result) => {
                if (!result.valido) {
                    this._router.navigateByUrl('/auth/login');
                }
            });

        return esqueceuSenha;
    }
}
