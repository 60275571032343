import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { FiltroImportacaoCotacao } from '../../shared/interfaces/filtro-importacao-cotacao.interface';
import { ImportacaoCotacao } from '../../shared/interfaces/importacao-cotacao.interface';
import { ImportacaoCotacaoModalService } from '../../shared/services/importacao-cotacao-modal.service';
import { ImportacaoCotacaoService } from '../../shared/services/importacao-cotacao.service';

@Component({
    selector: 'importacao-cotacao-grid',
    templateUrl: './importacao-cotacao-grid.component.html',
    styleUrls: ['./importacao-cotacao-grid.component.scss'],
})
export class ImportacaoCotacaoGridComponent
    extends GridBase<
    ImportacaoCotacao,
    FiltroImportacaoCotacao,
    ImportacaoCotacaoService
    >
    implements OnInit {
    constructor(
        service: ImportacaoCotacaoService,
        injector: Injector,
        changeDetectorRefs: ChangeDetectorRef,
        private _modalImportacaoCotacao: ImportacaoCotacaoModalService,
        private _importacaoCotacaoService: ImportacaoCotacaoService
    ) {
        super(service, injector, changeDetectorRefs);
    }

    ngOnInit(): void {
        this.initGrid();
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'arquivo', label: '' },
            { chave: 'status', label: '' },
            { chave: 'dataUpload', label: '' },
            { chave: 'usuario', label: '' },
        ];
    }

    upload(): void {
        this._modalImportacaoCotacao.upload().subscribe((result) => {
            this.atualizarGrid();
        });
    }

    visualizarErro(importacaoCotacao: ImportacaoCotacao): void {
        this._modalImportacaoCotacao
            .importacaoErro(importacaoCotacao)
            .subscribe((result) => { });
    }

    visualizarDetalhes(importacaoCotacao: ImportacaoCotacao): void {
        this._modalImportacaoCotacao
            .importacaoDetalhe(importacaoCotacao)
            .subscribe((result) => { });
    }

    download(importacaoCotacao: ImportacaoCotacao): void {
        this._importacaoCotacaoService
            .download(importacaoCotacao)
            .subscribe((result) => {
                DownloadUtil.base64(
                    result.data.arquivo,
                    result.data.name,
                    result.data.type
                );
            });
    }
}
