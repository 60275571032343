import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { ChangeDetectorRef, Injector } from '@angular/core';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { extend } from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ComponentBase } from '../component-base/component-base';
import { FiltroBase } from '../form-filtro-base/interfaces/filtro-base.interface';
import { ModelBase } from '../model-base/model-base';
import { ServiceGridBase } from '../service-base/service-grid-base';

export class GridBaseDatasource<
    T extends ModelBase,
    F extends FiltroBase,
    S extends ServiceGridBase<T, F>
    > implements DataSource<T> {
    private gridSubject = new BehaviorSubject<T[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    data: Array<T> = [];

    public loading$ = this.loadingSubject.asObservable();

    constructor(
        protected service: S,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _spinner: SpinnerService,
        protected _notification: NotificationService,
    ) { }

    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        return this.gridSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.gridSubject.complete();
        this.loadingSubject.complete();
    }

    load(
        filtro: F,
        filter = '',
        sortField = '',
        sortDirection = 'asc',
        pageNumber = 0,
        pageSize = 10,
        callback?
    ): void {
        callback = callback ? callback : () => { };
        filtro = filtro ? filtro : Object.assign({});
        filtro.filter = filter || '';
        filtro.sortOrder = sortDirection;
        filtro.sortField = sortField;
        filtro.page = pageNumber;
        filtro.perPage = pageSize;

        this._spinner.show();
        this.loadingSubject.next(true);
        this.service
            .find(filtro)
            .pipe(
                catchError(() => of([])),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(
                (result: any) => {
                    this._spinner.hide();
                    this._changeDetectorRefs.detectChanges();
                    this.gridSubject.next(result.data || []);
                    this.data = result.data || [];
                    callback(result);
                },
                (error) => {
                    this._spinner.hide()
                    this._notification.error(error.error.message)
                });
    }
}
