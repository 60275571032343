import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Gmud } from '../interfaces/gmud.interface';
import { GmudFluxoService } from '../services/gmud-fluxo.service';

@Injectable()
export class GmudFluxoResolver implements Resolve<Gmud> {
    constructor(private _service: GmudFluxoService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let id = route.params['id'];
        return this._service.liberarAprovacaoUsuario(id);
    }
}
