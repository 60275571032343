import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatusFinanceiro } from '../interfaces/status-financeiro.interface';
import { StatusFinanceiroService } from '../services/status-financeiro.service';

@Injectable()
export class StatusFinanceiroListaResolver
    implements Resolve<StatusFinanceiro> {
    constructor(private _statusFinanceiroService: StatusFinanceiroService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._statusFinanceiroService
            .find({})
            .pipe(map((result) => result.data));
    }
}
