import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { ChecklistService } from 'app/main/checklist/shared/services/checklist.service';
import { Observable } from 'rxjs';
import { map, switchMap, switchMapTo } from 'rxjs/operators';
import { CotacaoModulo } from '../interfaces/cotacao-modulo.interface';
import { FiltroCotacaoModulo } from '../interfaces/filtro-cotacao-modulo.interface';
import { CotacaoModuloService } from '../services/cotacao-modulo.service';

@Injectable()
export class CotacaoModuloChecklistListaResolver
    implements Resolve<CotacaoModulo> {
    constructor(
        private _cotacaoModuloService: CotacaoModuloService,
        private _checklistService: ChecklistService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const idChecklist = route.params['id'];

        return this._checklistService.findById(idChecklist).pipe(
            switchMap((c) => {
                const filtro: FiltroCotacaoModulo = {
                    dsnameConfig: 1,
                    idCotacao: c.data.cotacaoId,
                };
                return this._cotacaoModuloService
                    .find(filtro)
                    .pipe(map((d) => d.data));
            })
        );
    }
}
