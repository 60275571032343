import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { CotacaoDocumentoUpload } from '../interfaces/cotacao-documento-upload.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';

@Injectable({
    providedIn: 'root',
})
export class CotacaoDocumentoUploadService extends ServiceBase<CotacaoDocumentoUpload> {
    private path: string = `${environment.server}/cotacaodocumento`;

    constructor(private _http: HttpClient) {
        super();
    }

    upload(idCotacao, file: File): Observable<any> {
        const url = `${this.path}/${idCotacao}`;
        const formData = new FormData();
        formData.set('documento', file, file.name);

        return this._http.post(url, formData, {
            observe: 'events',
            reportProgress: true,
        });
    }
}
