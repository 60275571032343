import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Relatorio } from '../interfaces/relatorio.interface';
import { RelatorioService } from '../services/relatorio.service';

@Injectable()
export class RelatorioListaResolver implements Resolve<Relatorio> {
    constructor(private _relatorioService: RelatorioService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._relatorioService
            .find({})
            .pipe(map((result) => result.data));
    }
}
