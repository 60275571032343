import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Configuracao } from '../interfaces/configuracao.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ConfiguracaoService extends ServiceBase<Configuracao> {
    private path = `${environment.server}/configurar/notificacaoTipo`;

    constructor(private _http: HttpClient) {
        super();
    }

    findConfiguracao(): Observable<Response<Array<Configuracao>>> {
        const url = `${this.path}`;
        return this._http.get<Response<Array<Configuracao>>>(url);
    }

    save(listaConfiguracoes: Array<Configuracao>): Observable<void> {
        const url = `http://localhost:8091/configurar/notificacaoTipo`;
        return this._http.put<void>(url, { notificacaoTipo: listaConfiguracoes });
    }

    getConfiguracaoByChave(chave: NotificacaoTipo): Observable<boolean> {
        let id;

        switch (chave) {
            case 'NOTIFICACAO_EMAIL': id = 2; break;
            case 'NOTIFICACAO_POPUP': id = 3; break;
        }

        return this.findConfiguracao().pipe(
            map((result: Response<Array<Configuracao>>) => {
                const config = result.data.find((c: Configuracao) => c.id === id)
                return !!config.enviar
            })
        );
    }

}

export enum NotificacaoTipo {
    NOTIFICACAO_EMAIL = 'NOTIFICACAO_EMAIL',
    NOTIFICACAO_POPUP = 'NOTIFICACAO_POPUP',
}
