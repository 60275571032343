import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Banco } from '../interfaces/banco.interface';
import { BancoService } from '../services/banco.service';

@Injectable()
export class BancoDetalheResolver implements Resolve<Banco> {
    constructor(private _bancoService: BancoService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let id = route.params['id'];
        return this._bancoService.findby(id).pipe(map((result) => result.data));
    }
}
