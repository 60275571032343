import { SelectionModel } from '@angular/cdk/collections';
import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Banco } from 'app/main/banco/shareds/interfaces/banco.interface';
import { Empresa } from 'app/main/empresa/shareds/interfaces/empresa.interface';
import { OsEmpresa } from 'app/main/os/shareds/interfaces/os-empresa.interface';
import { OsStatus } from 'app/main/os/shareds/interfaces/os-status.interface';
import { OsVincularModalService } from 'app/main/os/shareds/services/os-vincular-modal.service';

import { FiltroProjetoAbertura } from 'app/main/projeto/shareds/interfaces/filtro-projeto-abertura.interface';
import { ProjetoAbertura } from 'app/main/projeto/shareds/interfaces/projeto-abertura.interface';
import { Projeto } from 'app/main/projeto/shareds/interfaces/projeto.interface';
import { ProjetoAberturaService } from 'app/main/projeto/shareds/services/projeto-abertura.service';
import { ProjetoAberturaConfiguracaoService } from '../../shareds/services/projeto-abertura-configuracao.service';
import { ProjetoModalService } from '../../shareds/services/projeto-modal.service';

@Component({
    selector: 'projeto-abertura-grid',
    templateUrl: './projeto-abertura-grid.component.html',
    styleUrls: ['./projeto-abertura-grid.component.scss'],
})
export class ProjetoAberturaGridComponent extends GridBase<
    ProjetoAbertura,
    FiltroProjetoAbertura,
    ProjetoAberturaService
>
    implements OnChanges, OnInit {
    @Input() projeto: Projeto;
    statussOs: Array<OsStatus>;
    empresasOs: Array<OsEmpresa>;
    bancos: Array<Banco>;

    selection = new SelectionModel<ProjetoAbertura>(true, []);

    isView: boolean = false;
    constructor(
        protected _service: ProjetoAberturaService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _route: ActivatedRoute,
        protected _modalOsVincular: OsVincularModalService,
        private _modalProjeto: ProjetoModalService,
        private _projectoAberturaConfiguracaoService: ProjetoAberturaConfiguracaoService,
    ) {
        super(_service, _injector, _changeDetectorRefs, 'ProjetoAberturaGrid');

        if (
            /\/projeto\/visualizar\/[0-9]+/.test(
                this._route['_routerState'].snapshot.url
            )
        ) {
            this.isView = true;
        }
    }

    ngOnInit(): void {
        this.filtro.idProjeto = this.projeto.id
        this.initGrid(this.filtro || {});
        this._route.data.subscribe((data) => {
            (this.bancos = data.bancos), (this.statussOs = data.statussOs);
            this.empresasOs = data.empresasOS;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filtro.idProjeto = this.projeto.id
        this.atualizarGrid(this.filtro || {});
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'select' },
            { chave: 'seleciona' },
            { chave: 'config', label: 'Configuração' },
            { chave: 'status', label: 'Status' },
            { chave: 'inscricao', label: 'Inscrição' },
            { chave: 'empresa', label: 'Empresa' },
            { chave: 'banco', label: 'Banco' },
            { chave: 'agencia', label: 'Agência' },
            { chave: 'agenciaDv', label: 'Agência DV' },
            { chave: 'conta', label: 'Conta' },
            { chave: 'contaDv', label: 'Conta DV' },
            { chave: 'convenio', label: 'Convênio' },
            { chave: 'os', label: 'OS' },
            { chave: 'squad', label: 'Time' },
            { chave: 'responsavelOs', label: 'Responsável' },
        ];
    }

    configurar() {
        const projetosAberturasSelecionados = this.selection.selected.map((v) => v.osAberturaId);
        this._spinner.show();
        this._projectoAberturaConfiguracaoService.configuracao(projetosAberturasSelecionados).subscribe(
            () => {
                this._notification.sucess("Aberturas configuradas com sucesso!")
                this._spinner.hide();
            },
            error => {
                this._spinner.hide();
                this._notification.error("Erro ao configurar as aberturas")
            })
    }

    vincularOs(): void {
        this._modalOsVincular.vincularOs(this.projeto).subscribe((val) => { });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows =
            this.paginacao.pageSize <= this.paginacao.length
                ? this.paginacao.pageSize
                : this.paginacao.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.listaDataSource.data.forEach((row) =>
                this.selection.select(row)
            );
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: ProjetoAbertura): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
            }`;
    }

    visualizar(projetoAbertura: ProjetoAbertura) {
        this._router.navigateByUrl(`projeto/abertura/visualizar/${projetoAbertura.osAberturaId}`);
    }

    editar(projetoAbertura: ProjetoAbertura) {
        this._router.navigateByUrl(`projeto/abertura/cadastro/${projetoAbertura.osAberturaId}`);
    }

    historico(projetoAbertura: ProjetoAbertura) {
        this._modalProjeto.historicoProjetoAbertura(projetoAbertura);
    }

    ativacaoPortal() {
        const projetosAberturasSelecionados = this.selection.selected.map((v) => v.osAberturaId);
        this._spinner.show();
        this._projectoAberturaConfiguracaoService.ativacaoPortal(projetosAberturasSelecionados).subscribe(
            () => {
                this._notification.sucess("Ativaçções enviadas com sucesso!")
                this._spinner.hide();
            },
            error => {
                this._spinner.hide();
                this._notification.error("Erro ao enviar as aberturas")
            })
    }
}
