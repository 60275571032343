import { Component, OnInit } from '@angular/core';
import { FiltroFaturamento } from '../../shared/interfaces/filtro-faturamento.interface';

@Component({
    selector: 'faturamento-editar-detalhe-page',
    templateUrl: './faturamento-editar-detalhe-page.component.html',
    styleUrls: ['./faturamento-editar-detalhe-page.component.scss'],
})
export class FaturamentoEditarDetalhePageComponent implements OnInit {
    filtro: FiltroFaturamento;

    constructor() {}

    ngOnInit() {}

    filtrar(filtro: FiltroFaturamento) {
        this.filtro = Object.assign({}, filtro);
    }
}
