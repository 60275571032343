import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalBase } from '@utils/base/modal-base/modal-base';
import { Perfil } from '../../shareds/interfaces/perfil.interface';
import { PerfilService } from '../../shareds/services/perfil.service';

@Component({
    selector: 'perfil-detalhe-modal',
    templateUrl: './perfil-detalhe-modal.component.html',
    styleUrls: ['./perfil-detalhe-modal.component.scss'],
})
export class PerfilDetalheModalComponent extends ModalBase implements OnInit {
    tituloModal = 'Dados do perfil';
    perfil: Perfil;

    constructor(
        public dialogRef: MatDialogRef<PerfilDetalheModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Perfil,
        private _perfilService: PerfilService,
        private _injector: Injector
    ) {
        super(_injector);
    }

    ngOnInit(): void {
        this._spinner.show();
        if (!this.data) {
            this._notification.error('Erro ao retornar os dados do perfil');
            console.log('Erro ao retornar os dados do perfil');
        }
        this._perfilService.findById(this.data.id).subscribe(
            (perfil) => {
                this._spinner.hide();
                this.perfil = perfil.data;
            },
            (error) => {
                this._spinner.hide();
                this._notification.error('Erro ao retornar os dados do perfil');
            }
        );
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
