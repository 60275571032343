import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalEditarLoteRelacionamentoAberturaComponent } from '../../modal/modal-editar-lote-relacionamento-abertura/modal-editar-lote-relacionamento-abertura.component';
import { RelacionamentoAberturaEditar } from '../interfaces/relacionamento-abertura-editar.interface';

@Injectable({
  providedIn: 'root'
})
export class RelacionamentoAberturaAlterarLoteModalService {

  constructor(public dialog: MatDialog) { }

  alteracaoEmLote(data: RelacionamentoAberturaEditar) {
    const dialogRef = this.dialog.open(ModalEditarLoteRelacionamentoAberturaComponent, {
      width: '90%',
      data: data,
    });

    return dialogRef.afterClosed();
  }
}
