import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewEncapsulation, Injector } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AuthBaseController } from '../../../shared/auth-base.controller';
import { environment } from 'environments/environment';

@Component({
    selector: 'esqueceu-senha-cliente-center',
    templateUrl: './esqueceu-senha-cliente-center.component.html',
    styleUrls: ['./esqueceu-senha-cliente-center.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class EsqueceuSenhaClienteCenterComponent
    extends AuthBaseController
    implements OnInit {
    esqueceuSenhaForm: FormGroup;
    erro: string = null;
    recaptcha: any[];
    recaptchaKey: string;

    /**
     * Constructor
     *
     * @param {Injector} _injector
     */
    constructor(protected _injector: Injector) {
        super(_injector);
        this.recaptchaKey = environment.recaptchaKey;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.esqueceuSenhaForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    enviar(): void {
        const email = this.esqueceuSenhaForm.get('email').value;
        this._authService.recuperarSenha(email, this.recaptcha);
    }

    recaptchaResolved(captchaResponse: any[]): void {
        this.recaptcha = captchaResponse;
    }
}
