import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjetoAberturaConfiguracao } from '../interfaces/projeto-abertura-configuracao.interface';
import { ProjetoAberturaService } from '../services/projeto-abertura.service';

@Injectable()
export class ProjetoAberuraDetalheResolver implements Resolve<ProjetoAberturaConfiguracao> {
    constructor(private _projetoAberturaService: ProjetoAberturaService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let id = route.params['id'];
        return this._projetoAberturaService
            .findById(id);
    }
}
