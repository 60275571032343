import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { RelacionamentoAberturaEditar } from '../../shared/interfaces/relacionamento-abertura-editar.interface';
import { RelacionamentoAberturaEditarService } from '../../shared/services/relacionamento-abertura-editar.service';

@Component({
    selector: 'editar-relacionamento-abertura',
    templateUrl: './editar-relacionamento-abertura.component.html',
    styleUrls: ['./editar-relacionamento-abertura.component.scss'],
})
export class EditarRelacionamentoAberturaComponent extends FormBase implements OnInit {
    private _relaEditar: RelacionamentoAberturaEditar;
    isView: boolean = false;

    checkRelacionamentoAberturaStatus: boolean = false;
    checkStatusFinanceiro: boolean = false;
    checkTipoCobranca: boolean = false;
    todosFull: boolean = false;

    @Output() atualizado = new EventEmitter<boolean>();

    get idsRelacionamentoAbertura() {
        return this._relaEditar;
    }

    @Input() set idsRelacionamentoAbertura(relaEditar: RelacionamentoAberturaEditar) {
        const idsRelacionamentoAbertura = relaEditar.idsRelacionamentoAbertura;
        this._relaEditar = relaEditar;
        if (idsRelacionamentoAbertura && idsRelacionamentoAbertura.length > 0) {
            this.isView = true;
        }
    }

    constructor(
        private _injector: Injector,
        private _service: RelacionamentoAberturaEditarService
    ) {
        super(_injector);
    }

    ngOnInit() {
        this._spinner.show();
        // this._statusService.find({}).subscribe(
        //     (result) => {
        //         this.listaRelacionamentoAberturaStatus = result.data;
        //         this._spinner.hide();
        //     },
        //     (error) => this.error(error)
        // );
        // this._statusFinanceiroService.find({}).subscribe(
        //     (result) => {
        //         this.listaStatusFinanceiro = result.data;
        //         this._spinner.hide();
        //     },
        //     (error) => this.error(error)
        // );
        // this._tipoCobrancaService.find({}).subscribe(
        //     (result) => {
        //         this.listaTipoCobranca = result.data;
        //         this._spinner.hide();
        //     },
        //     (error) => this.error(error)
        // );
    }

    // selecionaCheckRelacionamentoAberturaStatus(check) {
    //     this.checkRelacionamentoAberturaStatus = check;
    //     if (!check) {
    //         this.form.get('statusRelacionamentoAbertura').setValue('');
    //     }
    // }
    // selecionaCheckStatusFinanceiro(check) {
    //     this.checkStatusFinanceiro = check;
    //     if (!check) {
    //         this.form.get('statusFinanceiro').setValue('');
    //     }
    // }
    // selecionaCheckTipoCobranca(check) {
    //     this.checkTipoCobranca = check;
    //     if (!check) {
    //         this.form.get('tipoCobranca').setValue('');
    //     }
    // }

    selecionaTodos(check) {
        if (!check) {
            this.form.reset();
        }
        this.checkRelacionamentoAberturaStatus = check;
        this.checkStatusFinanceiro = check;
        this.checkTipoCobranca = check;
    }

    isDesabled(chave: string) {
        switch (chave) {
            case 'checkRelacionamentoAberturaStatus':
                return !this.checkRelacionamentoAberturaStatus;
                break;
            case 'checkStatusFinanceiro':
                return !this.checkStatusFinanceiro;
                break;
            case 'checkTipoCobranca':
                return !this.checkTipoCobranca;
                break;
        }
    }

    alterar() {
        this._relaEditar = Object.assign({}, this._relaEditar, {
            idRelacionamentoAberturaStatus: this.form.get('statusRelacionamentoAbertura').value,
            idStatusFinanceiro: this.form.get('statusFinanceiro').value,
            idTipoCobranca: this.form.get('tipoCobranca').value,
        });

        // if (this._relaEditar.idRelacionamentoAberturaStatus) {
        //     this._confirmation
        //         .confirm({
        //             titulo:
        //                 'Tem certeza que deseja continuar com a Alteração do Status da Cotação?',
        //             mensagem:
        //                 'Esta alteração impacta nos Projetos relacionados.',
        //         })
        //         .subscribe((result) => {
        //             if (result) {
        //                 this.alterarExec();
        //             }
        //         });
        // } else {
        //     this.alterarExec();
        // }
    }

    private alterarExec() {
        this._spinner.show();
        this._service.update(this._relaEditar).subscribe(
            () => {
                this.sucess('Cotações alteradas com sucesso!');
                this.atualizado.emit(true);
            },
            (error) => this.error(error)
        );
    }

    protected crateForm(): { [key: string]: any } {
        return {
            statusRelacionamentoAbertura: [],
            statusFinanceiro: [],
            tipoCobranca: [],
        };
    }
}
