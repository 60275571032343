import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChecklistContatoEmpresa } from '../interfaces/checklist-contato-empresa.interface';
import { ChecklistContatoEmpresaService } from '../services/checklist-contato-empresa.service';

@Injectable()
export class ChecklistContatoEmpresaListaResolver
    implements Resolve<ChecklistContatoEmpresa> {
    constructor(
        private _checklistContatoEmpresaService: ChecklistContatoEmpresaService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        return this._checklistContatoEmpresaService
            .findByChecklist(id)
            .pipe(map((result) => result.data));
    }
}
