import { Component, Injector, OnInit } from '@angular/core';
import { SimNao } from '@utils/interfaces/shareds/sim-nao.interface';
import { ChecklistConectividadeBase } from '../checklist-conectividade-base';
import { ChecklistConectividadesB2bi } from '../shareds/interfaces/checklist-conectividades-b2bi.interface';

@Component({
  selector: 'checklist-conectividades-b2bi',
  templateUrl: './checklist-conectividades-b2bi.component.html',
  styleUrls: ['./checklist-conectividades-b2bi.component.scss']
})
export class ChecklistConectividadesB2biComponent extends ChecklistConectividadeBase<ChecklistConectividadesB2bi> implements OnInit {

  public PORT_MASK = '99990000';

  public listaSimNao: Array<SimNao> = [
    { codigo: 'SIM', descricao: 'Sim' },
    { codigo: 'NAO', descricao: 'Não' }
  ]

  constructor(
    protected _injector: Injector
  ) {
    super(_injector)
  }

  ngOnInit() {
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      protocolo: [],
      idProtocolo: [],
      enderecoIp: [],
      porta: [],
      senha: [],
    }
  }

  protected setForm(data: ChecklistConectividadesB2bi) {
    this.form.patchValue({
      protocolo: data.protocolo,
      idProtocolo: data.idProtocolo,
      enderecoIp: data.enderecoIp,
      porta: data.porta,
      senha: data.senha,
    })
  }

  getForm() {
    const ip = this.form.get('enderecoIp').value
    const enderecoIp = ip ? ip.replace(/^([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{3})/gi, '$1.$2.$3.$4'): undefined;

    const data: ChecklistConectividadesB2bi = {
      protocolo: this.form.get('protocolo').value,
      idProtocolo: this.form.get('idProtocolo').value,
      enderecoIp: enderecoIp,
      porta: this.form.get('porta').value,
      senha: this.form.get('senha').value,
    }

    this.values.emit(data);
  }


}
