import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BiRoutingModule } from './bi-routing.module';
import { BiSuporteComponent } from './components/bi-suporte/bi-suporte.component';
import { BiBuComponent } from './components/bi-bu/bi-bu.component';
import { BiDiretoriaComponent } from './components/bi-diretoria/bi-diretoria.component';
import { BiGuard } from './bi.guard';
import { BiRelatorioComponent } from './components/bi-relatorio/bi-relatorio.component';
import { BiJBSComponent } from './components/bi-jbs/bi-jbs.component';
import { BiProdutoComponent } from './components/bi-produto/bi-produto.component';
import { BiPainelSuporteComponent } from './components/bi-painel-suporte/bi-painel-suporte.component';

@NgModule({
  declarations: [
    BiSuporteComponent, 
    BiBuComponent, 
    BiDiretoriaComponent, 
    BiRelatorioComponent,
    BiJBSComponent,
    BiProdutoComponent,
    BiPainelSuporteComponent
  ],
  imports: [
    CommonModule,
    BiRoutingModule
  ],
  providers:[
    BiGuard,
  ]
})
export class BiModule { }
