import { Injectable } from '@angular/core';
import { ThemeUser } from '@utils/interfaces/theme/theme-user.interface';
import { TokenJwt } from '@utils/interfaces/token/token-jwt.interface';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import jwt_decode from 'jwt-decode';

@Injectable()
export class AuthLocalStorageService {
    public getToken(): any {
        return JSON.parse(localStorage.getItem('authToken'));
    }

    public setToken(token: any): void {
        localStorage.setItem('authToken', JSON.stringify(token));
    }

    public getUsuario(): any {
        const usuario = JSON.parse(localStorage.getItem('dados-usuario'));
        const usuarioCliente = this.getUsuarioCliente();

        return usuario || usuarioCliente;
    }

    public setUsuario(dadosUsuario: any): void {
        localStorage.setItem('dados-usuario', JSON.stringify(dadosUsuario));
    }

    public deleteUsuario(): void {
        localStorage.removeItem('dados-usuario');
    }

    public getUsuarioCliente(): any {
        return JSON.parse(localStorage.getItem('dados-usuario-cliente'));
    }

    public setUsuarioCliente(dadosUsuario: any): void {
        localStorage.setItem(
            'dados-usuario-cliente',
            JSON.stringify(dadosUsuario)
        );
    }

    public deleteUsuarioCliente(): void {
        localStorage.removeItem('ados-usuario-cliente');
    }

    public deleteToken(): void {
        localStorage.removeItem('authToken');
    }

    public getThemeUser(): ThemeUser {
        const usuario: UsuarioLogado = this.getUsuario() || {};
        const themeUser: ThemeUser = {
            name: usuario.nome || '',
        };
        return themeUser || {};
    }

    public getAdminUser(): boolean {
        const usuario: UsuarioLogado = this.getUsuario() || {};
        var adminUser = false
        if (usuario && usuario.perfis) {
            if (usuario.perfis.length > 0) {
                for (let i = 0; i < usuario.perfis.length; i++) {
                    if (usuario.perfis[i].id == 2) {
                        adminUser = true
                    }
                }
            }
        }

        return adminUser || false;
    }

    public deleteSession(): void {
        this.deleteToken();
        this.deleteUsuario();
        this.deleteUsuarioCliente();
    }

    public isLoggedIn(): boolean {
        return !!this.getToken();
    }

    public recuperarSenha(email: string): void { }

    private decodeToken(): TokenJwt {
        const token = this.getToken();
        return token ? jwt_decode(token) : {};
    }
}
