import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Usuario } from '../../shared/interfaces/usuario.interface';

@Component({
    selector: 'usuario-detalhe',
    templateUrl: './usuario-detalhe.component.html',
    styleUrls: ['./usuario-detalhe.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UsuarioDetalheComponent {
    @Input() usuario: Usuario;

    constructor() {
        this.usuario = {};
    }
}
