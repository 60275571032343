import { Component, Injector, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { ModalBase } from '@utils/base/modal-base/modal-base';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { ImportacaoOsService } from '../../shared/services/importacao-os.service';

@Component({
  selector: 'importacao-os-upload-modal',
  templateUrl: './importacao-os-upload-modal.component.html',
  styleUrls: ['./importacao-os-upload-modal.component.scss']
})
export class ImportacaoOsUploadModalComponent
  extends ModalBase
  implements OnInit {
  tituloModal = 'Importar OS';

  constructor(
    public dialogRef: MatDialogRef<ImportacaoOsUploadModalComponent>,
    private _injector: Injector,
    private _importacaoOsService: ImportacaoOsService,
  ) {
    super(_injector);
  }

  ngOnInit(): void { }

  fechar(): void {
    this.dialogRef.close();
  }

  modeloDocumentoArquivo(): void {
    this._spinner.show();
    this._importacaoOsService
      .modeloDocumentoArquivo()
      .subscribe((result) => {
        this._spinner.hide();
        DownloadUtil.base64(
          result.data.arquivo,
          result.data.name,
          result.data.type
        );
      })
  }
}