import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { ImportacaoCotacao } from '../interfaces/importacao-cotacao.interface';
import { FiltroImportacaoCotacao } from '../interfaces/filtro-importacao-cotacao.interface';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root',
})
export class ImportacaoCotacaoService extends ServiceGridBase<
    ImportacaoCotacao,
    FiltroImportacaoCotacao
> {
    private path = `${environment.server}/importar`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro: FiltroImportacaoCotacao
    ): Observable<Response<Array<ImportacaoCotacao>>> {
        const params: string = this.toGetParams(filtro);
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<ImportacaoCotacao>>>(url);
    }

    findById(id: number | string): Observable<ImportacaoCotacao> {
        const url = `${this.path}/${id}`;
        return this._http.get<ImportacaoCotacao>(url);
    }

    upload(file: File): Observable<any> {
        const url = `${this.path}`;
        const formData = new FormData();
        formData.set('arquivo', file, file.name);

        return this._http.post(url, formData, {
            observe: 'events',
            reportProgress: true,
        });
    }

    download(importacaoCotacao: ImportacaoCotacao): Observable<any> {
        const url = `${environment.server}/${importacaoCotacao.download}`;
        return this._http.get(url);
    }
}
