import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FiltroProjetoAberturaStatus } from '../interfaces/filtro-projeto-abertura-status.interface';
import { ProjetoAberturaStatusConfiguracao, ProjetoAberturaStatusConfiguracaoRetorno } from '../interfaces/projeto-abertura-status-configuracao.interface';

@Injectable({
  providedIn: 'root'
})
export class ProjetoAberturaStatusService extends ServiceGridBase<ProjetoAberturaStatusConfiguracao, FiltroProjetoAberturaStatus> {
  private path: string = `${environment.server}/configurador/ultimo-historico-envio`;

  constructor(private _http: HttpClient) {
    super();
  }

  find(filtro: FiltroProjetoAberturaStatus): Observable<Response<Array<ProjetoAberturaStatusConfiguracao>>> {
    const params: string = filtro
      ? this.toGetParams(filtro, ['idDNSAbertura'])
      : '';
    const url = `${this.path}/${filtro.idDNSAbertura}${params}`;
    return this._http.get<Response<Array<ProjetoAberturaStatusConfiguracao>>>(url);
  }

  findById(id: number | string): Observable<ProjetoAberturaStatusConfiguracao> {
    const url = `${this.path}/${id}`;
    return this._http.get<Response<any>>(url).pipe(map((resp) => resp.data));
  }

  findHistorico(filtro: FiltroProjetoAberturaStatus): Observable<Response<ProjetoAberturaStatusConfiguracaoRetorno>> {
    const params: string = filtro
      ? this.toGetParams(filtro, ['idDNSAbertura'])
      : '';
    const url = `${environment.server}/configurador/historico-envio/${filtro.idDNSAbertura}${params}`;
    return this._http.get<Response<ProjetoAberturaStatusConfiguracaoRetorno>>(url);
  }
}
