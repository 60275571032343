import { Component, OnInit } from '@angular/core';
import { ThemeConfig } from '@utils/interfaces/theme/theme-config.interface';
import { themeConfig } from 'app/fuse-config/theme.config';

@Component({
    selector: 'footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
    themeConfig: ThemeConfig;
    constructor() {
        this.themeConfig = themeConfig;
    }
}
