import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class AclGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [
            { path: '/acl/perfil', role: Role.PERFIL_LISTAR },
            { path: '/acl/perfil/cadastro', role: Role.PERFIL_CADASTRAR },
            {
                path: '/acl/perfil/cadastro/[0-9]+',
                role: Role.PERFIL_ATUALIZAR,
            },
        ];
    }
}
