import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChecklistRelacionamentoProdutoFinancerio } from '../interfaces/checklist-relacionamento.interface';
import { ChecklistStatus } from '../interfaces/checklist-status.interface';
import { ChecklistRelacionamentoProdutoFinanceiroService } from '../services/checklist-relacionamento-produto-financerio.service';

@Injectable()
export class ChecklistProdutoFinanceiroListaProdutosResolver implements Resolve<ChecklistRelacionamentoProdutoFinancerio> {
    constructor(private _checklistRelacionamentoProdutoFinanceiroService: ChecklistRelacionamentoProdutoFinanceiroService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._checklistRelacionamentoProdutoFinanceiroService
            .findListProducts({ ativo: 1 })
            .pipe(map((result) => result.data));
    }
}
