import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Perfil } from '../interfaces/perfil.interface';
import { FiltroPerfil } from '../interfaces/filtro-perfil.interface';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { FiltroPermissao } from 'app/main/acl/permissao/shareds/interfaces/filtro-permissao.interface';
import { Response } from '../../../../../../@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root',
})
export class PerfilService extends ServiceGridBase<Perfil, FiltroPermissao> {
    private path = `${environment.server}/perfil`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroPerfil): Observable<Response<Array<Perfil>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<Perfil>>>(url);
    }

    findById(id: number | string): Observable<Response<Perfil>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Perfil>>(url);
    }

    save(perfil: Perfil): Observable<Response<Perfil>> {
        return perfil.id ? this.update(perfil) : this.create(perfil);
    }

    create(perfil: Perfil): Observable<Response<Perfil>> {
        const url = `${this.path}`;
        return this._http.post<Response<Perfil>>(
            url,
            this.perfilToDatabase(perfil)
        );
    }

    update(perfil: Perfil): Observable<Response<Perfil>> {
        const url = `${this.path}/${perfil.id}`;
        return this._http.put<Response<Perfil>>(
            url,
            this.perfilToDatabase(perfil)
        );
    }

    delete(perfil: Perfil): Observable<Response<Perfil>> {
        const url = `${this.path}/${perfil.id}`;
        return this._http.delete<Response<Perfil>>(url);
    }

    private perfilToDatabase(perfil: Perfil): any {
        const permissoes: Array<number> = [];
        if (perfil.permissoes) {
            perfil.permissoes.forEach((p) => permissoes.push(p.id));
        }

        return {
            id: perfil.id,
            nome: perfil.nome,
            tipo: perfil.tipo ? perfil.tipo.id : null,
            permissoes: permissoes,
        };
    }
}
