import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { ProjetoAberturaStatusService } from '../../shareds/services/projeto-abertura-status.service';

@Component({
  selector: 'projeto-abertura-status-historico',
  templateUrl: './projeto-abertura-status-historico.component.html',
  styleUrls: ['./projeto-abertura-status-historico.component.scss']
})
export class ProjetoAberturaStatusHistoricoComponent implements OnInit {

  @Input() statusConfiguracao: any;

  constructor(
    protected _service: ProjetoAberturaStatusService,
    protected _injector: Injector,
    protected _changeDetectorRefs: ChangeDetectorRef,
    protected _projetoAberturaStatusService: ProjetoAberturaStatusService
  ) { }

  ngOnInit() {
    this._projetoAberturaStatusService.findHistorico({ idDNSAbertura: this.statusConfiguracao[0].idDNSAbertura })
      .subscribe((data) => {
        this.statusConfiguracao = data.data;
      });
  }

}
