import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { Banco } from 'app/main/banco/shareds/interfaces/banco.interface';
import { CotacaoItemCotacao } from '../../shared/interfaces/cotacao-item-cotacao.interface';

@Component({
    selector: 'cotacao-banco-contratado',
    templateUrl: './cotacao-banco-contratado.component.html',
    styleUrls: ['./cotacao-banco-contratado.component.scss'],
})
export class CotacaoEmpresaContratadaComponent implements OnInit {


    _data: CotacaoItemCotacao;
    get data(): CotacaoItemCotacao {
        return this._data;
    }
    @Input() set data(value: CotacaoItemCotacao) {
        this._data = value;
        this.bancos = value.bancos
    }

    bancos: Array<String> = []

    constructor(
        private _notification: NotificationService,
        private _spinner: SpinnerService,
    ) { }

    ngOnInit() {
    }
}
