import { Component, Injector, OnInit, Output, EventEmitter } from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { FiltroBanco } from '../../shareds/interfaces/filtro-banco.interface';

@Component({
  selector: 'banco-grid-filtro',
  templateUrl: './banco-grid-filtro.component.html',
  styleUrls: ['./banco-grid-filtro.component.scss']
})
export class BancoGridFiltroComponent extends FormFiltroBase<FiltroBanco> implements OnInit {

  @Output() filtrar: any = new EventEmitter<FiltroBanco>();

  constructor(injector: Injector) {
    super('BancoGrid', injector);
  }

  ngOnInit() {
    this._route.data.subscribe((data) => {
      this.refreshOptionsConfig();
    });
  }

  camposFiltro(): string[] {
    return [
      'codigo',
      'nome',
      'sla'
    ];
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      codigo: [],
      nome: [],
      sla: [],
    };
  }

  limparFiltro(): void {
    this.limpar();
  }

  filtrarExecEnter(event): void {
    if (event.keyCode === 13) {
      this.filtrarExec();
    }
  }

  filtrarExec(): void {
    const bancoCodigo = this.form.get('codigo').value;
    const bancoNome = this.form.get('nome').value;
    const bancoSla = this.form.get('sla').value;


    const filtro: FiltroBanco = {
      codigo: bancoCodigo,
      nome: bancoNome,
      sla: bancoSla
    }

    this.filtrar.emit(filtro);
  }
}
