import { Component, ViewEncapsulation, Injector } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AuthBaseController } from '../../shared/auth-base.controller';

@Component({
    selector: 'esqueceu-senha-cliente',
    templateUrl: './esqueceu-senha-cliente.component.html',
    styleUrls: ['./esqueceu-senha-cliente.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class EsqueceuSenhaClienteComponent extends AuthBaseController {
    /**
     * Constructor
     *
     * @param {Injector} _injector
     */
    constructor(protected _injector: Injector) {
        super(_injector);
    }
}
