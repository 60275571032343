import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';

import { LOCALE_ID, NgModule } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { SafeHtmlPipe } from './pipe/safe-html/safe-html.pipe';
import { NotificationService } from './utils/notification/notification.service';
import { RoleDirective } from './directives/role.directive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ConfirmationModule } from './utils/confirmation/confirmation.module';
import { DndDirective } from './directives/dnd.directive';
import { ProgressComponent } from './utils/progress/progress.component';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { TelefonePipe } from './pipe/telefone/telefone.pipe';
import { SpinnerModule } from './utils/spinner/spinner.module';
import { CnpjPipe } from './pipe/cnpj/cnpj.pipe';
import { CpfcnpjPipe } from './pipe/cpfcnpj/cpfcnpj.pipe';
import { CpfPipe } from './pipe/cpf/cpf.pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { FieldNumeric } from './directives/field-numeric.directive';
import { FieldNumericMask } from './directives/field-numeric-mask.directive';
import { FieldLetter } from './directives/field-letter.directive';
import { FieldDate } from './directives/field-date.directive';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NoDblClickMatDirective } from './directives/no-dbl-click-mat.directive';
import { MatAutocompleteModule } from '@angular/material';
import { CepPipe } from './pipe/cep/cep.pipe';
import { FiltroService } from './utils/filtro/filtro.service';
import { ModalSalvarConfiguracaoFiltroComponent } from './utils/filtro/components/modal-salvar-configuracao-filtro/modal-salvar-configuracao-filtro.component';
import { ModalSalvarConfiguracaoGridComponent } from './utils/filtro/components/modal-salvar-configuracao-grid/modal-salvar-configuracao-grid.component';
import { FiltroModalService } from './utils/filtro/shareds/services/filtro-modal.service';
import { WebSocketService } from './services/web-socket.service';
import { SimNaoResolver } from './resolvers/sim-nao.resolver';
import { SimNaoService } from './services/sim-nao.service';
import { AngularEditorModule } from '@kolkov/angular-editor';

export const maskConfig: Partial<IConfig> = {
    validation: false,
};
registerLocaleData(localePt);

@NgModule({
    declarations: [
        SafeHtmlPipe,
        RoleDirective,
        DndDirective,
        FieldNumeric,
        FieldNumericMask,
        FieldLetter,
        FieldDate,
        ProgressComponent,
        TelefonePipe,
        CnpjPipe,
        CpfcnpjPipe,
        CpfPipe,
        CepPipe,
        NoDblClickMatDirective,
        ModalSalvarConfiguracaoFiltroComponent,
        ModalSalvarConfiguracaoGridComponent,
    ],
    imports: [
        FuseSharedModule,
        FlexLayoutModule,
        NgxMaskModule.forRoot(maskConfig),
        CurrencyMaskModule,
        AngularEditorModule,

        // Mat
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDividerModule,
        MatCardModule,
        MatDialogModule,
        MatTableModule,
        MatTooltipModule,
        MatAutocompleteModule,

        // Próprios
        ConfirmationModule,
        SpinnerModule,
    ],
    exports: [
        FuseSharedModule,
        FlexLayoutModule,
        ConfirmationModule,
        NgxMaskModule,
        CurrencyMaskModule,
        AngularEditorModule,

        // Mat
        MatIconModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDividerModule,
        MatCardModule,
        MatDialogModule,
        MatTooltipModule,
        MatAutocompleteModule,

        // Pipes
        SafeHtmlPipe,
        TelefonePipe,
        CnpjPipe,
        CpfcnpjPipe,
        CpfPipe,
        CepPipe,

        // Directives
        RoleDirective,
        DndDirective,
        FieldNumeric,
        FieldNumericMask,
        FieldLetter,
        FieldDate,
        NoDblClickMatDirective,

        // Próprios
        ConfirmationModule,
        SpinnerModule,

        // Components
        ProgressComponent,
    ],
    providers: [
        NotificationService,
        FiltroService,
        FiltroModalService,
        SimNaoService,
        SimNaoResolver,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
    ],
    entryComponents: [
        ModalSalvarConfiguracaoFiltroComponent,
        ModalSalvarConfiguracaoGridComponent
    ]
})
export class UtilsModule { }
