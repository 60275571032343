import { Component, Injector, OnInit } from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { Location } from '@angular/common';
import { ProdutoModuloCadastroBanco } from '../../shareds/interfaces/produto-modulo-cadastro-banco.interface';
import { BancoService } from '../../shareds/services/banco.service';
import { CadastroBanco } from '../../shareds/interfaces/cadastro-banco.interface';
import { ContatoBanco } from '../../shareds/interfaces/contato-banco.interface';
import { isEmpty, isNumber } from 'lodash';
import { prototype } from 'events';

@Component({
  selector: 'banco-cadastro',
  templateUrl: './banco-cadastro.component.html',
  styleUrls: ['./banco-cadastro.component.scss']
})
export class BancoCadastroComponent extends FormBase implements OnInit {

  public PHONE_MASK = '99 9 9999-9999';

  isView: boolean = false;
  listaDataSource: Array<ProdutoModuloCadastroBanco> = [];
  listaContatoDataSource: Array<ContatoBanco> = [];
  errorFoneContato: boolean = false;
  errorNomeContato: boolean = false;
  errorMailContato: boolean = false;

  constructor(
    private _location: Location,
    private _injector: Injector,
    private _service: BancoService
  ) {
    super(_injector, BancoCadastroCombos.combos());
  }

  ngOnInit() {
    if (/cadastro\/\:id\/visualizar/.test(this._route.snapshot.routeConfig.path)) {
      this.isView = true;
      this.form.clearValidators();
    }
    this._route.data.subscribe((data) => {
      this.optionList.listaProdutos = data.listaProdutos;
      this.optionList.listaModulos = data.listaModulos;
      if (data.dadosBanco) {
        this.setForm(data.dadosBanco);
      }
    });
    this.refreshOptionsConfig();
  }

  private setForm(dadosBanco: any) {
    let id = this._route.snapshot.params.id;
    this.form.patchValue({
      codigo: dadosBanco.codigo,
      nome: dadosBanco.nome,
      apelido: dadosBanco.apelido,
      sla: dadosBanco.sla
    });
    for (let bancoProduto of dadosBanco.bancoProdutosModulos) {
      const produto = bancoProduto.produto;
      const modulo = bancoProduto.modulo;
      this.listaDataSource.push({ idProduto: produto.id, produtoNome: produto.nome, idModulo: modulo.id, moduloNome: modulo.nome });
    }
    for (let bancoContato of dadosBanco.contatos) {
      this.listaContatoDataSource.push(bancoContato);
    }
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      codigo: [],
      nome: [],
      apelido: [],
      sla: [],
      indexProduto: [],
      produto: [],
      modulo: [],
      indexContato: [],
      nomeContato: [],
      foneContato: [],
      mailContato: [],
    };
  }

  isSelectedProduto(): boolean {
    if (this.form.get('produto').value != undefined &&
      this.form.get('produto').value != null &&
      this.form.get('produto').value != '') {
      return false;
    }
    return true;
  }

  isSelectedProdutoModuo(): boolean {
    let i = 0;
    if (this.form.get('produto').value != undefined &&
      this.form.get('produto').value != null &&
      this.form.get('produto').value != '') {
      i++;
    }

    if (this.form.get('modulo').value != undefined &&
      this.form.get('modulo').value != null &&
      this.form.get('modulo').value != '') {
      i++;
    }

    if (i == 2) {
      return false;
    }

    return true;
  }


  addProdutoModulo(): void {
    const indexProduto = this.form.get('indexProduto').value;
    let produto = this.form.get('produto').value;
    let modulo = this.form.get('modulo').value;
    let isEdit = false;

    if (isNumber(produto)) {
      produto = this.optionList.listaProdutos.find(item => item.id == produto);
      isEdit = true;
    }

    if (isNumber(modulo)) {
      modulo = this.optionList.listaModulos.find(item => item.id == modulo);
      isEdit = true;
    }

    let existe = this.listaDataSource.findIndex(item => (item.idProduto == produto.id && item.idModulo == modulo.id));
    if (existe < 0) {

      if (isEdit) {        
        this.listaDataSource.splice(indexProduto, 1);
        this.listaDataSource.push({
          idProduto: produto.id,
          produtoNome: produto.nome,
          idModulo: modulo.id,
          moduloNome: modulo.nome
        });                
      } else {
        this.listaDataSource.push({
          idProduto: produto.id,
          produtoNome: produto.nome,
          idModulo: modulo.id,
          moduloNome: modulo.nome
        });        
      }

      this.limparProdutoModulo();      
    } else {
      this._notification.error('Este produto e modulo já se encontra adicionado a lista.');
    }
  }

  limparProdutoModulo(): void {
    this.form.get('indexProduto').setValue('');
    this.form.get('produto').setValue('');
    this.form.get('modulo').setValue('');
  }

  deleteProduto(index: number): void {    
    if (this.listaDataSource.length > 1) {
      this.listaDataSource.splice(index, 1);
    } else {
      this._notification.error('Você deve conter pelo menos um Produto e um Modulo junto a lista. Adicione outro produto e modulo para assim excluir este item.');
    }

  }

  addContatoModulo(): void {    
    const indexContato = this.form.get('indexContato').value;
    const nome = this.form.get('nomeContato').value;
    const telefone = this.form.get('foneContato').value;
    const email = this.form.get('mailContato').value;

    let iError = 0;
    this.errorFoneContato = false;
    
    if (isEmpty(nome)) {
      this.errorNomeContato = true;
      iError++;
    } else {
      this.errorNomeContato = false;
    }
    if (isEmpty(email)) {
      this.errorMailContato = true;
      iError++;
    } else {
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.errorMailContato = true;
        iError++;
      } else {
        this.errorMailContato = false;
      }
    }


    if (iError > 0) {
      return;
    }

    let existe = this.listaContatoDataSource.findIndex(item => (item.nome == nome && item.telefone == telefone && item.email == email));
    if (existe < 0) {

      if (isNumber(indexContato)) {
        this.listaContatoDataSource.splice(indexContato, 1);
        this.listaContatoDataSource.push({
          nome: nome,
          telefone: telefone,
          email: email
        });        
      } else {
        this.listaContatoDataSource.push({
          nome: nome,
          telefone: telefone,
          email: email
        });
      }

      this.limparContatoModulo();

    } else {
      this._notification.error('Este contato já se encontra adicionado a lista.');
    }
  }

  limparContatoModulo() {
    this.form.get('indexContato').setValue('');
    this.form.get('nomeContato').setValue('');
    this.form.get('foneContato').setValue('');
    this.form.get('mailContato').setValue('');
  }

  deleteContato(index: number): void {
    if (this.listaContatoDataSource.length > 1) {
      this.listaContatoDataSource.splice(index, 1);
    } else {
      this._notification.error('Você deve conter pelo menos um Contato junto a lista. Adicione outro contatopara assim excluir este item.');
    }

  }


  salvar(): void {
    if (this.validateFields()) {
      this.executaSalvar();
    }
  }

  executaSalvar(): void {
    const id = this._route.snapshot.params.id;
    const codigo = this.form.get('codigo').value;
    const nome = this.form.get('nome').value;
    const sla = this.form.get('sla').value;
    const apelido = this.form.get('apelido').value;

    const CadastroBanco: CadastroBanco = {
      apelido,
      id: id,
      codigo: codigo,
      nome: nome,
      sla: sla,
      produto: this.listaDataSource,
      contato: this.listaContatoDataSource
    }
    this._service.save(CadastroBanco).subscribe(
      () => {
        this.sucess('Banco salvo com sucesso');
        this.limparForm();
      },
      (error) => this.error(error)
    );
  }

  limparForm() {
    this.listaDataSource = [];
    this.listaContatoDataSource = [];
    this.form.reset();
  }

  validateFields(): boolean {
    const codigo = this.form.get('codigo').value;
    const nome = this.form.get('nome').value;
    const sla = this.form.get('sla').value;

    if (codigo == undefined) {
      this._notification.error('Número do Banco e um campo obrigatório.');
      return false;
    } else {
      if (isNaN(codigo)) {
        this._notification.error('Número do Banco deve ser preechido somente com números.');
        return false;
      }
    }

    if (nome == undefined) {
      this._notification.error('Nome do Banco e um campo obrigatório.');
      return false;
    }

    if (sla == undefined) {
      this._notification.error('SLA e um campo obrigatório.');
      return false;
    } else {
      if (isNaN(sla)) {
        this._notification.error('SLA deve ser preechido somente com números.');
        return false;
      }
    }

    if (this.listaDataSource.length == 0) {
      this._notification.error('Você deve adicionar pelo menos um Produto e um Modulo junto a lista.');
      return false;
    }

    return true;
  }

  voltar(): void {
    this._location.back();
  }


  editarContato(element: any, index: number): void {    
    this.form.get('indexContato').setValue(index);
    this.form.get('nomeContato').setValue(element.nome);
    this.form.get('mailContato').setValue(element.email);
    this.form.get('foneContato').setValue(element.telefone);   
  }

  editarProduto(element: any, index: number): void {
    this.form.get('indexProduto').setValue(index);
    this.form.get('produto').setValue(element.idProduto);
    this.form.get('modulo').setValue(element.idModulo);    
  }

}

export class BancoCadastroCombos {
  static combos() {
    return [
      {
        listName: 'listaProdutos',
        filterName: 'filterProduto',
        fieldValue: 'nome',
      },
      {
        listName: 'listaModulos',
        filterName: 'filterModulo',
        fieldValue: 'nome',
      }
    ];
  }
}