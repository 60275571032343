import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OsFaseNome } from '../interfaces/os-fase-nome.interface';
import { OsFaseNomeService } from '../services/os-fase-nome.service';

@Injectable()
export class OsFaseNomeListaResolver implements Resolve<OsFaseNome> {
    constructor(private _osFaseNomeService: OsFaseNomeService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._osFaseNomeService
            .find({})
            .pipe(map((result) => result.data));
    }
}
