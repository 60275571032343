import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class BancoGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [
            { path: '/banco', role: Role.BANCO_LISTAR },
            { path: '/banco/cadastro', role: Role.BANCO_CADASTRAR },
            { path: '/banco/cadastro/[0-9]+/visualizar', role: Role.BANCO_BUSCAR },
            { path: '/banco/cadastro/[0-9]+/editar', role: Role.BANCO_ALTERAR },
        ];
    }
}
