import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'projeto-abertura-caixa-postal-grid-filtro',
    templateUrl: './projeto-abertura-caixa-postal-grid-filtro.component.html',
    styleUrls: ['./projeto-abertura-caixa-postal-grid-filtro.component.scss']
})
export class ProjetoAberturaCaixaPostalGridFiltroComponent
    implements OnInit {

    campoPesquisa: any;

    @Output() filtrar = new EventEmitter();

    constructor() {
    }

    ngOnInit() {

    }

    pesquisarPorTexto() {
        this.filtrar.emit(this.campoPesquisa);
    }

    limparCampoPesquisa() {
        this.campoPesquisa = '';
    }

}
