import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { FiltroProjetoObservacaoHistorico } from '../../shareds/interfaces/filtro-projeto-observacao-historico.interface';
import { ProjetoObservacaoHistorico } from '../../shareds/interfaces/projeto-observacao-historico.interface';
import { Projeto } from '../../shareds/interfaces/projeto.interface';
import { ProjetoObservacaoHistoricoService } from '../../shareds/services/projeto-observacao-historico.service';

@Component({
  selector: 'projeto-observacao-historico',
  templateUrl: './projeto-observacao-historico.component.html',
  styleUrls: ['./projeto-observacao-historico.component.scss']
})
export class ProjetoObservacaoHistoricoComponent extends FormBase implements OnInit {

  @Input() projeto: Projeto = {};

  public listaObservacoes: Array<ProjetoObservacaoHistorico> = []

  constructor(
    private _injector: Injector,
    private _projetoObservacaoHistoricoService: ProjetoObservacaoHistoricoService
  ) {
    super(_injector)
  }

  ngOnInit() {
    this.loadListaObservacoes();
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      observacao: []
    }
  }

  loadListaObservacoes() {
    const filtro: FiltroProjetoObservacaoHistorico = {
      idProjeto: this.projeto.id,
      sortField: 'data_criacao',
      sortOrder: 'desc'
    }
    this._spinner.show();
    this._projetoObservacaoHistoricoService.find(filtro).subscribe(result => {
      this.listaObservacoes = result.data;
      this._spinner.hide();
    },
      error => this.error(error))
  }

  salvar() {
    const observacao: ProjetoObservacaoHistorico = {
      idProjeto: this.projeto.id,
      observacao: this.form.get('observacao').value
    }

    this._spinner.show();
    this._projetoObservacaoHistoricoService.create(observacao).subscribe(result => {
      this._spinner.hide();
      this.form.reset();
      this.loadListaObservacoes();
    },
      error => this.error(error))
  }

}
