import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjetoProcessoStatusService } from '../services/projeto-processo-status.service';
import { ProjetoProcessoStatus } from '../interfaces/projeto-processo-status.interface';

@Injectable()
export class ProjetoProcessoStatusListaResolver implements Resolve<ProjetoProcessoStatus> {

    constructor(private _service: ProjetoProcessoStatusService) { };

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._service.find().pipe(map((result) => result.data));
    }
}


