import { Component, Injector, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Checklist } from 'app/main/checklist/shared/interfaces/checklist.interface';

@Component({
    selector: 'checklist-inicio',
    templateUrl: './checklist-inicio.component.html',
    styleUrls: ['./checklist-inicio.component.scss'],
})
export class ChecklistInicioComponent implements OnInit {
    @Input() checklist: Checklist;

    constructor(private _router: Router, private _route: ActivatedRoute) {}

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            if (
                data &&
                data.cotacoesCliente &&
                data.cotacoesCliente.totalCotacao > 0
            ) {
                this._router.navigateByUrl('/checklist');
            }
        });
    }

    iniciar(): void {
        this._router.navigate(['checklist', this.checklist.id, 'contato']);
    }
}
