import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { FiltroEmpresa } from '../../shareds/interfaces/filtro-empresa.interface';
import { Empresa } from '../../shareds/interfaces/empresa.interface';
import { EmpresaService } from '../../shareds/services/empresa.service';
import { EmpresaModalService } from '../../shareds/services/empresa-modal.service';
import { Checklist } from 'app/main/checklist/shared/interfaces/checklist.interface';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';

@Component({
    selector: 'empresa-grid',
    templateUrl: './empresa-grid.component.html',
    styleUrls: ['./empresa-grid.component.scss'],
})
export class EmpresaGridComponent
    extends GridBase<Empresa, FiltroEmpresa, EmpresaService>
    implements OnChanges, OnInit {
    @Input() checklist: Checklist;

    constructor(
        protected _service: EmpresaService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalEmpresa: EmpresaModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    pesquisarPorTexto(): void {
        const campoPesquisa = this.campoPesquisa
            .toLocaleLowerCase()
            .replace(/\./g, '')
            .replace(/\-/g, '')
            .replace(/\//g, '')
            .replace(/ /g, '');

        if (campoPesquisa) {
            this.filtro.filter = campoPesquisa;
            this.atualizarGrid(this.filtro);
        }
    }

    ngOnInit(): void {
        this.initGrid(this.getFiltro());
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.getFiltro());
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'inscricao', label: '' },
            { chave: 'nome', label: '' },
        ];
    }

    cadastrar() {
        this._modalEmpresa
            .empresaCadastro({ idChecklist: this.checklist.id })
            .subscribe(() => {
                this.atualizarGrid(this.getFiltro());
            });
    }

    visualizar(empresa: Empresa) {
        this._modalEmpresa.empresaDetalhe(empresa);
    }

    editar(empresa: Empresa) {
        empresa.idChecklist = this.checklist.id
        this._modalEmpresa.empresaCadastro(empresa).subscribe(() => {
            this.atualizarGrid(this.getFiltro());
        });
    }

    excluir(empresa: Empresa) {
        this._confirmation
            .confirm({ titulo: 'Deseja realmente excluir esse item?' })
            .subscribe((result) => {
                if (result) {
                    this._service.delete(empresa.id).subscribe(() => {
                        this._notification.sucess(
                            'Empresa excluída com sucesso!'
                        );
                        this.atualizarGrid();
                    });
                }
            });
    }

    private getFiltro(): FiltroEmpresa {
        this.filtro = this.filtro ? this.filtro : {};
        this.filtro.idChecklist = this.checklist.id;
        return this.filtro;
    }
}
