import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FaturamentoRecorrenteEditarLote } from '../../shared/interfaces/faturamento-recorrente-editar-lote.interface';

@Component({
  selector: 'modal-editar-lote-faturamento-recorrente',
  templateUrl: './modal-editar-lote-faturamento-recorrente.component.html',
  styleUrls: ['./modal-editar-lote-faturamento-recorrente.component.scss']
})
export class ModalEditarLoteFaturamentoRecorrenteComponent implements OnInit {

  tituloModal: string = 'Editar Faturamento Recorente em Lote';

  constructor(
    public dialogRef: MatDialogRef<ModalEditarLoteFaturamentoRecorrenteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FaturamentoRecorrenteEditarLote
  ) { }

  ngOnInit() { }

  fechar(): void {
    this.dialogRef.close();
  }

  atualizado(result) {
    this.dialogRef.close(result);
  }

}
