import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PerfilGridPageComponent } from './pages/perfil-grid-page/perfil-grid-page.component';
import { PerfilCadastroPageComponent } from './pages/perfil-cadastro-page/perfil-cadastro-page.component';
import { PerfilGuard } from './perfil.guard';
import { PerfilDetalheResolver } from './resolvers/perfil-detalhe.resolver';
import { PermissaoListaResolver } from '../permissao/resolvers/permissao-lista.resolver';
import { PermissaoGrupoListaResolver } from '../permissao/resolvers/permissao-grupo-lista.resolver';
import { UsuarioTipoResolver } from 'app/main/usuario/resolvers/usuario-tipo.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [PerfilGuard],
        canLoad: [PerfilGuard],
        component: PerfilGridPageComponent,
    },
    {
        path: 'cadastro',
        canActivate: [PerfilGuard],
        canLoad: [PerfilGuard],
        resolve: {
            permissoesGrupo: PermissaoGrupoListaResolver,
            usuarioTipos: UsuarioTipoResolver,
        },
        component: PerfilCadastroPageComponent,
    },
    {
        path: 'cadastro/:id',
        canActivate: [PerfilGuard],
        canLoad: [PerfilGuard],
        resolve: {
            perfil: PerfilDetalheResolver,
            permissoesGrupo: PermissaoGrupoListaResolver,
            usuarioTipos: UsuarioTipoResolver,
        },
        component: PerfilCadastroPageComponent,
    },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class PerfilRoutingModule {}
