import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-perfil-cadastro-page',
    templateUrl: './perfil-cadastro-page.component.html',
    styleUrls: ['./perfil-cadastro-page.component.scss'],
})
export class PerfilCadastroPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
