import { Component, OnInit } from '@angular/core';
import { FiltroChecklist } from '../../shared/interfaces/filtro-checklist.interface';

@Component({
    selector: 'checklist-grid-page',
    templateUrl: './checklist-grid-page.component.html',
    styleUrls: ['./checklist-grid-page.component.scss'],
})
export class ChecklistGridPageComponent implements OnInit {
    filtro: FiltroChecklist;

    constructor() {}

    ngOnInit(): void {}

    filtrar(filtro: FiltroChecklist): void {
        this.filtro = Object.assign({}, filtro);
    }
}
