import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Injector, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Banco } from '../../shareds/interfaces/banco.interface';
import { FiltroBanco } from '../../shareds/interfaces/filtro-banco.interface';
import { BancoModalService } from '../../shareds/services/banco-modal.service';
import { BancoService } from '../../shareds/services/banco.service';

@Component({
  selector: 'banco-grid',
  templateUrl: './banco-grid.component.html',
  styleUrls: ['./banco-grid.component.scss']
})
export class BancoGridComponent extends GridBase<Banco, FiltroBanco, BancoService> implements OnInit, OnChanges {
  selection = new SelectionModel<Banco>(true, []);

  constructor(
    protected _service: BancoService,
    protected _injector: Injector,
    protected _changeDetectorRefs: ChangeDetectorRef,
    protected _modalHistoricoBanco: BancoModalService
  ) {
    super(_service, _injector, _changeDetectorRefs, 'BancoGridComponent');
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.atualizarGrid(this.filtro || {});
  }

  configDisplayedColumns(): Array<GridCollumn> {
    return [
      { chave: 'select' },
      { chave: 'seleciona' },
      { chave: 'codigo', label: 'N. Banco' },
      { chave: 'nome', label: 'Banco' },
      { chave: 'apelido', label: 'Apelido' },
      { chave: 'sla', label: 'SLA' }
    ];
  }

  ngOnInit() {
    this.initGrid(this.filtro || {});
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows =
      this.paginacao.pageSize <= this.paginacao.length
        ? this.paginacao.pageSize
        : this.paginacao.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listaDataSource.data.forEach((row) =>
        this.selection.select(row)
      );
  }

  checkboxLabel(row?: Banco): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
      }`;
  }

  criar() {
    this._router.navigateByUrl(`banco/cadastro`);
  }

  editar(banco: Banco) {
    this._router.navigateByUrl(`banco/cadastro/${banco.id}/editar`);
  }

  visualizar(banco: Banco) {
    this._router.navigateByUrl(`banco/cadastro/${banco.id}/visualizar`);
  }

  historico(banco: Banco) {
    this._modalHistoricoBanco.historic(banco);
  }

  excluir(banco: Banco) {
    this._confirmation
      .confirm({
        titulo: 'Deseja excluir o banco?',
        mensagem:
          'Ao remover o banco o mesmo não ficará mais disponível',
      })
      .subscribe((result) => {
        if (result) {
          this._service.delete(banco.id).subscribe(
            () => {
              this.atualizarGrid();
              this.sucess('Banco removido com sucesso!');
            },
            (error) => this.error(error)
          );
        }
      });
  }
}
