import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OsEditar } from '../../shareds/interfaces/os-editar.interface';

@Component({
    selector: 'modal-editar-os',
    templateUrl: './modal-editar-os.component.html',
    styleUrls: ['./modal-editar-os.component.scss'],
})
export class ModalEditarOsComponent implements OnInit {
    tituloModal: string = 'Editar Os';

    constructor(
        public dialogRef: MatDialogRef<ModalEditarOsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OsEditar
    ) {}
    ngOnInit() {}

    fechar(): void {
        this.dialogRef.close();
    }

    atualizado(result) {
        this.dialogRef.close(result);
    }
}
