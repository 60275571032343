import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
} from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import {
    MatDialogModule,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import { EmpresaRoutingModule } from './empresa-routing.module';

import { EmpresaCadastroComponent } from './components/empresa-cadastro/empresa-cadastro.component';
import { EmpresaGridComponent } from './components/empresa-grid/empresa-grid.component';
import { EmpresaGridFiltroComponent } from './components/empresa-grid-filtro/empresa-grid-filtro.component';
import { EmpresaDetalheComponent } from './components/empresa-detalhe/empresa-detalhe.component';

import { EmpresaService } from './shareds/services/empresa.service';
import { EmpresaModalService } from './shareds/services/empresa-modal.service';
import { ModalEmpresaCadastroComponent } from './modal/modal-empresa-cadastro/modal-empresa-cadastro.component';
import { ModalEmpresaDetalheComponent } from './modal/modal-empresa-detalhe/modal-empresa-detalhe.component';
import { ModalEmpresaGridComponent } from './modal/modal-empresa-grid/modal-empresa-grid.component';
import { EmpresaDetalheResolver } from './shareds/resolvers/empresa-detalhe.resolver';

import { EmpresaGuard } from './empresa.guard';
import { EmpresaListaResolver } from './shareds/resolvers/empresa-lista.resolver';

@NgModule({
    declarations: [
        EmpresaCadastroComponent,
        EmpresaDetalheComponent,
        EmpresaGridComponent,
        EmpresaGridFiltroComponent,
        ModalEmpresaCadastroComponent,
        ModalEmpresaDetalheComponent,
        ModalEmpresaGridComponent,
    ],

    imports: [
        CommonModule,
        EmpresaRoutingModule,
        UtilsModule,

        //TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatDialogModule,
        MatButtonModule,

        //FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
    ],

    exports: [EmpresaGridComponent],
    providers: [
        EmpresaService,
        EmpresaModalService,
        EmpresaDetalheResolver,
        EmpresaGuard,
        EmpresaListaResolver,
    ],

    entryComponents: [
        ModalEmpresaGridComponent,
        ModalEmpresaCadastroComponent,
        ModalEmpresaDetalheComponent,
    ],
})
export class EmpresaModule { }
