import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { Gmud } from '../interfaces/gmud.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { GmudHistoricoAprovacao } from '../interfaces/gmud-historico-aprovacao.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GmudHistoricoAprovacaoService extends ServiceBase<GmudHistoricoAprovacao> {
    private path = `${environment.server}/gmud`;

    constructor(private _http: HttpClient) {
        super();
    }

    findByGmud(idGmud): Observable<GmudHistoricoAprovacao> {
        const url = `${this.path}/${idGmud}/historicoAprovacao`;
        return this._http.get<Response<GmudHistoricoAprovacao>>(url).pipe(map(result => result.data));
    }
}
