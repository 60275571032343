import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentoImporteComponent } from './components/documento-importe/documento-importe.component';
import { DocumentImportService } from './shareds/services/document-import.service';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatIcon, MatIconModule, MatInputModule, MatSelectModule } from '@angular/material';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ModalDocumentUploadComponent } from './modal/modal-document-upload/modal-document-upload.component';
import { DocumentModalService } from './shareds/services/document-modal.service';
import { DocumentoExporteComponent } from './components/documento-exporte/documento-exporte.component';
import { UtilsModule } from '@utils/utils.module';
import { DocumentoImporteChecklistDadosEmpresaListaResolver } from './shareds/resolvers/documento-importe-checklist-dados-empresa-lista.resolver';



@NgModule({
  declarations: [DocumentoImporteComponent, ModalDocumentUploadComponent, DocumentoExporteComponent],
  imports: [
    CommonModule,
    UtilsModule,

    // FORMULARIO
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatIconModule,
    DragDropModule,
  ],
  providers: [
    DocumentImportService,
    DocumentModalService,

    DocumentoImporteChecklistDadosEmpresaListaResolver,
  ],
  entryComponents: [
    ModalDocumentUploadComponent
  ],
  exports: [
    DocumentoImporteComponent
  ]
})
export class DocumentImportModule { }
