import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'perfis',
        title: 'Perfis',
        type: 'item',
        icon: 'https',
        url: '/acl/perfil',
        role: 'PERFIL_LISTAR',
        cy: 'menu-perfil',
    },
    {
        id: 'usuarios',
        title: 'Usuários',
        type: 'item',
        icon: 'group',
        url: '/usuario',
        role: 'USUARIO_LISTAR',
        cy: 'menu-usuario',
    },
    {
        id: 'grupoEmpresas',
        title: 'Grupo de Empresas',
        type: 'item',
        icon: 'business',
        url: '/grupo-empresas',
        role: 'USUARIO_LISTAR',
        cy: 'menu-grupo-empresa',
    },
    {
        id: 'cotacoes',
        title: 'Cotações',
        type: 'item',
        icon: 'assignment',
        url: '/cotacao',
        role: 'COTACAO_LISTAR',
        cy: 'menu-cotacao',
    },
    {
        id: 'importacao',
        title: 'Importações',
        type: 'collapsable',
        icon: 'cloud_upload',
        url: '',
        role: 'IMPORTACAO_LISTAR',
        cy: 'menu-importacao',
        children: [
            {
                id: 'importacao-cotacao',
                title: 'Cotacao',
                type: 'item',
                icon: 'cloud_upload',
                url: '/importacao/cotacao',
                role: 'IMPORTACAO_LISTAR',
                cy: 'menu-importacao-cotacao',
            },
            {
                id: 'importacao-os',
                title: 'OS',
                type: 'item',
                icon: 'cloud_upload',
                url: '/importacao/os',
                role: 'OS_IMPOTACAO_XLS',
                cy: 'menu-importacao-os',
            }
        ]
    },
    {
        id: 'checklist',
        title: 'Checklist',
        type: 'item',
        icon: 'playlist_add_check',
        url: '/checklist',
        role: 'CHECKLIST_LISTAR',
        cy: 'menu-checklist',
    },
    {
        id: 'os',
        title: 'OS',
        type: 'item',
        icon: 'business_center',
        url: '/os',
        role: 'PROJETO_BUSCA',
        cy: 'menu-os',
    },
    {
        id: 'relacionamento-abertura',
        title: 'Relacionamento Abertura',
        type: 'item',
        icon: 'business_center',
        url: '/relacionamento-abertura',
        role: 'RELACIONAMENTO_ABERTURA_LISTAR',
        cy: 'menu-relacionamento-abertura',
    },
    {
        id: 'projeto',
        title: 'Projetos',
        type: 'item',
        icon: 'assignment_turned_in',
        url: '/projeto',
        role: 'PROJETO_BUSCA',
        cy: 'menu-projetos',
    },
    {
        id: 'time',
        title: 'Time',
        type: 'item',
        icon: 'group_work',
        url: '/time',
        role: 'TIME_LISTAR',
        cy: 'menu-time',
    },    
    {
        id: 'contrato',
        title: 'Contrato',
        type: 'item',
        icon: 'archive',
        url: '/contrato',
        role: 'CONTRATO_LISTAR',
        cy: 'menu-contrato',
    },
    {
        id: 'gmud',
        title: 'GMUD',
        type: 'item',
        icon: 'dvr',
        url: '/gmud',
        role: 'GMUD_LISTAR',
        cy: 'menu-gmud',
    },
    // {
    //     id: 'faturamento',
    //     title: 'Faturamento',
    //     type: 'item',
    //     icon: 'attach_money',
    //     url: '/faturamento',
    //     role: 'FATURAMENTO_LISTAR',
    // },
    {
        id: 'relatorio',
        title: 'Relatórios',
        type: 'item',
        icon: 'pie_chart',
        url: '/relatorio',
        role: 'RELATORIO_LISTAR',
        cy: 'menu-relatorios',
    },
    {
        id: 'bi',
        title: 'BI',
        type: 'collapsable',
        icon: 'pie_chart',
        url: '',
        role: 'BI_MENU',
        cy: 'menu-bi',
        children: [
            {
                id: 'bi-suporte',
                title: 'Suporte',
                type: 'item',
                icon: 'pie_chart',
                url: '/bi/suporte',
                role: 'BI_SUPORTE',
                cy: 'bi-suporte',
            },
            {
                id: 'bi-bu',
                title: 'BU\'s',
                type: 'item',
                icon: 'pie_chart',
                url: '/bi/bu',
                role: 'BI_BU',
                cy: 'bi-bu',
            },
            {
                id: 'bi-painel-suporte',
                title: 'Painel Suporte',
                type: 'item',
                icon: 'pie_chart',
                url: '/bi/painel-suporte',
                role: 'BI_PAINEL_SUPORTE',
                cy: 'bi-painel-suporte',
            },
            {
                id: 'bi-diretoria',
                title: 'CSC Tecnologia',
                type: 'item',
                icon: 'pie_chart',
                url: '/bi/csc',
                role: 'BI_CSC_TECNOLOGIA',
                cy: 'bi-diretoria',
            },
            {
                id: 'bi-relatorio',
                title: 'Relatório de Projetos',
                type: 'item',
                icon: 'pie_chart',
                url: '/bi/relatorio',
                role: 'BI_DIRETORIA',
                cy: 'bi-relatorio',
            },
            {
                id: 'bi-JBS',
                title: 'Relatório JBS',
                type: 'item',
                icon: 'pie_chart',
                url: '/bi/jbs',
                role: 'BI_JBS',
                cy: 'bi-relatorio',
            },
            {
                id: 'bi-Produto',
                title: 'Relatório Financeiro',
                type: 'item',
                icon: 'pie_chart',
                url: '/bi/produto',
                role: 'BI_PRODUTO',
                cy: 'bi-relatorio',
            },
        ]
    },

    {
        id: 'configuracoes',
        title: 'Configurações',
        type: 'collapsable',
        icon: 'build',
        url: '',
        role: 'RELATORIO_LISTAR',
        cy: 'menu-configuracao',
        children: [
            {
                id: 'banco',
                title: 'Bancos',
                type: 'item',
                icon: 'account_balance',
                url: '/banco',
                role: 'BANCO_LISTAR',
                cy: 'menu-configuracoes-banco',
            },
            //         {
            //             id: 'adquirente',
            //             title: 'Adquirentes',
            //             type: 'item',
            //             icon: 'supervised_user_circle',
            //             url: '/adquirente',
            //             role: 'ADIQUIRENTE_LISTAR',
            //             cy: 'menu-adquirente',
            //         },
            //         {
            //             id: 'plano-projeto',
            //             title: 'Plano de Projeto',
            //             type: 'item',
            //             icon: 'book',
            //             url: '/plano-projeto',
            //             role: 'CARTA_ABERTURA_ALTERAR',
            //             cy: 'menu-plano-projeto',
            //         },
            //         {
            //             id: 'carta-abertura',
            //             title: 'Carta de Abertura',
            //             type: 'item',
            //             icon: 'mail',
            //             url: '/carta-abertura',
            //             role: 'CARTA_ABERTURA_ALTERAR',
            //             cy: 'menu-carta-abertura',
            //         }
        ]
    },
];
