import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ContratoItem } from '../../shared/interfaces/contrato-item.interface';

@Component({
  selector: 'modal-contrato-ajustar-valor',
  templateUrl: './modal-contrato-ajustar-valor.component.html',
  styleUrls: ['./modal-contrato-ajustar-valor.component.scss']
})
export class ModalContratoAjustarValorComponent implements OnInit {
  tituloModal: string = 'Ajustar valor';


  constructor(
    public dialogRef: MatDialogRef<ModalContratoAjustarValorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Array<number>
  ) { }
  ngOnInit() { }


  fechar(): void {
    this.dialogRef.close();
  }

  atualizado(val) {
    this.dialogRef.close(val);
  }
}

