import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { FaturamentoProduto } from '../interfaces/faturamento-produto.interface';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { FiltroFaturamentoProduto } from '../interfaces/filtro-faturamento-produto.interface';

@Injectable({
    providedIn: 'root',
})
export class FaturamentoProdutoService extends ServiceGridBase<FaturamentoProduto, FiltroFaturamentoProduto> {
    private path = `${environment.server}/faturamento/produtos`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroFaturamentoProduto): Observable<Response<Array<FaturamentoProduto>>> {
        const params: string = this.toGetParams(filtro, ['idFaturamento']);
        const url = `${this.path}/${filtro.idFaturamento}${params}`;
        return this._http.get<Response<Array<FaturamentoProduto>>>(url);
    }

}
