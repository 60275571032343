import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { FaturamentoRecorrenteStatus } from '../interfaces/faturamento-recorrente-status.interface';

@Injectable({
    providedIn: 'root',
})
export class FaturamentoRecorrenteStatusService extends ServiceBase<FaturamentoRecorrenteStatus> {
    private path: string = `${environment.server}/faturamento-recorrente-status`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: any): Observable<Response<Array<FaturamentoRecorrenteStatus>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<FaturamentoRecorrenteStatus>>>(url);
    }
}
