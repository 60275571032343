import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PerfilRoutingModule } from './perfil-routing.module';
import { PerfilGridComponent } from './components/perfil-grid/perfil-grid.component';
import { PerfilFormComponent } from './components/perfil-form/perfil-form.component';
import { PerfilGridPageComponent } from './pages/perfil-grid-page/perfil-grid-page.component';
import { PerfilCadastroPageComponent } from './pages/perfil-cadastro-page/perfil-cadastro-page.component';
import { PerfilGuard } from './perfil.guard';
import { UtilsModule } from '@utils/utils.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { PerfilDetalheResolver } from './resolvers/perfil-detalhe.resolver';
import { PerfilDetalheComponent } from './components/perfil-detalhe/perfil-detalhe.component';
import { PerfilDetalheModalComponent } from './modal/perfil-detalhe-modal/perfil-detalhe-modal.component';
import { PerfilModalService } from './shareds/services/perfil-modal.service';
import { PerfilService } from './shareds/services/perfil.service';

@NgModule({
    declarations: [
        PerfilGridComponent,
        PerfilFormComponent,
        PerfilGridPageComponent,
        PerfilCadastroPageComponent,
        PerfilDetalheComponent,
        PerfilDetalheModalComponent,
    ],
    imports: [
        CommonModule,
        PerfilRoutingModule,

        UtilsModule,

        // TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,

        // FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
    ],
    providers: [
        PerfilGuard,
        PerfilDetalheResolver,
        PerfilModalService,
        PerfilService,
    ],
    entryComponents: [PerfilDetalheModalComponent],
})
export class PerfilModule {}
