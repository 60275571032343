import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Route } from '@angular/router';

@Component({
    selector: 'modal-checklist-relacionamento-empresa-cadastro',
    templateUrl:
        './modal-checklist-relacionamento-empresa-cadastro.component.html',
    styleUrls: [
        './modal-checklist-relacionamento-empresa-cadastro.component.scss',
    ],
})
export class ModalChecklistRelacionamentoEmpresaCadastroComponent
    implements OnInit {
    tituloModal: string = 'Selecionar Dados da Empresa';
    relacionamentoIds: any;

    constructor(
        public dialogRef: MatDialogRef<ModalChecklistRelacionamentoEmpresaCadastroComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        if (this.data.relacionamento.descricao) {
            this.tituloModal = 'Selecionar Contas para produto financeiro ' + this.data.relacionamento.descricao;
        }
        if (this.data.relacionamento.produto) {
            this.tituloModal = 'Selecionar Dados da Empresa para produto ' + this.data.relacionamento.produto;
        }
    }

    returnRelacionamento(relacionamentoIds: any) {
        this.relacionamentoIds = relacionamentoIds;
        this.fechar();
    }

    fechar(): void {
        this.dialogRef.close(this.relacionamentoIds);
    }
}
