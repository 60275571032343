import { Injector } from '@angular/core';
import { FuseNavigation } from '@fuse/types';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';
import { PermissaoUtil } from '@utils/utils/class/permissao.utl';

export abstract class NavbarBase {
    usuarioLogado: UsuarioLogado;
    private _authLocalStorage: AuthLocalStorageService;

    constructor(injector: Injector) {
        this._authLocalStorage = injector.get(AuthLocalStorageService);
        this.usuarioLogado = this._authLocalStorage.getUsuario();
    }

    permissions(navigation: FuseNavigation[]): FuseNavigation[] {
        if (!this._authLocalStorage.isLoggedIn()) {
            return [];
        }

        return navigation.filter((nav: FuseNavigation) => {
            let isAccess: boolean;
            if (nav.role) {
                isAccess = PermissaoUtil.isAccess(this.usuarioLogado, nav.role);
            }
            return isAccess;
        });
    }
}
