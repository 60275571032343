import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FiltroContrato } from '../interfaces/filtro-contrato.interface';
import { Contrato } from '../interfaces/contrato.interface';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { Cotacao } from 'app/main/cotacao/shared/interfaces/cotacao.interface';

@Injectable({
    providedIn: 'root',
})
export class ContratoService extends ServiceGridBase<Contrato, FiltroContrato> {
    private path = `${environment.server}/contrato`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroContrato): Observable<Response<Array<Contrato>>> {
        const params: string = this.toGetParams(filtro);
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<Contrato>>>(url);
    }

    findById(id: number | string): Observable<Response<Contrato>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Contrato>>(url);
    }

    update(contrato: Contrato): Observable<Response<Contrato>> {
        const url = `${this.path}/${contrato.id}`;
        return this._http.put<Response<Contrato>>(url, contrato);
    }

    createLote(idsCotacoes: Array<number>): Observable<Response<any>> {
        const idsCotacoesStr = idsCotacoes.join(',')
        const url = `${this.path}`;
        return this._http.post<Response<any>>(url, { cotacoes: idsCotacoesStr });
    }

    vincularCotacaoContrato(idContrato: number, cotacao: Cotacao): Observable<Response<any>> {
        const url = `${this.path}/${idContrato}/vincular`;
        return this._http.put<Response<any>>(url, { cotacao });
    }

}
