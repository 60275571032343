import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroOsAbertura } from '../interfaces/filtro-os-abertura.interface';
import { OsAbertura } from '../interfaces/os-abertura.interface';

@Injectable({
    providedIn: 'root',
})
export class OsAberturaService extends ServiceGridBase<
    OsAbertura,
    FiltroOsAbertura
> {
    private path: string = `${environment.server}/os`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroOsAbertura): Observable<Response<Array<OsAbertura>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/${filtro.idOs}/osabertura${params}`;
        return this._http.get<Response<Array<OsAbertura>>>(url);
    }

    save(osAbertura: OsAbertura): Observable<Response<OsAbertura>> {
        const url = `${this.path}/${osAbertura.idOs}/osabertura`;
        return this._http.post<Response<OsAbertura>>(url, osAbertura);
    }

    update(osAbertura: OsAbertura): Observable<Response<OsAbertura>> {
        const url = `${this.path}/osabertura/${osAbertura.id}`;
        return this._http.put<Response<OsAbertura>>(url, osAbertura);
    }

    delete(osAbertura: OsAbertura): Observable<Response<void>> {
        const url = `${this.path}/osabertura/${osAbertura.id}`;
        return this._http.delete<Response<void>>(url);
    }
}
