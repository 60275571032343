import { Component, Injector, OnInit } from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { DataUtil } from '@utils/utils/class/data.util';
import { ChecklistStatus } from '../../shared/interfaces/checklist-status.interface';
import { FiltroChecklist } from '../../shared/interfaces/filtro-checklist.interface';

@Component({
    selector: 'checklist-grid-filtro',
    templateUrl: './checklist-grid-filtro.component.html',
    styleUrls: ['./checklist-grid-filtro.component.scss'],
})
export class ChecklistGridFiltroComponent
    extends FormFiltroBase<FiltroChecklist>
    implements OnInit {
    listaStatus: Array<ChecklistStatus> = [];

    constructor(injector: Injector) {
        super('ChecklistGrid', injector, ChecklistGridFiltroCombos.combos());
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.optionList.listaStatus = data.listaStatus;
            this.refreshOptionsConfig();
        });
    }

    camposFiltro(): string[] {
        return [
            'dataInicio',
            'dataFim',
            'checklistStatusId',
            'cotacaoNumero',
            'cotacaoEmpresaRazao',
        ]
    }

    filtrarExec(): void {
        const filtro: FiltroChecklist = this.form.value;
        const tipoData = this.form.get('tipoData').value;
        const dataInicio = this.form.get('dataInicio').value;
        const dataFim = this.form.get('dataFim').value;

        const dataInicioStr = dataInicio ? DataUtil.toDateBackend(dataInicio) : '';
        const dataFimStr = dataFim ? DataUtil.toDateBackend(dataFim) : '';

        if (tipoData === 'data-criacao') {
            // if (dataInicioStr) filtro.dataCriacaoInicio = dataInicioStr;
            // if (dataFimStr) filtro.dataCriacaoFinal = dataFimStr;

            if (dataInicioStr) filtro.dataInicio = dataInicioStr;
            if (dataFimStr) filtro.dataFim = dataFimStr;
        }
        if (tipoData === 'data-finalizacao') {
            if (dataInicioStr) filtro.dataPagamentoSetupInicio = dataInicioStr;
            if (dataFimStr) filtro.dataPagamentoSetupFinal = dataFimStr;
            delete filtro.dataInicio;
            delete filtro.dataFim;
        }

        this.filtrar.emit(filtro);
    }

    filtrarExecEnter(event): void {
        if (event.keyCode === 13) {
            this.filtrarExec()
        }
    }

    protected crateForm(): { [key: string]: any } {
        return {
            tipoData: ['data-criacao'],
            dataInicio: [],
            dataFim: [],
            checklistStatusId: [],
            cotacaoNumero: [],
            cotacaoEmpresaRazao: [],
        };
    }
}


export class ChecklistGridFiltroCombos {
    static combos() {
        return [
            {
                listName: 'listaStatus',
                filterName: 'filterStatus',
                fieldValue: 'descricao',
            },
        ];
    }
}