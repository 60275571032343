import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'perfil-grid-page',
    templateUrl: './perfil-grid-page.component.html',
    styleUrls: ['./perfil-grid-page.component.scss'],
})
export class PerfilGridPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
