import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const senhaForteValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const senha: string = control.parent.get('senha').value;

    if (
        /[0-9a-zA-Z!@#$%&*]{8,}/.test(senha) &&
        /(?=.*[a-z])/.test(senha) &&
        /(?=.*[A-Z])/.test(senha) &&
        /(?=.*[!@#$%&*])/.test(senha) &&
        /(?=.*\d)/.test(senha)
    ) {
        return null;
    }

    return {
        senhaForte: {
            parsedDomain: true,
        },
    };
};
