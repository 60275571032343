import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FiltroImportacaoOsErro } from '../../shared/interfaces/filtro-importacao-os-erro.interfaces';
import { ImportacaoOsErro } from '../../shared/interfaces/importacao-os-erro.interfaces';
import { ImportacaoOs } from '../../shared/interfaces/importacao-os.interfaces';
import { ImportacaoOsErroService } from '../../shared/services/importacao-os-erro.service';

@Component({
  selector: 'importacao-os-erro',
  templateUrl: './importacao-os-erro.component.html',
  styleUrls: ['./importacao-os-erro.component.scss']
})
export class ImportacaoOsErroComponent
  extends GridBase<
  ImportacaoOsErro,
  FiltroImportacaoOsErro,
  ImportacaoOsErroService
  >
  implements OnInit {
  @Input() importacao: ImportacaoOs = {};

  constructor(
    service: ImportacaoOsErroService,
    injector: Injector,
    changeDetectorRefs: ChangeDetectorRef
  ) {
    super(service, injector, changeDetectorRefs);
  }

  ngOnInit(): void {
    this.filtro.idArquivo = this.importacao.id;
    this.initGrid(this.filtro);
  }

  configDisplayedColumns(): Array<GridCollumn> {
    return [
      { chave: 'linha', label: '' },
      { chave: 'coluna', label: '' },
      { chave: 'descricao', label: '' },
    ];
  }
}
