import { Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from '@utils/utils/confirmation/confirmation.service';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { isObject } from 'lodash';

export abstract class ComponentBase {
    protected _route: ActivatedRoute;
    protected _router: Router;
    protected _spinner: SpinnerService;
    protected _notification: NotificationService;
    protected _confirmation: ConfirmationService

    constructor(injector: Injector) {
        this._route = injector.get(ActivatedRoute);
        this._router = injector.get(Router);
        this._spinner = injector.get(SpinnerService);
        this._notification = injector.get(NotificationService);
        this._confirmation = injector.get(ConfirmationService)
    }

    protected error(error) {
        this._spinner.hide();
        if (error) {
            if (error.error.errors && isObject(error.error.errors)) {
                if (Object.keys(error.error.errors).length > 0) {                    
                    var arrayErrors = Object.keys(error.error.errors)
                        .map(function (key) {     
                            if (typeof key == "string") {
                                for (let i = 0; i < error.error.errors[key].length; i++) {
                                    const element = error.error.errors[key][i];
                                    error.error.errors[key][i] = element + ' ' + key.charAt(0).toUpperCase() + key.slice(1);
                                }
                            }
                            return error.error.errors[key];
                        });                        
                    arrayErrors.forEach(e => {                        
                        this._notification.error(e);
                    })
                } else {
                    this._notification.error(error.error.message);
                }
            } else if (error.error && Array.isArray(error.error)) {
                error.error.forEach(e => {
                    this._notification.error(e);
                })
            } else {
                this._notification.error(error.error.message);
            }
        }
        else {
            console.log(error)
        }
    }

    protected sucess(mensagem: string) {
        this._spinner.hide();
        this._notification.sucess(mensagem);
    }
}
