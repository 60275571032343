import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalEditarRelacionamentoAberturaComponent } from '../../modal/modal-editar-relacionamento-abertura/modal-editar-relacionamento-abertura.component';
import { ModalHistoricoDetalheRelacionamentoAberturaComponent } from '../../modal/modal-historico-detalhe-relacionamento-abertura/modal-historico-detalhe-relacionamento-abertura.component';
import { ModalHistoricoRelacionamentoAberturaComponent } from '../../modal/modal-historico-relacionamento-abertura/modal-historico-relacionamento-abertura.component';
import { RelacionamentoAberturaEditar } from '../interfaces/relacionamento-abertura-editar.interface';
import { RelacionamentoAbertura } from '../interfaces/relacionamento-abertura.interface';

@Injectable({
    providedIn: 'root',
})
export class RelacionamentoAberturaModalService {
    constructor(public dialog: MatDialog) { }

    cotacaoEditar(data: RelacionamentoAberturaEditar) {
        const dialogRef = this.dialog.open(ModalEditarRelacionamentoAberturaComponent, {
            width: '50%',
            data: data,
        });

        return dialogRef.afterClosed();
    }


    historico(data: RelacionamentoAbertura) {
        const dialogRef = this.dialog.open(ModalHistoricoRelacionamentoAberturaComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    historicoDetalhe(data: any) {
        const dialogRef = this.dialog.open(
            ModalHistoricoDetalheRelacionamentoAberturaComponent,
            {
                width: '90%',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }

}
