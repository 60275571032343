import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { ProjetoStatus } from '../interfaces/projeto-status.interface';
import { ProjetoStatusService } from '../services/projeto-status.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ProjetoStatusListaResolver implements Resolve<ProjetoStatus> {
    
    constructor(private _projetoStatusService: ProjetoStatusService){};
    
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
        ): Observable<any>|Promise<any>|any {
            return this._projetoStatusService.find().pipe(map((result) => result.data));
        }
    }
    
    
    