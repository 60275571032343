import { Component, Input, OnInit } from '@angular/core';
import { FiltroRelatorio } from '../../shareds/interfaces/filtro-relatorio.interface';

@Component({
  selector: 'relatorio-grid-page',
  templateUrl: './relatorio-grid-page.component.html',
  styleUrls: ['./relatorio-grid-page.component.scss']
})
export class RelatorioGridPageComponent implements OnInit {

  filtro: FiltroRelatorio

  constructor() { }

  ngOnInit() {
  }

  filtrar(filtro: FiltroRelatorio) {
    this.filtro = Object.assign({}, filtro)
  }
}
