import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalChecklistRelacionamentoEmpresaCadastroComponent } from '../../modal/modal-checklist-relacionamento-empresa-cadastro/modal-checklist-relacionamento-empresa-cadastro.component';
import { ModalChecklistRelacionamentoEmpresaDetalheComponent } from '../../modal/modal-checklist-relacionamento-empresa-detalhe/modal-checklist-relacionamento-empresa-detalhe.component';
import { ChecklistRelacionamento } from '../interfaces/checklist-relacionamento.interface';
import { Checklist } from '../interfaces/checklist.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistRelacionamentoEmpresaModalService {
    constructor(public dialog: MatDialog) { }

    checklistRelacionamentoEmpresaCadastro(data: any) {
        const dialogRef = this.dialog.open(
            ModalChecklistRelacionamentoEmpresaCadastroComponent,
            {
                width: '95%',
                height: '95%',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }

    checklistRelacionamentoEmpresaDetalhe(data: any) {
        const dialogRef = this.dialog.open(
            ModalChecklistRelacionamentoEmpresaDetalheComponent,
            {
                width: '95%',
                height: '95%',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }
}
