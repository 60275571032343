import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { Checklist } from 'app/main/checklist/shared/interfaces/checklist.interface';
import { DocumentImportInterface } from '../../shareds/interfaces/document-import.interface';
import { DocumentImportService } from '../../shareds/services/document-import.service';
import { DocumentModalService } from '../../shareds/services/document-modal.service';

@Component({
    selector: 'documento-importe',
    templateUrl: './documento-importe.component.html',
    styleUrls: ['./documento-importe.component.scss'],
})
export class DocumentoImporteComponent extends FormBase implements OnInit {
    form!: FormGroup;
    @Input() chave: string;
    @Input() checklist: Checklist;
    @Input() hideImportarDadosEmpresa: boolean;
    private listaDocumentos: DocumentImportInterface;
    private selectedFile: Boolean = false;
    private downloadFile: string;
    isVisualizacao: boolean = false;

    protected crateForm(): { [key: string]: any } {
        return {
            documentImport: ['', Validators.required],
        };
    }

    constructor(
        private _injector: Injector,
        private _documentImportService: DocumentImportService,
        private _documentModalService: DocumentModalService
    ) {
        super(_injector);
    }

    get f() {
        return this.form.controls;
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.listaDocumentos = data.listaDocumentos;
            this.isVisualizacao = data.isVisualizacao;
        });
    }

    onChange() {
        this.selectedFile = false;
        if (
            this.f.documentImport.value != '' ||
            this.f.documentImport.value != undefined
        ) {
            this.selectedFile = true;
        }
    }

    download() {
        let idDocumentoArquivo = parseInt(this.f.documentImport.value.id);
        this._documentImportService.download(idDocumentoArquivo).subscribe(
            (result) => {
                DownloadUtil.base64(
                    result.data.arquivo,
                    result.data.name,
                    result.data.type
                );
            },
            (error) => this.error(error)
        );
    }

    uploadData() {
        let data = {
            tituloModal: 'Importar dados da empresa',
            chave: this.chave,
            file: undefined,
            origem: 'CHECKLIST_DADOS_EMPRESA',
            checklist: this.checklist,
        };
        this._documentModalService.upload(data);
    }

    uploadEnterprise() {
        let data = {
            tituloModal: 'Importar empresa',
            chave: this.chave,
            file: undefined,
            origem: 'CHECKLIST_EMPRESA',
            checklist: this.checklist,
        };
        this._documentModalService.upload(data);
    }
}
