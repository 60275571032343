import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import * as moment from 'moment';
import { ContratoPrioridade } from '../../shared/interfaces/contrato-prioridade.interface';
import { ContratoSituacao } from '../../shared/interfaces/contrato-situacao.interface';
import { ContratoStatus } from '../../shared/interfaces/contrato-status.interface';
import { FiltroContrato } from '../../shared/interfaces/filtro-contrato.interface';
import { FiltroPreContrato } from '../../shared/interfaces/filtro-pre-contrato.interface';

@Component({
  selector: 'pre-contrato-grid-filtro',
  templateUrl: './pre-contrato-grid-filtro.component.html',
  styleUrls: ['./pre-contrato-grid-filtro.component.scss']
})
export class PreContratoGridFiltroComponent extends FormFiltroBase<FiltroPreContrato> implements OnInit {

  desabilitarDataFinal: boolean = false

  listaStatus: Array<ContratoStatus> = []
  listaSituacao: Array<ContratoSituacao> = []
  listaPrioridade: Array<ContratoPrioridade> = []
  listaSegmento: Array<any> = []

  @Output() filtrarPreContrato = new EventEmitter<FiltroPreContrato>();

  constructor(injector: Injector) {
    super('PreContratoGrid', injector, PreContratoGridFiltroCombos.combos());
  }

  ngOnInit() {
    this._route.data.subscribe((data) => {
      this.optionList.listaStatus = data.statuss;
      this.optionList.listaSituacao = data.situacoes;
      this.optionList.listaSegmento = data.segmentos;
      this.optionList.listaProdutos = data.produtos;
      this.optionList.listaPrioridade = data.prioridades;
      this.refreshOptionsConfig();
    });
  }

  camposFiltro(): string[] {
    return [
      'preNomeEmpresa',
      'preCnpj',
      'preNumeroCotacao',
      'preDataInicial',
      'preDataFinal',
      'preProduto',
      'preSegmento',
      'prePrioridade',
    ];
  }

  protected crateForm(): { [key: string]: any } {
    return {
      preNomeEmpresa: [],
      preCnpj: [],
      preNumeroCotacao: [],
      preTipoData: ['dataCriacao'],
      preDataInicial: [],
      preDataFinal: [],
      preProduto: [],
      preSegmento: [],
      prePrioridade: [],
    };
  }

  limpar(): void {
    this.form.reset();
    this.form.patchValue({ preTipoData: 'dataCriacao' })
    this.filtro = Object.assign({});
    this.filtrar.emit(this.filtro);
    this.filtrarExec();
  }

  filtrarExecEnter(event): void {
    if (event.keyCode === 13) {
      this.filtrarExec();
    }
  }

  filtrarExec(): void {
    const nomeEmpresa = this.form.get('preNomeEmpresa').value;
    let cnpj = this.form.get('preCnpj').value;
    const numeroCotacao = this.form.get('preNumeroCotacao').value;
    const tipoData = this.form.get('preTipoData').value;
    const dataInicial = this.form.get('preDataInicial').value;
    const dataFinal = this.form.get('preDataFinal').value;
    const segmento = this.form.get('preSegmento').value;
    const prioridade = this.form.get('prePrioridade').value;
    const produto = this.form.get('preProduto').value;

    cnpj = cnpj ? cnpj.replace(/[\-\.\/]/g, '') : '';

    const filtro: FiltroContrato = {
      nomeEmpresa: nomeEmpresa,
      inscricaoEmpresa: cnpj,
      numeroCotacao: numeroCotacao,
      dataAceiteInicial: dataInicial && tipoData == 'dataAceite' ? moment(dataInicial, 'yyyy-MM-dd').toDate() : null,
      dataAceiteFinal: dataFinal && tipoData == 'dataAceite' ? moment(dataFinal, 'yyyy-MM-dd').toDate() : null,
      dataCriacaoInicial: dataInicial && tipoData == 'dataCriacao' ? moment(dataInicial, 'yyyy-MM-dd').toDate() : null,
      dataCriacaoFinal: dataFinal && tipoData == 'dataCriacao' ? moment(dataFinal, 'yyyy-MM-dd').toDate() : null,
      segmentoId: segmento ? segmento.id : null,
      prioridade: prioridade ? prioridade.id : null,
      produto: produto ? produto : null,
    };

    this.filtrarPreContrato.emit(filtro);
  }

  editar() { }

}

export class PreContratoGridFiltroCombos {
  static combos() {
    return [
      {
        listName: 'listaSituacao',
        filterName: 'filterSituacao',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaStatus',
        filterName: 'filterStatus',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaProdutos',
        filterName: 'filterProduto',
        fieldValue: 'nome',
      },
      {
        listName: 'listaPrioridade',
        filterName: 'filterPrioridade',
        fieldValue: 'nome',
      },
      {
        listName: 'listaSegmento',
        filterName: 'filterSegmento',
        fieldValue: 'nome',
      },
    ];
  }
}
