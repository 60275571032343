import {
    ChangeDetectorRef,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { SelectionModel } from '@angular/cdk/collections';
import { Faturamento } from '../../shared/interfaces/faturamento.interface';
import { FiltroFaturamento } from '../../shared/interfaces/filtro-faturamento.interface';
import { FaturamentoModalService } from '../../shared/services/faturamento-modal.service';
import { FaturamentoService } from '../../shared/services/faturamento.service';
import { FaturamentoStatus } from '../../shared/interfaces/faturamento-status.interface';
import { ModelBase } from '@utils/base/model-base/model-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FaturamentoSetupRelatorioService } from '../../shared/services/faturamento-setup-relatorio.service';

@Component({
    selector: 'faturamento-grid',
    templateUrl: './faturamento-grid.component.html',
    styleUrls: ['./faturamento-grid.component.scss'],
})
export class FaturamentoGridComponent extends GridBase<FaturamentoGrid, FiltroFaturamento, FaturamentoService> implements OnInit, OnChanges {

    selection = new SelectionModel<FaturamentoGrid>(true, []);

    constructor(
        protected _faturamentoService: FaturamentoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalFaturamento: FaturamentoModalService,
        private _modalFaturamentoEditar: FaturamentoModalService,
        private _faturamentoRelatorioservice: FaturamentoSetupRelatorioService,
    ) {
        super(_faturamentoService, _injector, _changeDetectorRefs, 'FaturamentoGrid');
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {}, () => this.selection.clear());
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows =
            this.paginacao.pageSize <= this.paginacao.length
                ? this.paginacao.pageSize
                : this.paginacao.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.listaDataSource.data.forEach((row) =>
                this.selection.select(row)
            );
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Faturamento): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
            }`;
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'select' },
            { chave: 'seleciona' },
            { chave: 'status', label: 'Status' },
            { chave: 'tipoSetup', label: 'Tipo de Setup' },
            { chave: 'tipoFaturamento', label: 'Tipo de Faturamento' },
            { chave: 'statusFinanceiro', label: 'Status Financeiro' },
            { chave: 'tipoCobranca', label: 'Tipo de Cobrança' },
            { chave: 'empresa', label: 'Empresa' },
            { chave: 'inscricao', label: 'Inscrição' },
            { chave: 'parcelamento', label: 'Parcelamento' },
            { chave: 'numeroContrato', label: 'Número de Contrato' },
            { chave: 'valorNota', label: 'Valor da Nota' },
            { chave: 'vencimento', label: 'Vencimento' },
            { chave: 'dataPagamento', label: 'Data de Pagamento' },
            { chave: 'dataEmissao', label: 'Data de Emissão' },
            { chave: 'cotacao', label: 'Cotação' },
            { chave: 'responsavel', label: 'Responsável' },
        ];
    }


    editarLote() {
        const faturamentosSelecionadas = this.selection.selected.map((v) => v.faturamento.id);
        this._modalFaturamentoEditar
            .faturamentoEditar({ idFaturamentos: faturamentosSelecionadas })
            .subscribe((result) => {
                if (result) {
                    this.selection.clear()
                    this.atualizarGrid(this.filtro);
                }
            });
    }

    gerarRelatorio() {
        this._spinner.show();
        this._faturamentoRelatorioservice.gerarRelatorio(this.filtro).subscribe(
            (result) => {
                // DownloadUtil.base64(
                //     result.data.arquivo,
                //     result.data.name,
                //     result.data.type
                // );
                this.sucess('Seu relatório está em processamento, acompanhe a tela de retirada de relatórios para download');
            },
            (error) => this.error(error)
        );
        this._spinner.hide();
    }

    editar(faturamentoGrid: FaturamentoGrid) {
        this._router.navigateByUrl(`faturamento/cadastro/${faturamentoGrid.faturamento.id}`)
    }

    visualizar(faturamentoGrid: FaturamentoGrid) {
        this._router.navigateByUrl(`faturamento/cadastro/${faturamentoGrid.faturamento.id}/visualizar`)
    }

    historico(faturamento: Faturamento) {
        this._modalFaturamento.historico(faturamento);
    }

}

export interface FaturamentoGrid extends ModelBase {
    dataDePagamento?: string;
    dataEmissao?: string;
    dataVencimento?: string;
    diasAVencer?: number;
    faturamento?: Faturamento;
    faturamentoParcelaStatus?: FaturamentoStatus;
    parcela?: string;
    valorNota?: number;
}