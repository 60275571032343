import { Time } from '@angular/common';
import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { DataUtil } from '@utils/utils/class/data.util';
import { TabelaDominio } from 'app/main/tabela-dominio/shareds/interfaces/tabela-dominio.interface';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { UsuarioModalService } from 'app/main/usuario/shared/services/usuario-modal.service';
import * as moment from 'moment';
import { GmudFluxo } from '../../shared/interfaces/gmud-fluxo.interface';
import { Gmud, GmudRequest } from '../../shared/interfaces/gmud.interface';
import { GmudService } from '../../shared/services/gmud.service';

@Component({
    selector: 'gmud-aba-gmud',
    templateUrl: './gmud-aba-gmud.component.html',
    styleUrls: ['./gmud-aba-gmud.component.scss']
})
export class GmudAbaGmudComponent extends FormBase implements OnInit {

    gmudFluxo: GmudFluxo = {}
    gmud: Gmud = {};
    isEdit: boolean = false;
    isOutros: boolean = false;
    responsavelProducao: Usuario;
    responsavelDesenvolvimento: Usuario;

    listaStatusGmud: Array<TabelaDominio> = []
    listaTipoGmud: Array<TabelaDominio> = []
    listaTipoMudancas: Array<TabelaDominio> = []
    listaJustificativas: Array<TabelaDominio> = []
    listaTimes: Array<Time> = []
    listaUrgencias: Array<TabelaDominio> = []
    listaImpactos: Array<TabelaDominio> = []
    listaExitos: Array<TabelaDominio> = []
    listaSolucoes: Array<TabelaDominio> = []

    constructor(
        protected _gmudService: GmudService,
        protected _injector: Injector,
        protected _changeDetectorRef: ChangeDetectorRef,
        private _modalUsuario: UsuarioModalService,
    ) {
        super(_injector);
    }

    ngOnInit() {
        this._route.data.subscribe(data => {
            this.listaStatusGmud = data.gmudStatus;
            this.listaTipoGmud = data.gmudTipos;

            this.listaTipoMudancas = data.gmudTipoMudancas;
            this.listaJustificativas = data.gmudJustificativas;
            this.listaUrgencias = data.gmudUrgencias;
            this.listaImpactos = data.gmudImpactos;
            this.listaExitos = data.gmudExitos;
            this.listaTimes = data.times;
            this.listaSolucoes = data.gmudSolucoes;


            if (data.gmudFluxo) {
                this.gmudFluxo = data.gmudFluxo
            }
            if (data.gmud) {
                this.gmud = data.gmud;
                this.isEdit = this.gmud.podeEditarGmud;
                this.setForm(data.gmud);

                //temporario
                // if (!this.gmud.gmudStatus || !this.gmud.gmudStatus.id) {
                //   this.isEdit = true;
                //   this.gmud.podeEditarGmud = true;
                // }
            }
        })
    }

    protected crateForm(): { [key: string]: any } {
        return {
            id: [],
            gmudTipo: ['', Validators.required],
            gmudTipoOutros: [''],
            dataExecucao: ['', Validators.required],
            horaExecucao: ['', Validators.required],
            gmudTipoMudanca: ['', Validators.required],
            gmudJustificativa: ['', Validators.required],
            usuarioRespProd: ['', Validators.required],
            usuarioRespDev: ['', Validators.required],
            gmudUrgencia: ['', Validators.required],
            gmudImpacto: ['', Validators.required],
            justificativaGrauImpacto: ['', Validators.required],
            gmudExito: ['', Validators.required],
            motivoFalha: [],
            gmudSolucao: ['', Validators.required],
            descricaoSolucao: ['', Validators.required],
        };
    }

    limparForm() {
        this.form.reset();
    }

    enviar() {
        const gmudId = this.form.get('id').value;
        const gmudTipo = this.form.get('gmudTipo').value;
        const gmudTipoOutros = this.form.get('gmudTipoOutros').value;
        const dataExecucao = this.form.get('dataExecucao').value;
        const horaExecucao = this.form.get('horaExecucao').value;
        const gmudTipoMudanca = this.form.get('gmudTipoMudanca').value;
        const gmudJustificativa = this.form.get('gmudJustificativa').value;
        const gmudUrgencia = this.form.get('gmudUrgencia').value;
        const gmudImpacto = this.form.get('gmudImpacto').value;
        const justificativaGrauImpacto = this.form.get('justificativaGrauImpacto').value;
        const gmudExito = this.form.get('gmudExito').value;
        const motivoFalha = this.form.get('motivoFalha').value;
        const gmudSolucao = this.form.get('gmudSolucao').value;
        const descricaoSolucao = this.form.get('descricaoSolucao').value;

        if (gmudExito.value === 'n' && !motivoFalha) {
            this._notification.error('Preencha o motivo da falha');
            return;
        }
        const dataHoraExecucao = dataExecucao && horaExecucao ? `${DataUtil.toDateBackend(dataExecucao)} ${horaExecucao.replace(/([0-9]{2})([0-9]{2})/gi, '$1:$2')}` : undefined

        const imp: GmudRequest = {
            gmudId,
            gmudTipo: gmudTipo ? gmudTipo.id : undefined,
            gmudTipoOutros,
            gmudTipoMudanca: gmudTipoMudanca ? gmudTipoMudanca.id : undefined,
            gmudJustificativa: gmudJustificativa ? gmudJustificativa.id : undefined,
            usuarioRespDev: this.responsavelDesenvolvimento ? this.responsavelDesenvolvimento.id : undefined,
            usuarioRespProd: this.responsavelProducao ? this.responsavelProducao.id : undefined,
            gmudUrgencia: gmudUrgencia ? gmudUrgencia.id : undefined,
            gmudImpacto: gmudImpacto ? gmudImpacto.id : undefined,
            gmudExito: gmudExito ? gmudExito.id : undefined,
            gmudSolucao: gmudSolucao ? gmudSolucao.id : undefined,
            dataExecucao: dataHoraExecucao ? dataHoraExecucao : undefined,
            justificativaGrauImpacto,
            motivoFalha,
            descricaoSolucao
        }

        this._spinner.show();
        this._gmudService.update(imp).subscribe(result => {
            this._spinner.hide();
            this._notification.sucess('GMUD enviado com sucesso!')
            this.redirectTo(`/gmud/cadastro/${this.gmud.id}`)
        },
            error => this.error(error))
    }

    aprovarReprovar(aprovacao: boolean) {

        if (!this.gmud || !this.gmud.id) {
            this._notification.error("GMUD não definida")
            return
        }

        const acao = aprovacao ? 'aprova' : 'reprova';

        this._confirmation.confirm({ titulo: `Deseja ${acao}r o GMUD?` }).subscribe(result => {
            if (result) {
                this._spinner.show();
                this._gmudService.aprovacao(aprovacao, this.gmud.id).subscribe(
                    () => {
                        this.sucess(`GMUD ${acao}do realizado com sucesso`)
                        if (aprovacao) {
                            this.redirectTo(`/gmud/cadastro/${this.gmud.id}`)
                        } else {
                            this._router.navigateByUrl(`gmud`)
                        }
                    },
                    error => this.error(error)
                )
            }
        })
    }

    pesquisaUsuarioProducao() {
        this._modalUsuario.usuarioPesquisa({}).subscribe((usuario) => {
            if (usuario) {
                this.responsavelProducao = usuario;
            }
        });
    }

    pesquisaUsuarioDesenvolvimento() {
        this._modalUsuario.usuarioPesquisa({}).subscribe((usuario) => {
            if (usuario) {
                this.responsavelDesenvolvimento = usuario;
            }
        });
    }

    verificaOutros(gmudTipo) {
        this.form.get('gmudTipoOutros').clearValidators()
        this.form.get('gmudTipoOutros').updateValueAndValidity()
        this.isOutros = false

        if (gmudTipo && gmudTipo.value && gmudTipo.value.value === 'outros') {
            this.isOutros = true
            this.form.get('gmudTipoOutros').setValidators([Validators.required])
            this.form.get('gmudTipoOutros').updateValueAndValidity()
        }

    }

    private setForm(gmud: Gmud) {
        this.form.patchValue({
            id: gmud.id,
            gmudTipo: gmud.gmudTipo,
            gmudTipoOutros: gmud.gmudTipoOutros,
            dataExecucao: gmud.dataExecucao ? new Date(gmud.dataExecucao) : undefined,
            horaExecucao: gmud.dataExecucao ? DataUtil.toDateBackend(gmud) : '',
            gmudTipoMudanca: gmud.gmudTipoMudanca,
            gmudJustificativa: gmud.gmudJustificativa,
            usuarioRespProd: gmud.usuarioRespProd,
            usuarioRespDev: gmud.usuarioRespDev,
            gmudUrgencia: gmud.gmudUrgencia,
            gmudImpacto: gmud.gmudImpacto,
            justificativaGrauImpacto: gmud.justificativaGrauImpacto,
            gmudExito: gmud.gmudExito,
            motivoFalha: gmud.motivoFalha,
            gmudSolucao: gmud.gmudSolucao,
            descricaoSolucao: gmud.descricaoSolucao,
        })

        this.responsavelProducao = gmud.usuarioRespProd
        this.responsavelDesenvolvimento = gmud.usuarioRespDev


        if (gmud.gmudTipo && gmud.gmudTipo.value === "outros") {
            this.isOutros = true
            this.form.get('gmudTipoOutros').setValidators([Validators.required])
            this.form.get('gmudTipoOutros').updateValueAndValidity()
        }
    }

    private redirectTo(uri: string) {
        this._router.navigateByUrl('/gmud', { skipLocationChange: true }).then(() =>
            this._router.navigate([uri]));
    }
}
