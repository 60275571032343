import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'modal-historico-status-projeto-abertura',
  templateUrl: './modal-historico-status-projeto-abertura.component.html',
  styleUrls: ['./modal-historico-status-projeto-abertura.component.scss']
})
export class ModalHistoricoStatusProjetoAberturaComponent implements OnInit {

  tituloModal: string = 'Histórico';

  constructor(
    public dialogRef: MatDialogRef<ModalHistoricoStatusProjetoAberturaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  ngOnInit() { }

  fechar(): void {
    this.dialogRef.close();
  }

}
