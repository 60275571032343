import { saveAs } from 'file-saver';

export class DownloadUtil {
    static base64(
        b64Data: string,
        nomeArquivo: string,
        contentType: string,
        sliceSize = 512
    ): void {
        const blob = this.b64toBlob(b64Data, contentType);
        saveAs(blob, nomeArquivo);
    }

    private static b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }
}
