import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { Faturamento } from '../../shared/interfaces/faturamento.interface';
import { FiltroFaturamento } from '../../shared/interfaces/filtro-faturamento.interface';
import { FaturamentoRecorrenteEmpresaService } from '../../shared/services/faturamento-recorrente-empresa.service';
import { FaturamentoService } from '../../shared/services/faturamento.service';

@Component({
    selector: 'faturamento-empresa-aba',
    templateUrl: './faturamento-empresa-aba.component.html',
    styleUrls: ['./faturamento-empresa-aba.component.scss']
})
export class FaturamentoEmpresaAbaComponent extends FormFiltroBase<FiltroFaturamento>
    implements OnInit {

    public faturamento: Faturamento = {};
    public isEdit: boolean = true;

    @Input() isRecorrente: boolean;


    constructor(
        injector: Injector,
        protected _faturamentoService: FaturamentoService,
        protected _faturamentoRecorrenteEmpresaService: FaturamentoRecorrenteEmpresaService
    ) {
        super('FaturamentoEmpresaAba', injector);
    }

    ngOnInit() {
        this._route.url.subscribe(params => {
            let isView = false;
            params.forEach(param => {
                if (param.path === 'visualizar') isView = true;
            })
            this.isEdit = !isView
        })

        this._route.data.subscribe((data) => {
            if (this.isRecorrente) {
                this.faturamento = data.faturamentoRecorrenteEmpresa;
            }
            else {
                this.faturamento = data.faturamento;
            }

            if (this.faturamento) {
                this.setForm(this.faturamento);
            }
        });
    }

    camposFiltro(): string[] {
        return []
    }

    protected crateForm(): { [key: string]: any } {
        return {
            nomeContato: [],
            telefone: [],
            email: [],
            emailCobranca: []
        };
    }

    salvar() {
        const nomeContato = this.form.get('nomeContato').value;
        const telefone = this.form.get('telefone').value;
        const email = this.form.get('email').value;
        const emailCobranca = this.form.get('emailCobranca').value;
        const emailCobrancaArray = emailCobranca.split(/[\,\;]/gi)

        if (this.isRecorrente) {
            this._spinner.show();
            this._faturamentoRecorrenteEmpresaService.updateContato(this.faturamento.id, nomeContato, telefone, email, emailCobrancaArray).subscribe(() => {
                this._notification.sucess('Dados atualizados com sucesso');
                this._spinner.hide();
            },
                error => {
                    this._notification.error('Erro ao atualizar os dados');
                    this._spinner.hide();
                }
            )
        }
        else {
            this._spinner.show();
            this._faturamentoService.updateContato(this.faturamento.id, nomeContato, telefone, email, emailCobrancaArray).subscribe(() => {
                this._notification.sucess('Dados atualizados com sucesso');
                this._spinner.hide();
            },
                error => {
                    this._notification.error('Erro ao atualizar os dados');
                    this._spinner.hide();
                }
            )
        }

    }

    private setForm(faturamento: Faturamento) {
        this.form.patchValue({
            id: faturamento.id,
            nomeContato: faturamento.cotacao.contatoNome,
            telefone: faturamento.cotacao.contatoTelefone,
            email: faturamento.cotacao.contatoEmail,
            emailCobranca: faturamento.cotacao.contatoCobranca
        })
    }

}
