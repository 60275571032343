import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ContratoEstadoResolver implements Resolve<any> {
    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const fakeData = new Observable<any>((observer) => {
            observer.next({
                data: [
                    { uf: 'AC', nome: 'Acre' },
                    { uf: 'AL', nome: 'Alagoas' },
                    { uf: 'AP', nome: 'Amapá' },
                    { uf: 'AM', nome: 'Amazonas' },
                    { uf: 'BA', nome: 'Bahia' },
                    { uf: 'CE', nome: 'Ceará' },
                    { uf: 'DF', nome: 'Distrito Federal' },
                    { uf: 'ES', nome: 'Espirito Santo' },
                    { uf: 'GO', nome: 'Goiás' },
                    { uf: 'MA', nome: 'Maranhão' },
                    { uf: 'MS', nome: 'Mato Grosso do Sul' },
                    { uf: 'MT', nome: 'Mato Grosso' },
                    { uf: 'MG', nome: 'Minas Gerais' },
                    { uf: 'PA', nome: 'Pará' },
                    { uf: 'PB', nome: 'Paraíba' },
                    { uf: 'PR', nome: 'Paraná' },
                    { uf: 'PE', nome: 'Pernambuco' },
                    { uf: 'PI', nome: 'Piauí' },
                    { uf: 'RJ', nome: 'Rio de Janeiro' },
                    { uf: 'RN', nome: 'Rio Grande do Norte' },
                    { uf: 'RS', nome: 'Rio Grande do Sul' },
                    { uf: 'RO', nome: 'Rondônia' },
                    { uf: 'RR', nome: 'Roraima' },
                    { uf: 'SC', nome: 'Santa Catarina' },
                    { uf: 'SP', nome: 'São Paulo' },
                    { uf: 'SE', nome: 'Sergipe' },
                    { uf: 'TO', nome: 'Tocantins' }
                ]
            });
            observer.complete();
        });
        return fakeData.pipe(map((result) => result.data));
    }
}
