import { Component, ViewEncapsulation, Injector, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import textos from './email-confirmacao-cliente-textos';
import { AuthBaseController } from '../../shared/auth-base.controller';

@Component({
    selector: 'email-confirmacao-cliente',
    templateUrl: './email-confirmacao-cliente.component.html',
    styleUrls: ['./email-confirmacao-cliente.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class EmailConfirmacaoClienteComponent
    extends AuthBaseController
    implements OnInit {
    conteudo: any;
    email: string;

    /**
     * Constructor
     *
     * @param {Injector} _injector
     */
    constructor(protected _injector: Injector) {
        super(_injector);
        this._route.params.subscribe((params) => {
            const layout = params.layout;
            this.email = params.email;
            switch (layout) {
                case 'confirmacao-email':
                    this.conteudo = this.trataConteudo(textos.emailConfirmacao);
                    break;
                case 'recupera-senha':
                    this.conteudo = this.trataConteudo(
                        textos.emailRecuperacaoSenha
                    );
                    break;
                default:
                    this._router.navigateByUrl('/auth/');
                    return;
                    break;
            }
        });
    }

    ngOnInit(): void {
        this.email = null;
    }

    private trataConteudo(obj): any {
        const conteudo = Object.assign({}, obj);
        conteudo.texto = conteudo.texto.replace('{#!EMAIL}', this.email);
        return conteudo;
    }
}
