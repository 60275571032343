import { filter } from "lodash";
import * as moment from "moment";

export class DataUtil {
    static idade(dataNascimento: Date): number {
        if (dataNascimento) {
            const data = new Date(dataNascimento);
            const d = new Date();
            const anoAtual = d.getFullYear();
            const mesAtual = d.getMonth() + 1;
            const diaAtual = d.getDate();
            const anoAniversario = +data.getFullYear();
            const mesAniversario = +data.getMonth() + 1;
            const diaAniversario = +data.getDate();

            let quantosAnos = anoAtual - anoAniversario;

            if (
                mesAtual < mesAniversario ||
                (mesAtual === mesAniversario && diaAtual < diaAniversario)
            ) {
                quantosAnos--;
            }

            return quantosAnos < 0 ? 0 : quantosAnos;
        } else {
            return null;
        }
    }

    static toDateBackend(data: any): string {
        let dataStr = ''
        if (data && Object.prototype.toString.call(data) === "[object Date]" && !isNaN(data)) {
            dataStr = data.toISOString().substring(0, 10);
        }
        else if (data instanceof moment) {
            dataStr = moment(data).format('YYYY-MM-DD')
        }
        else if (typeof data === 'string' && /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}.*/gi.test(data)) {
            dataStr = data.replace(/^([0-9]{4}\-[0-9]{2}\-[0-9]{2}).*/gi, '$1')
        }
        else if (typeof data === 'string' && /^[0-9]{2}[\-\/][0-9]{2}[\-\/][0-9]{4}.*/gi.test(data)) {
            dataStr = data.replace(/^([0-9]{2})[\-\/]([0-9]{2})[\-\/]([0-9]{4}).*/gi, '$3-$2-$1')
        }

        return dataStr
    }

    static toDateTimeBackend(data: any): string {
        let dataStr = ''
        if (data && Object.prototype.toString.call(data) === "[object Date]" && !isNaN(data)) {
            dataStr = data.toISOString().substring(0, 19).replace('T', ' ');
        }
        else if (data instanceof moment) {
            dataStr = moment(data).format('YYYY-MM-DD HH:mm:SS')
        }
        else if (typeof data === 'string' && /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}.*/gi.test(data)) {
            dataStr = data.replace(/^([0-9]{4}\-[0-9]{2}\-[0-9]{2}).*/gi, '$1')
        }
        else if (typeof data === 'string' && /^[0-9]{2}[\-\/][0-9]{2}[\-\/][0-9]{4}.*/gi.test(data)) {
            dataStr = data.replace(/^([0-9]{2})[\-\/]([0-9]{2})[\-\/]([0-9]{4}).*/gi, '$3-$2-$1')
        }

        return dataStr
    }

    static toDateBackendRange(dataInicio: any, dataFim?: any): string {
        if (!dataInicio) return undefined;
        const dataInicioStr = dataInicio ? `${DataUtil.toDateBackend(dataInicio)}|` : '';
        const dataFimStr = dataFim ? DataUtil.toDateBackend(dataFim) : DataUtil.toDateBackend(new Date());
        return dataInicioStr + dataFimStr
    }

    static toDateView(date: Date | string): string {
        return moment(date).format('DD/MM/YYYY');
    }
}
