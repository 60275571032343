export class FiltroUtil<F>{
    public getFiltro(chave: string): any {
        if (!chave) return;
        const str = localStorage.getItem(chave)
        return str ? JSON.parse(str) : undefined;
    }

    public save(chave: string, filtro: F) {
        localStorage.setItem(chave, JSON.stringify(filtro));
    }
}