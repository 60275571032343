import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { UsuarioTipo } from '../interfaces/usuario-tipo.interface';
import { Response } from '@utils/interfaces/http/response.interface';
import { FiltroUsuarioTipo } from '../interfaces/filtro-usuario-tipo.interface';

@Injectable()
export class UsuarioTipoService extends ServiceBase<UsuarioTipo> {
    private path = `${environment.server}/usuariotipo`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroUsuarioTipo): Observable<Response<Array<UsuarioTipo>>> {
        const params = this.toGetParams(filtro);
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<UsuarioTipo>>>(url);
    }

    findById(id: number | string): Observable<Response<UsuarioTipo>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<UsuarioTipo>>(url);
    }
}
