import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { Faturamento } from '../interfaces/faturamento.interface';
import { FiltroFaturamento } from '../interfaces/filtro-faturamento.interface';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { FaturamentoEditar } from '../interfaces/faturamento-editar.interface';
import { FaturamentoGrid } from '../../components/faturamento-grid/faturamento-grid.component';

@Injectable({
    providedIn: 'root',
})
export class FaturamentoService extends ServiceGridBase<FaturamentoGrid, FiltroFaturamento> {
    private path = `${environment.server}/faturamento`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroBase): Observable<Response<Array<FaturamentoGrid>>> {
        const params: string = this.toGetParams(filtro);
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<FaturamentoGrid>>>(url);
    }

    findById(id: number | string): Observable<Response<Faturamento>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Faturamento>>(url);
    }

    findTipoSetup(): Observable<Response<Array<any>>> {
        const url = `${this.path}/tipo-setup`;
        return this._http.get<Response<any>>(url);
    }

    update(faturamento: FaturamentoEditar): Observable<Response<Faturamento>> {
        const url = `${environment.server}/faturamento-lote`;
        return this._http.put<Response<Faturamento>>(url, faturamento);
    }

    updateFaturamento(idFaturamento: number, faturamento: FaturamentoEditar): Observable<Response<Faturamento>> {
        const url = `${environment.server}/faturamento/${idFaturamento}`;
        return this._http.put<Response<Faturamento>>(url, faturamento);
    }

    updateDadosComplementares(idFaturamento: number, dadosComplementares: string): Observable<Response<void>> {
        const url = `${environment.server}/faturamento-dados-complementares/${idFaturamento}`;
        return this._http.put<Response<void>>(url, { dadosComplementares: dadosComplementares });
    }

    updateContato(idFaturamento: number, contatoNome: string, contatoTelefone: string, contatoEmail: string, emailsCobranca: string): Observable<Response<void>> {
        const url = `${environment.server}/faturamento-atualizar-contato/${idFaturamento}`;
        return this._http.put<Response<void>>(url, {
            contatoNome,
            contatoTelefone,
            contatoEmail,
            emailsCobranca,
        });
    }

    iniciarFaturamento(idsCotacoes: Array<number>): Observable<Response<any>> {
        const idsCotacoesStr = idsCotacoes.join(',')
        const url = `${environment.server}/cotacao/faturamento/iniciar`;
        return this._http.post<Response<any>>(url, { cotacaoId: idsCotacoes });
    }
}
