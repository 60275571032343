import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroProjetoAbertura } from '../interfaces/filtro-projeto-abertura.interface';
import { FiltroProjetoHistorico } from '../interfaces/filtro-projeto-historico.interface';
import { ProjetoAberturaHistorico } from '../interfaces/projeto-abertura-historico.interface';
import { ProjetoHistorico } from '../interfaces/projeto-historico.interface';

@Injectable({
    providedIn: 'root',
})
export class ProjetoAberturaHistoricoService extends ServiceGridBase<ProjetoAberturaHistorico, FiltroProjetoAbertura> {
    private path: string = `${environment.server}/osAberturaConfiguracao/historico`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroProjetoAbertura
    ): Observable<Response<Array<ProjetoHistorico>>> {
        const params: string = filtro
            ? this.toGetParams(filtro, ['idOsAbertura'])
            : '';
        const url = `${this.path}/${filtro.idOsAbertura}${params}`;
        return this._http.get<Response<Array<ProjetoHistorico>>>(url);
    }
}
