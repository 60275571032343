import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthLocalStorageService } from '../services/auth-local-storage.service';
import { AuthService } from '../../app/main/public/auth/shared/services/auth.service';
import { catchError, map } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import { NotificationService } from '@utils/utils/notification/notification.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        public auth: AuthService,
        private localStorage: AuthLocalStorageService,
        private router: Router,
        private _notification: NotificationService
    ) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const authData = this.localStorage.getToken();
        let requestItem = request;
        if (authData) {
            requestItem = request.clone({
                headers: request.headers.set(
                    'Authorization',
                    'Bearer ' + authData
                ),
            });
        }
        return next.handle(requestItem).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.refreshToken(event);
                }
                return event;
            }),
            catchError((err) => {
                if (err.status === 401) {
                    this.localStorage.deleteToken();
                    this.router.navigateByUrl('/auth/login');
                } else if (err.status === 403) {
                    console.log('Erro 403');
                    console.log(err);
                    return observableThrowError(err);
                } else if (err.status === 404) {
                    console.log('Erro 404');
                    console.log(err);
                    return observableThrowError(err);
                } else if (err.status === 400) {
                    console.log('Erro 400');
                    console.log(err);
                    return observableThrowError(err);
                } else if (err.status === 500) {
                    this._notification.error(err.error.message);
                    console.log(err);
                    return observableThrowError(err);
                } else {
                    console.log(err);
                    return observableThrowError(err);
                }

                return observableThrowError(err);
            })
        );
    }

    refreshToken(event: HttpResponse<any>) {
        let resp: HttpResponse<any> = event;
        let auth = resp.headers.get('Authorization');
        if (auth !== undefined && auth !== null) {
            auth = auth.substring(7);
            this.localStorage.setToken(auth);
        }
    }
}
