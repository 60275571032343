import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Time } from '../interfaces/time.interface';
import { TimeService } from '../services/time.service';

@Injectable()
export class TimeDetalheResolver implements Resolve<Time> {
    constructor(private _timeService: TimeService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let id = route.params['id'];
        return this._timeService
            .findById(id)
            .pipe(map((result) => result.data));
    }
}
