import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OsEmpresa } from '../interfaces/os-empresa.interface';
import { OsEmpresaService } from '../services/os-empresa.service';

@Injectable()
export class OsEmpresaListaResolver implements Resolve<OsEmpresa> {

    constructor(private _osEmpresaService: OsEmpresaService){}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any>|Promise<any>|any {
        return []//this._osEmpresaService.find({}).pipe(map(result => result.data))
    }
}
    