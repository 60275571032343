import { Injector, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { Checklist } from '../interfaces/checklist.interface';

export abstract class ChacklistPageBase {
    protected _route: ActivatedRoute;
    protected _router: Router;
    protected _spinner: SpinnerService;

    public checklist: Checklist;

    constructor(protected _injector: Injector) {
        this._route = _injector.get(ActivatedRoute);
        this._router = _injector.get(Router);
        this._spinner = _injector.get(SpinnerService);

        this._route.data.subscribe((data) => {
            this.checklist = data.checklist;
        });
    }
}
