import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Cotacao } from '../../shared/interfaces/cotacao.interface';

@Component({
  selector: 'modal-contrato-vincular',
  templateUrl: './modal-contrato-vincular.component.html',
  styleUrls: ['./modal-contrato-vincular.component.scss']
})
export class ModalContratoVincularComponent implements OnInit {
  tituloModal: string = 'Vincular contrato';

  constructor(
      public dialogRef: MatDialogRef<ModalContratoVincularComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
  ngOnInit() {}

  selecionado(contratoVincular: Cotacao) {
      this.dialogRef.close(contratoVincular);
  }

  fechar(): void {
      this.dialogRef.close();
  }
}
