import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContratoSubEndpoint } from '../interfaces/contrato-sub-endpoint.interface';
import { ContratoSubEndpointService } from '../services/contrato-sub-endpoint.service';

@Injectable()
export class ContratoStatusSecundarioResolver implements Resolve<ContratoSubEndpoint[]> {
    constructor(private _service: ContratoSubEndpointService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._service
            .findStatusSecundario()
            .pipe(map((result) => result.data));
    }
}
