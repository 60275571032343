import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FiltroCotacao } from '../interfaces/filtro-cotacao.interface';
import { Cotacao } from '../interfaces/cotacao.interface';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { CotacaoStatus } from '../interfaces/cotacao-status.interface';
import { Contrato } from 'app/main/contrato/shared/interfaces/contrato.interface';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';

@Injectable({
    providedIn: 'root',
})
export class CotacaoService extends ServiceGridBase<Cotacao, FiltroCotacao> {
    private path = `${environment.server}/cotacao`;
    private pathUsuario = `${environment.server}/usuario`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroCotacao): Observable<Response<Array<Cotacao>>> {
        const params: string = this.toGetParams(filtro);
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<Cotacao>>>(url);
    }

    findStatus(): Observable<Response<Array<CotacaoStatus>>> {
        const url = `${this.path}/status`;
        return this._http.get<Response<Array<CotacaoStatus>>>(url);
    }

    findById(id: number | string): Observable<Response<Cotacao>> {
        const url = `${this.path}/${id}/completo`;
        return this._http.get<Response<Cotacao>>(url);
    }

    update(cotacao: Cotacao): Observable<Response<Cotacao>> {
        const url = `${this.path}/${cotacao.id}`;
        return this._http.put<Response<Cotacao>>(url, cotacao);
    }

    gerarRelatorio(filtro: FiltroCotacao): Observable<Response<any>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${environment.server}/relatorio/cotacao${params}`;
        return this._http.get<Response<any>>(url);
    }

    sendAccess(id: number | string) {
        const url = `${this.pathUsuario}/reenviar-link-primeiro-acesso`;
        console.log(id)
        var reenvio = {
            idCotacao: id
        }
        return this._http.post<Response<any>>(url, reenvio);
    }

    criarContrato(contrato: Contrato) {
        const params: string = contrato ? this.toGetParams(contrato) : '';
        const url = `${environment.server}/relatorio/cotacao${params}`;
        return this._http.post<Response<any>>(url, contrato);
    }

    isContratos(idsCotacoes: Array<number>): Observable<Response<any>> {
        const idsCotacoesStr = idsCotacoes.join(',')
        const url = `${environment.server}/cotacaovalidacontrato?cotacoes=${idsCotacoesStr}`;
        return this._http.get<Response<any>>(url);
    }

    squad(id: number): Observable<Response<any>> {
        const url = `${this.path}/${id}/squad`
        return this._http.get<Response<any>>(url);
    }

    updateSquad(id: number, squadUsers: {usuarios: Array<Usuario>}): Observable<Response<any>> {
        const url = `${this.path}/${id}/squad`
        return this._http.put<Response<any>>(url, squadUsers);
    }

}

