import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroModulo } from '../interfaces/filtro-modulo.interface';
import { Modulo } from '../interfaces/modulo.interface';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root',
})
export class ModuloService extends ServiceGridBase<Modulo, FiltroModulo> {
    private path: string = `${environment.server}/modulos`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroModulo): Observable<Response<Array<Modulo>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<Modulo>>>(url);
    }

    findByProduto(idProduto: number): Observable<Response<Array<Modulo>>> {
        const url = `${this.path}/${idProduto}`;
        return this._http.get<Response<Array<Modulo>>>(url);
    }
}
