import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalBase } from '@utils/base/modal-base/modal-base';
import { ImportacaoOs } from '../../shared/interfaces/importacao-os.interfaces';

@Component({
  selector: 'app-importacao-os-detalhe-modal',
  templateUrl: './importacao-os-detalhe-modal.component.html',
  styleUrls: ['./importacao-os-detalhe-modal.component.scss']
})
export class ImportacaoOsDetalheModalComponent
  extends ModalBase
  implements OnInit {
  tituloModal = 'Detalhes da importação';
  importacaoOs: ImportacaoOs;

  constructor(
    public dialogRef: MatDialogRef<ImportacaoOsDetalheModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImportacaoOs,
    private _injector: Injector
  ) {
    super(_injector);
    this.importacaoOs = {};
  }

  ngOnInit(): void {
    if (!this.data) {
      this._notification.error('Erro ao retornar os dados');
      console.log('Erro ao retornar os dados');
    }
    this.importacaoOs = this.data;
  }

  fechar(): void {
    this.dialogRef.close();
  }
}
