import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Empresa } from 'app/main/empresa/shareds/interfaces/empresa.interface';
import { Banco } from 'app/main/banco/shareds/interfaces/banco.interface';
import { OsStatus } from '../../shareds/interfaces/os-status.interface';
import { OsVincular } from '../../shareds/interfaces/os-vincular.interface';
import { OsEmpresa } from '../../shareds/interfaces/os-empresa.interface';
import { Projeto } from 'app/main/projeto/shareds/interfaces/projeto.interface';

@Component({
    selector: 'modal-os-vincular',
    templateUrl: './modal-os-vincular.component.html',
    styleUrls: ['./modal-os-vincular.component.scss'],
})
export class ModalOsVincularComponent implements OnInit {
    tituloModal: string = 'Vincular/Desvincular OS';

    statuss: Array<OsStatus>;
    empresas: Array<Empresa>;
    bancos: Array<Banco>;
    osVinculos: Array<OsVincular>;

    constructor(
        public dialogRef: MatDialogRef<ModalOsVincularComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Projeto
    ) {}
    ngOnInit() {}

    fechar(): void {
        this.dialogRef.close();
    }

    vincularOs() {}
}
