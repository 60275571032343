import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroRelacionamentoAberturaHistorico } from '../interfaces/filtro-relacionamento-abertura-historico.interface';
import { RelacionamentoAberturaHistorico } from '../interfaces/relacionamento-abertura-historico.interface';

@Injectable({
    providedIn: 'root',
})
export class RelacionamentoAberturaHistoricoService extends ServiceGridBase<
    RelacionamentoAberturaHistorico,
    FiltroRelacionamentoAberturaHistorico
> {
    private path: string = `${environment.server}/osAbertura/historico`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroRelacionamentoAberturaHistorico
    ): Observable<Response<Array<RelacionamentoAberturaHistorico>>> {
        const params: string = filtro
            ? this.toGetParams(filtro, ['idOsAbertura'])
            : '';
        const url = `${this.path}/${filtro.idOsAbertura}${params}`;
        return this._http.get<Response<Array<RelacionamentoAberturaHistorico>>>(url);
    }
}
