import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-home-index-page',
    templateUrl: './home-index-page.component.html',
    styleUrls: ['./home-index-page.component.scss'],
})
export class HomeIndexPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
