import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChecklistStatus } from '../interfaces/checklist-status.interface';
import { ChecklistRelacionamentoProdutoFinanceiroService } from '../services/checklist-relacionamento-produto-financerio.service';

@Injectable()
export class ChecklistProdutoFinanceiroListaResolver implements Resolve<ChecklistStatus> {
    constructor(private _checklistRelacionamentoProdutoFinanceiroService: ChecklistRelacionamentoProdutoFinanceiroService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        return this._checklistRelacionamentoProdutoFinanceiroService
            .find({ idChecklist: id, modulo: 'EDI' })
            .pipe(map((result) => result.data));
    }
}
