import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { Faturamento } from '../interfaces/faturamento.interface';
import { FiltroFaturamento } from '../interfaces/filtro-faturamento.interface';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { FaturamentoGridRecorrente } from '../interfaces/faturamento-recorrente-grid-interface';
import { FaturamentoRecorrente } from '../interfaces/faturamento-recorrente.interface';
import { FaturamentoRecorrenteEditarLote } from '../interfaces/faturamento-recorrente-editar-lote.interface';

@Injectable({
    providedIn: 'root',
})
export class FaturamentoRecorrenteService extends ServiceGridBase<FaturamentoGridRecorrente, FiltroFaturamento> {
    private path = `${environment.server}/faturamento-recorrente`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroBase): Observable<Response<Array<FaturamentoGridRecorrente>>> {
        const params: string = this.toGetParams(filtro);
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<FaturamentoGridRecorrente>>>(url);
    }

    findById(id: number | string): Observable<Response<FaturamentoRecorrente>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Faturamento>>(url);
    }

    emitirNota(faturamento: any): Observable<Response<Faturamento>> {
        const url = `${this.path}/emitir-nota-fiscal`;
        return this._http.post<Response<Faturamento>>(url, { ids: faturamento });
    }

    update(recorrencia: FaturamentoRecorrente): Observable<Response<FaturamentoRecorrente>> {
        const url = `${this.path}/${recorrencia.id}`;
        return this._http.put<Response<FaturamentoRecorrente>>(url, recorrencia);
    }

    updateLote(faturamento: FaturamentoRecorrenteEditarLote): Observable<Response<any>> {
        const url = `${environment.server}/faturamento-recorrente-lote`;
        return this._http.put<Response<any>>(url, faturamento);
    }

    gerarRelatorio(filtro: FiltroBase): Observable<Response<Array<FaturamentoGridRecorrente>>> {
        const params: string = this.toGetParams(filtro);
        const url = `${environment.server}/relatorio/faturamento-recorrente${params}`;
        return this._http.get<Response<Array<FaturamentoGridRecorrente>>>(url);
    }
}
