import { Component, ViewEncapsulation, Injector } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { AuthBaseController } from '../../shared/auth-base.controller';

@Component({
    selector: 'login-admin',
    templateUrl: './login-admin.component.html',
    styleUrls: ['./login-admin.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class LoginAdminComponent extends AuthBaseController {
    /**
     * Constructor
     *
     * @param {Injector} _injector
     */
    constructor(protected _injector: Injector) {
        super(_injector);
    }
}
