import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Os } from '../interfaces/os.interface';
import { OsService } from '../services/os.service';

@Injectable()
export class OsCotacaoResolver implements Resolve<Os> {

    constructor(private _osService: OsService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let id = route.params['id']
        return this._osService.findCotacao(id).pipe(map(result => result.data))
    }
}
