import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalBase } from '@utils/base/modal-base/modal-base';
import { ImportacaoOs } from '../../shared/interfaces/importacao-os.interfaces';

@Component({
  selector: 'importacao-os-erro-modal',
  templateUrl: './importacao-os-erro-modal.component.html',
  styleUrls: ['./importacao-os-erro-modal.component.scss']
})
export class ImportacaoOsErroModalComponent
  extends ModalBase
  implements OnInit {
  tituloModal = 'Erros na importação';
  importacaoOs: ImportacaoOs;

  constructor(
    public dialogRef: MatDialogRef<ImportacaoOsErroModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImportacaoOs,
    private _injector: Injector
  ) {
    super(_injector);
    this.importacaoOs = {};
  }

  ngOnInit(): void {
    if (!this.data) {
      this._notification.error('Erro ao retornar os dados');
      console.log('Erro ao retornar os dados');
    }
    this.importacaoOs = this.data;
  }

  uploadFinalizado(isFinalizado): void {
    if (isFinalizado) {
      this.fechar();
    }
  }

  fechar(): void {
    this.dialogRef.close();
  }
}
