import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChecklistDsnameConfigTipo } from '../interfaces/checklist-dsname-config.interface';
import { ChecklistDsnameConfigTipoService } from '../services/checklist-dsname-config.service';

@Injectable()
export class ChecklistDsnameConfigTipoListaResolver
    implements Resolve<ChecklistDsnameConfigTipo> {
    constructor(
        private _checklistDsnameConfigTipoService: ChecklistDsnameConfigTipoService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._checklistDsnameConfigTipoService
            .find()
            .pipe(map((result) => result.data));
    }
}
