import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { ChecklistConectividade } from '../interfaces/checklist-conectividade.interface';
import { FiltroChecklistConectividade } from '../interfaces/filtro-checklist-conectividade.interface';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistConectividadeService extends ServiceBase<ChecklistConectividade> {
    private path = `${environment.server}/checklist/conectividade`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroChecklistConectividade
    ): Observable<Response<Array<ChecklistConectividade>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/${filtro.idCotacao}`;
        return this._http.get<Response<Array<ChecklistConectividade>>>(url);
    }

    save(idCotacao: number, conectividade: any): Observable<Response<any>> {
        const url = `${this.path}/${idCotacao}`;
        return this._http.put<Response<any>>(url, conectividade);
    }
}
