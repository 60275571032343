import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Modulo } from '../interfaces/modulo.interface';
import { ModuloService } from '../services/modulo.service';

@Injectable()
export class ModuloListaResolver implements Resolve<Modulo> {
    constructor(private _produtoService: ModuloService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._produtoService.find().pipe(map((result) => result.data));
    }
}
