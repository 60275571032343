import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RelacionamentoAberturaEditar } from '../../shared/interfaces/relacionamento-abertura-editar.interface';

@Component({
    selector: 'modal-editar-relacionamento-abertura',
    templateUrl: './modal-editar-relacionamento-abertura.component.html',
    styleUrls: ['./modal-editar-relacionamento-abertura.component.scss'],
})
export class ModalEditarRelacionamentoAberturaComponent implements OnInit {
    tituloModal: string = 'Editar relacionamento abertura';

    constructor(
        public dialogRef: MatDialogRef<ModalEditarRelacionamentoAberturaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RelacionamentoAberturaEditar
    ) { }
    ngOnInit() { }

    fechar(): void {
        this.dialogRef.close();
    }

    atualizado(result) {
        this.dialogRef.close(result);
    }
}
