import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Contrato } from '../interfaces/contrato.interface';
import { FiltroAdendoContrato } from '../interfaces/filtro-adendo.interface';

@Injectable({
    providedIn: 'root',
})
export class ContratoAdendoService extends ServiceGridBase<
    Contrato,
    FiltroAdendoContrato
> {
    private path = `${environment.server}/contrato`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroAdendoContrato): Observable<Response<Contrato[]>> {
        const params: string = this.toGetParams(filtro);
        const url = `${this.path}/${filtro.idContrato}/aditivos`;
        return this._http.get<Response<Array<Contrato>>>(url);
    }
}
