import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Permissao } from '../interfaces/permissao.interface';
import { FiltroPermissao } from '../interfaces/filtro-permissao.interface';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root',
})
export class PermissaoService extends ServiceBase<Permissao> {
    private path = `${environment.server}/permissao`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroPermissao): Observable<Response<Array<Permissao>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<Permissao>>>(url);
    }

    findById(id: number | string): Observable<Response<Permissao>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Permissao>>(url);
    }
}
