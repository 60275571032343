import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'faturamento-cadastro',
  templateUrl: './faturamento-cadastro.component.html',
  styleUrls: ['./faturamento-cadastro.component.scss']
})
export class FaturamentoCadastroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
