import { Component, Injector, OnInit } from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { Configuracao } from '../../shareds/interfaces/configuracao.interface';
import { ConfiguracaoService } from '../../shareds/services/configuracao.service';

@Component({
  selector: 'configuracao',
  templateUrl: './configuracao.component.html',
  styleUrls: ['./configuracao.component.scss']
})
export class ConfiguracaoComponent extends FormBase implements OnInit {

  public listaConfiguracoes: Array<Configuracao> = []

  constructor(
    protected _injector: Injector,
    private _configuracapService: ConfiguracaoService
  ) {
    super(_injector);
  }

  ngOnInit() {
    this._route.data.subscribe((data) => {
      this.listaConfiguracoes = data.listaConfiguracoes

      if (this.listaConfiguracoes) {
        this.setForm(this.listaConfiguracoes);
      }
    });
  }

  protected crateForm(): { [key: string]: any } {
    return {};
  }

  getForm(): Array<Configuracao> {
    const listaConfiguracoes = this.listaConfiguracoes.map(config => {
      const campoNome = `campo${config.id}`
      config.enviar = this.form.get(campoNome).value ? 1 : 0;
      return config
    })

    return listaConfiguracoes;
  }

  setForm(listaConfiguracoes: Array<Configuracao>) {

    const campos = {}
    const group = {}
    listaConfiguracoes.map(config => {
      const campoNome = `campo${config.id}`
      campos[campoNome] = config.enviar
      group[campoNome] = []
    })
    this.form = this._formBuilder.group(group)
    this.form.patchValue(campos)
  }

  salvar() {
    const listaConfiguracoes: Array<Configuracao> = this.getForm()

    this._spinner.show();
    this._configuracapService.save(listaConfiguracoes).subscribe(
      () => {
        this.sucess('Configuração salva com sucesso!')
      },
      error => this.error(error)
    )
  }

}
