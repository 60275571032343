import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { ContratoSubEndpoint } from '../interfaces/contrato-sub-endpoint.interface';

@Injectable({
    providedIn: 'root',
})
export class ContratoSubEndpointService extends ServiceGridBase<ContratoSubEndpoint, FiltroBase> {
    private path = `${environment.server}`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroBase): Observable<Response<Array<ContratoSubEndpoint>>> {
        return of();
    }

    findStatusPrimario(): Observable<Response<Array<ContratoSubEndpoint>>> {
        const url = `${this.path}/contrato-status-primario`;
        return this._http.get<Response<Array<ContratoSubEndpoint>>>(url);
    }

    findStatusSecundario(): Observable<Response<Array<ContratoSubEndpoint>>> {
        const url = `${this.path}/contrato-status-secundario`;
        return this._http.get<Response<Array<ContratoSubEndpoint>>>(url);
    }

    findTipoMinuta(): Observable<Response<Array<ContratoSubEndpoint>>> {
        const url = `${this.path}/contrato-tipo-minuta`;
        return this._http.get<Response<Array<ContratoSubEndpoint>>>(url);
    }

}
