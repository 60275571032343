import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroBanco } from '../interfaces/filtro-banco.interface';
import { Banco } from '../interfaces/banco.interface';
import { Response } from '@utils/interfaces/http/response.interface';
import { CadastroBanco } from '../interfaces/cadastro-banco.interface';

@Injectable({
    providedIn: 'root',
})
export class BancoService extends ServiceGridBase<Banco, FiltroBanco> {
    private path: string = `${environment.server}/banco`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroBanco): Observable<Response<Array<Banco>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<Banco>>>(url);
    }

    findby(id: number): Observable<Response<Array<Banco>>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Array<Banco>>>(url);
    }

    historico(idBanco: number): Observable<Response<Array<Banco>>> {
        const url = `${this.path}/historico/${idBanco}`;
        return this._http.get<Response<Array<Banco>>>(url);
    }

    save(banco: CadastroBanco): Observable<Response<CadastroBanco>> {
        let produtos = [];
        let modulos = [];
        banco.produto.forEach((field) => {
            produtos.push(field.idProduto);
            modulos.push(field.idModulo);

        });

        const bancoData = {
            codigo: banco.codigo,
            apelido: banco.apelido,
            nome: banco.nome,
            sla: banco.sla,
            produtos: produtos,
            modulos: modulos,
            contatos: banco.contato
        }

        if (banco.id) {
            return this.update(bancoData, banco.id);
        }

        return this.create(bancoData);
    }

    create(bancoData: any): Observable<Response<Banco>> {
        const url = `${this.path}`;
        return this._http.post<Response<CadastroBanco>>(url, bancoData);
    }

    update(bancoData: any, id: number): Observable<Response<Banco>> {
        const url = `${this.path}/${id}`;
        return this._http.put<Response<CadastroBanco>>(url, bancoData);
    }

    delete(id: number): Observable<Response<Banco>> {
        const url = `${this.path}/${id}`;
        return this._http.delete<Response<CadastroBanco>>(url);
    }
}
