import { Component, Injector, OnInit } from '@angular/core';
import { ChacklistPageBase } from '../../shared/base/checklist-page-base';

@Component({
    selector: 'checklist-relacionamento-page',
    templateUrl: './checklist-relacionamento-page.component.html',
    styleUrls: ['./checklist-relacionamento-page.component.scss'],
})
export class ChecklistRelacionamentoPageComponent
    extends ChacklistPageBase
    implements OnInit {
    carregarMenu: boolean;
    constructor(protected _injector: Injector) {
        super(_injector);
    }

    ngOnInit(): void {}

    atualizarMenu(rusult) {
        this.carregarMenu = Object.assign({});
    }
}
