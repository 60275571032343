import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { isString } from 'lodash';
import { ChecklistConfiguracaoLayout } from '../../shared/interfaces/checklist-configuracao.interface';
import { ChecklistDsnameConfig, ChecklistDsnameConfigTipo } from '../../shared/interfaces/checklist-dsname-config.interface';
import { ChecklistDsnameLayout } from '../../shared/interfaces/checklist-dsname-layout.interface';
import { ChecklistDsnameModal } from '../../shared/interfaces/checklist-dsname-modal.interface';
import { ChecklistDsname, ChecklistDsnameRequest } from '../../shared/interfaces/checklist-dsname.interface';
import { ChecklistConfiguracaoArquivoModalService } from '../../shared/services/checklist-configuracao-arquivo-modal.service';
import { ChecklistConfiguracaoService } from '../../shared/services/checklist-configuracao.service';
import { ChecklistDsnameModalService } from '../../shared/services/checklist-dsname-modal.service';

@Component({
  selector: 'checklist-dsname-layout-edi',
  templateUrl: './checklist-dsname-layout-edi.component.html',
  styleUrls: ['./checklist-dsname-layout-edi.component.scss']
})
export class ChecklistDsnameLayoutEdiComponent extends FormBase implements OnInit {

  @Input() titulo: string;
  @Output() salvando = new EventEmitter();
  private _configuracao: ChecklistConfiguracaoLayout;

  direcoes: any;
  checklist: any;
  dsnameConfigTipos: any;
  tamanhoArquivo: any;
  isVisualizacao: any;
  produtoFinanceiro: any;

  layoutRender: Array<any> = [];
  panelOpenState = false;
  showDropDownTipoDsName: boolean = false;

  listaTipoNomenclatura: Array<any> = [
    { id: 1, descricao: 'Utilizar nomenclatura personalizada' },
    { id: 2, descricao: 'Utilizar nomenclatura padrão' }
  ]
  listaProdutoFinancerioComExemplo: any;

  get configuracao(): ChecklistConfiguracaoLayout {
    return this._configuracao;
  }

  @Input() set configuracao(configuracao: ChecklistConfiguracaoLayout) {
    this.form.reset();
    this._configuracao = configuracao;
    /*
    if (
      this._configuracao.checklistConfiguracaoDsname &&
      this._configuracao.checklistConfiguracaoDsname.length > 0
    ) {      
    } else {
      this.direcoes.forEach((direcao) => {
        this._configuracao.checklistConfiguracaoDsname.push({
          direcaoId: direcao.id,
        });
      });
    }
    */
  }

  constructor(
    private _injector: Injector,
    private _dsnameModal: ChecklistDsnameModalService,
    private _checklistConfiguracaoService: ChecklistConfiguracaoService,
    private _modalArquivo: ChecklistConfiguracaoArquivoModalService) {
    super(_injector, TipoNomenclaturaCombos.combos());

    this._route.data.subscribe((data) => {
      this.direcoes = data.direcoes;
      this.checklist = data.checklist;
      this.dsnameConfigTipos = data.dsnameConfigTipos;
      this.tamanhoArquivo = data.dsnameLimite
        ? data.dsnameLimite.limite
        : 23;
    });
  }

  ngOnInit() {
    this._route.data.subscribe((data) => {
      this.isVisualizacao = data.isVisualizacao;
      this.produtoFinanceiro = data.listaProdutoFinanceiro;
      this.optionList.listaTipoNomenclatura = this.listaTipoNomenclatura;
      this.setRenderPage();
      this.form = this._formBuilder.group(this.generateForm() || null);
      this.setForm();
      this.refreshOptionsConfig();
    });
  }

  setForm() {
    if (Array.isArray(this.layoutRender)) {
      if (this.layoutRender.length > 0) {

        for (let k = 0; k < this.configuracao.checklistConfiguracaoDsname.length; k++) {
          const elChecklistConfig = this.configuracao.checklistConfiguracaoDsname[k];

          elChecklistConfig.dsnames = this.getDsnameValue(elChecklistConfig.checklistDsname);
          let dsnameValue1 = '';
          let checklistDsnamesViaExemplo = this.preencheDsnameViaExemplo(elChecklistConfig.produtoFinanceiro.dsname_exemplo, elChecklistConfig.direcaoId);
          for (let l = 0; l < checklistDsnamesViaExemplo.length; l++) {
            const elDsnames = checklistDsnamesViaExemplo[l]
            dsnameValue1 += (elDsnames.campo.exemplo) ? elDsnames.campo.exemplo : '';
          }
          let produto = elChecklistConfig.produtoFinanceiro.nome.replace(/\s+/g, '');
          if (this.form.get('tipoNomenclatura' + produto).value == null ||
            this.form.get('tipoNomenclatura' + produto).value == undefined) {
          }


          for (let i = 0; i < this.layoutRender.length; i++) {
            const elRende = this.layoutRender[i];

            if (this.form.get('tipoNomenclatura' + elRende.objProdutoFinancerio).value == 1) {
              elRende.showButtonTipoDsName = false;
            }

            for (let j = 0; j < elRende.direcoes.length; j++) {
              const elForm = elRende.direcoes[j];

              if (elChecklistConfig.produtoFinanceiro &&
                elChecklistConfig.produtoFinanceiro.codigo == elForm.codigoProduto) {

                if (elChecklistConfig.direcaoId == elForm.direcaoId) {

                  if ((elForm.dsname == '' || elForm.dsname == undefined || elForm.dsname == null) ||
                    (elChecklistConfig.checklistDsname != undefined)) {
                    elForm.checklistDsname = elChecklistConfig.checklistDsname;
                    if (elForm.dsnames.length == 0) {
                      elForm.dsnames = elChecklistConfig.checklistDsname;
                    }
                    let dsnameValue2 = '';
                    for (let l = 0; l < elChecklistConfig.checklistDsname.length; l++) {
                      const elDsnames = elChecklistConfig.checklistDsname[l]
                      dsnameValue2 += (elDsnames.campo) ? (elDsnames.campo.exemplo) ? elDsnames.campo.exemplo : '' :
                        (elDsnames.dsnameCampo) ? (elDsnames.dsnameCampo.exemplo) : '';
                    }
                    this.form.get('dsname' + elForm.nome + elForm.nomeProduto).setValue(dsnameValue2);

                    if (dsnameValue1 === dsnameValue2) {
                      this.form.get('tipoNomenclatura' + produto).setValue(2);
                    } else {
                      this.form.get('tipoNomenclatura' + produto).setValue(1);
                    }

                  } else {
                    elChecklistConfig.checklistDsname = elForm.checklistDsname;
                    this.form.get('dsname' + elForm.nome + elForm.nomeProduto).setValue(elForm.dsname);
                  }

                  if (this.form.get('layout' + elForm.nome + elForm.nomeProduto).value == '' && elChecklistConfig.layout) {
                    this.form.get('layout' + elForm.nome + elForm.nomeProduto).setValue(elChecklistConfig.layout);
                  }

                  if (this.form.get('observacao' + elForm.nome + elForm.nomeProduto).value == null && elChecklistConfig.observacao) {
                    this.form.get('observacao' + elForm.nome + elForm.nomeProduto).setValue(elChecklistConfig.observacao);
                  }


                }
              }
            }
          }
        }
      }
    }
  }

  getDsnameValue(checklistDsname: ChecklistDsnameRequest[] | string): string {
    let dsnameValue = '';
    if (!isString(checklistDsname)) {
      for (let i = 0; i < checklistDsname.length; i++) {
        const elDsnames = checklistDsname[i]
        dsnameValue += (elDsnames.dsnameCampo) ? elDsnames.dsnameCampo.exemplo : elDsnames.exemplo;
      }
    } else {
      dsnameValue = checklistDsname;
    }
    return dsnameValue;
  }

  protected crateForm(): { [key: string]: any; } {
    let formTemp = {};
    if (Array.isArray(this.layoutRender)) {
      if (this.layoutRender.length > 0) {
        for (let i = 0; i < this.layoutRender.length; i++) {
          const elRende = this.layoutRender[i];
          formTemp['tipoNomenclatura' + elRende.objProdutoFinancerio] = [];

          for (let j = 0; j < elRende.direcoes.length; j++) {
            const elForm = elRende.direcoes[j];
            formTemp['dsname' + elForm.nome + elForm.nomeProduto] = ['', Validators.required];
            formTemp['layout' + elForm.nome + elForm.nomeProduto] = ['', Validators.required];
            formTemp['observacao' + elForm.nome + elForm.nomeProduto] = [];
          }
        }
      }
    }
    return formTemp;
  }

  setRenderPage() {
    if (this.produtoFinanceiro) {

      for (let i = 0; i < this.produtoFinanceiro.length; i++) {
        const elprodutoFinanceiro = this.produtoFinanceiro[i];

        let direcoes = [];

        let checklistDsnames: any;
        let checklistDsnamesViaExemplo: Array<any>;
        let dsnameValue = '';
        let layoutValue: number;
        let observacaoValue = '';

        elprodutoFinanceiro.produtoFinanceiro.showDropDownTipoDsName = false;
        elprodutoFinanceiro.produtoFinanceiro.showButtonTipoDsName = false;
        if (elprodutoFinanceiro.produtoFinanceiro.dsname_exemplo) {
          elprodutoFinanceiro.produtoFinanceiro.showDropDownTipoDsName = true;
          elprodutoFinanceiro.produtoFinanceiro.showButtonTipoDsName = true;
        }

        for (let j = 0; j < this.direcoes.length; j++) {
          const elDirecoes = this.direcoes[j];
          dsnameValue = '';

          if (elprodutoFinanceiro.produtoFinanceiro.dsname_exemplo) {
            checklistDsnamesViaExemplo = this.preencheDsnameViaExemplo(elprodutoFinanceiro.produtoFinanceiro.dsname_exemplo, elDirecoes.id);
            checklistDsnames = { checklistDsname: checklistDsnamesViaExemplo };
            for (let l = 0; l < checklistDsnamesViaExemplo.length; l++) {
              const elDsnames = checklistDsnamesViaExemplo[l]
              dsnameValue += (elDsnames.campo.exemplo) ? elDsnames.campo.exemplo : '';
            }

            let addConfig: ChecklistDsnameLayout = {
              direcaoId: elDirecoes.id,
              checklistDsname: checklistDsnamesViaExemplo,
              dsnames: dsnameValue,
              id: elprodutoFinanceiro.produtoFinanceiro.codigo,
              layout: null,
              observacao: '',
              produtoFinanceiro: elprodutoFinanceiro.produtoFinanceiro
            };

            this.configuracao.checklistConfiguracaoDsname.push(addConfig)
          }

          direcoes.push({
            nome: elDirecoes.nome,
            direcaoId: elDirecoes.id,
            dsnames: (checklistDsnames) ? checklistDsnames.checklistDsname : [],
            dsname: dsnameValue,
            layout: layoutValue,
            observacao: observacaoValue,
            panelOpenState: false,
            nomeProduto: elprodutoFinanceiro.produtoFinanceiro.nome.replace(/\s+/g, ''),
            codigoProduto: elprodutoFinanceiro.produtoFinanceiro.codigo,
            exemploProduto: elprodutoFinanceiro.produtoFinanceiro.dsname_exemplo,
          })
        }

        this.layoutRender.push(
          {
            nomeProdutoFinancerio: elprodutoFinanceiro.produtoFinanceiro.nome,
            objProdutoFinancerio: elprodutoFinanceiro.produtoFinanceiro.nome.replace(/\s+/g, ''),
            showDropDownTipoDsName: elprodutoFinanceiro.produtoFinanceiro.showDropDownTipoDsName,
            showButtonTipoDsName: elprodutoFinanceiro.produtoFinanceiro.showButtonTipoDsName,
            direcoes: direcoes
          }
        );

      }
    }
  }

  preencheDsnameViaExemplo(dsname_exemplo: any, direcao: number): any[] {
    if (dsname_exemplo == null) {
      return [];
    }
    const match = dsname_exemplo.split('][');
    let arr = [], array = [];
    for (let i = 0; i < match.length; i++) {
      const exemplo = match[i];
      let ex = exemplo.replace('[', '').replace(']', '');
      array = ex.split('_');

      if (array.length == 1) {
        arr.push(array[0]);
      } else {
        arr.push(array[0]);
        arr.push('_');
        arr.push(array[1]);
      }
    }

    if (direcao == 1) {
      arr.push('.');
      arr.push('REM');
    } else {
      arr.push('.');
      arr.push('RET');
    }
    let dsname: any;
    array = [];
    let m = 0;
    for (let j = 0; j < arr.length; j++) {
      const exemplo = arr[j];
      for (let k = 0; k < this.dsnameConfigTipos.length; k++) {
        const tipo = this.dsnameConfigTipos[k];
        for (let l = 0; l < tipo.campos.length; l++) {
          const campo = tipo.campos[l];
          if (campo.nome == exemplo) {

            let dsname = {
              valor: null,
              ordem: m++,
              tipo: this.getDsnameTipo(tipo.id),
              exemplo: exemplo,
              campo: this.getDsname(tipo.id, campo.id),
              dsnameCampoTipoId: tipo.id,
              dsnameCampoId: campo.id,
            }

            array.push(dsname);

          }
          if (exemplo == '_' || exemplo == '.') {
            if (campo.nome == 'Inserir ' + exemplo) {
              let dsname = {
                valor: null,
                ordem: m++,
                tipo: this.getDsnameTipo(tipo.id),
                exemplo: 'Inserir ' + exemplo,
                campo: this.getDsname(tipo.id, campo.id),
                dsnameCampoTipoId: tipo.id,
                dsnameCampoId: campo.id,
              }

              array.push(dsname);

            }
          }
        }
      }
    }

    return array;
  }

  addDsname(direcao: any) {
    const checklistDsname = direcao.dsnames
      ? direcao.dsnames
      : this.preencheDsnameConfigTipos(direcao.checklistDsname);
    const dataModal: ChecklistDsnameModal = {
      dsnames: checklistDsname || [],
      tamanhoArquivo: this.tamanhoArquivo,
    };

    this._dsnameModal
      .checklistDsname(dataModal)
      .subscribe((ChecklistDsname: Array<ChecklistDsname>) => {
        if (ChecklistDsname) {
          this.preencheDsname(ChecklistDsname, direcao);
        }
      });
  }

  private getDsnameTipo(idTipo: number): ChecklistDsnameConfigTipo {
    const lista = this.dsnameConfigTipos.filter((t) => t.id === idTipo);
    return lista && lista.length > 0 ? lista[0] : null;
  }

  private getDsname(idTipo: number, idDsname: number): ChecklistDsnameConfig {
    const listaTipos: ChecklistDsnameConfigTipo = this.getDsnameTipo(
      idTipo
    );
    const lista = listaTipos.campos.filter((t) => t.id === idDsname);
    return lista && lista.length > 0 ? lista[0] : null;
  }

  private preencheDsnameConfigTipos(
    checklistDsnames: Array<ChecklistDsnameRequest>
  ): Array<ChecklistDsname> {
    checklistDsnames = checklistDsnames ? checklistDsnames : [];
    const lista: Array<ChecklistDsname> = [];
    checklistDsnames.forEach((d) => {
      const dsnameCampoTipoId = d.dsnameCampoTipoId;
      const dsnameCampoId = d.dsnameCampoId;
      const campo = dsnameCampoId
        ? this.getDsname(dsnameCampoTipoId, dsnameCampoId)
        : null;
      lista.push({
        valor: d.campoValor,
        ordem: d.ordem,
        tipo: this.getDsnameTipo(dsnameCampoTipoId),
        campo: campo,
        exemplo: campo ? campo.exemplo : d.campoValor,
        dsnameCampoTipoId: dsnameCampoTipoId,
        dsnameCampoId: dsnameCampoId,

      });
    });
    return lista;
  }

  private preencheDsname(
    dsnames: Array<ChecklistDsname>,
    direcao: any,
    isSetForm?: boolean
  ) {
    dsnames = dsnames ? dsnames : [];
    let campo = '';
    dsnames.forEach((d) => (campo += d.exemplo));
    direcao.direcaoId = (direcao.nome == 'Remessa') ? 1 : 2;

    for (let i = 0; i < this.configuracao.checklistConfiguracaoDsname.length; i++) {
      const elCCD = this.configuracao.checklistConfiguracaoDsname[i];
      if (elCCD.direcaoId == direcao.direcaoId &&
        elCCD.produtoFinanceiro.codigo == direcao.codigoProduto) {
        elCCD.direcaoId = direcao.direcaoId;
        elCCD.dsnames = dsnames;
        elCCD.checklistDsname = dsnames;
        elCCD.layout = direcao.layout;
        elCCD.observacao = direcao.observacao;
      }
    }



    this.form.get('dsname' + direcao.nome + direcao.nomeProduto).setValue(campo);
    if (!isSetForm) {
      const layout = direcao;
      layout.dsnames = dsnames;
    }
  }

  changeTipoNomenclatura(change: any, layout: any) {
    layout.showButtonTipoDsName = (change.id == 2);
    let formName = null;
    if (change.id == 2) {
      for (let i = 0; i < this.configuracao.checklistConfiguracaoDsname.length; i++) {
        const elCCD = this.configuracao.checklistConfiguracaoDsname[i];
        const dsnameExemplo = this.preencheDsnameViaExemplo(elCCD.produtoFinanceiro.dsname_exemplo, elCCD.direcaoId);
        let dsnameValue = '';
        for (let l = 0; l < dsnameExemplo.length; l++) {
          const elDsnames = dsnameExemplo[l]
          dsnameValue += (elDsnames.campo.exemplo) ? elDsnames.campo.exemplo : '';
        }
        if (elCCD.direcaoId == 1) {
          formName = elCCD.produtoFinanceiro.nome.replace(/\s+/g, '');
          this.form.get('dsnameRemessa' + formName).setValue(dsnameValue);
        } else {
          formName = elCCD.produtoFinanceiro.nome.replace(/\s+/g, '');
          this.form.get('dsnameRetorno' + formName).setValue(dsnameValue);
        }
      }
    } else {
      for (let i = 0; i < this.configuracao.checklistConfiguracaoDsname.length; i++) {
        const elCCD = this.configuracao.checklistConfiguracaoDsname[i];
        if (elCCD.direcaoId == 1) {
          formName = elCCD.produtoFinanceiro.nome.replace(/\s+/g, '');
          this.form.get('dsnameRemessa' + formName).setValue(elCCD.dsnames);
        } else {
          formName = elCCD.produtoFinanceiro.nome.replace(/\s+/g, '');
          this.form.get('dsnameRetorno' + formName).setValue(elCCD.dsnames);
        }
      }
    }
  }

  modalArquivo() {
    this._modalArquivo
      .checklistArquivo({
        idChecklist: this.checklist.id,
        idChecklistConfiguracao: this.configuracao.id,
      },
        this.isVisualizacao)
      .subscribe(() => { });
  }

  gravar() {
    // Obtem valores do formulario
    let checklistConfiguracaoDsname = {
      checklistConfiguracaoAnexo: [],
      checklistConfiguracaoDsname: [],
      id: this.configuracao.id,
      isValid: false,
      moduloId: 6
    };

    for (let i = 0; i < this.layoutRender.length; i++) {
      const elRende = this.layoutRender[i];
      for (let j = 0; j < elRende.direcoes.length; j++) {
        const elForm = elRende.direcoes[j];

        let ObjCheckList = {
          moduloId: elForm.moduloId,
          checklistDsname: this.converteDname(elForm.dsnames),
          direcaoId: elForm.direcaoId,
          dsnames: this.form.get('dsname' + elForm.nome + elForm.nomeProduto).value,
          layout: this.form.get('layout' + elForm.nome + elForm.nomeProduto).value,
          observacao: this.form.get('observacao' + elForm.nome + elForm.nomeProduto).value,
          produtoFinanceiro: { descricao: elForm.nomeProduto, codigo: elForm.codigoProduto }

        }

        checklistConfiguracaoDsname.checklistConfiguracaoDsname.push(ObjCheckList);
      }
    }


    /*
    for (let k = 0; k < this.configuracao.checklistConfiguracaoDsname.length; k++) {
      const elChecklistConfig = this.configuracao.checklistConfiguracaoDsname[k];
      checklistConfiguracaoDsname.id = this.configuracao.id;
     
        for (let l = 0; l < checklistConfiguracaoDsname.length; l++) {        
          const elChecklistConfiguracaoDsname = checklistConfiguracaoDsname[l];
  
          if ((elChecklistConfig.direcaoId == elChecklistConfiguracaoDsname.direcaoId) &&
            (elChecklistConfig.produtoFinanceiro.codigo == elChecklistConfiguracaoDsname.produtoFinanceiro.codigo)) {
            elChecklistConfig.checklistDsname = elChecklistConfiguracaoDsname.checklistDsname;
            elChecklistConfig.dsnames = elChecklistConfiguracaoDsname.dsnames;
            elChecklistConfig.layout = elChecklistConfiguracaoDsname.layout;
            elChecklistConfig.observacao = elChecklistConfiguracaoDsname.observacao;
            elChecklistConfig.produtoFinanceiro = elChecklistConfiguracaoDsname.produtoFinanceiro;
          };
        }      
    }
    */


    // Add Configurações
    const obj = {
      checklistConfiguracao: [],
    };
    obj.checklistConfiguracao.push(checklistConfiguracaoDsname);

    // Send Backend
    this._spinner.show();
    this._checklistConfiguracaoService
      .save(this.checklist.id, obj)
      .subscribe(
        (config) => {
          this.sucess(`Configuração ${this.titulo} salvo com sucesso!`);
          this.salvando.emit(true);
        },
        (error) => this.error(error)
      );
  }

  private converteDname(
    dsnames: Array<ChecklistDsname>
  ): Array<ChecklistDsnameRequest> {
    const dsnamesRequest: Array<ChecklistDsnameRequest> = [];
    for (let i = 0; i < dsnames.length; i++) {
      const elDsnames = dsnames[i]
      if (elDsnames.dsnameCampoId != null) {
        dsnamesRequest.push({
          dsnameCampoId: elDsnames.campo ? elDsnames.campo.id : (elDsnames.dsnameCampoId) ? elDsnames.dsnameCampoId : null,
          dsnameCampoTipoId: elDsnames.tipo ? elDsnames.tipo.id : (elDsnames.dsnameCampoTipoId) ? elDsnames.dsnameCampoTipoId : null,
          ordem: elDsnames.ordem,
          campoValor: null,
          exemplo: elDsnames.exemplo,
        });
      }
    }
    return (dsnamesRequest.length > 0) ? dsnamesRequest : dsnames;
  }

}

export class TipoNomenclaturaCombos {
  static combos() {
    return [
      {
        listName: 'listaTipoNomenclatura',
        filterName: 'filterTipoNomenclatura',
        fieldValue: 'id',
      },
    ];
  }
}