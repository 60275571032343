import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { ImportacaoOsDetalheModalComponent } from '../../modal/importacao-os-detalhe-modal/importacao-os-detalhe-modal.component';
import { ImportacaoOsErroModalComponent } from '../../modal/importacao-os-erro-modal/importacao-os-erro-modal.component';
import { ImportacaoOsUploadModalComponent } from '../../modal/importacao-os-upload-modal/importacao-os-upload-modal.component';
import { ImportacaoOs } from '../interfaces/importacao-os.interfaces';

@Injectable({
  providedIn: 'root'
})
export class ImportacaoOsModalService {
  constructor(public dialog: MatDialog) { }

  importacaoErro(data: ImportacaoOs): Observable<any> {
    const dialogRef = this.dialog.open(
      ImportacaoOsErroModalComponent,
      {
        minWidth: '500px',
        width: '80%',
        minHeight: '200px',
        direction: 'ltr',
        data: data,
      }
    );

    return dialogRef.afterClosed();
  }

  importacaoDetalhe(data: ImportacaoOs): Observable<any> {
    const dialogRef = this.dialog.open(
      ImportacaoOsDetalheModalComponent,
      {
        minWidth: '500px',
        width: '80%',
        minHeight: '200px',
        direction: 'ltr',
        data: data,
      }
    );

    return dialogRef.afterClosed();
  }

  upload(): Observable<any> {
    const dialogRef = this.dialog.open(
      ImportacaoOsUploadModalComponent,
      {
        minWidth: '500px',
        width: '80%',
        minHeight: '200px',
        direction: 'ltr',
      }
    );

    return dialogRef.afterClosed();
  }
}
