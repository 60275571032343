import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { DataUtil } from '@utils/utils/class/data.util';
import { ContratoItem } from 'app/main/contrato/shared/interfaces/contrato-item.interface';
import * as moment from 'moment';
import { FaturamentoParcela } from '../../shared/interfaces/faturamento-parcela.interface';
import { FaturamentoRecorrenteStatus } from '../../shared/interfaces/faturamento-recorrente-status.interface';
import { FaturamentoRecorrente, FaturamentoRecorrenteItemNota } from '../../shared/interfaces/faturamento-recorrente.interface';
import { FaturamentoRequest } from '../../shared/interfaces/faturamento.interface';
import { FaturamentoRecorrenteService } from '../../shared/services/faturamento-recorrente.service';

@Component({
    selector: 'faturamento-recorrente-faturar-aba',
    templateUrl: './faturamento-recorrente-faturar-aba.component.html',
    styleUrls: ['./faturamento-recorrente-faturar-aba.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FaturamentoRecorrenteFaturarAbaComponent extends FormBase implements OnInit {
    public faturamento: FaturamentoRecorrente = {};
    public faturamentoItens: Array<FaturamentoRecorrenteItemNota> = [];
    public itensValores: any = {}
    public totalFaturamento: number = 0;
    public isEdit: boolean = true;
    public isView: boolean = false;
    public saldoEmitir: number = 0;

    private itensContrato: Array<FaturamentoRecorrenteItemNota> = []

    constructor(
        injector: Injector,
        protected _faturamentoRecorrenteService: FaturamentoRecorrenteService
    ) {
        super(injector, FaturamentoFaturarAbaCombos.combos());
    }

    ngOnInit() {
        this._route.url.subscribe(params => {
            let isView = false;
            params.forEach(param => {
                if (param.path === 'visualizar') this.isView = true;
            })

            this.isEdit = !isView

        })

        this._route.data.subscribe((data) => {
            const faturamentoItens = this.billerItemToFaturamentoItemList(data.listaFaturamentoItens)
            console.log(faturamentoItens)
            this.faturamento = data.faturamento;

            this.optionList.listaStatus = data.listaStatus;
            this.optionList.listaEmissor = data.listaEmissor;
            this.optionList.listaTipoCobranca = data.listaTipoCobranca;


            this.itensContrato = this.contratoItemToFaturamentoItemList(data.listaItensContrato)

            this.getListaItemLinha(this.itensContrato)

            if (this.faturamento) {
                this.setForm(this.faturamento);

            }

            if (faturamentoItens && faturamentoItens.length > 0) {
                faturamentoItens.forEach(item => {
                    this.addItemLinha(item)
                })

            }
            else {
                this.addItemLinha();
            }


            this.refreshOptionsConfig();
            this.calculos();
        });
    }

    protected crateForm(): { [key: string]: any } {
        return {
            id: [],
            contrato: [],
            status: [],
            emissor: ['', Validators.required],
            tipoCobranca: ['', Validators.required],
            saldoEmitir: ['', Validators.required],
            valorRecorrencia: ['', Validators.required],
            descontoTotal: [],
            valorNota: [],
            valorDesconto: [],

            diasVencer: [],
            dataEmissao: [],
            dataEmissaoStr: [],
            dataVencimento: [],
            dataPagamento: [],
            notaEmitida: [],
        };
    }

    getForm(): FaturamentoRecorrente {
        const id = this.form.get('id').value;
        const status = this.form.get('status').value;
        const idEmissor = this.form.get('emissor').value;
        const idTipoCobranca = this.form.get('tipoCobranca').value;
        const diasAVencer = this.form.get('diasVencer').value;
        let desconto = this.form.get('descontoTotal').value;
        let valorRecorrencia = this.form.get('valorRecorrencia').value;
        let valorDesconto = this.form.get('valorDesconto').value;
        let valorNota = this.form.get('valorNota').value;
        let dataVencimento = this.form.get('dataVencimento').value;

        dataVencimento = dataVencimento ? DataUtil.toDateBackend(dataVencimento) : undefined;
        desconto = desconto ? desconto.toString() : null;

        valorRecorrencia = valorRecorrencia ? valorRecorrencia.toString() : undefined;
        valorRecorrencia = !/[\.]/.test(valorRecorrencia) ? `${valorRecorrencia}.00` : valorRecorrencia;

        valorDesconto = valorDesconto ? valorDesconto.toString() : `0.00`;
        valorDesconto = !/[\.]/.test(valorDesconto) ? `${valorDesconto}.00` : valorDesconto;

        valorNota = valorNota ? valorNota.toString() : undefined;
        valorNota = !/[\.]/.test(valorNota) ? `${valorNota}.00` : valorNota;

        const faturamentoRecorrente = Object.assign({}, {
            id,
            idEmissor,
            idTipoCobranca,
            valorRecorrencia,
            valorNota,
            diasAVencer,
            dataVencimento,
            desconto,
            valorDesconto,
            itens: this.getFormItens()
        })

        return faturamentoRecorrente;
    }

    getFormItens(): Array<FaturamentoRecorrenteItemNota> {
        const itens: Array<FaturamentoRecorrenteItemNota> = []

        for (let index = 0; index < this.faturamentoItens.length; index++) {
            const itemLinha = this.form.get(`itensLinha${index}`).value
            itens.push({
                idFaturamentoRecorrenteItem: itemLinha ? itemLinha.id : undefined,
                valorItem: this.form.get(`valorItem${index}`).value,
                quantidade: this.form.get(`quantidade${index}`).value,
                valorFaturar: this.form.get(`valorFaturar${index}`).value,
                valorTotalItem: this.form.get(`valorTotalItem${index}`).value,
                desconto: this.form.get(`desconto${index}`).value,
            })
        }

        return itens
    }

    getIsEdit(parcela: FaturamentoParcela) {
        if (this.isView) {
            return false;
        }
        if (parcela && parcela.faturamentoParcelaStatus) {
            const idStatus = parcela.faturamentoParcelaStatus.id
            if ([2, 3, 5].find(id => id === idStatus)) {
                return true;
            }
        }
    }

    setForm(faturamento: FaturamentoRecorrente) {
        this.form.patchValue({
            id: faturamento.id,
            contrato: faturamento.contrato ? faturamento.contrato.numeroContrato : undefined,
            // status: faturamento.cotacao && faturamento.cotacao.status ? faturamento.cotacao.status.descricao : undefined,
            emissor: faturamento.emissor ? faturamento.emissor.id : undefined,
            tipoCobranca: faturamento.cotacao && faturamento.cotacao.tipoCobranca ? faturamento.cotacao.tipoCobranca.id : undefined,

            valorRecorrencia: faturamento.valorRecorrencia,
            descontoTotal: faturamento.descontoTotal,
            valorNota: faturamento.valorNota,
            valorDesconto: faturamento.valorDesconto,

            diasVencer: faturamento.diasAVencer || 7,
            dataEmissao: new Date(),
            // dataEmissao: faturamento.dataEmissao, 
            // dataEmissaoStr: faturamento.dataEmissaoStr, 
            dataVencimento: faturamento.dataVencimento,
            // dataPagamento: faturamento.dataPagamento, 
            // notaEmitida: faturamento.notaEmitida, 
        })

        this.calculaDataVencimento()
    }

    somaValorRecorrencia() {
        let saldoTotal = 0;
        let itensValores = {};
        this.faturamentoItens.forEach((item: FaturamentoRecorrenteItemNota) => {
            const id = item.id;
            if (id) {
                itensValores[id] = itensValores[id] ? itensValores[id] : 0;
                itensValores[id] = itensValores[id] + item.valor;
                saldoTotal += item.valor;
            }
        })
        this.itensValores = Object.assign({}, itensValores)
        this.form.get('valorRecorrencia').setValue(saldoTotal);
        this.validaItensLinhas();
    }

    validaItensLinhas(): boolean {
        let isValido = true;
        this.faturamentoItens.forEach(item => {
            const idItem = item.id;
            const totalValorFaturar = this.itensValores[idItem];
            if (totalValorFaturar > item.valorTotalItem) {
                this._notification.error(`O item ${item.descricao} ultrapassou o valor total!`)
                isValido = false;
            }
        })
        return isValido;
    }

    salvar() {
        if (
            this.validaItensLinhas()
        ) {

            const faturamentoRecorrente: FaturamentoRecorrente = this.getForm()
            if (!faturamentoRecorrente.id) {
                this._notification.error("Faturamento recorrente não encontrado")
                return;
            }
            this._spinner.show();
            this._faturamentoRecorrenteService.update(faturamentoRecorrente).subscribe(
                result => {
                    this.sucess('Faturamento salvo com sucesso')
                },
                error => this.error(error))
        }
    }

    removeFormFaturamentoItem(item: FaturamentoRecorrenteItemNota, index: number) {
        this.form.removeControl(`itensLinha${index}`);
        this.form.removeControl(`valorItem${index}`);
        this.form.removeControl(`quantidade${index}`);
        this.form.removeControl(`valorFaturar${index}`);
        this.form.removeControl(`valorTotalItem${index}`);
        this.form.removeControl(`desconto${index}`);
    }

    limparFormFaturamentoItem(index: number) {
        this.form.get(`itensLinha${index}`).setValue('');
        this.form.get(`valorItem${index}`).setValue('');
        this.form.get(`quantidade${index}`).setValue('');
        this.form.get(`valorFaturar${index}`).setValue('');
        this.form.get(`valorTotalItem${index}`).setValue('');
        this.form.get(`desconto${index}`).setValue('');
    }

    removeItemLinha(item: FaturamentoRecorrenteItemNota, index: number) {
        if (this.faturamentoItens.length === 1) {
            this.limparFormFaturamentoItem(index)
        }
        else {
            this.removeFormFaturamentoItem(item, index)
            this.faturamentoItens.splice(index, 1)
        }
        this.getForm();
    }

    addItemLinha(item?: FaturamentoRecorrenteItemNota) {
        const index = this.faturamentoItens.length;
        this.addFormFaturamentoItem(item, index);
        this.faturamentoItens[index] = {};
    }

    addFormFaturamentoItem(data: FaturamentoRecorrenteItemNota, index: number) {
        this.form.addControl(`itensLinha${index}`, new FormControl());
        this.form.addControl(`filterItenLinha${index}`, new FormControl());
        this.form.addControl(`valorItem${index}`, new FormControl());
        this.form.addControl(`quantidade${index}`, new FormControl());
        this.form.addControl(`valorFaturar${index}`, new FormControl());
        this.form.addControl(`valorTotalItem${index}`, new FormControl());
        this.form.addControl(`desconto${index}`, new FormControl());


        if (data) {
            this.addFormItemLinha(data, index)
        }
    }

    addFormItemLinha(data, index) {
        let valorFaturar = 0
        let valorRestante = 0;

        if (data.valorFaturar) {
            valorFaturar = parseFloat(data.valorFaturar)
        }
        else {
            valorFaturar = this.calculaDesconto(data.desconto || 0, data.valorTotal);
        }

        if (this.itensValores[data.id]) {
            valorRestante = data.valorTotal - this.itensValores[data.id];
        }
        this.form.get(`itensLinha${index}`).setValue(data ? { id: data.id, descricao: data.itemCotacao.descricao } : undefined);
        this.form.get(`valorItem${index}`).setValue(data.valorItem);
        this.form.get(`quantidade${index}`).setValue(data.quantidade);
        this.form.get(`valorFaturar${index}`).setValue(valorFaturar);
        this.form.get(`valorTotalItem${index}`).setValue(data.valorTotalItem);
        this.form.get(`desconto${index}`).setValue(valorRestante);
    }

    mostraBtoCancelarNota(): boolean {
        if (!this.isEdit) {
            return false;
        }

        return true;
    }

    mostraBtoEmitirNota(): boolean {
        if (!this.isEdit) {
            return false;
        }

        return true;
    }

    verificaListaItemLinhaSelecionados(itemSelecionado) {
        const listaItemLinha: Array<any> = []
        let selecionado = false;

        if (!itemSelecionado) {
            return;
        }

        this.faturamentoItens.forEach((item: FaturamentoRecorrenteItemNota, index: number) => {
            const itensLinha = this.form.get(`itensLinha${index}`).value;
            if (itensLinha === itemSelecionado.value.id) {
                selecionado = true;
            }
        })

        if (selecionado) {
            this._notification.warning("Item já selecionado!");
            this.form.get(itemSelecionado.source.ngControl.name).setValue("");
            return;
        }

        const index = parseInt(itemSelecionado.source.ngControl.name.replace('itensLinha', ''))
        this.addDadosItemLinha(itemSelecionado.value, index);

        this.calculos();
    }

    addDadosItemLinha(itemLinha, index) {
        const produto = this.itensContrato.find(produto => produto.id === itemLinha.id);
        this.addFormItemLinha(produto, index)

    }

    calculos() {
        this.faturamentoItens.forEach((item: FaturamentoRecorrenteItemNota, index: number) => {
            this.calculaValorFaturarItem(index);
        });

        this.calculaValorRecorrencia();
        this.calculaValorDesconto();
        this.calculaNota();
    }

    calculaValorFaturarItem(index: number): void {
        const data = {
            porcentagem: this.form.get(`desconto${index}`).value,
            valorTotalItem: this.form.get(`valorTotalItem${index}`).value,

        }
        const valorDesconto = this.calculaDesconto(data.porcentagem, data.valorTotalItem)
        this.form.get(`valorFaturar${index}`).setValue(valorDesconto)

    }

    calculaValorRecorrencia(): void {
        const itens: Array<FaturamentoRecorrenteItemNota> = this.getFormItens();
        let valor = 0
        itens.forEach(item => {
            valor += parseFloat(item.valorTotalItem.toString())
        })
        this.form.get('valorRecorrencia').setValue(valor);
    }

    calculaValorDesconto(): void {
        const itens: Array<FaturamentoRecorrenteItemNota> = this.getFormItens();
        let valorRecorrencia = 0
        let valorFaturar = 0
        itens.forEach(item => {
            valorRecorrencia += parseFloat(item.valorTotalItem.toString())
            valorFaturar += parseFloat(item.valorFaturar.toString())
        })
        const valor = valorRecorrencia - valorFaturar
        this.form.get('valorDesconto').setValue(valor);
    }

    calculaNota(): void {
        const itens: Array<FaturamentoRecorrenteItemNota> = this.getFormItens();
        let valor = 0
        itens.forEach(item => {
            if (item.valorFaturar) {
                valor += parseFloat(item.valorFaturar.toString())
            }
        })
        this.form.get('valorNota').setValue(valor);
    }

    calculaDataVencimento() {
        const diaVencer = this.form.get('diasVencer').value || 0;
        const dataEmissaoForm = this.form.get('dataEmissao').value;
        const dataEmissao = dataEmissaoForm ? DataUtil.toDateBackend(dataEmissaoForm) : new Date();
        const dataVencimento = moment(dataEmissao).add(diaVencer, 'day').format('DD/MM/YYYY');
        this.form.get('dataVencimento').setValue(dataVencimento)
    }

    setDescontoItens() {
        const itens: Array<FaturamentoRecorrenteItemNota> = this.getFormItens();
        const desconto = this.form.get('descontoTotal').value || 0;

        itens.forEach((item, index) => {
            this.form.get('desconto' + index).setValue(desconto)
        })

        this.calculos();
    }

    private calculaDesconto(porcentagem, valorTotalItem) {
        porcentagem = parseFloat(porcentagem);
        valorTotalItem = parseFloat(valorTotalItem);
        const valorPercentual = (valorTotalItem / 100) * porcentagem;
        return valorTotalItem - valorPercentual
    }

    private getListaItemLinha(produtos: Array<FaturamentoRecorrenteItemNota>) {
        this.optionList.listaItenLinha = [];
        produtos.forEach(item => {
            if (item && item.descricao) {
                this.optionList.listaItenLinha.push({
                    id: item.id,
                    descricao: item.descricao
                })
            }
        })
    }

    private contratoItemToFaturamentoItemList(items: Array<ContratoItem>): Array<FaturamentoRecorrenteItemNota> {
        const faturamentoItens: Array<FaturamentoRecorrenteItemNota> = []
        items.forEach(item => {
            const fatItem: FaturamentoRecorrenteItemNota = {
                id: item.id,
                // descricao: item.itemCotacao && item.itemCotacao.descricao ? item.itemCotacao.descricao : '',
                // valorItem: item.valorItem,
                quantidade: item.quantidade,
                valorTotalItem: item.valorTotal ? parseFloat(item.valorTotal) : undefined
            }

            faturamentoItens.push(fatItem)
        })
        return faturamentoItens
    }

    private billerItemToFaturamentoItemList(items: Array<any>): Array<FaturamentoRecorrenteItemNota> {
        const faturamentoItens: Array<FaturamentoRecorrenteItemNota> = []
        items.forEach(item => {
            const fatItem: FaturamentoRecorrenteItemNota = {
                id: item.contratoItem.id,
                idFaturamentoRecorrenteItem: item.id,
                itemCotacao: item.contratoItem.itemCotacao,
                descricao: item.contratoItem && item.contratoItem.produto && item.contratoItem.produto.nome ? item.contratoItem.produto.nome : '',
                valorItem: item.valorItem,
                quantidade: item.qtdeItem,
                valorTotalItem: item.valorTotal
            }

            faturamentoItens.push(fatItem)
        })
        return faturamentoItens
    }

}

export class FaturamentoFaturarAbaCombos {
    static combos() {
        return [
            {
                listName: 'listaStatus',
                filterName: 'filterStatus',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaEmissor',
                filterName: 'filterEmissor',
                fieldValue: 'descricao'
            },
            {
                listName: 'listaTipoCobranca',
                filterName: 'filterTipoCobranca',
                fieldValue: 'descricao'
            },
            {
                listName: 'listaItenLinha',
                fieldValue: 'descricao'
            }
        ]
    }
}