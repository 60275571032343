import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contrato } from '../interfaces/contrato.interface';
import { ContratoService } from '../services/contrato.service';
import { PreContratoService } from '../services/pre-contrato.service';

@Injectable()
export class PreContratoDetalheResolver implements Resolve<Contrato> {
    constructor(private _preContratoService: PreContratoService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        return this._preContratoService
            .findById(id)
            .pipe(map((result) => result.data));
    }
}
