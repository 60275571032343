import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Gmud } from '../interfaces/gmud.interface';
import { GmudService } from '../services/gmud.service';

@Injectable()
export class GmudResolver implements Resolve<Gmud> {
    constructor(private _gmudService: GmudService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._gmudService.find({}).pipe(map((result) => result.data));
    }
}
