import { Component, Input, OnInit } from '@angular/core';
import { Time } from '../../shareds/interfaces/time.interface';

@Component({
    selector: 'time-detalhe',
    templateUrl: './time-detalhe.component.html',
    styleUrls: ['./time-detalhe.component.scss'],
})
export class TimeDetalheComponent implements OnInit {
    @Input() time: Time;

    constructor() {}

    ngOnInit() {}
}
