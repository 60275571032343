import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import * as moment from 'moment';
import { FiltroAdendoContrato } from '../../shared/interfaces/filtro-adendo.interface';

@Component({
  selector: 'contrato-adendo-filtro',
  templateUrl: './contrato-adendo-filtro.component.html',
  styleUrls: ['./contrato-adendo-filtro.component.scss']
})
export class ContratoAdendoFiltroComponent extends FormFiltroBase<FiltroAdendoContrato> implements OnInit {

  @Output() filtrar = new EventEmitter<FiltroAdendoContrato>();
  desabilitarDataFinal: boolean;

  constructor(injector: Injector) {
    super('ContratoAdendoGrid', injector, ContratoAdendoFiltroCombos.combos());
  }

  ngOnInit() {
    this._route.data.subscribe((data) => {
      this.optionList.listaStatus = data.statuss;
      this.optionList.listaStatus2 = data.statuss;
      this.optionList.listaSituacao = data.situacoes;
      this.optionList.listaProduto = data.produtos;
      this.optionList.listaPrioridade = data.prioriodades;
      this.refreshOptionsConfig();
    })
  }

  camposFiltro(): string[] {
    return [
      'numeroContrato',
      'nomeEmpresa',
      'inscricao',
      'numeroCotacao',
      'produto',
      'prioridade',
      'situacao',
      'status',
      'status2',
      'dataCriacao',
      'dataVigencia',
      'dataAssinatura',
      'dataInicial',
      'dataFinal',
      'inativarDataFinalIndeterminada'
    ]
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      numeroContrato: [],
      nomeEmpresa: [],
      inscricao: [],
      numeroCotacao: [],
      produto: [],
      prioridade: [],
      situacao: [],
      status: [],
      status2: [],
      dataCriacao: [],
      tipoData: [],
      dataInicial: [],
      dataFinal: [],
      dataFinalVigenciaIndeterminado: []
    }
  }

  limpar(): void {
    this.form.reset();
    this.filtrar.emit(this.filtro);
  }

  filtrarExecEnter(event: { keyCode: number; }): void {
    if (event.keyCode === 13) {
      this.filtrarExec();
    }
  }

  filtrarExec(): void {
    const numeroContrato = this.form.get('numeroContrato').value;
    const nomeEmpresa = this.form.get('nomeEmpresa').value;
    const inscricao = this.form.get('inscricao').value;
    const numeroCotacao = this.form.get('numeroCotacao').value;
    const produto = this.form.get('produto').value;
    const prioridade = this.form.get('prioridade').value;
    const situacao = this.form.get('situacao').value;
    const status = this.form.get('status').value;
    const status2 = this.form.get('status2').value;
    const tipoData = this.form.get('tipoData').value;
    const dataInicial = this.form.get('dataInicial').value;
    const dataFinal = this.form.get('dataFinal').value;
    const inativarDataFinalIndeterminad = this.form.get('inativarDataFinalIndeterminad').value;

    const filtro: FiltroAdendoContrato = {
      contratoNumero: numeroContrato,
      nomeEmpresa: nomeEmpresa,
      inscricaoEmpresa: inscricao,
      numeroCotacao: numeroCotacao,
      produto: produto,
      prioridade: prioridade,
      situacao: situacao,
      statusId: status,
      statusSegId: status2,
      dataCriacaoInicial: dataInicial && tipoData == 'dataCriacao' ? moment(dataInicial, 'yyyy-MM-dd').toDate() : null,
      dataVigenciaInicial: dataInicial && tipoData == 'dataVigencia' ? moment(dataInicial, 'yyyy-MM-dd').toDate() : null,
      dataAssinaturaInicial: dataInicial && tipoData == 'dataAssinatura' ? moment(dataInicial, 'yyyy-MM-dd').toDate() : null,
      dataFinalVigenciaIndeterminado: inativarDataFinalIndeterminad
    }

    this.filtrar.emit(filtro);
  }

  inativarDataFinalIndeterminada() {
    const tipoData = this.form.get('tipoData').value;
    const isDataCriacao = tipoData === 'dataCriacao'
    if (isDataCriacao) {
      this.desabilitarDataFinal = false
      this.form.get('dataFinalVigenciaIndeterminado').setValue(false)
    }
    return isDataCriacao
  }

  desabilitarDataFinalIndeterminada(indeterminado) {
    const tipoData = this.form.get('tipoData').value;

    if (tipoData === 'dataVigencia' && indeterminado.checked) {
      this.form.get('dataFinal').setValue('');
      this.desabilitarDataFinal = true
    }
    else {
      this.desabilitarDataFinal = false
    }
  }
}

export class ContratoAdendoFiltroCombos {
  static combos() {
    return [
      {
        listName: 'listaProduto',
        filterName: 'filterProduto',
        fieldValue: 'nome',
      },
      {
        listName: 'listaPrioridade',
        filterName: 'filterPrioridade',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaSituacao',
        filterName: 'filterSituacao',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaStatus',
        filterName: 'filterStatus',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaStatus2',
        filterName: 'filterStatus2',
        fieldValue: 'descricao',
      }
    ]
  }
}