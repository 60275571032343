import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';

@Injectable({
    providedIn: 'root',
})
export class ChecklistNovoUsuarioService extends ServiceBase<any> {
    private path = `${environment.server}/checklist`;

    constructor(private _http: HttpClient) {
        super();
    }

    createNewUser(idChecklist: number, contatos: Array<number>): Observable<Response<Array<any>>> {
        const url = `${this.path}/${idChecklist}/criar-usuario`;
        const body = {
            'cce_id' : contatos
        }
        return this._http.post<Response<Array<any>>>(url, body);
    }
}
