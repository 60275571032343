import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { ChecklistDetalhe } from '../interfaces/checklist-detalhe.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistDetalheService extends ServiceBase<ChecklistDetalhe> {
    private path = `${environment.server}/checklist`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(idChecklist): Observable<Response<Array<ChecklistDetalhe>>> {
        const url = `${this.path}/${idChecklist}/detalhe`;
        return this._http.get<Response<Array<ChecklistDetalhe>>>(url);
    }
}
