import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroEmpresa } from '../interfaces/filtro-empresa.interface';
import { Empresa } from '../interfaces/empresa.interface';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root',
})
export class EmpresaService extends ServiceGridBase<Empresa, FiltroEmpresa> {
    private path: string = `${environment.server}/empresa`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroEmpresa): Observable<Response<Array<Empresa>>> {
        const params: string = filtro
            ? this.toGetParams(filtro, ['idChecklist'])
            : '';
        const url = `${environment.server}/checklist/${filtro.idChecklist}/empresa${params}`;
        return this._http.get<Response<Array<Empresa>>>(url);
    }

    findById(id: number | string): Observable<Response<Empresa>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Empresa>>(url);
    }

    save(empresa: Empresa): Observable<Response<Empresa>> {
        if (empresa.id) {
            return this.update(empresa);
        }
        else {
            return this.create(empresa);
        }
    }

    create(empresa: Empresa): Observable<Response<Empresa>> {
        const url = `${this.path}`;
        return this._http.post<Response<Empresa>>(
            url,
            Object.assign({}, empresa, { IdChecklist: empresa.idChecklist })
        );
    }

    update(empresa: Empresa): Observable<Response<Empresa>> {
        const url = `${this.path}/${empresa.id}`;
        return this._http.put<Response<Empresa>>(url, empresa);
    }

    delete(id: number): Observable<Response<Empresa>> {
        const url = `${this.path}/${id}`;
        return this._http.delete<Response<Empresa>>(url);
    }
}
