import {
    AfterViewInit,
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatOption, MatSelect } from "@angular/material"
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { ChecklistStatus } from 'app/main/checklist/shared/interfaces/checklist-status.interface';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { FormControl } from '@angular/forms';
import { DataUtil } from '@utils/utils/class/data.util';
import { Produto } from 'app/main/produto/shareds/interfaces/produto.interface';
import { FiltroRelacionamentoAbertura } from '../../shared/interfaces/filtro-relacionamento-abertura.interface';

@Component({
    selector: 'relacionamento-abertura-grid-filtro',
    templateUrl: './relacionamento-abertura-grid-filtro.component.html',
    styleUrls: ['./relacionamento-abertura-grid-filtro.component.scss'],
})
export class RelacionamentoAberturaGridFiltroComponent
    extends FormFiltroBase<FiltroRelacionamentoAbertura>
    implements OnInit {

    @Output() filtrar = new EventEmitter<FiltroRelacionamentoAbertura>();

    public filterStatusFinanceiro: FormControl = new FormControl();

    listaStatus: Array<any> = [];
    listaStatusFinanceiro: Array<any> = [];
    listaRelacionamentoAberturaStatus: Array<any> = [];
    listaTipoCobrancas: Array<any> = [];
    listaStatusChecklist: Array<ChecklistStatus> = [];
    listaProdutos: Array<Produto> = [];
    usuarios: Array<Usuario> = [];
    allSelected = false;
    @ViewChild('usuarioSel', { static: true }) skillSel: MatSelect;

    constructor(injector: Injector) {
        super('RelacionamentoAberturaGrid', injector, RelacionamentoAberturaGridFiltroCombos.combos());
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.optionList.listaStatusOs = data.listaStatusOs;
            this.optionList.listaProjetoStatus = data.listaProjetoStatus;
            this.optionList.listaResponsavelProjeto = data.listaUsuarios;
            this.optionList.listaResponsavelOs = data.listaUsuarios;
            this.optionList.listaTimeProjetos = data.listaTimes;
            this.optionList.listaTimeOs = data.listaTimes;
            this.optionList.listaBancos = data.listaBancos;

            this.form.patchValue({ tipoData: 'data-criacao', tipoDirecao: 'remessa' });

            this.refreshOptionsConfig();
        });
    }

    camposFiltro(): string[] {
        return [
            'nomeCotacao',
            'numeroCotacao',
            'osStatus',
            'os',
            'projeto',
            'projetoStatus',
            'responsavelProjeto',
            'responsavelOs',
            'empresa',
            'inscricao',
            'timeProjeto',
            'timeOs',
            'grupo',
            'banco',
            'agencia',
            'conta',
            'convenio',
            // 'tipoData',
            // 'dataInicio',
            // 'dataFim',
            'tipoDirecao',
            'caixaPostalOrigem',
            'caixaPostalDestino',
            'dsName'
        ]
    }

    protected crateForm(): { [key: string]: any } {
        return {
            nomeCotacao: [],
            numeroCotacao: [],
            osStatus: [],
            os: [],
            projeto: [],
            projetoStatus: [],
            responsavelProjeto: [],
            responsavelOs: [],
            empresa: [],
            inscricao: [],
            timeProjeto: [],
            timeOs: [],
            grupo: [],
            banco: [],
            agencia: [],
            conta: [],
            convenio: [],
            tipoData: [],
            dataInicio: [],
            dataFim: [],
            tipoDirecao: [],
            caixaPostalOrigem: [],
            caixaPostalDestino: [],
            dsName: []
        };
    }

    limparFiltro(): void {
        this.limpar();
        this.form.patchValue({ tipoData: 'data-criacao', tipoDirecao: 'remessa' });
    }

    filtrarExecEnter(event): void {
        if (event.keyCode === 13) {
            this.filtrarExec();
        }
    }

    filtrarExec(): void {
        const cotacaoNome = this.form.get('nomeCotacao').value;
        const cotacaoNumero = this.form.get('numeroCotacao').value;
        const osStatus = this.form.get('osStatus').value || {};
        const idOs = this.form.get('os').value;
        const idProjeto = this.form.get('projeto').value;
        const projetoStatus = this.form.get('projetoStatus').value || {};
        const empresaNome = this.form.get('empresa').value;
        const empresaInscricao = this.form.get('inscricao').value;
        const grupo = this.form.get('grupo').value;
        const banco = this.form.get('banco').value || {};
        const agencia = this.form.get('agencia').value;
        const conta = this.form.get('conta').value;
        const convenio = this.form.get('convenio').value;
        const tipoData = this.form.get('tipoData').value;
        const dataInicio = this.form.get('dataInicio').value;
        const dataFim = this.form.get('dataFim').value;
        const tipoDirecao = this.form.get('tipoDirecao').value;
        const caixaPostalOrigem = this.form.get('caixaPostalOrigem').value;
        const caixaPostalDestino = this.form.get('caixaPostalDestino').value;
        const dsName = this.form.get('dsName').value;


        //Responsável
        let responsavelProjeto = this.form.get('responsavelProjeto').value;
        responsavelProjeto = responsavelProjeto ? responsavelProjeto : [];
        let idResponsavelProjeto = [];
        if (responsavelProjeto) idResponsavelProjeto = [].concat(responsavelProjeto.map((u) => u.id));

        let responsavelOs = this.form.get('responsavelOs').value;
        responsavelOs = responsavelOs ? responsavelOs : [];
        let idResponsavelOs = [];
        if (responsavelOs) idResponsavelOs = [].concat(responsavelOs.map((u) => u.id));

        //Time
        let timeProjeto = this.form.get('timeProjeto').value;
        timeProjeto = timeProjeto ? timeProjeto : [];
        let idTimeProjeto = [];
        if (timeProjeto) idTimeProjeto = [].concat(timeProjeto.map((u) => u.id));

        let timeOs = this.form.get('timeOs').value;
        timeOs = timeOs ? timeOs : [];
        let idTimeOs = [];
        if (timeOs) idTimeOs = [].concat(timeOs.map((u) => u.id));

        const dataInicioStr = dataInicio ? DataUtil.toDateBackend(dataInicio) : '';
        const dataFimStr = dataFim ? DataUtil.toDateBackend(dataFim) : '';

        const filtro: FiltroRelacionamentoAbertura = {
            cotacaoNome,
            cotacaoNumero,
            idOs,
            idProjeto,
            empresaNome,
            agencia,
            conta,
            convenio,
            empresaInscricao,
            responsavelProjeto: idResponsavelProjeto.join(','),
            responsavelOs: idResponsavelOs.join(','),
            timeProjeto: idTimeProjeto.join(','),
            timeOs: idTimeOs.join(','),
            grupo,
            caixaPostalOrigemRemessa: tipoDirecao === 'remessa' ? caixaPostalOrigem : undefined,
            caixaPostalDestinoRemessa: tipoDirecao === 'remessa' ? caixaPostalDestino : undefined,
            caixaPostalOrigemRetorno: tipoDirecao === 'retorno' ? caixaPostalOrigem : undefined,
            caixaPostalDestinoRetorno: tipoDirecao === 'retorno' ? caixaPostalDestino : undefined,
            caixaPostalOrigemAtivacao: tipoDirecao === 'ativacao_portal' ? caixaPostalOrigem : undefined,
            caixaPostalDestinoAtivacao: tipoDirecao === 'ativacao_portal' ? caixaPostalDestino : undefined,
            dsName: dsName
        };

        filtro.statusOs = [];
        if (osStatus) {
            for (let i = 0; i < osStatus.length; i++) {
                const element = osStatus[i];
                filtro.statusOs.push(element.id)
            }
        }

        filtro.statusProjeto = [];
        if (projetoStatus) {
            for (let i = 0; i < projetoStatus.length; i++) {
                const element = projetoStatus[i];
                filtro.statusProjeto.push(element.id)
            }
        }

        filtro.banco = [];
        if (banco) {
            for (let i = 0; i < banco.length; i++) {
                const element = banco[i];
                filtro.banco.push(element.id)
            }
        }

        this.filtrar.emit(filtro);
    }

    selecionarTudo(): void {
        this.allSelected = !this.allSelected;  // to control select-unselect

        if (this.allSelected) {
            this.skillSel.options.forEach(
                (item: MatOption) => {
                    if (item.value != 'is_null') {
                        item.select()
                    }
                }
            );
        } else {
            this.skillSel.options.forEach(
                (item: MatOption) => {
                    item.deselect()
                });
        }
    }
}

export class RelacionamentoAberturaGridFiltroCombos {
    static combos() {
        return [
            {
                listName: 'listaStatusOs',
                filterName: 'filterStatusOs',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaProjetoStatus',
                filterName: 'filterProjetoStatus',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaResponsavelProjeto',
                filterName: 'filterResponsavelProjeto',
                fieldValue: 'nome'
            },
            {
                listName: 'listaResponsavelOs',
                filterName: 'filterResponsavelOs',
                fieldValue: 'nome'
            },
            {
                listName: 'listaTimeProjetos',
                filterName: 'filterTimeProjeto',
                fieldValue: 'nome'
            },
            {
                listName: 'listaTimeOs',
                filterName: 'filterTimeOs',
                fieldValue: 'nome'
            },
            {
                listName: 'listaBancos',
                filterName: 'filterBancos',
                fieldValue: 'descricaoCompleta'
            }
        ]
    }
}
