import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GmudImpStatus } from '../interfaces/gmud-imp-status.interface';
import { GmudImpStatusService } from '../services/gmud-imp-status.service';

@Injectable()
export class GmudImpStatusListaResolver implements Resolve<GmudImpStatus> {
    constructor(private _service: GmudImpStatusService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._service.find({}).pipe(map((result) => result.data));
    }
}
