import { Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { ChecklistProdutoPortal } from '../../shared/interfaces/checklist-produto-portal.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistService } from '../../shared/services/checklist.service';

@Component({
    selector: 'checklist-confirmacao-step',
    templateUrl: './checklist-confirmacao-step.component.html',
    styleUrls: ['./checklist-confirmacao-step.component.scss'],
})
export class ChecklistConfirmacaoStepComponent extends ComponentBase implements OnInit {
    @Input() checklist: Checklist;

    produtoBus: Array<ChecklistProdutoPortal> = [];
    isValid: boolean = false;

    constructor(
        private _checklistService: ChecklistService,
        private _injector: Injector
    ) {
        super(_injector);
    }

    ngOnInit(): void { 
        this._route.data.subscribe(data =>{
            this.produtoBus = data.produtoPortal
            this.isValid = !this.produtoBus.length ||
             (this.produtoBus.length > 0 && this.produtoBus.filter(p => !p.finalizado).length == 0)
        }) 
    }

    concluir(): void {
        console.log(this.checklist);
        this._confirmation
            .confirm({
                titulo: 'Deseja finalizar os steps do checklist?',
                mensagem:
                    'Ao finalizar os steps do checklist, o mesmo não poderá ser mais alterado!',
            })
            .subscribe((result) => {
                if (result) {
                    this._spinner.show();
                    this._checklistService
                        .finalizarStep(this.checklist.id)
                        .subscribe(
                            () => {
                                this.sucess('Steps do checklist finalizado com sucesso!');
                                this._router.navigate([
                                    'checklist',
                                    this.checklist.id,
                                    'bu',
                                ]);
                            },
                            (error) => {
                                this.error(error);
                            }
                        );
                }
            });
    }
}
