import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Projeto } from 'app/main/projeto/shareds/interfaces/projeto.interface';
import { ModalOsVincularComponent } from '../../modal/modal-os-vincular/modal-os-vincular.component';

@Injectable({
    providedIn: 'root',
})
export class OsVincularModalService {
    constructor(public dialog: MatDialog) {}

    vincularOs(data: Projeto) {
        const dialogRef = this.dialog.open(ModalOsVincularComponent, {
            width: '95%',
            data: data,
        });

        return dialogRef.afterClosed();
    }
}
