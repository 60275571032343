import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Contrato } from '../../shared/interfaces/contrato.interface';
import { ContratoService } from '../../shared/services/contrato.service';
import { FormBase } from '@utils/base/form-base/form-base';
import { ContratoStatus } from '../../shared/interfaces/contrato-status.interface';
import { ContratoSituacao } from '../../shared/interfaces/contrato-situacao.interface';
import * as moment from 'moment';
import { ContratoModalService } from '../../shared/services/contrato-modal.service';
import { TabelaDominio } from 'app/main/tabela-dominio/shareds/interfaces/tabela-dominio.interface';

@Component({
    selector: 'contrato-detalhe',
    templateUrl: './contrato-detalhe.component.html',
    styleUrls: ['./contrato-detalhe.component.scss'],
})
export class ContratoDetalheComponent extends FormBase implements OnInit {
    isEdit: boolean = false;
    public form: FormGroup;
    public contrato: Contrato = {};

    public multa: string;
    public responsabilidade: string;

    validadoRF: {
        validadoRF: boolean;
        naoEncontrado: boolean;
        texto: string;
    } = {
            validadoRF: false,
            naoEncontrado: true,
            texto: 'Não Encontrado',
        };
    listaStatus: Array<ContratoStatus> = [];
    listaSituacao: Array<ContratoSituacao> = [];
    listaRepresentante: Array<any>[];
    listaFinnetCNPJ: Array<TabelaDominio>[] = [];

    @Output() atualizaGrid = new EventEmitter<boolean>();

    constructor(
        protected _injector: Injector,
        protected _service: ContratoService,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalContrato: ContratoModalService,
        _formBuilder: FormBuilder
    ) {
        super(_injector, ContratoDetalheCombos.combos());
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.optionList.listaAutorizacaoBloqueio = data.autorizacaoBloqueio;
            this.optionList.listaAutorizacaoProtesto = data.autorizacaoProtesto;
            this.optionList.listaAutorizacaoUsoMarca = data.autorizacaoUsoMarca;
            this.optionList.listaStatus = data.statuss;
            this.optionList.listaSituacao = data.situacoes;
            this.optionList.listaProdutos = data.produtos;
            this.optionList.listaSegmento = data.segmentos;
            this.optionList.listaTipoMinuta = data.tipoMinuta;
            this.optionList.listaTipoCobranca = data.tipoCobranca;
            this.optionList.listaCnpjFinnet = data.finnetCNPJ;
            this.optionList.listaPrioridades = data.prioridades;
            this.optionList.listaStatusPrimario = data.statusPrimario;
            this.optionList.listaStatusSecundario = data.statusSecundario;
            this.optionList.listaIndiceReajuste = data.indiceReajuste;
            this.optionList.listaEstado = data.estados;
            this.optionList.listaLimitacaoResponsabilidade = [
                {},
                ...data.limitacaoResponsabilidade,
            ];
            this.optionList.listaMultaRescisoria = data.multaRescisoria;

            this.contrato = data.contrato;
            this.contrato.avisoPrevioEmDias =
                data.contrato.avisoPrevioEmDias || 3;
            this.contrato.prazoPagamentoDDF =
                data.contrato.prazoPagamentoDDF || 7;
            this.setForm(this.contrato);
            this.refreshOptionsConfig();
        });
    }

    public setForm(contrato: Contrato) {
        console.info(contrato);
        this.form.patchValue({
            vigenciaInicial: contrato.inicioVigencia
                ? new Date(contrato.inicioVigencia)
                : null,
            vigenciaFinal: contrato.finalVigencia
                ? new Date(contrato.finalVigencia)
                : null,
            vigenciaIndeterminado: contrato.finalVigenciaIndeterminado,
            empresaInscricao: contrato.cotacao.empresaInscricao,
            nome: contrato.cotacao.nome,
            representante: contrato.representanteLegal,

            status: contrato.status,
            cnpj: contrato.cotacao.inscricao,
            nomeEmpresa: contrato.cotacao.nomeEmpresa,
            inscricaoEstadual: contrato.empresa
                ? contrato.empresa.inscricaoEstadual
                : '',
            representanteLegal: contrato.representanteLegal,
            situacaoLegal: contrato.empresa
                ? contrato.empresa.situacaoCadastral
                : '',

            contato: contrato.cotacao.contato,
            telefone: contrato.cotacao.telefone,
            email: contrato.cotacao.email,
            cep: contrato.empresa ? contrato.empresa.cep : '',
            logradouro: contrato.empresa ? contrato.empresa.logradrouro : '',
            complemento: contrato.empresa ? contrato.empresa.complemento : '',
            numero: contrato.empresa ? contrato.empresa.numero : '',
            bairro: contrato.empresa ? contrato.empresa.bairro : '',
            municipio: contrato.empresa ? contrato.empresa.municipio : '',
            estado: contrato.empresa ? contrato.empresa.estado : '',

            finnetCNPJ: contrato.finnetCNPJ,
            segmento: contrato.segmento,
            indiceReajuste: contrato.indiceReajuste,
            autorizacaoUsoMarca: contrato.autorizacaoUsoMarca,
            autorizacaoBloqueio: contrato.autorizacaoBloqueio,
            autorizacaoProtesto: contrato.autorizacaoProtesto,
            tipoCobranca: contrato.tipoCobranca,
            avisoPrevioEmDias: contrato.avisoPrevioEmDias,
            prazoPagamentoDDF: contrato.prazoPagamentoDDF,
            diretorioContrato: contrato.diretorioContrato,
            limitacaoResponsabilidade: contrato.limitacaoResponsabilidade,
            multaRescisoria: contrato.multaRescisoria,
            prioridade: contrato.prioridade,
            situacao: contrato.situacao,
            statusPrimario: contrato.statusPrimario,
            statusSecundario: contrato.statusSecundario,
            testemunha: contrato.testemunha,
            observacao: contrato.observacao,

            limitacaoResponsabilidadeInformacao:
                contrato.limitacaoResponsabilidadeInformacao,
            multaRescisoriaInformacao: contrato.multaRescisoriaInformacao,
            tipoMinuta: contrato.tipoMinuta,
        });
        this.refreshOptionsConfig();
    }

    protected crateForm(): { [key: string]: any } {
        return {
            status: [{ value: '', disabled: true }, Validators.required],
            cnpj: [{ value: '', disabled: true }, Validators.required],
            nome: [{ value: '', disabled: true }, Validators.required],
            inscricaoEstadual: [{ value: '', disabled: true }],
            representanteLegal: [{ value: '', disabled: true }],
            situacaoLegal: [{ value: '', disabled: true }],

            contato: [{ value: '', disabled: true }],
            telefone: [{ value: '', disabled: true }],
            email: [{ value: '', disabled: true }],
            cep: [{ value: '', disabled: true }],
            logradouro: [{ value: '', disabled: true }],
            complemento: [{ value: '', disabled: true }],
            numero: [{ value: '', disabled: true }],
            bairro: [{ value: '', disabled: true }],
            municipio: [{ value: '', disabled: true }],
            estado: [{ value: '', disabled: true }],

            finnetCNPJ: [{ value: '', disabled: true }],
            segmento: [{ value: '', disabled: true }],
            indiceReajuste: [{ value: '', disabled: true }],
            autorizacaoUsoMarca: [{ value: '', disabled: true }],
            autorizacaoBloqueio: [{ value: '', disabled: true }],
            autorizacaoProtesto: [{ value: '', disabled: true }],
            tipoCobranca: [{ value: '', disabled: true }],
            avisoPrevioEmDias: [{ value: '', disabled: true }],
            prazoPagamentoDDF: [{ value: '', disabled: true }],
            diretorioContrato: [{ value: '', disabled: true }],
            limitacaoResponsabilidade: [{ value: '', disabled: true }],
            multaRescisoria: [{ value: '', disabled: true }],
            prioridade: [{ value: '', disabled: true }],
            situacao: [{ value: '', disabled: true }],
            statusContrato: [{ value: '', disabled: true }],
            statusPrimario: [{ value: '', disabled: true }],
            statusSecundario: [{ value: '', disabled: true }],

            testemunha: [{ value: '', disabled: true }],
            observacao: [{ value: '', disabled: true }],

            tipoMinuta: [{ value: '', disabled: true }],

            limitacaoResponsabilidadeInformacao: [
                { value: '', disabled: true },
            ],

            multaRescisoriaInformacao: [{ value: '', disabled: true }],

            // Filters
            filterEstado: '',
            filterSituacaoLegal: '',
        };
    }

    editar() {
        Object.keys(this.form.controls).forEach((key) =>
            this.form.controls[key].enable()
        );
        this.isEdit = true;
    }

    salvar() {
        if (!this.isEdit) {
            return;
        }

        const autorizacaoBloqueio = this.form.get('autorizacaoBloqueio').value;
        const autorizacaoProtesto = this.form.get('autorizacaoProtesto').value;
        const autorizacaoUsoMarca = this.form.get('autorizacaoUsoMarca').value;
        const finnetCNPJ = this.form.get('finnetCNPJ').value;
        const indiceReajuste = this.form.get('indiceReajuste').value;
        const limitacaoResponsabilidade = this.form.get(
            'limitacaoResponsabilidade'
        ).value;
        const multaRescisoria = this.form.get('multaRescisoria').value;
        const prioridade = this.form.get('prioridade').value;
        const situacao = this.form.get('situacao').value;
        const statusPrimario = this.form.get('statusPrimario').value;
        const statusSecundario = this.form.get('statusSecundario').value;
        const tipoCobranca = this.form.get('tipoCobranca').value;
        const tipoMinuta = this.form.get('tipoMinuta').value;

        const contrato: any = {
            id: this.contrato.id,
            idAutorizacaoBloqueio:
                (autorizacaoBloqueio && autorizacaoBloqueio.id) || null,
            idAutorizacaoProtesto:
                (autorizacaoProtesto && autorizacaoProtesto.id) || null,
            idAutorizacaoUsoMarca:
                (autorizacaoUsoMarca && autorizacaoUsoMarca.id) || null,
            idFinnetCNPJ: (finnetCNPJ && finnetCNPJ.id) || null,
            idIndiceReajuste: (indiceReajuste && indiceReajuste.id) || null,
            idLimitacaoResponsabilidade:
                (limitacaoResponsabilidade && limitacaoResponsabilidade.id) ||
                null,
            idMultaRescisoria: (multaRescisoria && multaRescisoria.id) || null,
            idPrioridade: (prioridade && prioridade.id) || null,
            idSituacao: (situacao && situacao.id) || null,
            idStatus: this.contrato.status.id,
            idStatusPrimario: (statusPrimario && statusPrimario.id) || null,
            idStatusSecundario:
                (statusSecundario && statusSecundario.id) || null,
            idTipoCobranca: (tipoCobranca && tipoCobranca.id) || null,
            idTipoMinuta: (tipoMinuta && tipoMinuta.id) || null,
            avisoPrevioEmDias: this.form.get('avisoPrevioEmDias').value,
            limitacaoResponsabilidadeInformacao: this.form.get(
                'limitacaoResponsabilidadeInformacao'
            ).value,
            multaRescisoriaInformacao: this.form.get(
                'multaRescisoriaInformacao'
            ).value,
            observacao: this.form.get('observacao').value || '',
            prazoPagamentoDDF: this.form.get('prazoPagamentoDDF').value,
            representanteLegal: this.form.get('representanteLegal').value,
            testemunha: this.form.get('testemunha').value,
        };

        console.log(contrato);

        this._spinner.show();
        this._service.update(contrato).subscribe(
            () => {
                this._spinner.hide();
                this._notification.sucess('Contrato atualizado com sucesso!');
            },
            (error) => {
                console.log(error);
                this._spinner.hide();

                if (error && error.error && error.error.errors) {
                    for (let es in error.error.errors) {
                        error.error.errors[es].forEach((e) => {
                            this._notification.error(e);
                        });
                    }
                }
            }
        );
    }

    bloquear() {
        Object.keys(this.form.controls).forEach((key) =>
            this.form.controls[key].disable()
        );
        this.isEdit = false;
    }

    limpaVigenciaFinal(indeterminado) {
        if (indeterminado.checked) {
            this.form.get('vigenciaFinal').setValue('');
        }
    }

    limpaVigenciaFinalIndeterminado(event) {
        const vigenciaFinal = this.form.get('vigenciaFinal').value;
        if (vigenciaFinal) {
            this.form.get('vigenciaIndeterminado').setValue(false);
        }
    }

    atualizarVigencia() {
        this._modalContrato
            .atualizarVigencia(this.contrato.id)
            .subscribe((result) => {
                if (result) {
                    this.atualizaContrato();
                    this.atualizaGrid.emit(true);
                }
            });
    }

    private atualizaContrato() {
        this._service
            .findById(this.contrato.id)
            .subscribe((result) => (this.contrato = result.data));
    }

    donwloadPropostaAceite() { }

    public setResponsabilidade(event) {
        this.responsabilidade = event.value.value;
    }

    public setMultaRescisoria(event) {
        this.multa = event.value.value;
    }

    public validarResponsabilidadeInformacao({ target }) {
        if (target.type.toUpperCase() === 'NUMBER') {
            if (target.value < 0) {
                target.value = 0;
            }
            target.value = parseInt(target.value);
        }
    }
}

export class ContratoDetalheCombos {
    static combos() {
        return [
            {
                listName: 'listaSituacao',
                filterName: 'filterSituacao',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaStatus',
                filterName: 'filterStatus',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaProdutos',
                filterName: 'filterProduto',
                fieldValue: 'nome',
            },
            {
                listName: 'listaCnpjFinnet',
                filterName: 'filterFinnetCNPJ',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaSegmento',
                filterName: 'filterSegmento',
                fieldValue: 'nome',
            },
            {
                listName: 'listaPrioridades',
                filterName: 'filterPrioridade',
                fieldValue: 'nome',
            },
            {
                listName: 'listaStatusPrimario',
                filterName: 'filterStatusPrimario',
                fieldValue: 'nome',
            },
            {
                listName: 'listaStatusSecundario',
                filterName: 'filterStatusSecundario',
                fieldValue: 'nome',
            },
            {
                listName: 'listaTipoMinuta',
                filterName: 'filterTipoMinuta',
                fieldValue: 'nome',
            },
            {
                listName: 'listaEstado',
                filterName: 'filterEstado',
                fieldValue: 'nome',
            },
            {
                listName: 'listaSituacaoLegal',
                fitlerName: 'filterSituacaoLegal',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaLimitacaoResponsabilidade',
                filterName: 'filterLimitacaoResponsabilidade',
                fieldValue: 'descricao',
            },
        ];
    }
}
