import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { FiltroProjetoAbertura } from '../interfaces/filtro-projeto-abertura.interface';
import { ProjetoAbertura } from '../interfaces/projeto-abertura.interface';
import { ProjetoAberturaConfiguracao } from '../interfaces/projeto-abertura-configuracao.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';

@Injectable({
    providedIn: 'root',
})
export class ProjetoAberturaConfiguracaoService extends ServiceBase<ProjetoAberturaConfiguracao> {
    private path: string = `${environment.server}/projetoabertura`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroProjetoAbertura
    ): Observable<Response<Array<ProjetoAbertura>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/busca${params}`;
        return this._http.get<Response<Array<ProjetoAbertura>>>(url);
    }

    configuracao(ids: Array<number>): Observable<Response<Array<ProjetoAbertura>>> {
        const url = `${environment.server}/configuracaoosabertura/${ids}`;
        return this._http.get<Response<Array<ProjetoAbertura>>>(url);
    }

    configuracaoItem(config: any, type: number) {
        let abertura_id = config['abertura_id'];
        let id = 0
        if (type == 1) {
            id = config['remessa_id'];
        } else if (type == 2) {
            id = config['retorno_id'];
        } else if (type == 3) {
            id = config['portal_id'];
        }
        if (!id) {
            throw new Error('Parametro dsname não encontrado');
        }
        const url = `${environment.server}/configuracaoosabertura/${abertura_id}/dsname/${id}`;
        return this._http.post<Response<Array<ProjetoAbertura>>>(url, config);
    }

    ativacaoPortal(ids: Array<number>): Observable<Response<Array<ProjetoAbertura>>> {
        const url = `${environment.server}/configurador/ativar-portais/${ids}`;
        return this._http.get<Response<Array<ProjetoAbertura>>>(url);
    }
}
