import { Component, Injector, OnInit } from '@angular/core';
import { ChacklistPageBase } from '../../shared/base/checklist-page-base';

@Component({
    selector: 'checklist-detalhe-page',
    templateUrl: './checklist-detalhe-page.component.html',
    styleUrls: ['./checklist-detalhe-page.component.scss'],
})
export class ChecklistDetalhePageComponent
    extends ChacklistPageBase
    implements OnInit {
    constructor(protected _injector: Injector) {
        super(_injector);
    }

    ngOnInit() {}
}
