import { Component, Injector, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { StatusFinanceiro } from 'app/main/cotacao/shared/interfaces/status-financeiro.interface';
import { TipoCobranca } from 'app/main/cotacao/shared/interfaces/tipo-cobranca.interface';
import { StatusFinanceiroService } from 'app/main/cotacao/shared/services/status-financeiro.service';
import { TipoCobrancaService } from 'app/main/cotacao/shared/services/tipo-cobranca.service';
import { FaturamentoEmissor } from '../../shared/interfaces/faturamento-emissor.interface';
import { FaturamentoRecorrenteEditarLote } from '../../shared/interfaces/faturamento-recorrente-editar-lote.interface';
import { EmissorService } from '../../shared/services/faturamento-emissor.service';
import { FaturamentoRecorrenteService } from '../../shared/services/faturamento-recorrente.service';

@Component({
  selector: 'editar-faturamento-recorrente',
  templateUrl: './editar-faturamento-recorrente.component.html',
  styleUrls: ['./editar-faturamento-recorrente.component.scss']
})
export class EditarFaturamentoRecorrenteComponent extends FormBase implements OnInit {

  private _faturamentoEditar: FaturamentoRecorrenteEditarLote;
  isView: boolean = false;
  listaEmissor: Array<FaturamentoEmissor> = [];
  listaStatusFinanceiro: Array<StatusFinanceiro> = [];
  listaTipoCobranca: Array<TipoCobranca> = [];

  checkEmissor: boolean = false;
  checkStatusFinanceiro: boolean = false;
  checkTipoCobranca: boolean = false;
  todosFull: boolean = false;

  @Output() atualizado = new EventEmitter<boolean>();

  get idsFaturamentoRecorrente() {
    return this._faturamentoEditar;
  }

  @Input() set idsFaturamentoRecorrente(faturamentoEditar: FaturamentoRecorrenteEditarLote) {
    const idsFaturamentoRecorrente = faturamentoEditar.idFaturamentoRecorrente;
    this._faturamentoEditar = faturamentoEditar;
    if (idsFaturamentoRecorrente && idsFaturamentoRecorrente.length > 0) {
      this.isView = true;
    }
  }

  constructor(
    private _injector: Injector,
    private _emissorService: EmissorService,
    private _statusFinanceiroService: StatusFinanceiroService,
    private _tipoCobrancaService: TipoCobrancaService,
    private _service: FaturamentoRecorrenteService
  ) {
    super(_injector, EditarFaturamentoCombos.combos());
  }

  ngOnInit() {
    this._spinner.show();
    this._emissorService.find({}).subscribe(
      (result) => {
        this.optionList.listaEmissor = result.data;
        this._spinner.hide();
        this.refreshOptionsConfig();
      },
      (error) => this.error(error)
    );
    this._statusFinanceiroService.find({}).subscribe(
      (result) => {
        this.optionList.listaStatusFinanceiro = result.data;
        this._spinner.hide();
        this.refreshOptionsConfig();
      },
      (error) => this.error(error)
    );
    this._tipoCobrancaService.find({}).subscribe(
      (result) => {
        this.optionList.listaTipoCobranca = result.data;
        this._spinner.hide();
        this.refreshOptionsConfig();
      },
      (error) => this.error(error)
    );
  }

  selecionaCheckEmissor(check) {
    this.checkEmissor = check;
    if (!check) {
      this.form.get('emissor').setValue('');
    }
  }
  selecionaCheckStatusFinanceiro(check) {
    this.checkStatusFinanceiro = check;
    if (!check) {
      this.form.get('statusFinanceiro').setValue('');
    }
  }
  selecionaCheckTipoCobranca(check) {
    this.checkTipoCobranca = check;
    if (!check) {
      this.form.get('tipoCobranca').setValue('');
    }
  }

  selecionaTodos(check) {
    if (!check) {
      this.form.reset();
    }
    this.checkEmissor = check;
    this.checkStatusFinanceiro = check;
    this.checkTipoCobranca = check;
  }

  isDesabled(chave: string) {
    switch (chave) {
      case 'checkEmissor':
        return !this.checkEmissor;
        break;
      case 'checkStatusFinanceiro':
        return !this.checkStatusFinanceiro;
        break;
      case 'checkTipoCobranca':
        return !this.checkTipoCobranca;
        break;
    }
  }

  alterar() {
    const idEmissor = this.form.get('emissor').value;
    const idStatusFinanceiro = this.form.get('statusFinanceiro').value;
    const idTipoCobranca = this.form.get('tipoCobranca').value;

    this._faturamentoEditar = Object.assign({}, this._faturamentoEditar, {
      idEmissor,
      idStatusFinanceiro,
      idTipoCobranca,
    });

    if (this._faturamentoEditar.idEmissor) {
      this._confirmation
        .confirm({
          titulo:
            'Tem certeza que deseja continuar com a Alteração dos faturamentos?',
          mensagem:
            'Esta alteração impacta nos Projetos relacionados.',
        })
        .subscribe((result) => {
          if (result) {
            this.alterarExec();
          }
        });
    } else {
      this.alterarExec();
    }
  }

  private alterarExec() {
    this._spinner.show();
    this._service.updateLote(this._faturamentoEditar).subscribe(
      () => {
        this.sucess('Faturamentos alterados com sucesso!');
        this.atualizado.emit(true);
      },
      (error) => this.error(error)
    );
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      emissor: [],
      statusFinanceiro: [],
      tipoCobranca: [],
    };
  }

}

export class EditarFaturamentoCombos {
  static combos() {
    return [
      {
        listName: 'listaEmissor',
        filterName: 'filterEmissor',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaStatusFinanceiro',
        filterName: 'filterStatusFinanceiro',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaTipoCobranca',
        filterName: 'filterTipoCobranca',
        fieldValue: 'descricao',
      },
    ];
  }
}