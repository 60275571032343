import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { GmudImp } from '../interfaces/gmud-imp.interface';
import { Gmud } from '../interfaces/gmud.interface';
import { GmudImpService } from '../services/gmud-imp.service';
import { GmudService } from '../services/gmud.service';

@Injectable()
export class GmudDetalheResolver implements Resolve<Gmud> {
    constructor(private _service: GmudService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let id = route.params['id'];
        return this._service.findById(id);
    }
}
