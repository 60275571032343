import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bi-relatorio',
  templateUrl: './bi-relatorio.component.html',
  styleUrls: ['./bi-relatorio.component.scss']
})
export class BiRelatorioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
