import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { ChecklistHistorico } from '../interfaces/checklist-historico.interface';
import { FiltroChecklistHistorico } from '../interfaces/filtro-checklist-historico.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistHistoricoService extends ServiceGridBase<
    ChecklistHistorico,
    FiltroChecklistHistorico
> {
    private path: string = `${environment.server}/checklist/historico`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroChecklistHistorico
    ): Observable<Response<Array<ChecklistHistorico>>> {
        const params: string = filtro
            ? this.toGetParams(filtro, ['idChecklist'])
            : '';
        const url = `${this.path}/${filtro.idChecklist}${params}`;
        return this._http.get<Response<Array<ChecklistHistorico>>>(url);
    }
}
