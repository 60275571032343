import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'modal-document-upload',
    templateUrl: './modal-document-upload.component.html',
    styleUrls: ['./modal-document-upload.component.scss'],
})
export class ModalDocumentUploadComponent implements OnInit {
    tituloModal: string = 'Enviar Arquivo';
    chave: string;

    constructor(
        public dialogRef: MatDialogRef<ModalDocumentUploadComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        if (this.data.tituloModal != undefined) {
            this.tituloModal = this.data.tituloModal;
        }

        if (this.data.chave != undefined) {
            this.chave = this.data.chave;
        }
    }

    fechar(): void {
        this.dialogRef.close();
    }

    uploadFinalizado(finalizado: any) {
        if (finalizado) {
            this.dialogRef.close();
        }
    }
}
