import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Gmud } from '../interfaces/gmud.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { GmudFluxo } from '../interfaces/gmud-fluxo.interface';
import { map } from 'rxjs/operators';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root',
})
export class GmudFluxoService extends ServiceBase<Gmud> {
    private path = `${environment.server}/gmud`;

    constructor(private _http: HttpClient) {
        super();
    }

    liberarAprovacaoUsuario(idGmud): Observable<GmudFluxo> {
        const url = `${this.path}/${idGmud}/liberarAprovacaoUsuario`;
        return this._http.get<Response<GmudFluxo>>(url).pipe(map(result => result.data));
    }
}
