import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalHistoricoDetalheGmudComponent } from '../../modal/modal-historico-detalhe-gmud/modal-historico-detalhe-gmud.component';
import { ModalHistoricoGmudComponent } from '../../modal/modal-historico-gmud/modal-historico-gmud.component';
import { GmudHistorico } from '../interfaces/gmud-historico.interface';
import { Gmud } from '../interfaces/gmud.interface';

@Injectable({
    providedIn: 'root',
})
export class GmudModalService {
    constructor(public dialog: MatDialog) { }


    historico(data: Gmud) {
        const dialogRef = this.dialog.open(ModalHistoricoGmudComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    historicoDetalhe(data: GmudHistorico) {
        const dialogRef = this.dialog.open(
            ModalHistoricoDetalheGmudComponent,
            {
                width: '90%',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }

}
