import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { FiltroImportacaoOsDetalhe } from '../interfaces/filtro-importacao-os-detalhe.interfaces';
import { ImportacaoOsDetalhe } from '../interfaces/importacao-os-detalhe.interfaces';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
  providedIn: 'root'
})
export class ImportacaoOsDetalheService extends ServiceGridBase<
ImportacaoOsDetalhe,
FiltroImportacaoOsDetalhe
> {
  private path = `${environment.server}/os-importar/os`;

  constructor(private _http: HttpClient) {
    super();
  }

  find(filtro: FiltroImportacaoOsDetalhe): Observable<Response<Array<ImportacaoOsDetalhe>>> {
    const url = `${this.path}/${filtro.idArquivo}`;
    return this._http.get<Response<Array<ImportacaoOsDetalhe>>>(url);
  }
}