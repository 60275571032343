import { Component, OnInit } from '@angular/core';
import { FiltroProjeto } from '../../shareds/interfaces/filtro-projeto.interface';

@Component({
  selector: 'projeto-grid-page',
  templateUrl: './projeto-grid-page.component.html',
  styleUrls: ['./projeto-grid-page.component.scss']
})
export class ProjetoGridPageComponent implements OnInit {

  filtro: FiltroProjeto

  constructor() { }

  ngOnInit() {
  }

  filtrar(filtro: FiltroProjeto){
    this.filtro = Object.assign({}, filtro) 
  }
}
