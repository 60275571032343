import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CotacaoCliente } from '../interfaces/cotacao-cliente.interface';
import { CotacaoClienteService } from '../services/cotacao-cliente.service';

@Injectable()
export class CotacaoClienteContarResolver
    implements Resolve<Array<CotacaoCliente>> {
    constructor(private _cotacaoClienteService: CotacaoClienteService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._cotacaoClienteService
            .find()
            .pipe(map((result) => result.data));
    }
}
