import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'usuario-grid-page',
    templateUrl: './usuario-grid-page.component.html',
    styleUrls: ['./usuario-grid-page.component.scss'],
})
export class UsuarioGridPageComponent {
    constructor() {}
}
