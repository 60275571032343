import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { SimNao } from '@utils/interfaces/shareds/sim-nao.interface';
import { SimNaoService } from '@utils/services/sim-nao.service';
import { Observable, of } from 'rxjs';

@Injectable()
export class SimNaoResolver implements Resolve<SimNao> {
    constructor(
        private service: SimNaoService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this.service.find();
    }
}
