import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FiltroImportacaoCotacaoErro } from '../../shared/interfaces/filtro-importacao-cotacao-erro.interface';
import { ImportacaoCotacaoErro } from '../../shared/interfaces/importacao-cotacao-erro.interface';
import { ImportacaoCotacao } from '../../shared/interfaces/importacao-cotacao.interface';
import { ImportacaoCotacaoErroService } from '../../shared/services/importacao-cotacao-erro.service';

@Component({
    selector: 'importacao-cotacao-erro',
    templateUrl: './importacao-cotacao-erro.component.html',
    styleUrls: ['./importacao-cotacao-erro.component.scss'],
})
export class ImportacaoCotacaoErroComponent
    extends GridBase<
    ImportacaoCotacaoErro,
    FiltroImportacaoCotacaoErro,
    ImportacaoCotacaoErroService
    >
    implements OnInit {
    @Input() importacao: ImportacaoCotacao = {};

    constructor(
        service: ImportacaoCotacaoErroService,
        injector: Injector,
        changeDetectorRefs: ChangeDetectorRef
    ) {
        super(service, injector, changeDetectorRefs);
    }

    ngOnInit(): void {
        this.filtro.idArquivo = this.importacao.id;
        this.initGrid(this.filtro);
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'linha', label: '' },
            { chave: 'coluna', label: '' },
            { chave: 'descricao', label: '' },
        ];
    }
}
