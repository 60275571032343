import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { FiltroChecklist } from '../interfaces/filtro-checklist.interface';
import { Response } from '@utils/interfaces/http/response.interface';
import { ChecklistStatus } from '../interfaces/checklist-status.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';

@Injectable({
    providedIn: 'root',
})
export class ChecklistStatusService extends ServiceBase<ChecklistStatus> {
    private path = `${environment.server}/checklist-status`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroChecklist
    ): Observable<Response<Array<ChecklistStatus>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<ChecklistStatus>>>(url);
    }

    findById(id: number | string): Observable<Response<ChecklistStatus>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<ChecklistStatus>>(url);
    }
}
