import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidacaoDigitoAgenciaContaModule } from './validacao-digito-agencia-conta/validacao-digito-agencia-conta.module';
import { ValidacaoDigitoAgenciaContaService } from './validacao-digito-agencia-conta/validacao-digito-agencia-conta.service';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,

    ValidacaoDigitoAgenciaContaModule
  ],
})
export class ValidacaoModule { }
