import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImportacaoCotacaoRoutingModule } from './importacao-cotacao-routing.module';
import { ImportacaoCotacaoGridPageComponent } from './pages/importacao-cotacao-grid-page/importacao-cotacao-grid-page.component';
import { ImportacaoCotacaoGuard } from './importacao-cotacao.guard';
import {
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
} from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@utils/utils.module';
import { ImportacaoCotacaoGridComponent } from './components/importacao-cotacao-grid/importacao-cotacao-grid.component';
import { ImportacaoCotacaoService } from './shared/services/importacao-cotacao.service';
import { ImportacaoCotacaoErroModalComponent } from './modal/importacao-cotacao-erro-modal/importacao-cotacao-erro-modal.component';
import { ImportacaoCotacaoUploadModalComponent } from './modal/importacao-cotacao-upload-modal/importacao-cotacao-upload-modal.component';
import { ImportacaoCotacaoErroComponent } from './components/importacao-cotacao-erro/importacao-cotacao-erro.component';
import { ImportacaoCotacaoUploadComponent } from './components/importacao-cotacao-upload/importacao-cotacao-upload.component';
import { ImportacaoCotacaoErroService } from './shared/services/importacao-cotacao-erro.service';
import { ImportacaoCotacaoModalService } from './shared/services/importacao-cotacao-modal.service';
import { ImportacaoCotacaoDetalheModalComponent } from './modal/importacao-cotacao-detalhe-modal/importacao-cotacao-detalhe-modal.component';
import { ImportacaoCotacaoDetalheComponent } from './components/importacao-cotacao-detalhe/importacao-cotacao-detalhe.component';

@NgModule({
    declarations: [
        ImportacaoCotacaoGridPageComponent,
        ImportacaoCotacaoGridComponent,
        ImportacaoCotacaoErroModalComponent,
        ImportacaoCotacaoUploadModalComponent,
        ImportacaoCotacaoErroComponent,
        ImportacaoCotacaoUploadComponent,
        ImportacaoCotacaoDetalheModalComponent,
        ImportacaoCotacaoDetalheComponent,
    ],
    imports: [
        CommonModule,
        ImportacaoCotacaoRoutingModule,

        UtilsModule,

        // TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,

        // FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
    ],
    providers: [
        ImportacaoCotacaoGuard,
        ImportacaoCotacaoService,
        ImportacaoCotacaoErroService,
        ImportacaoCotacaoModalService,
    ],
    entryComponents: [
        ImportacaoCotacaoErroModalComponent,
        ImportacaoCotacaoUploadModalComponent,
        ImportacaoCotacaoDetalheModalComponent,
    ],
})
export class ImportacaoCotacaoModule {}
