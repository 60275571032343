import { Component, Injector, OnInit } from '@angular/core';
import { ChecklistConectividadeBase } from '../checklist-conectividade-base';
import { ChecklistConectividadesFinnetFastClient } from '../shareds/interfaces/checklist-conectividades-finnet-fast-client.interface';

@Component({
  selector: 'checklist-conectividades-finnet-fast-client',
  templateUrl: './checklist-conectividades-finnet-fast-client.component.html',
  styleUrls: ['./checklist-conectividades-finnet-fast-client.component.scss']
})
export class ChecklistConectividadesFinnetFastClientComponent extends ChecklistConectividadeBase<ChecklistConectividadesFinnetFastClient> implements OnInit {

  constructor(
    protected _injector: Injector
  ) {
    super(_injector)
  }

  ngOnInit() {
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      caixaPostal: [],
      caixaPostalSenha: [],
    }
  }

  protected setForm(data: ChecklistConectividadesFinnetFastClient) {
    this.form.patchValue({
      caixaPostal: data.caixaPostal,
      caixaPostalSenha: data.caixaPostalSenha,
    })
  }

  public getForm() {
    const data: ChecklistConectividadesFinnetFastClient = {
      caixaPostal: this.form.get('caixaPostal').value,
      caixaPostalSenha: this.form.get('caixaPostalSenha').value,
    }

    this.values.emit(data);
  }

}
