import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Cotacao } from '../../shared/interfaces/cotacao.interface';

@Component({
    selector: 'cotacao-detalhe-page',
    templateUrl: './cotacao-detalhe-page.component.html',
    styleUrls: ['./cotacao-detalhe-page.component.scss'],
})
export class CotacaoDetalhePageComponent implements OnInit {
    cotacao: Cotacao = {};

    constructor(private _route: ActivatedRoute) {
        this._route.data.subscribe((data) => {
            this.cotacao = data.cotacao || {};
        });
    }

    ngOnInit(): void {}
}
