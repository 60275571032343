import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { CotacaoHistorico } from '../../shared/interfaces/cotacao-historico.interface';
import { Cotacao } from '../../shared/interfaces/cotacao.interface';
import { FiltroCotacaoHistorico } from '../../shared/interfaces/filtro-cotacao-historico.interface';
import { CotacaoHistoricoService } from '../../shared/services/cotacao-historico.service';
import { CotacaoModalService } from '../../shared/services/cotacao-modal.service';

@Component({
    selector: 'cotacao-historico',
    templateUrl: './cotacao-historico.component.html',
    styleUrls: ['./cotacao-historico.component.scss'],
})
export class CotacaoHistoricoComponent
    extends GridBase<
    CotacaoHistorico,
    FiltroCotacaoHistorico,
    CotacaoHistoricoService
    >
    implements OnChanges, OnInit {
    private _cotacao: Cotacao;
    get cotacao(): Cotacao {
        return this._cotacao;
    }
    @Input() set cotacao(cotacao: Cotacao) {
        this._cotacao = cotacao;
        this.filtro.idCotacao = cotacao.id;
        this.initGrid(this.filtro || {});
    }

    constructor(
        protected _service: CotacaoHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _modalCotacaoHistoricoService: CotacaoModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void { }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'data', label: '' },
            { chave: 'item', label: '' },
            { chave: 'acao', label: '' },
            { chave: 'usuario', label: '' },
        ];
    }

    selecionar(cotacaoHistorico: CotacaoHistorico) {
        this._modalCotacaoHistoricoService
            .historicoDetalhe(cotacaoHistorico)
            .subscribe(() => { });
    }
}
