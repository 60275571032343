import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContratoSituacao } from '../interfaces/contrato-situacao.interface';
import { ContratoSituacaoService } from '../services/contrato-situacao.service';

@Injectable()
export class ContratoSituacaoListaResolver implements Resolve<ContratoSituacao> {
    constructor(private _contratoSituacaoService: ContratoSituacaoService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._contratoSituacaoService.find({}).pipe(map((result) => result.data));
    }
}
