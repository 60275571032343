import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportacaoCotacaoGuard } from './importacao-cotacao.guard';
import { ImportacaoCotacaoGridPageComponent } from './pages/importacao-cotacao-grid-page/importacao-cotacao-grid-page.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [ImportacaoCotacaoGuard],
        canLoad: [ImportacaoCotacaoGuard],
        component: ImportacaoCotacaoGridPageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ImportacaoCotacaoRoutingModule {}
