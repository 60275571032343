import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { GmudImpRequest } from '../interfaces/gmud-imp.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GmudImpService extends ServiceBase<GmudImpRequest> {
    private path = `${environment.server}/imp`;

    constructor(private _http: HttpClient) {
        super();
    }



    save(imp: GmudImpRequest): Observable<GmudImpRequest> {
        if (imp.id) {
            return this.update(imp);
        } else {
            return this.create(imp);
        }
    }

    create(imp: GmudImpRequest): Observable<GmudImpRequest> {
        const url = `${this.path}`;
        return this._http.post<Response<GmudImpRequest>>(url, imp).pipe(map(result => result.data));
    }

    update(imp: GmudImpRequest): Observable<GmudImpRequest> {
        const url = `${this.path}`;
        return this._http.put<Response<GmudImpRequest>>(url, imp).pipe(map(result => result.data));
    }

    aprovacao(aprovado: boolean, idGmud: number): Observable<void> {
        const url = `${this.path}/${idGmud}/aprovar`;
        return this._http.post<Response<void>>(url, { aprovado }).pipe(map(result => result.data));
    }
}
