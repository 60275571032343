import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModuloListaResolver } from '../modulo/shareds/resolvers/modulo-lista.resolver';
import { ProdutoListaResolver } from '../produto/shareds/resolvers/produto-lista.resolver';
import { BancoGuard } from './banco.guard';
import { BancoCadastroPageComponent } from './pages/banco-cadastro-page/banco-cadastro-page.component';
import { BancoGridPageComponent } from './pages/banco-grid-page/banco-grid-page.component';
import { BancoDetalheResolver } from './shareds/resolvers/banco-detalhe.resolver';
import { BancoListaResolver } from './shareds/resolvers/banco-lista.resolver';


const routes: Routes = [
  {
    path: '',
    canActivate: [BancoGuard],
    canLoad: [BancoGuard],
    resolve: {
      listaBancos: BancoListaResolver
    },
    component: BancoGridPageComponent,
  },
  {
    path: 'cadastro',
    canActivate: [BancoGuard],
    canLoad: [BancoGuard],
    resolve: {
      listaProdutos: ProdutoListaResolver,
      listaModulos: ModuloListaResolver
    },
    component: BancoCadastroPageComponent,
  },
  {
    path: 'cadastro/:id/editar',
    canActivate: [BancoGuard],
    canLoad: [BancoGuard],
    resolve: {
      dadosBanco: BancoDetalheResolver,
      listaProdutos: ProdutoListaResolver,
      listaModulos: ModuloListaResolver,
      banco: BancoDetalheResolver,
    },
    component: BancoCadastroPageComponent,
  },
  {
    path: 'cadastro/:id/visualizar',
    canActivate: [BancoGuard],
    canLoad: [BancoGuard],
    resolve: {
      dadosBanco: BancoDetalheResolver,
      listaProdutos: ProdutoListaResolver,
      listaModulos: ModuloListaResolver,
      banco: BancoDetalheResolver,
    },
    component: BancoCadastroPageComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BancoRoutingModule { }
