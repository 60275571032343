import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { ContratoDocumentoDownload } from '../interfaces/contrato-documento-download.interface';

@Injectable({
    providedIn: 'root',
})
export class ContratoDocumentoDownloadService extends ServiceBase<ContratoDocumentoDownload> {
    private path: string = `${environment.server}/contrato`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(idContrato: number): Observable<Response<Array<ContratoDocumentoDownload>>> {
        const url = `${this.path}/${idContrato}/documento`;
        return this._http.get<Response<Array<ContratoDocumentoDownload>>>(url);
    }

    download(idDocumento: number): Observable<any> {
        const url = `${this.path}/documento/${idDocumento}/download`;
        return this._http.get<Response<ContratoDocumentoDownload>>(url);
    }

    delete(idDocumento): Observable<any> {
        const url = `${this.path}/documento/${idDocumento}`;
        return this._http.delete(url);
    }
}
