import { Injectable } from "@angular/core";
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class EmpresaGuard extends BaseGuard{
    protected configPermissions(): Array<GuardConfigPermission>{
        return [
            //{path: '/empresa', role: Role.LISTAR}
        ];
    }
}