import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { ConfirmationService } from './confirmation.service';
import {
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    declarations: [ModalConfirmationComponent],
    imports: [
        CommonModule,
        MatDialogModule,
        FlexLayoutModule,

        // Mat
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
    ],
    providers: [ConfirmationService],
    entryComponents: [ModalConfirmationComponent],
})
export class ConfirmationModule {}
