import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FiltroContrato } from '../../shared/interfaces/filtro-contrato.interface';
import { Location } from '@angular/common';
@Component({
    selector: 'contrato-detalhe-page',
    templateUrl: './contrato-detalhe-page.component.html',
    styleUrls: ['./contrato-detalhe-page.component.scss'],
})
export class ContratoDetalhePageComponent implements OnInit {
    filtro: FiltroContrato;
    atualizarGrid: boolean;
    atualizarAditivoGrid: boolean;
    contrato: any;
    prevLink: string;

    constructor(
        private _route: ActivatedRoute,
        private _location: Location,
        ) {}

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.contrato = data.contrato;
        });
        this._route.url.subscribe(
            (url) =>
                (this.prevLink = url.find((segment) =>
                    segment.path.includes('pre')
                )
                    ? 'pre-contrato'
                    : 'contrato')
        );
    }
    voltar() {
        this._location.back();
    }
    filtrar(filtro: FiltroContrato) {
        this.filtro = Object.assign({}, filtro);
    }

    atualizaGrid(isAtualizar: boolean) {
        this.atualizarGrid = Object.assign({});
    }

    atualizarGridAditivo(isAtualizar: boolean) {
        this.atualizarAditivoGrid = Object.assign({});
    }
}
