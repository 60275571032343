import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-projeto-abertura-cadastro-page',
    templateUrl: './projeto-abertura-cadastro-page.component.html',
    styleUrls: ['./projeto-abertura-cadastro-page.component.scss']
})
export class ProjetoAberturaCadastroPageComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
