import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TimeCadastroPageComponent } from './pages/time-cadastro-page/time-cadastro-page.component';
import { TimeGridPageComponent } from './pages/time-grid-page/time-grid-page.component';
import { TimeDetalhePageComponent } from './pages/time-detalhe-page/time-detalhe-page.component';
import { TimeDetalheResolver } from './shareds/resolvers/time-detalhe.resolver';
import { TimeGuard } from './time.guard';
import { UsuarioListaResolver } from '../usuario/resolvers/usuario-lista.resolver';
import { ProdutoListaResolver } from '../produto/shareds/resolvers/produto-lista.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [TimeGuard],
        canLoad: [TimeGuard],
        component: TimeGridPageComponent,
    },

    {
        path: 'cadastro',
        canActivate: [TimeGuard],
        canLoad: [TimeGuard],
        resolve: {
            produtos: ProdutoListaResolver,
        },
        component: TimeCadastroPageComponent,
    },

    {
        path: ':id/editar',
        canActivate: [TimeGuard],
        canLoad: [TimeGuard],
        resolve: {
            time: TimeDetalheResolver,
            produtos: ProdutoListaResolver,
        },
        component: TimeCadastroPageComponent,
    },

    {
        path: ':id/visualizar',
        canActivate: [TimeGuard],
        canLoad: [TimeGuard],
        resolve: {
            timeDetalheResolver: TimeDetalheResolver,
        },
        component: TimeDetalhePageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class TimeRoutingModule { }
