import {
    FormGroup,
    Validators,
    ValidatorFn,
    AbstractControl,
    ValidationErrors,
} from '@angular/forms';
import {
    Component,
    OnInit,
    ViewEncapsulation,
    Injector,
    OnDestroy,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { fuseAnimations } from '@fuse/animations';
import { AuthBaseController } from '../../../shared/auth-base.controller';
import { confirmaSenhaValidator } from '../../../shared/validators/confirma-senha.validator';
import { senhaForteValidator } from '../../../shared/validators/senha-forte.validator';
import { AuthRedefinirSenhaCliente } from '../../../shared/interfaces/auth-redefinir-senha-cliente.interface';
import { environment } from 'environments/environment';

@Component({
    selector: 'redefinir-senha-cliente-center',
    templateUrl: './redefinir-senha-cliente-center.component.html',
    styleUrls: ['./redefinir-senha-cliente-center.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class RedefinirSenhaClienteCenterComponent
    extends AuthBaseController
    implements OnInit, OnDestroy {
    recuperarSenhaForm: FormGroup;
    erro: string = null;
    errorSenhaForte: string = null;
    rederfinirSenha: AuthRedefinirSenhaCliente;
    recaptcha: any[];
    recaptchaKey: string;

    private _unsubscribeAll: Subject<any>;

    constructor(protected _injector: Injector) {
        super(_injector);
        this._unsubscribeAll = new Subject();
        this.recaptchaKey = environment.recaptchaKey;
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.rederfinirSenha = data.redefinirSenha;
        });

        this.recuperarSenhaForm = this._formBuilder.group({
            senha: [
                '',
                Validators.compose([Validators.required, senhaForteValidator]),
            ],
            senhaConfirm: [
                '',
                Validators.compose([
                    Validators.required,
                    confirmaSenhaValidator,
                ]),
            ],
        });

        this.recuperarSenhaForm
            .get('senha')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.recuperarSenhaForm
                    .get('senhaConfirm')
                    .updateValueAndValidity();
            });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    enviar(): void {
        this.rederfinirSenha.senha = this.recuperarSenhaForm.get('senha').value;
        this.rederfinirSenha.recaptcha = this.recaptcha;
        this._spinner.show();
        this._authService.redefinirSenha(this.rederfinirSenha).subscribe(
            (response) => {
                this._spinner.hide();
                this._notification.sucess('A senha foi alterada com sucesso');
                this._router.navigateByUrl('/auth/login');
            },
            (error: any) => {
                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
    }

    recaptchaResolved(captchaResponse: any[]): void {
        this.recaptcha = captchaResponse;
    }
}
