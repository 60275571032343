import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import {
    ChecklistDsnameConfig,
    ChecklistDsnameConfigTipo,
} from '../../shared/interfaces/checklist-dsname-config.interface';
import {
    ChecklistDsname,
    ChecklistDsnameRequest,
} from '../../shared/interfaces/checklist-dsname.interface';
import { ChecklistDsnameConfigTipoService } from '../../shared/services/checklist-dsname-config.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ArrayUtil } from '@utils/utils/class/array.util';
import { ChecklistConfiguracaoDsnameService } from '../../shared/services/checklist-configuracao-dsname.service';
import { ComponentBase } from '@utils/base/component-base/component-base';

@Component({
    selector: 'checklist-dsname',
    templateUrl: './checklist-dsname.component.html',
    styleUrls: ['./checklist-dsname.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChecklistDsnameComponent extends ComponentBase implements OnInit {
    @Output() adicionando = new EventEmitter();
    @Input() tamanhoArquivo: number = 23;

    listaDsnameConfigTipo: Array<ChecklistDsnameConfigTipo>;
    selecionados: Array<ChecklistDsname> = [];
    campoPesquisa: string;
    listaDsnames: Array<ChecklistDsname> = [];

    get dsnames(): Array<ChecklistDsname> {
        return this.listaDsnames;
    }

    @Input() set dsnames(dsnames: Array<ChecklistDsname>) {
        this.listaDsnames = dsnames;
    }

    constructor(
        private _checklistDsnameConfigTipoService: ChecklistDsnameConfigTipoService,
        private _checklistConfiguracaoDsnameService: ChecklistConfiguracaoDsnameService,
        private _Injector: Injector
    ) {
        super(_Injector);
        this._checklistConfiguracaoDsnameService
            .findLimite()
            .subscribe((limite) => {
                this.tamanhoArquivo = limite.data.limite;
            });
        this._spinner.show();
        this._checklistDsnameConfigTipoService.find().subscribe(
            (result) => {
                this._spinner.hide();
                this.listaDsnameConfigTipo = result.data;
                this.addTagCustomizada(this.dsnames);
                this.atualizaDsname();
            },
            (error) => this.error(error)
        );
    }

    ngOnInit() { }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.selecionados,
            event.previousIndex,
            event.currentIndex
        );
    }

    addCampoDiferente(
        campoDiferente: string,
        tipo: ChecklistDsnameConfigTipo,
        index: number
    ) {
        if (campoDiferente) {
            this.listaDsnameConfigTipo[index].campos.push({
                nome: campoDiferente,
                exemplo: campoDiferente,
                tooltip: campoDiferente,
                isCustomizado: true,
            });
        }
    }

    selecionarCampo(
        campo: ChecklistDsnameConfig,
        tipo: ChecklistDsnameConfigTipo
    ) {
        if (!campo || !tipo) return;

        const exemplo = campo.exemplo ? campo.exemplo : campo.nome;
        if (!this.validaTamanhoNomeArquivo(exemplo)) {
            this._notification.info(
                `O nome do arquivo deve conter no máximo ${this.tamanhoArquivo} caracteres`
            );
        } else {
            this.selecionados.push({
                campo,
                tipo,
                valor: campo.nome,
                exemplo: exemplo,
            });
        }
    }

    removerCampo(index: number) {
        this.selecionados.splice(index, 1);
    }

    removerCampoBto(indexTipo: number, indexCampo: number) {
        this.listaDsnameConfigTipo[indexTipo].campos.splice(indexCampo, 1);
    }

    adicionar() {
        // if (!this.validaExtensao()) {
        //     this._notification.error(
        //         'O nome do arquivo deve finalizar com uma extensão'
        //     );
        //     return;
        // }

        const dsnames: Array<ChecklistDsname> = [];
        this.selecionados.forEach((dsname, index) => {
            dsnames.push({
                campo: dsname.campo,
                tipo: dsname.tipo,
                ordem: index + 1,
                valor: !dsname.campo.id ? dsname.valor : null,
                exemplo: dsname.exemplo,
                dsnameCampoId: dsname.campo ? dsname.campo.id : null,
                dsnameCampoTipoId: dsname.campo ? dsname.tipo.id : null,
            });
        });

        this.adicionando.emit(dsnames);
    }

    limpar() {
        this.selecionados = []
    }

    totalTamanhoNomeArquivo(exemplo?: string): number {
        exemplo = exemplo ? exemplo : '';
        let total = exemplo.length;
        this.selecionados.forEach(
            (selecionado) => (total += selecionado.exemplo.length)
        );
        return total;
    }

    private validaTamanhoNomeArquivo(exemplo: string): boolean {
        const total = this.totalTamanhoNomeArquivo(exemplo);
        return total <= this.tamanhoArquivo;
    }

    private validaExtensao(): boolean {
        if (this.selecionados.length === 0) {
            return false;
        }

        return this.selecionados[this.selecionados.length - 1].tipo.isUltimo;
    }

    private atualizaDsname() {
        if (!this.listaDsnames || this.listaDsnames.length === 0) return;
        this.listaDsnames.sort(ArrayUtil.sortAttr('ordem')).forEach((d) => {
            let campo = d.campo;
            if (d.valor) {
                campo = {
                    exemplo: d.valor,
                    isCustomizado: true,
                    nome: d.valor,
                    tooltip: d.valor,
                };
            }
            this.selecionarCampo(campo, d.tipo);
        });
    }

    private addTagCustomizada(dsnames: Array<ChecklistDsname>): void {
        dsnames.forEach((d) => {
            if (d.valor) {
                const index = this.listaDsnameConfigTipo.findIndex(
                    (t) => t.id === d.tipo.id
                );
                if (index >= 0) {
                    this.addCampoDiferente(d.valor, d.tipo, index);
                }
            }
        });
    }
}
