import { ModelBase } from '../model-base/model-base';
import { DataUtil } from '@utils/utils/class/data.util';

export abstract class ServiceBase<T extends ModelBase> {
    protected toGetParams(obj: object, removeAttr?: Array<any>): string {
        removeAttr = removeAttr ? removeAttr : [];
        let url = '';
        if (obj) {
            url = '?';
            let isPrimeiroParam = true;
            Object.entries(obj).forEach(([index, val]) => {
                let isParam = true;
                removeAttr.forEach((rem) => {
                    if (rem === index) {
                        isParam = false;
                    }
                });
                if (isParam) {
                    if (val instanceof Date) {
                        val = DataUtil.toDateBackend(val);
                    }
                    if ((val || val === 0) && val.length !== 0) {
                        if (!isPrimeiroParam) {
                            url += '&';
                        }
                        isPrimeiroParam = false;
                        url += index + '=' + val;
                    }
                }
            });
        }
        return encodeURI(url);
    }
}
