import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FiltroProjetoAbertura } from '../../shareds/interfaces/filtro-projeto-abertura.interface';
import { FiltroProjetoHistorico } from '../../shareds/interfaces/filtro-projeto-historico.interface';
import { ProjetoAberturaHistorico } from '../../shareds/interfaces/projeto-abertura-historico.interface';
import { ProjetoAbertura } from '../../shareds/interfaces/projeto-abertura.interface';
import { ProjetoHistorico } from '../../shareds/interfaces/projeto-historico.interface';
import { Projeto } from '../../shareds/interfaces/projeto.interface';
import { ProjetoAberturaHistoricoService } from '../../shareds/services/projeto-abertura-historico.service';
import { ProjetoHistoricoService } from '../../shareds/services/projeto-historico.service';
import { ProjetoModalService } from '../../shareds/services/projeto-modal.service';

@Component({
    selector: 'projeto-abertura-historico',
    templateUrl: './projeto-abertura-historico.component.html',
    styleUrls: ['./projeto-abertura-historico.component.scss'],
})
export class ProjetoAberturaHistoricoComponent extends GridBase<ProjetoAberturaHistorico, FiltroProjetoAbertura, ProjetoAberturaHistoricoService> implements OnChanges, OnInit {

    private _projetoAbertura: ProjetoAbertura;
    get projetoAbertura(): ProjetoAbertura {
        return this._projetoAbertura;
    }
    @Input() set projetoAbertura(projetoAbertura: ProjetoAbertura) {
        this._projetoAbertura = projetoAbertura;
        this.filtro.idOsAbertura = projetoAbertura.osAberturaId;
        this.initGrid(this.filtro || {});
    }

    constructor(
        protected _service: ProjetoAberturaHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _modalProjetoHistoricoService: ProjetoModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void { }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'data', label: '' },
            { chave: 'item', label: '' },
            { chave: 'acao', label: '' },
            { chave: 'usuario', label: '' },
        ];
    }

    selecionar(projetoHistorico: ProjetoHistorico) {
        this._modalProjetoHistoricoService
            .historicoDetalhe(projetoHistorico)
            .subscribe(() => { });
    }
}
