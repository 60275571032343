import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { PerfilDetalheModalComponent } from '../../modal/perfil-detalhe-modal/perfil-detalhe-modal.component';
import { Perfil } from '../interfaces/perfil.interface';

@Injectable({
    providedIn: 'root',
})
export class PerfilModalService {
    constructor(public dialog: MatDialog) {}

    detalhes(data: Perfil): Observable<any> {
        const dialogRef = this.dialog.open(PerfilDetalheModalComponent, {
            minWidth: '500px',
            width: '80%',
            minHeight: '200px',
            direction: 'ltr',
            data: data,
        });

        return dialogRef.afterClosed();
    }
}
