import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ModalBase } from '@utils/base/modal-base/modal-base';
import { ImportacaoCotacao } from '../../shared/interfaces/importacao-cotacao.interface';

@Component({
    selector: 'importacao-cotacao-detalhe-modal',
    templateUrl: './importacao-cotacao-detalhe-modal.component.html',
    styleUrls: ['./importacao-cotacao-detalhe-modal.component.scss'],
})
export class ImportacaoCotacaoDetalheModalComponent
    extends ModalBase
    implements OnInit {
    tituloModal = 'Detalhes da importação';
    importacaoCotacao: ImportacaoCotacao;

    constructor(
        public dialogRef: MatDialogRef<ImportacaoCotacaoDetalheModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ImportacaoCotacao,
        private _injector: Injector
    ) {
        super(_injector);
        this.importacaoCotacao = {};
    }

    ngOnInit(): void {
        if (!this.data) {
            this._notification.error('Erro ao retornar os dados');
            console.log('Erro ao retornar os dados');
        }
        this.importacaoCotacao = this.data;
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
