import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportacaoCotacaoGuard } from './importacao-cotacao/importacao-cotacao.guard';
import { ImportacaoOsGuard } from './importacao-os/importacao-os.guard';

const routes: Routes = [
    {
        path: 'cotacao',
        canActivate: [ImportacaoCotacaoGuard],
        canActivateChild: [ImportacaoCotacaoGuard],
        canLoad: [ImportacaoCotacaoGuard],
        loadChildren:
            'app/main/importacao/importacao-cotacao/importacao-cotacao.module#ImportacaoCotacaoModule',
    },
    {
        path: 'os',
        canActivate: [ImportacaoOsGuard],
        canActivateChild: [ImportacaoOsGuard],
        canLoad: [ImportacaoOsGuard],
        loadChildren:
            'app/main/importacao/importacao-os/importacao-os.module#ImportacaoOsModule',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ImportacaoRoutingModule { }
