import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Banco } from '../interfaces/banco.interface';
import { BancoService } from '../services/banco.service';

@Injectable()
export class BancoListaResolver implements Resolve<Banco> {
    constructor(private _bancoService: BancoService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._bancoService.find().pipe(map((result) => result.data));
    }
}
