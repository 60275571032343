import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroTime } from '../interfaces/filtro-time.interface';
import { Time } from '../interfaces/time.interface';
import { Response } from '@utils/interfaces/http/response.interface';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';

@Injectable({
    providedIn: 'root',
})
export class TimeService extends ServiceGridBase<Time, FiltroTime> {
    private path: string = `${environment.server}/time`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroTime): Observable<Response<Array<Time>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<Time>>>(url);
    }

    findById(id: number | string): Observable<Response<Time>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Time>>(url);
    }

    save(time: Time): Observable<Response<Time>> {
        if (time.id) {
            return this.update(time);
        } else {
            return this.create(time);
        }
    }

    create(time: Time): Observable<Response<Time>> {
        const url = `${this.path}`;
        return this._http.post<Response<Time>>(url, time);
    }

    update(time: Time): Observable<Response<Time>> {
        const url = `${this.path}/${time.id}`;
        return this._http.put<Response<Time>>(url, time);
    }

    updateUsuarios(
        time: Time,
        usuarios: Array<Usuario>
    ): Observable<Response<Time>> {
        const url = `${this.path}/${time.id}/usuarios`;
        return this._http.put<Response<Time>>(url, { usuarios: usuarios });
    }

    delete(id: number): Observable<Response<Time>> {
        const url = `${this.path}/${id}`;
        return this._http.delete<Response<Time>>(url);
    }
}
