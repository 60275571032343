import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { TabelaDominioService } from 'app/main/tabela-dominio/shareds/services/tabela-dominio.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configurador } from '../interfaces/configurador.interface';

@Injectable()
export class ConfiguradorInsertTimestampListaResolver implements Resolve<Configurador> {
    constructor(private _service: TabelaDominioService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._service.find('ConfiguradorInsertTimestamp').pipe(map((result) => result.data));
    }
}
