import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { OsDocumentoDownload } from '../interfaces/os-documento-download.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';

@Injectable({
    providedIn: 'root',
})
export class OsDocumentoDownloadService extends ServiceBase<OsDocumentoDownload> {
    private path: string = `${environment.server}/os/documento`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(idOs: number): Observable<Response<Array<OsDocumentoDownload>>> {
        const url = `${this.path}/${idOs}`;
        return this._http.get<Response<Array<OsDocumentoDownload>>>(url);
    }

    download(idDocumento: number): Observable<any> {
        const url = `${this.path}/download/${idDocumento}`;
        return this._http.get<Response<OsDocumentoDownload>>(url);
    }

    delete(idDocumento): Observable<any> {
        const url = `${this.path}/${idDocumento}`;
        return this._http.delete(url);
    }
}
