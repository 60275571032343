export const environment = {
    production: false,
    hmr: false,
    homol: false,
    dev: true,
    preprod: false,

    host: 'https://dev.projectfinnet.com.br/#',
    server: 'https://api-dev.projectfinnet.com.br',
    websocket: '127.0.0.1:6001',
    isRecaptcha: false,
    recaptchaKey: '6LdasOMZAAAAADSbn7bOp5NTlgC61PcNAIDj8KCz',
};
