import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OsEditar } from '../../shareds/interfaces/os-editar.interface';

@Component({
    selector: 'modal-vincular-projeto',
    templateUrl: './modal-vincular-projeto.component.html',
    styleUrls: ['./modal-vincular-projeto.component.scss'],
})
export class ModalVincularProjetoComponent implements OnInit {
    tituloModal: string = 'Vincular projetos';

    constructor(
        public dialogRef: MatDialogRef<ModalVincularProjetoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
    ngOnInit() {}

    fechar(): void {
        this.dialogRef.close();
    }

    atualizado(result) {
        this.dialogRef.close(result);
    }
}
