import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MatListModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
} from '@angular/material';

import { RelatorioRoutingModule } from './relatorio-routing.module';

import { RelatorioService } from './shareds/services/relatorio.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { RelatorioTipoService } from './shareds/services/relatorio-tipo.service';
import { RelatorioListaResolver } from './shareds/resolvers/relatorio-lista.resolver';
import { RelatorioTipoListaResolver } from './shareds/resolvers/relatorio-tipo-lista.resolver';
import { RelatorioGridPageComponent } from './pages/relatorio-grid-page/relatorio-grid-page.component';
import { RelatorioGridFiltroComponent } from './components/relatorio-grid-filtro/relatorio-grid-filtro.component';
import { RelatorioGridComponent } from './components/relatorio-grid/relatorio-grid.component';
import { RelatorioGuard } from './relatorio.guard';

@NgModule({
    declarations: [
        RelatorioGridPageComponent,
        RelatorioGridFiltroComponent,
        RelatorioGridComponent
    ],

    imports: [
        CommonModule,
        RelatorioRoutingModule,
        UtilsModule,
        NgxMatSelectSearchModule,

        //TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatListModule,

        //FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatRadioModule,

        //MODULOS
    ],

    providers: [
        RelatorioService,
        RelatorioTipoService,

        RelatorioListaResolver,
        RelatorioTipoListaResolver,
        RelatorioGuard,
    ],

    entryComponents: [
    ],
})
export class RelatorioModule { }
