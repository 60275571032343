import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@utils/utils.module';

import { ProdutoService } from './shareds/services/produto.service';
import { ProdutoListaResolver } from './shareds/resolvers/produto-lista.resolver';

@NgModule({
    declarations: [],

    imports: [CommonModule, UtilsModule],

    providers: [ProdutoService, ProdutoListaResolver],
})
export class ProdutoModule {}
