import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@utils/utils.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ChecklistRoutingModule } from './checklist-routing.module';
import { ChecklistGuard } from './checklist.guard';
import { ChecklistGridPageComponent } from './pages/checklist-grid-page/checklist-grid-page.component';
import { ChecklistInicioPageComponent } from './pages/checklist-inicio-page/checklist-inicio-page.component';
import {
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatRadioModule,
    MatSlideToggleModule,
} from '@angular/material';
import { ChecklistInicioComponent } from './components/checklist-inicio/checklist-inicio.component';
import { ChecklistNavegationComponent } from './components/checklist-navegation/checklist-navegation.component';
import { ChecklistContatoEmpresaPageComponent } from './pages/checklist-contato-empresa-page/checklist-contato-empresa-page.component';
import { ChecklistContatoEmpresaComponent } from './components/checklist-contato-empresa/checklist-contato-empresa.component';
import { ChecklistConectividadePageComponent } from './pages/checklist-conectividade-page/checklist-conectividade-page.component';
import { ChecklistConectividadeComponent } from './components/checklist-conectividade/checklist-conectividade.component';
import { ChecklistGridComponent } from './components/checklist-grid/checklist-grid.component';
import { ChecklistDadosEmpresaComponent } from './components/checklist-dados-empresa/checklist-dados-empresa.component';
import { ChecklistDadosEmpresaPageComponent } from './pages/checklist-dados-empresa/checklist-dados-empresa-page.component';
import { ChecklistGridFiltroComponent } from './components/checklist-grid-filtro/checklist-grid-filtro.component';
import { ChecklistConfirmacaoStepComponent } from './components/checklist-confirmacao-step/checklist-confirmacao-step.component';
import { ChecklistConfirmacaoStepPageComponent } from './pages/checklist-confirmacao-step-page/checklist-confirmacao-step-page.component';

import { ChecklistService } from './shared/services/checklist.service';
import { ChecklistDetalheService } from './shared/services/checklist-detalhe.service';
import { NgxPhoneMaskBrModule } from 'ngx-phone-mask-br';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ChecklistContatoEmpresaListaResolver } from './shared/resolvers/checklist-contato-empresa-lista.resolver';
import { ChecklistConectividadeListaResolver } from './shared/resolvers/checklist-conectividade-lista.resolver';
import { ChecklistConectividadeService } from './shared/services/checklist-conectividade.service';
import { ChecklistContatoEmpresaService } from './shared/services/checklist-contato-empresa.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChecklistDadosEmpresaService } from './shared/services/checklist-dados-empresa.service';
import { ChecklistConfiguracaoModuloComponent } from './components/checklist-configuracao-modulo/checklist-configuracao-modulo.component';
import { ChecklistDsnameComponent } from './components/checklist-dsname/checklist-dsname.component';
import { ModalChecklistDsnameComponent } from './modal/modal-checklist-dsname/modal-checklist-dsname.component';
import { ChecklistRelacionamentoPageComponent } from './pages/checklist-relacionamento/checklist-relacionamento-page.component';
import { ChecklistConfiguracaoComponent } from './components/checklist-configuracao/checklist-configuracao.component';
import { ChecklistDsnameLayoutComponent } from './components/checklist-dsname-layout/checklist-dsname-layout.component';
import { ChecklistConfiguracaoPageComponent } from './pages/checklist-configuracao-page/checklist-configuracao-page.component';
import { EmpresaModalService } from '../empresa/shareds/services/empresa-modal.service';
import { ChecklistDsnameConfigTipoService } from './shared/services/checklist-dsname-config.service';
import { ChecklistDsnameModalService } from './shared/services/checklist-dsname-modal.service';
import { ChecklistDadosEmpresaListaResolver } from './shared/resolvers/checklist-dados-empresa-lista.resolver';
import { ChecklistRelacionamentoComponent } from './components/checklist-relacionamento/checklist-relacionamento.component';
import { ChecklistRelacionamentoService } from './shared/services/checklist-relacionamento.service';
import { ChecklistRelacionamentoEmpresaModalService } from './shared/services/checklist-relacionamento-empresa-modal.service';
import { ChecklistStatusListaResolver } from './shared/resolvers/checklist-status-lista.resolver';
import { ChecklistStatusService } from './shared/services/checklist-status.service';
import { ChecklistConfiguracaoService } from './shared/services/checklist-configuracao.service';
import { ChecklistCondiguracaoDetalheResolver } from './shared/resolvers/checklist-configuracao-detalhe.resolver';
import { ChecklistConfiguracaoDsnameService } from './shared/services/checklist-configuracao-dsname.service';
import { ChecklistCondiguracaoDsnameLimiteResolver } from './shared/resolvers/checklist-configuracao-dsname-limite.resolver';
import { ChecklistDsnameDirecaoService } from './shared/services/checklist-dsname-direcao.service';
import { ChecklistDsnameDirecaoListaResolver } from './shared/resolvers/checklist-dsname-direcao-lista.resolver';
import { ChecklistDsnameConfigTipoListaResolver } from './shared/resolvers/checklist-dsname-config-tipo-lista.resolver';
import { ModalChecklistRelacionamentoEmpresaCadastroComponent } from './modal/modal-checklist-relacionamento-empresa-cadastro/modal-checklist-relacionamento-empresa-cadastro.component';
import { ModalChecklistRelacionamentoEmpresaDetalheComponent } from './modal/modal-checklist-relacionamento-empresa-detalhe/modal-checklist-relacionamento-empresa-detalhe.component';
import { ModalChecklistConfiguracaoArquivoComponent } from './modal/modal-checklist-configuracao-arquivo/modal-checklist-configuracao-arquivo.component';
import { ChecklistRelacionamentoEmpresaCadastroComponent } from './components/checklist-relacionamento-empresa-cadastro/checklist-relacionamento-empresa-cadastro.component';
import { ChecklistRelacionamentoDetalheComponent } from './components/checklist-relacionamento-detalhe/checklist-relacionamento-detalhe.component';
import { ChecklistConfiguracaoArquivoDownloadComponent } from './components/checklist-configuracao-arquivo-download/checklist-configuracao-arquivo-download.component';
import { ChecklistConfiguracaoArquivoUploadComponent } from './components/checklist-configuracao-arquivo-upload/checklist-configuracao-arquivo-upload.component';
import { ChecklistConfiguracaoArquivoModalService } from './shared/services/checklist-configuracao-arquivo-modal.service';
import { ChecklistConfiguracaoArquivoComponent } from './components/checklist-configuracao-arquivo/checklist-configuracao-arquivo.component';
import { ChecklistDetalhePageComponent } from './pages/checklist-detalhe-page/checklist-detalhe-page.component';
import { ChecklistDetalheComponent } from './components/checklist-detalhe/checklist-detalhe.component';
import { ModalChecklistPesquisaComponent } from './modal/modal-pesquisa-checklist/modal-checklist-pesquisa.component';
import { ChecklistPesquisaComponent } from './components/checklist-pesquisa/checklist-pesquisa.component';
import { ModalChecklistPesquisaService } from './shared/services/checklist-pesquisa-modal.service';
import { ChecklistVisualizacaoDetalheResolver } from './shared/resolvers/checklist-viualizacao-detalhe.resolver';
import { ChecklistNavegacaoService } from './shared/services/checklist-navegacao.service';
import { ChecklistStepGuard } from './checklist-step.guard';
import { ChecklistDetalheGuard } from './checklist-detalhe.guard';
import { ChecklistDadosEmpresaRelacionamentoService } from './shared/services/checklist-dados-empresa-relacionamento.service';
import { ChecklistDetalheResolver } from './shared/resolvers/checklist-detalhe.resolver';
import { ChecklistProdutoPortalResolver } from './shared/resolvers/checklist-produto-portal.resolver';
import { ModalHistoricoChecklistComponent } from './modal/modal-historico-checklist/modal-historico-checklist.component';
import { ModalHistoricoDetalheChecklistComponent } from './modal/modal-historico-detalhe-checklist/modal-historico-detalhe-checklist.component';
import { ChecklistModalService } from './shared/services/checklist-modal.service';
import { ChecklistHistoricoComponent } from './components/checklist-historico/checklist-historico.component';
import { ChecklistHistoricoDetalheComponent } from './components/checklist-historico-detalhe/checklist-historico-detalhe.component';
import { ChecklistHistoricoService } from './shared/services/checklist-historico.service';
import { ChecklistConfirmacaoChecklistComponent } from './components/checklist-confirmacao-checklist/checklist-confirmacao-checklist.component';
import { ChecklistConfirmacaoChecklistPageComponent } from './pages/checklist-confirmacao-checklist-page/checklist-confirmacao-checklist-page.component';
import { ChecklistBuPageComponent } from './pages/checklist-bu-page/checklist-bu-page.component';
import { ChecklistIsVisualizacaoResolver } from './shared/resolvers/checklist-is-visualizacao.resolver';
import { DocumentImportModule } from '../document-import/document-import.module';
import { ChecklistConectividadesFastWebComponent } from './components/conectividades/checklist-conectividades-fast-web/checklist-conectividades-fast-web.component';
import { ChecklistConectividadesWebServiceEdiComponent } from './components/conectividades/checklist-conectividades-web-service-edi/checklist-conectividades-web-service-edi.component';
import { ChecklistConectividadesApiOpenBankingComponent } from './components/conectividades/checklist-conectividades-api-open-banking/checklist-conectividades-api-open-banking.component';
import { ChecklistConectividadesSfptComponent } from './components/conectividades/checklist-conectividades-sfpt/checklist-conectividades-sfpt.component';
import { ChecklistConectividadesFinnetFastClientComponent } from './components/conectividades/checklist-conectividades-finnet-fast-client/checklist-conectividades-finnet-fast-client.component';
import { ChecklistConectividadesWebDavComponent } from './components/conectividades/checklist-conectividades-web-dav/checklist-conectividades-web-dav.component';
import { ChecklistConectividadesConnectDirectComponent } from './components/conectividades/checklist-conectividades-connect-direct/checklist-conectividades-connect-direct.component';
import { ChecklistConectividadesB2biComponent } from './components/conectividades/checklist-conectividades-b2bi/checklist-conectividades-b2bi.component';
import { ChecklistProdutoFinanceiroPageComponent } from './pages/checklist-produto-financeiro-page/checklist-produto-financeiro-page.component';
import { ChecklistProdutoFinanceiroComponent } from './components/checklist-produto-financeiro/checklist-produto-financeiro.component';
import { ChecklistProdutoFinanceiroListaProdutosResolver } from './shared/resolvers/checklist-produto-financeiro-lista-produtos.resolver';
import { ChecklistProdutoFinanceiroListaResolver } from './shared/resolvers/checklist-produto-financeiro-lista.resolver';
import { ModalProdutoFinanceiroUploadComponent } from './modal/modal-produto-financeiro-upload/modal-produto-financeiro-upload.component';
import { ChecklistProdutoFinanceiroUploadComponent } from './components/checklist-produto-financeiro-upload/checklist-produto-financeiro-upload.component';
import { ChecklistDsnameLayoutEdiComponent } from './components/checklist-dsname-layout-edi/checklist-dsname-layout-edi.component';
import { ChecklistProdutoFinancerioComExemploResolver } from './shared/resolvers/checklist-produto-financeiro-com-exemplo.resolver';
import { ChecklistNovoUsuarioService } from './shared/services/checklist-novo-usuario.service';

@NgModule({
    declarations: [
        ChecklistGridPageComponent,
        ChecklistInicioPageComponent,
        ChecklistInicioComponent,
        ChecklistNavegationComponent,
        ChecklistContatoEmpresaPageComponent,
        ChecklistContatoEmpresaComponent,
        ChecklistConectividadePageComponent,
        ChecklistConectividadeComponent,
        ChecklistGridComponent,
        ChecklistDadosEmpresaComponent,
        ChecklistDadosEmpresaPageComponent,
        ChecklistInicioPageComponent,
        ChecklistGridFiltroComponent,
        ChecklistConfirmacaoStepComponent,
        ChecklistConfirmacaoStepPageComponent,
        ChecklistConfiguracaoModuloComponent,
        ChecklistDsnameComponent,
        ModalChecklistDsnameComponent,
        ChecklistConfiguracaoPageComponent,
        ChecklistRelacionamentoPageComponent,
        ChecklistConfiguracaoComponent,
        ChecklistDsnameLayoutComponent,
        ChecklistRelacionamentoComponent,
        ModalChecklistRelacionamentoEmpresaCadastroComponent,
        ChecklistRelacionamentoEmpresaCadastroComponent,
        ModalChecklistRelacionamentoEmpresaDetalheComponent,
        ChecklistRelacionamentoDetalheComponent,
        ModalChecklistConfiguracaoArquivoComponent,
        ChecklistConfiguracaoArquivoComponent,
        ChecklistConfiguracaoArquivoDownloadComponent,
        ChecklistConfiguracaoArquivoUploadComponent,
        ChecklistDetalhePageComponent,
        ChecklistDetalheComponent,
        ChecklistPesquisaComponent,
        ModalChecklistPesquisaComponent,
        ModalHistoricoChecklistComponent,
        ModalHistoricoDetalheChecklistComponent,
        ChecklistHistoricoComponent,
        ChecklistHistoricoDetalheComponent,
        ChecklistConfirmacaoChecklistComponent,
        ChecklistConfirmacaoChecklistPageComponent,
        ChecklistBuPageComponent,
        ChecklistConectividadesFastWebComponent,
        ChecklistConectividadesWebServiceEdiComponent,
        ChecklistConectividadesApiOpenBankingComponent,
        ChecklistConectividadesSfptComponent,
        ChecklistConectividadesFinnetFastClientComponent,
        ChecklistConectividadesWebDavComponent,
        ChecklistConectividadesConnectDirectComponent,
        ChecklistConectividadesB2biComponent,
        ChecklistProdutoFinanceiroPageComponent,
        ChecklistProdutoFinanceiroComponent,
        ModalProdutoFinanceiroUploadComponent,
        ChecklistProdutoFinanceiroUploadComponent,
        ChecklistDsnameLayoutEdiComponent,
    ],
    imports: [
        CommonModule,
        ChecklistRoutingModule,
        NgxMatSelectSearchModule,

        UtilsModule,

        // TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,

        // FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        NgxPhoneMaskBrModule,
        NgxMatSelectSearchModule,
        DragDropModule,

        // DOWNLOAD
        DocumentImportModule,

        // EXPANSION PANEL
        MatExpansionModule
    ],

    providers: [
        // SERVICES
        ChecklistService,
        EmpresaModalService,
        ChecklistConectividadeService,
        ChecklistContatoEmpresaService,
        ChecklistDsnameConfigTipoService,
        ChecklistDsnameModalService,
        ChecklistDadosEmpresaService,
        ChecklistRelacionamentoService,
        ChecklistRelacionamentoEmpresaModalService,
        ChecklistStatusService,
        ChecklistConfiguracaoService,
        ChecklistConfiguracaoDsnameService,
        ChecklistConfiguracaoArquivoModalService,
        ChecklistDsnameDirecaoService,
        ChecklistDetalheService,
        ChecklistNavegacaoService,
        ChecklistDadosEmpresaRelacionamentoService,
        ChecklistModalService,
        ChecklistHistoricoService,
        ChecklistNovoUsuarioService,

        // RESOLVERS
        ChecklistContatoEmpresaListaResolver,
        ChecklistConectividadeListaResolver,
        ChecklistDadosEmpresaListaResolver,
        ChecklistDetalheResolver,
        ChecklistStatusListaResolver,
        ChecklistCondiguracaoDetalheResolver,
        ChecklistCondiguracaoDsnameLimiteResolver,
        ChecklistDsnameDirecaoListaResolver,
        ChecklistDsnameConfigTipoListaResolver,
        ChecklistVisualizacaoDetalheResolver,
        ChecklistProdutoPortalResolver,
        ChecklistIsVisualizacaoResolver,
        ChecklistProdutoFinanceiroListaResolver,
        ChecklistProdutoFinanceiroListaProdutosResolver,
        ChecklistProdutoFinancerioComExemploResolver,


        // MODAIS
        ModalChecklistPesquisaService,

        // GUARDS
        ChecklistGuard,
        ChecklistStepGuard,
        ChecklistDetalheGuard,

    ],
    entryComponents: [
        ModalChecklistDsnameComponent,
        ModalChecklistRelacionamentoEmpresaCadastroComponent,
        ModalChecklistRelacionamentoEmpresaDetalheComponent,
        ModalChecklistConfiguracaoArquivoComponent,
        ModalChecklistPesquisaComponent,
        ModalHistoricoChecklistComponent,
        ModalHistoricoDetalheChecklistComponent,
        ModalProdutoFinanceiroUploadComponent,
    ],
})
export class ChecklistModule { }
