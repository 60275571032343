import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { RelacionamentoAbertura } from '../interfaces/relacionamento-abertura.interface';
import { FiltroRelacionamentoAbertura } from '../interfaces/filtro-relacionamento-abertura.interface';

@Injectable({
    providedIn: 'root',
})
export class RelacionamentoAberturaService extends ServiceGridBase<RelacionamentoAbertura, FiltroRelacionamentoAbertura> {
    private path: string = `${environment.server}/abertura-relacionamento`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroRelacionamentoAbertura): Observable<Response<Array<RelacionamentoAbertura>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<RelacionamentoAbertura>>>(url);
    }

    findById(id: number | string): Observable<Response<RelacionamentoAbertura>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<RelacionamentoAbertura>>(url);
    }


    gerarRelatorio(filtro: FiltroRelacionamentoAbertura) {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${environment.server}/relatorio/relacionamento-abertura${params}`;
        return this._http.get<Response<any>>(url);
    }
}
