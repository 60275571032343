import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroGmudHistorico } from '../interfaces/filtro-gmud-historico.interface';
import { GmudHistorico } from '../interfaces/gmud-historico.interface';

@Injectable({
    providedIn: 'root',
})
export class GmudHistoricoService extends ServiceGridBase<
GmudHistorico,
FiltroGmudHistorico
> {
    private path: string = `${environment.server}/gmud/historico`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroGmudHistorico
    ): Observable<Response<Array<GmudHistorico>>> {
        const params: string = filtro
            ? this.toGetParams(filtro, ['idGmud'])
            : '';
        const url = `${this.path}/${filtro.idGmud}${params}`;
        return this._http.get<Response<Array<GmudHistorico>>>(url);
    }
}
