import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of} from 'rxjs';
import { ProjetoStatus } from '../interfaces/projeto-status.interface';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';

@Injectable({
    providedIn: 'root'
})
export class ProjetoStatusService extends ServiceBase<ProjetoStatus> {
    private path: string = `${environment.server}/projetostatus`
    
    constructor(private _http: HttpClient) {
        super()
    }
    
    find(filtro?: FiltroBase): Observable<Response<Array<ProjetoStatus>>>{
        const params: string = filtro ? this.toGetParams(filtro) : ''
        const url = `${this.path}${params}`
        return this._http.get<Response<Array<ProjetoStatus>>>(url)
        
    }
    
    
            
    
}
