import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { ChecklistRelacionamento } from '../interfaces/checklist-relacionamento.interface';
import { FiltroChecklistRelacionamento } from '../interfaces/filtro-checklist-relacionamento.interface';
import { ChecklistRelacionamentoPageComponent } from '../../pages/checklist-relacionamento/checklist-relacionamento-page.component';
import { ChecklistRelacionamentoRequest } from '../interfaces/checklist-relacionamentoRequest.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistRelacionamentoService extends ServiceBase<ChecklistRelacionamento> {
    private path = `${environment.server}/checklist`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroChecklistRelacionamento
    ): Observable<Response<Array<ChecklistRelacionamento>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/${filtro.idChecklist}/cotacao-item-cotacao/checklist-relacionamentos${params}`;
        return this._http.get<Response<Array<ChecklistRelacionamento>>>(url);
    }

    findByChecklistAndId(idChecklist: number, idCotacaoItemCotacao: number) {
        const url = `${this.path}/${idChecklist}/cotacao-item-cotacao/${idCotacaoItemCotacao}/checklist-relacionamentos`;
        return this._http.get<Response<ChecklistRelacionamento>>(url);
    }

    save(relacionamento: ChecklistRelacionamentoRequest): Observable<Response<ChecklistRelacionamentoRequest>> {
        const url = `${this.path}/${relacionamento.idChecklist}/vincular-relacionamentos-a-item-da-cotacao`;
        return this._http.put<Response<ChecklistRelacionamentoRequest>>(url,relacionamento);
    }
}
