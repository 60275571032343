import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { TabelaDominio } from 'app/main/tabela-dominio/shareds/interfaces/tabela-dominio.interface';
import { FaturamentoRecorrenteStatusService } from '../services/faturamento-recorrente-status.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';




@Injectable()
export class FaturamentoStatusFaturamentoRecorrenteListaResolver implements Resolve<TabelaDominio> {
    constructor(private _service: FaturamentoRecorrenteStatusService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._service.find('FaturamentoStatusFaturamento').pipe(map((result) => result.data));
    }
}
