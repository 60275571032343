import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BancoHistorico } from '../../shareds/interfaces/banco-historico.interface';
import { Banco } from '../../shareds/interfaces/banco.interface';

@Component({
  selector: 'modal-historico-banco-detalhe',
  templateUrl: './modal-historico-banco-detalhe.component.html',
  styleUrls: ['./modal-historico-banco-detalhe.component.scss']
})
export class ModalHistoricoBancoDetalheComponent implements OnInit {

  tituloModal: string = 'Histórico';

  constructor(
    public dialogRef: MatDialogRef<ModalHistoricoBancoDetalheComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BancoHistorico
  ) { }
  ngOnInit() { }

  selecionado(checklist: Banco) {
    this.dialogRef.close(checklist);
  }

  fechar(): void {
    this.dialogRef.close();
  }

}
