import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { GmudHistorico } from '../../shared/interfaces/gmud-historico.interface';
import { GmudHistoricoService } from '../../shared/services/gmud-historico.service';

@Component({
    selector: 'gmud-historico-detalhe',
    templateUrl: './gmud-historico-detalhe.component.html',
    styleUrls: ['./gmud-historico-detalhe.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class GmudHistoricoDetalheComponent implements OnChanges, OnInit {
    @Input() historico: GmudHistorico = {};

    constructor(
        protected _service: GmudHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) { }

    ngOnInit(): void { }

    ngOnChanges(): void { }
}
