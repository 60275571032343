import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalTimeDetalheComponent } from '../../modal/modal-time-detalhe/modal-time-detalhe.component';
import { Time } from '../../shareds/interfaces/time.interface';
import { ModalTimePesquisaComponent } from '../../modal/modal-time-pesquisa/modal-time-pesquisa.component';

@Injectable({
    providedIn: 'root',
})
export class TimeModalService {
    constructor(public dialog: MatDialog) {}

    timeDetalhe(data: Time) {
        const dialogRef = this.dialog.open(ModalTimeDetalheComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    timePesquisa(data: Time) {
        const dialogRef = this.dialog.open(ModalTimePesquisaComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }
}
