import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportacaoOsGuard } from './importacao-os.guard';
import { ImportacaoOsGridPageComponent } from './pages/importacao-os-grid-page/importacao-os-grid-page.component';


const routes: Routes = [
  {
    path: '',
    canActivate: [ImportacaoOsGuard],
    canLoad: [ImportacaoOsGuard],
    component: ImportacaoOsGridPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ImportacaoOsRoutingModule { }
