import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Checklist } from 'app/main/checklist/shared/interfaces/checklist.interface';
import { FiltroOsEmpresa } from '../../shareds/interfaces/filtro-os-empresa.interface';
import { OsEmpresa } from '../../shareds/interfaces/os-empresa.interface';

@Component({
    selector: 'modal-os-empresa-grid',
    templateUrl: './modal-os-empresa-grid.component.html',
    styleUrls: ['./modal-os-empresa-grid.component.scss'],
})
export class ModalOsEmpresaGridComponent implements OnInit {
    filtro: FiltroOsEmpresa;
    tituloModal: string = 'Gestão de empresa';

    constructor(
        public dialogRef: MatDialogRef<ModalOsEmpresaGridComponent>,
        @Inject(MAT_DIALOG_DATA) public checklist: Checklist
    ) {}
    ngOnInit() {}

    fechar(): void {
        this.dialogRef.close();
    }

    filtrar(filtro: FiltroOsEmpresa) {
        this.filtro = Object.assign({}, filtro);
    }

    selecionado(empresa: OsEmpresa) {
        if (empresa) {
            this.dialogRef.close(empresa);
        }
    }
}
