import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bi-suporte',
  templateUrl: './bi-suporte.component.html',
  styleUrls: ['./bi-suporte.component.scss']
})
export class BiSuporteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
