import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { Banco } from 'app/main/banco/shareds/interfaces/banco.interface';
import { BancoService } from 'app/main/banco/shareds/services/banco.service';
import { Empresa } from 'app/main/empresa/shareds/interfaces/empresa.interface';
import { EmpresaModalService } from 'app/main/empresa/shareds/services/empresa-modal.service';
import { EmpresaService } from 'app/main/empresa/shareds/services/empresa.service';
import { ValidacaoDigitoAgenciaContaService } from 'app/main/validacao/validacao-digito-agencia-conta/validacao-digito-agencia-conta.service';
import { ChecklistDadosEmpresa } from '../../shared/interfaces/checklist-dados-empresa.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { ChecklistDadosEmpresaService } from '../../shared/services/checklist-dados-empresa.service';

@Component({
    selector: 'checklist-dados-empresa',
    templateUrl: './checklist-dados-empresa.component.html',
    styleUrls: ['./checklist-dados-empresa.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ChecklistDadosEmpresaComponent extends FormBase implements OnInit {
    @Input() checklist: Checklist;
    @Output() gravando = new EventEmitter();
    isEdit = false;
    isVisualizacao: boolean = false;

    listaEmpresas: Array<Empresa> = [];
    relacionamentos: Array<ChecklistDadosEmpresa> = [];

    constructor(
        private _injector: Injector,
        private _modalEmpresa: EmpresaModalService,
        private _empresaService: EmpresaService,
        private _bancoService: BancoService,
        private _checklistDadosEmpresaService: ChecklistDadosEmpresaService,
        private _validacaoDigitoAgenciaContaService: ValidacaoDigitoAgenciaContaService
    ) {
        super(_injector, ChecklistDadosEmpresaCombo.combos());
    }

    ngOnInit(): void {
        this.carregaEmpresas();
        this._route.data.subscribe((data) => {
            this.isVisualizacao = data.isVisualizacao;
            this.optionList.listaBancos = data.bancos;
            this.listaEmpresas = data.empresas;
            data.relacionamentos.forEach((r) => {
                this.relacionamentos.push(
                    Object.assign({}, r, {
                        empresa: data.empresas.find(
                            (e) => e.id === r.idEmpresa
                        ),
                        banco: data.bancos.find((b) => b.id === r.idBanco),
                    })
                );
            });
            this.refreshOptionsConfig();
        });
    }

    protected crateForm(): { [key: string]: any } {
        return {
            banco: ['', Validators.required],
            empresa: ['', Validators.required],
            convenio: [],
            agencia: ['', Validators.required],
            agenciaDv: [],
            conta: ['', Validators.required],
            contaDv: ['', Validators.required],
            index: [],
        };
    }

    limparForm(): void {
        this.isEdit = false;
        this.form.reset();
    }

    adicionar(): void {
        const relacionamento: ChecklistDadosEmpresa = this.form.value;
        relacionamento.idBanco = relacionamento.banco.id;
        relacionamento.idEmpresa = relacionamento.empresa.id;

        this.relacionamentos.push(relacionamento);
        this.limparForm();

        // this._spinner.show()
        // this._validacaoDigitoAgenciaContaService.validate({
        //     banco: relacionamento.banco && relacionamento.banco.codigo ? parseInt(relacionamento.banco.codigo) : undefined,
        //     agencia: relacionamento.agencia,
        //     conta: relacionamento.conta,
        //     agenciaDv: relacionamento.agenciaDv,
        //     contaDv: relacionamento.contaDv
        // }).subscribe(result => {
        //     if (result) {
        //         this._spinner.hide();
        //         this.relacionamentos.push(relacionamento);
        //         this.limparForm();
        //     }
        // },
        //     error => this.error(error))

    }

    atualizar(): void {
        const index = this.form.get('index').value;
        const relacionamento: ChecklistDadosEmpresa = this.form.value;
        relacionamento.idBanco = relacionamento.banco.id;
        relacionamento.idEmpresa = relacionamento.empresa.id;
        this.relacionamentos[index] = relacionamento;
        this.limparForm();
    }

    remover(relacionamento: ChecklistDadosEmpresa, index: number): void {
        this._confirmation
            .confirm({ titulo: `Deseja remover o relacionamento?` })
            .subscribe((result) => {
                if (result) {
                    this.relacionamentos.splice(index, 1);
                }
            });
    }

    editar(relacionamento: ChecklistDadosEmpresa, index: number): void {
        this.isEdit = true;
        this.form.patchValue(relacionamento);
        this.form.get('index').setValue(index);
    }

    gerenciarEmpresa() {
        this._modalEmpresa
            .empresaGrid(this.checklist)
            .subscribe(() => this.carregaEmpresas());
    }

    gravar() {
        this._spinner.show();
        this._checklistDadosEmpresaService
            .save(this.checklist.id, this.relacionamentos)
            .subscribe(
                () => {
                    this._spinner.hide();
                    this.gravando.emit(true);
                    this._notification.sucess(
                        'Relacionamentos salvos com sucesso!'
                    );
                },
                (error) => this.error(error)
            );
    }

    private carregaEmpresas() {
        this._spinner.show();
        this._empresaService.find({ idChecklist: this.checklist.id }).subscribe(
            (result) => {
                this.listaEmpresas = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
    }
}

export class ChecklistDadosEmpresaCombo {
    static combos() {
        return [
            {
                listName: 'listaBancos',
                filterName: 'filterBancos',
                fieldValue: 'descricaoCompleta',
            },
        ];
    }
}
