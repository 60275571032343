import { Component, OnInit } from '@angular/core';
import { FiltroTime } from '../../shareds/interfaces/filtro-time.interface';

@Component({
  selector: 'time-grid-page',
  templateUrl: './time-grid-page.component.html',
  styleUrls: ['./time-grid-page.component.scss']
})
export class TimeGridPageComponent implements OnInit {

  filtro: FiltroTime

  constructor() { }

  ngOnInit() {
  }

  filtrar(filtro: FiltroTime){
    this.filtro = Object.assign({}, filtro) 
  }
}
