import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjetoCadastroPageComponent } from './pages/projeto-cadastro-page/projeto-cadastro-page.component';
import { ProjetoGridPageComponent } from './pages/projeto-grid-page/projeto-grid-page.component';
import { ProjetoDetalheResolver } from './shareds/resolvers/projeto-detalhe.resolver';
import { ProjetoGuard } from './projeto.guard';
import { BancoListaResolver } from '../banco/shareds/resolvers/banco-lista.resolver';
import { ProjetoStatusListaResolver } from './shareds/resolvers/projeto-status-lista.resolver';
import { ProdutoListaResolver } from '../produto/shareds/resolvers/produto-lista.resolver';
import { ChecklistDsnameDirecaoListaResolver } from '../checklist/shared/resolvers/checklist-dsname-direcao-lista.resolver';
import { OsStatusListaResolver } from '../os/shareds/resolvers/os-status.resolver';
import { OsEmpresaListaResolver } from '../os/shareds/resolvers/os-empresa.resolver';
import { TimeListaResolver } from '../time/shareds/resolvers/time-lista.resolver';
import { UsuarioFinnetAtivoResolver } from '../usuario/resolvers/usuario-finnet-ativo.resolver';
import { ConfiguradorListaResolver } from '../configurador/shareds/resolvers/configurador-lista.resolver';
import { ProjetoAberturaCadastroPageComponent } from './pages/projeto-abertura-cadastro-page/projeto-abertura-cadastro-page.component';
import { ConfiguradorLayoutListaResolver } from '../configurador/shareds/resolvers/configurador-layout-lista.resolver';
import { ConfiguradorCustoTraficoListaResolver } from '../configurador/shareds/resolvers/configurador-custo-trafico-lista.resolver';
import { ConfiguradorFormatoListaResolver } from '../configurador/shareds/resolvers/configurador-formato-list.resolver';
import { ConfiguradorTimestampTXListaResolver } from '../configurador/shareds/resolvers/configurador-timestamp-tx-lista-resolver';
import { ConfiguradorTimestampRXListaResolver } from '../configurador/shareds/resolvers/configurador-timestamp-rx-lista-resolver';
import { ConfiguradorInsertTimestampListaResolver } from '../configurador/shareds/resolvers/configurador-insert-timestamp-lista.resolver';
import { ConfiguradorInsertSeqTypeListaResolver } from '../configurador/shareds/resolvers/configurador-insert-seq-type-lista.resolver';
import { ConfiguradorCRLFListaResolver } from '../configurador/shareds/resolvers/configurador-crlf-lista.resolver';
import { ConfiguradorInsertSeqListaResolver } from '../configurador/shareds/resolvers/configurador-insert-seq-lista.resolver';
import { ConfiguradorRemoveAcentosListaResolver } from '../configurador/shareds/resolvers/configurador-remove-acentos-lista.resolver';
import { ProjetoAberuraDetalheResolver } from './shareds/resolvers/projeto-abertura-detalhe.resolver';
import { ModuloListaResolver } from '../modulo/shareds/resolvers/modulo-lista.resolver';
import { CustoTrafegoListaResolver } from './shareds/resolvers/custo-trafego-lista-resolver';
import { StatusReportListResolver } from './shareds/resolvers/status-report-lista.resolver';
import { SimNaoResolver } from '@utils/resolvers/sim-nao.resolver';
import { ProjetoProcessoStatusListaResolver } from './shareds/resolvers/projeto-processo-status-lista.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [ProjetoGuard],
        canLoad: [ProjetoGuard],
        resolve: {
            produtos: ProdutoListaResolver,
            statuss: ProjetoStatusListaResolver,
            times: TimeListaResolver,
            usuarios: UsuarioFinnetAtivoResolver,
            modulos: ModuloListaResolver,
            listaStatusReports: StatusReportListResolver,
            listaSimNao: SimNaoResolver,
            listaProcessoStatus: ProjetoProcessoStatusListaResolver,
        },
        component: ProjetoGridPageComponent,
    },
    {
        path: 'visualizar/:id',
        canActivate: [ProjetoGuard],
        canLoad: [ProjetoGuard],
        resolve: {
            projeto: ProjetoDetalheResolver,
            bancos: BancoListaResolver,
            statuss: ProjetoStatusListaResolver,
            direcoes: ChecklistDsnameDirecaoListaResolver,
            configurador: ConfiguradorListaResolver,
            listaStatusReport: StatusReportListResolver,
            listaSimNao: SimNaoResolver,
            listaProcessoStatus: ProjetoProcessoStatusListaResolver,
        },
        component: ProjetoCadastroPageComponent,
    },
    {
        path: 'cadastro/:id',
        canActivate: [ProjetoGuard],
        canLoad: [ProjetoGuard],
        resolve: {
            projeto: ProjetoDetalheResolver,
            bancos: BancoListaResolver,
            statuss: ProjetoStatusListaResolver,
            statussOs: OsStatusListaResolver,
            empresasOS: OsEmpresaListaResolver,
            configurador: ConfiguradorListaResolver,
            listaStatusReport: StatusReportListResolver,
            listaSimNao: SimNaoResolver,
            listaProcessoStatus: ProjetoProcessoStatusListaResolver,
        },
        component: ProjetoCadastroPageComponent,
    },
    {
        path: 'abertura/visualizar/:id',
        canActivate: [ProjetoGuard],
        canLoad: [ProjetoGuard],
        resolve: {
            statuss: ProjetoStatusListaResolver,
            direcoes: ChecklistDsnameDirecaoListaResolver,
            configurador: ConfiguradorListaResolver,
            layouts: ConfiguradorLayoutListaResolver,
            custos: ConfiguradorCustoTraficoListaResolver,
            formatos: ConfiguradorFormatoListaResolver,
            timestamptx: ConfiguradorTimestampTXListaResolver,
            timestamptr: ConfiguradorTimestampRXListaResolver,
            inserttimestamp: ConfiguradorInsertTimestampListaResolver,
            insertseqtype: ConfiguradorInsertSeqTypeListaResolver,
            insertseq: ConfiguradorInsertSeqListaResolver,
            crlf: ConfiguradorCRLFListaResolver,
            removeacentos: ConfiguradorRemoveAcentosListaResolver,
            projetoAbertura: ProjetoAberuraDetalheResolver,
            custoTrafego: CustoTrafegoListaResolver,
        },
        component: ProjetoAberturaCadastroPageComponent,
    },
    {
        path: 'abertura/cadastro/:id',
        canActivate: [ProjetoGuard],
        canLoad: [ProjetoGuard],
        resolve: {
            statuss: ProjetoStatusListaResolver,
            direcoes: ChecklistDsnameDirecaoListaResolver,
            configurador: ConfiguradorListaResolver,
            layouts: ConfiguradorLayoutListaResolver,
            custos: ConfiguradorCustoTraficoListaResolver,
            formatos: ConfiguradorFormatoListaResolver,
            timestamptx: ConfiguradorTimestampTXListaResolver,
            timestamptr: ConfiguradorTimestampRXListaResolver,
            inserttimestamp: ConfiguradorInsertTimestampListaResolver,
            insertseqtype: ConfiguradorInsertSeqTypeListaResolver,
            insertseq: ConfiguradorInsertSeqListaResolver,
            crlf: ConfiguradorCRLFListaResolver,
            removeacentos: ConfiguradorRemoveAcentosListaResolver,
            projetoAbertura: ProjetoAberuraDetalheResolver,
            custoTrafego: CustoTrafegoListaResolver,
        },
        component: ProjetoAberturaCadastroPageComponent,
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ProjetoRoutingModule { }
