import { Component, Injector, OnInit } from '@angular/core';
import { SimNao } from '@utils/interfaces/shareds/sim-nao.interface';
import { ChecklistConectividadeBase } from '../checklist-conectividade-base';
import { ChecklistConectividadesFastWeb } from '../shareds/interfaces/checklist-conectividades-fast-web.interface';

@Component({
  selector: 'checklist-conectividades-fast-web',
  templateUrl: './checklist-conectividades-fast-web.component.html',
  styleUrls: ['./checklist-conectividades-fast-web.component.scss']
})
export class ChecklistConectividadesFastWebComponent extends ChecklistConectividadeBase<ChecklistConectividadesFastWeb> implements OnInit {

  public CNPJ_MASK = '00.000.000/0000-00';

  public possuiCaixaPostalStr: string = null;

  public listaSimNao: Array<SimNao> = [
    { codigo: 'SIM', descricao: 'Sim' },
    { codigo: 'NAO', descricao: 'Não' }
  ]

  constructor(
    protected _injector: Injector
  ) {
    super(_injector)
  }

  ngOnInit() {
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      possuiCaixaPostal: [],
      possuiCaixaPostalQual: [],
      produtoBancario: [],
      razaoSocial: [],
      cnpj: [],
      diretorioRemessa: [],
      nomenclaturaRemessa: [],
      diretorioRetorno: [],
      nomenclaturaRetorno: [],
      observacao: [],
    }
  }

  protected setForm(data: ChecklistConectividadesFastWeb) {
    const possuiCaixaPostalVal = this.listaSimNao.find(val => val.codigo === data.possuiCaixaPostal)
    this.possuiCaixaPostalStr = data.possuiCaixaPostal;
    this.form.patchValue({
      possuiCaixaPostal: possuiCaixaPostalVal,
      possuiCaixaPostalQual: data.possuiCaixaPostalQual,
      produtoBancario: data.produtoBancario,
      razaoSocial: data.razaoSocial,
      cnpj: data.cnpj,
      diretorioRemessa: data.diretorioRemessa,
      nomenclaturaRemessa: data.nomenclaturaRemessa,
      diretorioRetorno: data.diretorioRetorno,
      nomenclaturaRetorno: data.nomenclaturaRetorno,
      observacao: data.observacao,
    })
  }

  private getForm() {
    const possuiCaixaPostalVal = this.form.get('possuiCaixaPostal').value;
    const possuiCaixaPostalQual = this.form.get('possuiCaixaPostalQual').value;
    const produtoBancario = this.form.get('produtoBancario').value;
    const razaoSocial = this.form.get('razaoSocial').value;
    const cnpj = this.form.get('cnpj').value;
    const diretorioRemessa = this.form.get('diretorioRemessa').value;
    const nomenclaturaRemessa = this.form.get('nomenclaturaRemessa').value;
    const diretorioRetorno = this.form.get('diretorioRetorno').value;
    const nomenclaturaRetorno = this.form.get('nomenclaturaRetorno').value;
    const observacao = this.form.get('observacao').value;

    const data: ChecklistConectividadesFastWeb = {
      possuiCaixaPostal: possuiCaixaPostalVal && possuiCaixaPostalVal.codigo ? possuiCaixaPostalVal.codigo : undefined,
      possuiCaixaPostalQual: possuiCaixaPostalQual ? possuiCaixaPostalQual : undefined,
      produtoBancario: produtoBancario ? produtoBancario : undefined,
      razaoSocial: razaoSocial ? razaoSocial : undefined,
      cnpj: cnpj ? cnpj : undefined,
      diretorioRemessa: diretorioRemessa ? diretorioRemessa : undefined,
      nomenclaturaRemessa: nomenclaturaRemessa ? nomenclaturaRemessa : undefined,
      diretorioRetorno: diretorioRetorno ? diretorioRetorno : undefined,
      nomenclaturaRetorno: nomenclaturaRetorno ? nomenclaturaRetorno : undefined,
      observacao: observacao ? observacao : undefined,
    }

    this.values.emit(data);
  }

  changePossuiCaixaPostal() {
    const possuiCaixaPostalVal = this.form.get('possuiCaixaPostal').value
    this.possuiCaixaPostalStr = possuiCaixaPostalVal.codigo;
    this.limpaSubForm();
    this.getForm();
  }

  limpaSubForm() {
    this.form.get('produtoBancario').reset();
    this.form.get('razaoSocial').reset();
    this.form.get('cnpj').reset();
    this.form.get('diretorioRemessa').reset();
    this.form.get('nomenclaturaRemessa').reset();
    this.form.get('diretorioRetorno').reset();
    this.form.get('nomenclaturaRetorno').reset();
    this.form.get('observacao').reset();
  }

}
