import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FaturamentoRecorrenteDadosComplementares } from '../interfaces/faturamento-recorrente-dados-complementares.interface';
import { FaturamentoRecorrenteDadosComplementaresService } from '../services/faturamento-recorrente-dados-complementares.service';

@Injectable()
export class FaturamentoRecorrenteDadosComplementaresResolver implements Resolve<FaturamentoRecorrenteDadosComplementares> {
    constructor(private _service: FaturamentoRecorrenteDadosComplementaresService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let id = route.params['id']
        return this._service.findById(id).pipe(map(result => result.data))
    }
}
