import { Component, Injector, Input, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { Produto } from 'app/main/produto/shareds/interfaces/produto.interface';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { UsuarioService } from 'app/main/usuario/shared/services/usuario.service';
import { filter } from 'lodash';
import { Time } from '../../shareds/interfaces/time.interface';
import { TimeService } from '../../shareds/services/time.service';

@Component({
    selector: 'time-form',
    templateUrl: './time-form.component.html',
    styleUrls: ['./time-form.component.scss'],
})
export class TimeFormComponent extends FormBase implements OnInit {
    @Input() time: Time = {};
    produtos: Array<Produto> = [];

    //--------------------
    campoPesquisa: string;
    usuariosFull: Array<Usuario> = [];
    usuariosFullTotal: Array<Usuario> = [];
    usuariosSelecionados: Array<Usuario> = [];
    usuariosFullCheckbox: Array<Usuario> = [];
    usuariosSelecionadosCheckbox: Array<Usuario> = [];
    todosUsuariosFull: boolean = false;
    todosUsuariosSelecionado: boolean = false;
    private campoPesquisaOld: string;
    selectedProdutos: Produto[];

    //--------------------

    constructor(
        private _timeService: TimeService,
        private _usuarioService: UsuarioService,
        private _injector: Injector
    ) {
        super(_injector);

        this._route.data.subscribe((data) => {
            this.produtos = data.produtos;
            // this.usuariosFull = data.usuarios;
            // this.usuariosFullTotal = data.usuarios;
            if (data.time) {
                this.time = data.time;
                this.setForm(data.time);
            }
        });
    }

    ngOnInit() { }

    salvar() {
        debugger;
        const time: Time = {
            id: this.time.id,
            nome: this.form.get('nome').value,
            idProduto: this.form.get('produto').value,
            usuarios: this.usuariosSelecionados,
            recebeNotificacao: this.form.get('recebeNotificacao').value
        };
        if (!time.idProduto) {
            delete time.idProduto;
        }
        if (!/[a-zA-Z]/.test(time.nome)) {
            this._notification.error(
                'O nome do time deve conter ao menos uma letra'
            );
            return;
        }
        if (!time.usuarios || time.usuarios.length === 0) {
            this._notification.error('O time deve conter ao menos um usuário');
            return;
        }
        this._spinner.show();
        this._timeService.save(time).subscribe(
            (timeResponse: Response<Time>) => {
                this._timeService
                    .updateUsuarios(
                        timeResponse.data,
                        this.usuariosSelecionados
                    )
                    .subscribe(
                        () => {
                            this.sucess(
                                'Dados do time atualizados com sucesso'
                            );
                        },
                        (error) => this.error(error)
                    );
            },
            (error) => this.error(error)
        );
    }

    selectedOption(o1: any, o2: any) {
        return o1.id == o2.id;
    }

    protected crateForm(): { [key: string]: any } {
        return {
            nome: ['', Validators.required],
            produto: [this.selectedProdutos],
            recebeNotificacao: []
        };
    }

    private setForm(time: Time): void {
        this.form.patchValue({
            nome: time.nome,
            produto: time.produtos,
            recebeNotificacao: time.recebeNotificacao
        });
        this.selectedProdutos = time.produtos;

        if (time.usuarios) {
            time.usuarios.forEach((u) => {
                // this.usuariosFull.forEach((uf, idx) => {
                //     if (u.id === uf.id) this.adicionarSelecionado(u, idx);
                // });
                this._usuarioService.findById(u.id).subscribe(user => {
                    this.adicionarSelecionado(user.data)
                })
            });
        }
    }

    //-----------------------------------
    pesquisarPorTexto(): void {
        // this.usuariosFull = this.usuariosFullTotal.filter((usuario) => {
        //     const regex = new RegExp(this.campoPesquisa.toLocaleLowerCase());
        //     return (
        //         usuario.nome.toLowerCase().match(regex) ||
        //         usuario.email.toLowerCase().match(regex)
        //     );
        // });
        this._usuarioService.find({ filter: this.campoPesquisa.toLocaleLowerCase() }).subscribe(listUser => {
            this.usuariosFull = listUser.data;
        })
    }

    limparCampoPesquisa(): void {
        this.campoPesquisa = '';
        this.usuariosFull = this.usuariosFullTotal;
    }

    // Usuarios Full
    addFullCheckbox(): void {
        const usuariosFullCheckbox = Array.from(this.usuariosFullCheckbox);

        usuariosFullCheckbox.forEach((r: Usuario, i: number) => {
            const index = this.usuariosFull.findIndex((rel) => rel.id === r.id);
            r.selecionado = false;
            this.adicionarSelecionado(r, index);
        });

        this.todosUsuariosFull = false;
        this.usuariosFullCheckbox = [];
    }

    removeFullCheckbox(usuario: Usuario): void {
        const index = this.usuariosFullCheckbox.findIndex(
            (r) => r.id === usuario.id
        );
        this.usuariosFullCheckbox.splice(index, 1);
    }

    adicionarFull(usuario: Usuario, index: number): void {
        usuario.selecionado = false;
        this.usuariosFull.push(usuario);
        this.usuariosFullTotal = this.usuariosFull;
        this.removeSelecionadoCheckboxArray(usuario);
        this.removeUsuarioSelecionado(index);
    }

    removeUsuarioFull(index: number): void {
        this.usuariosFull.splice(index, 1);
        this.usuariosFullTotal = this.usuariosFull;
    }

    selecionaFull(usuario: Usuario, checked: boolean): void {
        if (checked) {
            this.usuariosFullCheckbox.push(usuario);
        } else {
            this.removeFullCheckbox(usuario);
        }
    }

    setTodosUsuariosFull(selecionado: boolean) {
        this.todosUsuariosFull = selecionado;
        if (this.usuariosFull === null) {
            return;
        }
        if (selecionado) {
            this.usuariosFullCheckbox = Array.from(this.usuariosFull);
        } else {
            this.usuariosFullCheckbox = [];
        }
        this.usuariosFull.forEach((r) => (r.selecionado = selecionado));
    }

    // Usuarios Selecionados
    removeSelecionadosCheckbox(): void {
        const usuariosSelecionadosCheckbox = Array.from(
            this.usuariosSelecionadosCheckbox
        );

        usuariosSelecionadosCheckbox.forEach((r: Usuario, i: number) => {
            const index = this.usuariosSelecionados.findIndex(
                (rel) => rel.id === r.id
            );
            r.selecionado = false;
            this.adicionarFull(r, index);
        });

        this.todosUsuariosSelecionado = false;
        this.usuariosSelecionadosCheckbox = [];
    }

    adicionarSelecionado(usuario: Usuario, index?: number): void {
        usuario.selecionado = false;
        this.usuariosSelecionados.push(usuario);
        this.removeFullCheckbox(usuario);
        if (index) {
            this.removeUsuarioFull(index);
        }
    }

    removeSelecionadoCheckboxArray(usuario: Usuario): void {
        const index = this.usuariosFullCheckbox.findIndex(
            (r) => r.id === usuario.id
        );
        this.usuariosFullCheckbox.splice(index, 1);
    }

    removeUsuarioSelecionado(index: number): void {
        this.usuariosSelecionados.splice(index, 1);
    }

    selecionaSelecionado(usuario: Usuario, checked: boolean): void {
        if (checked) {
            this.usuariosSelecionadosCheckbox.push(usuario);
        } else {
            this.removeSelecionadoCheckboxArray(usuario);
        }
    }

    setTodosUsuariosSelecionado(selecionado: boolean) {
        this.todosUsuariosSelecionado = selecionado;
        if (this.usuariosSelecionados === null) {
            return;
        }
        if (selecionado) {
            this.usuariosSelecionadosCheckbox = Array.from(
                this.usuariosSelecionados
            );
        } else {
            this.usuariosSelecionadosCheckbox = [];
        }
        this.usuariosSelecionados.forEach((r) => (r.selecionado = selecionado));
    }

    //-----------------------------------
}
