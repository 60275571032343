import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FiltroUsuario } from '../interfaces/filtro-usuario.interface';
import { Usuario } from '../interfaces/usuario.interface';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable()
export class UsuarioService extends ServiceGridBase<Usuario, FiltroUsuario> {
    private path = `${environment.server}/usuario`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroUsuario): Observable<Response<Array<Usuario>>> {
        const params = this.toGetParams(filtro);
        const url = `${environment.server}/usuario${params}`;
        return this._http.get<Response<Array<Usuario>>>(url);
    }

    findById(id: number | string): Observable<Response<Usuario>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Usuario>>(url);
    }

    save(usuario: Usuario): Observable<Response<Usuario>> {
        const url = `${this.path}`;
        return this._http.post<Response<Usuario>>(url, usuario);
    }

    alterarPerfil(usuario: Usuario): Observable<Response<Usuario>> {
        const url = `${this.path}/${usuario.id}/alterarperfil`;
        const perfis = [];
        usuario.perfis.forEach((perfil) => perfis.push(perfil.id));
        return this._http.put<Response<Usuario>>(url, { perfis: perfis });
    }

    alterarStatus(usuario: Usuario): Observable<Response<Usuario>> {
        const url = `${this.path}/${usuario.id}/alterarstatus`;
        return this._http.put<Response<Usuario>>(url, {
            status: usuario.status,
        });
    }
}
