import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SimNao } from '@utils/interfaces/shareds/sim-nao.interface';

@Injectable({
    providedIn: 'root',
})
export class SimNaoService {

    constructor() {
    }

    find(): Observable<Array<SimNao>> {
        return of([
            { codigo: '1', descricao: 'Sim' },
            { codigo: '0', descricao: 'Não' },
        ])
    }
}
