import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { FiltroImportacaoOsErro } from '../interfaces/filtro-importacao-os-erro.interfaces';
import { ImportacaoOsErro } from '../interfaces/importacao-os-erro.interfaces';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
  providedIn: 'root'
})
export class ImportacaoOsErroService extends ServiceGridBase<
ImportacaoOsErro,
FiltroImportacaoOsErro
> {
  private path = `${environment.server}/os-importar`;

  constructor(private _http: HttpClient) {
    super();
  }

  find(
    filtro: FiltroImportacaoOsErro
  ): Observable<Response<Array<ImportacaoOsErro>>> {
    const params: string = this.toGetParams(filtro);
    const url = `${this.path}/erros/${filtro.idArquivo}${params}`;
    return this._http.get<Response<Array<ImportacaoOsErro>>>(url);
  }
}