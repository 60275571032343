import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChecklistStatus } from '../interfaces/checklist-status.interface';
import { ChecklistStatusService } from '../services/checklist-status.service';

@Injectable()
export class ChecklistStatusListaResolver implements Resolve<ChecklistStatus> {
    constructor(private _checklistStatusService: ChecklistStatusService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._checklistStatusService
            .find()
            .pipe(map((result) => result.data));
    }
}
