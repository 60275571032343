import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroProjeto } from '../interfaces/filtro-projeto.interface';
import { Projeto, ProjetoRequest } from '../interfaces/projeto.interface';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root',
})
export class ProjetoCaixaPostalService extends ServiceGridBase<Projeto, FiltroProjeto> {
    private path: string = `${environment.server}/configurador/caixaPostal`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(data?: any): Observable<Response<Array<Projeto>>> {
        const url = `${this.path}/${data.aberturaId}/${data.direcaoId}/${data.campo}`;
        return this._http.get<Response<Array<Projeto>>>(url);
    }
}
