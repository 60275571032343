import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AclGuard } from './main/acl/acl.guard';
import { ChecklistGuard } from './main/checklist/checklist.guard';
import { HomeGuard } from './main/home/home.guard';
import { ImportacaoGuard } from './main/importacao/importacao.guard';
import { ProfileGuard } from './main/profile/profile.guard';
import { CotacaoGuard } from './main/cotacao/cotacao.guard';
import { UsuarioGuard } from './main/usuario/usuario.guard';
import { ProjetoGuard } from './main/projeto/projeto.guard';
import { TimeGuard } from './main/time/time.guard';
import { OsGuard } from './main/os/os.guard';
import { ContratoGuard } from './main/contrato/contrato.guard';
import { FaturamentoGuard } from './main/faturamento/faturamento.guard';
import { GmudGuard } from './main/gmud/gmud.guard';
import { RelatorioGuard } from './main/relatorio/relatorio.guard';
import { RelacionamentoAberturaGuard } from './main/relacionamento-abertura/relacionamento-abertura.guard';
// import { NotificacaoGuard } from './main/notificacao/notificacao.guard';
// import { ConfiguracaoGuard } from './main/configuracao/configuracao.guard';
// import { RelacionamentoAberturaGuard } from './main/relacionamento-abertura/relacionamento-abertura.guard';
import { BancoGuard } from './main/banco/banco.guard';
import { GroupEnterpriseGuard } from './main/group-enterprise/group-enterprise.guard';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: 'app/main/public/auth/auth.module#AuthModule',
    },
    {
        path: 'home',
        canActivate: [HomeGuard],
        canLoad: [HomeGuard],
        loadChildren: 'app/main/home/home.module#HomeModule',
    },
    {
        path: 'empresa',
        //canActivate: [EmpresaGuard],
        //canLoad: [EmpresaGuard],
        loadChildren: 'app/main/empresa/empresa.module#EmpresaModule',
    },
    {
        path: 'profile',
        canActivate: [ProfileGuard],
        canLoad: [ProfileGuard],
        loadChildren: 'app/main/profile/profile.module#ProfileModule',
    },
    {
        path: 'acl',
        canActivate: [AclGuard],
        canActivateChild: [AclGuard],
        canLoad: [AclGuard],
        loadChildren: 'app/main/acl/acl.module#AclModule',
    },
    {
        path: 'usuario',
        canActivate: [UsuarioGuard],
        canActivateChild: [UsuarioGuard],
        canLoad: [UsuarioGuard],
        loadChildren: 'app/main/usuario/usuario.module#UsuarioModule',
    },
    {
        path: 'grupo-empresas',
        canActivate: [GroupEnterpriseGuard],
        canActivateChild: [GroupEnterpriseGuard],
        canLoad: [GroupEnterpriseGuard],
        loadChildren: 'app/main/group-enterprise/group-enterprise.module#GroupEnterpriseModule',
    },
    {
        path: 'cotacao',
        canActivate: [CotacaoGuard],
        canActivateChild: [CotacaoGuard],
        canLoad: [CotacaoGuard],
        loadChildren: 'app/main/cotacao/cotacao.module#CotacaoModule',
    },
    {
        path: 'importacao',
        canActivate: [ImportacaoGuard],
        canActivateChild: [ImportacaoGuard],
        canLoad: [ImportacaoGuard],
        loadChildren: 'app/main/importacao/importacao.module#ImportacaoModule',
    },
    {
        path: 'checklist',
        canActivate: [ChecklistGuard],
        canActivateChild: [ChecklistGuard],
        canLoad: [ChecklistGuard],
        loadChildren: 'app/main/checklist/checklist.module#ChecklistModule',
    },
    {
        path: 'projeto',
        canActivate: [ProjetoGuard],
        canActivateChild: [ProjetoGuard],
        canLoad: [ProjetoGuard],
        loadChildren: 'app/main/projeto/projeto.module#ProjetoModule',
    },

    {
        path: 'os',
        canActivate: [OsGuard],
        canActivateChild: [OsGuard],
        canLoad: [OsGuard],
        loadChildren: 'app/main/os/os.module#OsModule',
    },

    {
        path: 'relacionamento-abertura',
        canActivate: [RelacionamentoAberturaGuard],
        canActivateChild: [RelacionamentoAberturaGuard],
        canLoad: [RelacionamentoAberturaGuard],
        loadChildren: 'app/main/relacionamento-abertura/relacionamento-abertura.module#RelacionamentoAberturaModule',
    },

    {
        path: 'time',
        canActivate: [TimeGuard],
        canActivateChild: [TimeGuard],
        canLoad: [TimeGuard],
        loadChildren: 'app/main/time/time.module#TimeModule',
    },

    {
        path: 'contrato',
        canActivate: [ContratoGuard],
        canActivateChild: [ContratoGuard],
        canLoad: [ContratoGuard],
        loadChildren: 'app/main/contrato/contrato.module#ContratoModule',
    },

    {
        path: 'faturamento',
        canActivate: [FaturamentoGuard],
        canActivateChild: [FaturamentoGuard],
        canLoad: [FaturamentoGuard],
        loadChildren: 'app/main/faturamento/faturamento.module#FaturamentoModule',
    },

    {
        path: 'gmud',
        canActivate: [GmudGuard],
        canActivateChild: [GmudGuard],
        canLoad: [GmudGuard],
        loadChildren: 'app/main/gmud/gmud.module#GmudModule',
    },

    {
        path: 'relatorio',
        canActivate: [RelatorioGuard],
        canActivateChild: [RelatorioGuard],
        canLoad: [RelatorioGuard],
        loadChildren: 'app/main/relatorio/relatorio.module#RelatorioModule',
    },
    {
        path: 'bi',
        loadChildren: 'app/main/bi/bi.module#BiModule',
    },

    // {
    //     path: 'notificacao',
    //     canActivate: [NotificacaoGuard],
    //     canActivateChild: [NotificacaoGuard],
    //     canLoad: [NotificacaoGuard],
    //     loadChildren: 'app/main/notificacao/notificacao.module#NotificacaoModule',
    // },

    // {
    //     path: 'configuracao',
    //     canActivate: [ConfiguracaoGuard],
    //     canActivateChild: [ConfiguracaoGuard],
    //     canLoad: [ConfiguracaoGuard],
    //     loadChildren: 'app/main/configuracao/configuracao.module#ConfiguracaoModule',
    // },

    {
        path: 'banco',
        canActivate: [BancoGuard],
        canActivateChild: [BancoGuard],
        canLoad: [BancoGuard],
        loadChildren: 'app/main/banco/banco.module#BancoModule'
    },

    { path: 'manager', redirectTo: 'auth/admin/login' },

    { path: '**', redirectTo: 'auth/login' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule { }
