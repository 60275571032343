import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FiltroUsuario } from '../../shared/interfaces/filtro-usuario.interface';
import { Usuario } from '../../shared/interfaces/usuario.interface';
import { UsuarioEditarService } from '../../shared/services/usuario-editar.service';
import { UsuarioModalService } from '../../shared/services/usuario-modal.service';
import { UsuarioService } from '../../shared/services/usuario.service';

@Component({
    selector: 'usuario-grid',
    templateUrl: './usuario-grid.component.html',
    styleUrls: ['./usuario-grid.component.scss'],
})
export class UsuarioGridComponent extends GridBase<Usuario, FiltroUsuario, UsuarioEditarService> implements OnInit {
    constructor(
        service: UsuarioEditarService,
        injector: Injector,
        changeDetectorRefs: ChangeDetectorRef,
        private _serviceModal: UsuarioModalService,
        private _usuarioService: UsuarioService
    ) {
        super(service, injector, changeDetectorRefs);
    }

    ngOnInit(): void {
        this.initGrid();
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'nome', label: '' },
            { chave: 'email', label: '' },
            { chave: 'tipo', label: '' },
        ];
    }

    alterarStatus(usuario: Usuario): void {
        const status = !usuario.status;
        const titulo = status
            ? `Deseja ativar o usuário ${usuario.nome}?`
            : `Deseja desativar o usuário ${usuario.nome}?`;

        this._confirmation.confirm({ titulo: titulo }).subscribe((result) => {
            if (result) {
                usuario = Object.assign({}, usuario, {
                    status: usuario.status,
                });
                this._usuarioService.alterarStatus(usuario).subscribe(
                    (user) => {
                        this.sucess('Status atualizado com sucesso!');
                    },
                    (error) => this.error(error)
                );
            } else {
                usuario.status = !status;
            }
        });
    }

    alterarPerfil(usuario: Usuario): void {
        this._serviceModal.alterarPerfil(usuario).subscribe((val) => {
            console.log('Fechou o modal');
        });
    }

    visualizar(usuario: Usuario): void {
        this._serviceModal.detalhes(usuario).subscribe((val) => {
            console.log('Fechou o modal');
        });
    }
}
