import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,
} from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@utils/utils.module';
import { FaturamentoService } from './shared/services/faturamento.service';
import { FaturamentoRoutingModule } from './faturamento-routing.module';
import { FaturamentoGuard } from './faturamento.guard';
import { FaturamentoGridComponent } from './components/faturamento-grid/faturamento-grid.component';
import { FaturamentoGridPageComponent } from './pages/faturamento-grid-page/faturamento-grid-page.component';
import { FaturamentoGridFiltroComponent } from './components/faturamento-grid-filtro/faturamento-grid-filtro.component';
import { FaturamentoResolver } from './shared/resolvers/faturamento.resolver';
import { FaturamentoVisualizarDetalheComponent } from './components/faturamento-visualizar-detalhe/faturamento-visualizar-detalhe.component';
import { FaturamentoHistoricoDetalheComponent } from './components/faturamento-historico-detalhe/faturamento-historico-detalhe.component';
import { FaturamentoHistoricoService } from './shared/services/faturamento-historico.service';
import { ModalHistoricoFaturamentoComponent } from './modal/modal-historico-faturamento/modal-historico-faturamento.component';
import { ModalHistoricoDetalheFaturamentoComponent } from './modal/modal-historico-detalhe-faturamento/modal-historico-detalhe-faturamento.component';
import { ModalEditarFaturamentoComponent } from './modal/modal-editar-faturamento/modal-editar-faturamento.component';
import { EditarFaturamentoComponent } from './components/editar-faturamento/editar-faturamento.component';
import { FaturamentoEditarDetalhePageComponent } from './pages/faturamento-detalhe-page/faturamento-editar-detalhe-page.component';
import { FaturamentoCadastroComponent } from './components/faturamento-cadastro/faturamento-cadastro.component';
import { FaturamentoFaturarAbaComponent } from './components/faturamento-faturar-aba/faturamento-faturar-aba.component';
import { FaturamentoEmpresaAbaComponent } from './components/faturamento-empresa-aba/faturamento-empresa-aba.component';
import { FaturamentoProdutoAbaComponent } from './components/faturamento-produto-aba/faturamento-produto-aba.component';
import { FaturamentoDadosComplementaresAbaComponent } from './components/faturamento-dados-complementares-aba/faturamento-dados-complementares-aba.component';
import { FaturamentoItemNotaComponent } from './components/faturamento-item-nota/faturamento-item-nota.component';
import { FaturamentoProdutoGridComponent } from './components/faturamento-produto-grid/faturamento-produto-grid.component';
import { FaturamentoHistoricoComponent } from './components/faturamento-historico/faturamento-historico.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FaturamentoTipoFaturamentoListaResolver } from './shared/resolvers/faturamento-tipo-faturamento-lista.resolver';
import { FaturamentoEmissorListaResolver } from './shared/resolvers/faturamento-emissor-lista.resolver';
import { FaturamentoParcelaStatusResolver } from './shared/resolvers/faturamento-parcela-status.resolver';
import { FaturamentoParcelarListaResolver } from './shared/resolvers/faturamento-parcelar-lista.resolver';
import { FaturamentoParcelaStatusService } from './shared/services/faturamento-parcela-status.service';
import { FaturamentoParcelasListaResolver } from './shared/resolvers/faturamento-parcelas-lista.resolver';
import { FaturamentoProdutoService } from './shared/services/faturamento-produto.service';
import { FaturamentoProdutoListaResolver } from './shared/resolvers/faturamento-produto-lista.resolver';
import { FaturamentoProdutoCotacaoService } from './shared/services/faturamento-produto-cotacao.service';
import { FaturamentoGridFiltroRecorrenteComponent } from './components/faturamento-grid-filtro-recorrente/faturamento-grid-filtro-recorrente.component';
import { FaturamentoGridRecorrenteComponent } from './components/faturamento-grid-recorrente/faturamento-grid-recorrente.component';
import { FaturamentoRecorrenteService } from './shared/services/faturamento-recorrente.service';
import { ModalEditarLoteFaturamentoRecorrenteComponent } from './modal/modal-editar-lote-faturamento-recorrente/modal-editar-lote-faturamento-recorrente.component';
import { EditarFaturamentoRecorrenteComponent } from './components/editar-faturamento-recorrente/editar-faturamento-recorrente.component';
import { FaturamentoRecorrenteDetalhePageComponent } from './pages/faturamento-recorrente-detalhe-page/faturamento-recorrente-detalhe-page.component';
import { FaturamentoRecorrenteFaturarAbaComponent } from './components/faturamento-recorrente-faturar-aba/faturamento-recorrente-faturar-aba.component';
import { FaturamentoRecorrenteCadastroComponent } from './components/faturamento-recorrente-cadastro/faturamento-recorrente-cadastro.component';
import { FaturamentoRecorrenteResolver } from './shared/resolvers/faturamento-recorrente.resolver';
// import { TipoSetupFinanceiroListaResolver } from '../cotacao/shared/resolvers/tipo-setup-financeiro-lista.resolver';
import { FaturamentoStatusFaturamentoRecorrenteListaResolver } from './shared/resolvers/faturamento-status-financeiro-recorrente.resolver';
import { FaturamentoRecorrenteItemService } from './shared/services/faturamento-recorrente-item.service';
import { FaturamentoRecorrenteItemListaResolver } from './shared/resolvers/faturamento-recorrente-item-lista.resolver';
import { FaturamentoRecorrenteStatusService } from './shared/services/faturamento-recorrente-status.service';
import { FaturamentoRecorrenteStatusResolver } from './shared/resolvers/faturamento-recorrente-status.resolver';
import { FaturamentoRecorrenteEmpresaService } from './shared/services/faturamento-recorrente-empresa.service';
import { FaturamentoRecorrenteEmpresaResolver } from './shared/resolvers/faturamento-recorrente-empresa.resolver';
import { FaturamentoRecorrenteProdutoGridComponent } from './components/faturamento-recorrente-produto-grid/faturamento-recorrente-produto-grid.component';
import { FaturamentoRecorrenteItemProdutoService } from './shared/services/faturamento-recorrente-item-produto.service';
import { FaturamentoRecorrenteDadosComplementaresService } from './shared/services/faturamento-recorrente-dados-complementares.service';
import { FaturamentoRecorrenteDadosComplementaresResolver } from './shared/resolvers/faturamento-recorrente-dados-complementares.resolver';

@NgModule({
    declarations: [

        // PAGES 
        FaturamentoEditarDetalhePageComponent,
        FaturamentoGridPageComponent,
        FaturamentoHistoricoComponent,
        FaturamentoRecorrenteDetalhePageComponent,

        //COMPONENTS
        FaturamentoGridComponent,
        FaturamentoGridFiltroComponent,
        FaturamentoVisualizarDetalheComponent,
        FaturamentoHistoricoComponent,
        FaturamentoHistoricoDetalheComponent,
        EditarFaturamentoComponent,
        FaturamentoCadastroComponent,
        FaturamentoFaturarAbaComponent,
        FaturamentoEmpresaAbaComponent,
        FaturamentoProdutoAbaComponent,
        FaturamentoDadosComplementaresAbaComponent,
        FaturamentoItemNotaComponent,
        FaturamentoProdutoGridComponent,
        FaturamentoGridFiltroRecorrenteComponent,
        FaturamentoGridRecorrenteComponent,
        FaturamentoRecorrenteFaturarAbaComponent,
        FaturamentoRecorrenteCadastroComponent,
        FaturamentoRecorrenteProdutoGridComponent,

        //MODAIS
        ModalHistoricoFaturamentoComponent,
        ModalHistoricoDetalheFaturamentoComponent,
        ModalEditarFaturamentoComponent,
        ModalEditarLoteFaturamentoRecorrenteComponent,
        EditarFaturamentoRecorrenteComponent,

    ],
    imports: [
        CommonModule,
        UtilsModule,
        FaturamentoRoutingModule,
        NgxMatSelectSearchModule,

        // TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatTabsModule,

        // FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDatepickerModule,
        MatRadioModule,
    ],
    providers: [
        FaturamentoGuard,
        FaturamentoResolver,
        FaturamentoService,
        FaturamentoHistoricoService,
        FaturamentoParcelaStatusService,
        FaturamentoProdutoService,
        FaturamentoProdutoCotacaoService,
        FaturamentoRecorrenteItemService,
        FaturamentoRecorrenteService,
        FaturamentoRecorrenteStatusService,
        FaturamentoRecorrenteEmpresaService,
        FaturamentoRecorrenteItemProdutoService,
        FaturamentoRecorrenteDadosComplementaresService,

        FaturamentoTipoFaturamentoListaResolver,
        FaturamentoStatusFaturamentoRecorrenteListaResolver,
        FaturamentoEmissorListaResolver,
        FaturamentoParcelaStatusResolver,
        FaturamentoParcelarListaResolver,
        FaturamentoParcelasListaResolver,
        FaturamentoProdutoListaResolver,
        FaturamentoRecorrenteResolver,
        // TipoSetupFinanceiroListaResolver,


        FaturamentoRecorrenteService,
        FaturamentoRecorrenteItemListaResolver,
        FaturamentoRecorrenteStatusResolver,
        FaturamentoRecorrenteEmpresaResolver,
        FaturamentoRecorrenteDadosComplementaresResolver
    ],
    entryComponents: [
        ModalHistoricoFaturamentoComponent,
        ModalHistoricoDetalheFaturamentoComponent,
        ModalEditarFaturamentoComponent,
        ModalEditarLoteFaturamentoRecorrenteComponent
    ],
})
export class FaturamentoModule { }
