import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { FiltroImportacaoCotacaoDetalhe } from '../interfaces/filtro-importacao-cotacao-detalhe.interface';
import { ImportacaoCotacaoDetalhe } from '../interfaces/importacao-cotacao-detalhe.interface';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root',
})
export class ImportacaoCotacaoDetalheService extends ServiceGridBase<
    ImportacaoCotacaoDetalhe,
    FiltroImportacaoCotacaoDetalhe
> {
    private path = `${environment.server}/importar`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro: FiltroImportacaoCotacaoDetalhe
    ): Observable<Response<Array<ImportacaoCotacaoDetalhe>>> {
        // const params: string = this.toGetParams(filtro);
        const url = `${this.path}/cotacoes/${filtro.idArquivo}`;
        return this._http.get<Response<Array<ImportacaoCotacaoDetalhe>>>(url);
    }
}
