import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { DataUtil } from '@utils/utils/class/data.util';
import { ConfirmationService } from '@utils/utils/confirmation/confirmation.service';
import * as moment from 'moment';
import { ContratoVigencia } from '../../shared/interfaces/contrato-vigencia.interface';
import { ContratoAtualizarVigenciaService } from '../../shared/services/contrato-atualizar-vigencia.service';

@Component({
    selector: 'contrato-atualizar-vigencia',
    templateUrl: './contrato-atualizar-vigencia.component.html',
    styleUrls: ['./contrato-atualizar-vigencia.component.scss'],
})
export class ContratoAtualizarVigenciaComponent
    extends FormBase
    implements OnInit {

    isView: boolean = false;

    desabilitarDataFinal: boolean = false

    @Output() atualizado = new EventEmitter();

    @Input() private _idContrato: number

    get idContrato() {
        return this._idContrato;
    }

    @Input() set idContrato(idContrato: number) {
        this._idContrato = idContrato;
        if (idContrato) {
            this.isView = true;
        }
    }

    constructor(
        protected _injector: Injector,
        private _contratoAtualizarVigenciaService: ContratoAtualizarVigenciaService
    ) {

        super(_injector);

    }

    ngOnInit() {
    }

    protected crateForm(): { [key: string]: any } {
        return {
            inicioVigencia: ['', Validators.required],
            finalVigencia: [''],
            dataBiller: ['', Validators.required],
            finalVigenciaIndeterminado: [],
        };
    }



    editar() {
        const inicioVigencia = this.form.get('inicioVigencia').value
        const finalVigencia = this.form.get('finalVigencia').value
        const dataBiller = this.form.get('dataBiller').value
        const finalVigenciaIndeterminado = this.form.get('finalVigenciaIndeterminado').value;

        if (!finalVigencia && !finalVigenciaIndeterminado) {
            this._notification.error('Preencha uma data final de vigência ou selecione a vigência final indeterminado!');
            return;
        }

        const contratoVigencia: ContratoVigencia = {
            inicioVigencia: inicioVigencia ? DataUtil.toDateBackend(inicioVigencia) : '',
            finalVigencia: finalVigencia ? DataUtil.toDateBackend(finalVigencia) : '',
            dataBiller: dataBiller ? DataUtil.toDateBackend(dataBiller) : '',
            contratoId: this._idContrato,
            finalVigenciaIndeterminado: finalVigenciaIndeterminado ? '1' : '0'
        };
        this._confirmation
            .confirm({
                titulo:
                    'Tem certeza que deseja continuar com a Alteração?',
                mensagem:
                    'Esta alteração impacta nos contratos relacionados.',
            })
            .subscribe((result) => {
                if (result) {
                    this.editarExec(contratoVigencia);
                }
            });
    }

    private editarExec(contratoVigencia) {
        this._spinner.show();
        this._contratoAtualizarVigenciaService.update(contratoVigencia).subscribe(
            () => {
                this.sucess('Informações alteradas com sucesso!');
                this.atualizado.emit('true');
            },
            (error) => this.error(error)
        );
    }

    desabilitarDataFinalIndeterminada(indeterminado) {
        if (indeterminado.checked) {
            this.form.get('finalVigencia').setValue('');
            this.desabilitarDataFinal = true
        }
        else {
            this.desabilitarDataFinal = false
        }
    }
}
