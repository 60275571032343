import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OsStatus } from '../interfaces/os-status.interface';
import { OsStatusService } from '../services/os-status.service';

@Injectable()
export class OsStatusListaResolver implements Resolve<OsStatus> {
    constructor(private _osStatusService: OsStatusService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._osStatusService
            .find({})
            .pipe(map((result) => result.data));
    }
}
