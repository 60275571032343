import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { OsMenu } from '../../shareds/enum/os-menu.enum';

@Component({
    selector: 'os-menu',
    templateUrl: './os-menu.component.html',
    styleUrls: ['./os-menu.component.scss'],
})
export class OsMenuComponent implements OnInit {
    @Output() selecionado = new EventEmitter();
    menuSelecionado: string = 'ABERTURA';

    constructor(
        private _notification: NotificationService,
        private spinner: SpinnerService
    ) {}

    ngOnInit() {}

    selecionar(menu: string) {
        this.menuSelecionado = menu;
        this.selecionado.emit(menu);
    }
}
