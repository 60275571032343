import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { CustoTrafego } from 'app/main/os/shareds/interfaces/custo-trafego.interface';
import { CustoTrafegoService } from 'app/main/os/shareds/services/custo-trafego.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CustoTrafegoListaResolver implements Resolve<CustoTrafego> {
    constructor(private _service: CustoTrafegoService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._service.find({}).pipe(map((result) => result.data));
    }
}
