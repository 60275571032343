import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContratoItem } from '../interfaces/contrato-item.interface';
import { ContratoItemService } from '../services/contrato-item.service';

@Injectable()
export class ContratoItemListaResolver implements Resolve<Array<ContratoItem>> {
    constructor(private _service: ContratoItemService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        return this._service.find({ idContrato: id }).pipe(map((result) => result.data));
    }
}
