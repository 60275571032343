import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroRelatorioTipo } from '../interfaces/filtro-relatorio-tipo.interface';
import { RelatorioTipo } from '../interfaces/relatorio-tipo.interface';

@Injectable({
    providedIn: 'root'
})
export class RelatorioTipoService extends ServiceGridBase<RelatorioTipo, FiltroRelatorioTipo> {
    private path: string = `${environment.server}/relatorio`


    constructor(private _http: HttpClient) {
        super()
    }

    find(filtro?: FiltroRelatorioTipo): Observable<Response<Array<RelatorioTipo>>> {
        const params: string = filtro ? this.toGetParams(filtro) : ''
        const url = `${this.path}${params}`
        return this._http.get<Response<Array<RelatorioTipo>>>(url)
    }

}
