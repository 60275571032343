import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImportacaoOsRoutingModule } from './importacao-os-routing.module';
import { ImportacaoOsGridComponent } from './components/importacao-os-grid/importacao-os-grid.component';
import { ImportacaoOsGridPageComponent } from './pages/importacao-os-grid-page/importacao-os-grid-page.component';
import { ImportacaoOsUploadModalComponent } from './modal/importacao-os-upload-modal/importacao-os-upload-modal.component';
import { ImportacaoOsUploadComponent } from './components/importacao-os-upload/importacao-os-upload.component';
import { UtilsModule } from '@utils/utils.module';
import { MatFormFieldModule, MatInputModule, MatPaginatorModule, MatSelectModule, MatSlideToggleModule, MatSortModule, MatTableModule, MatTooltipModule } from '@angular/material';
import { ReactiveFormsModule } from '@angular/forms';
import { ImportacaoOsGuard } from './importacao-os.guard';
import { ImportacaoOsDetalheModalComponent } from './modal/importacao-os-detalhe-modal/importacao-os-detalhe-modal.component';
import { ImportacaoOsErroModalComponent } from './modal/importacao-os-erro-modal/importacao-os-erro-modal.component';
import { ImportacaoOsService } from './shared/services/importacao-os.service';
import { ImportacaoOsErroService } from './shared/services/importacao-os-erro.service';
import { ImportacaoOsModalService } from './shared/services/importacao-os-modal.service';
import { ImportacaoOsErroComponent } from './components/importacao-os-erro/importacao-os-erro.component';
import { ImportacaoOsDetalheComponent } from './components/importacao-os-detalhe/importacao-os-detalhe.component';


@NgModule({
  declarations: [
    ImportacaoOsGridComponent,
    ImportacaoOsGridPageComponent,
    ImportacaoOsUploadModalComponent,
    ImportacaoOsUploadComponent,
    ImportacaoOsDetalheModalComponent,
    ImportacaoOsErroModalComponent,
    ImportacaoOsErroComponent,
    ImportacaoOsDetalheComponent],
  imports: [
    CommonModule,
    ImportacaoOsRoutingModule,
    UtilsModule,

    // TABLE
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,

    // FORMULARIO
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
  ],
  providers: [
    ImportacaoOsGuard,
    ImportacaoOsService,
    ImportacaoOsErroService,
    ImportacaoOsModalService,
  ],
  entryComponents: [
    ImportacaoOsErroModalComponent,
    ImportacaoOsUploadModalComponent,
    ImportacaoOsDetalheModalComponent,
  ],
})
export class ImportacaoOsModule { }
