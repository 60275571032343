import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { Os } from '../../shareds/interfaces/os.interface';
import { OsService } from '../../shareds/services/os.service';
import { OsNotificacao } from '../../shareds/interfaces/os-notificacao.interface';
import { OsModalService } from '../../shareds/services/os-modal.service';

@Component({
    selector: 'os-notificacao',
    templateUrl: './os-notificacao.component.html',
    styleUrls: ['./os-notificacao.component.scss'],
})
export class OsNotificacaoComponent implements OnInit {
    @Output() selecionado = new EventEmitter();
    menuSelecionado: string = 'NOTIFICACAO';

    private _os: Os;

    public reenvio: number[] = [];

    public notificacoes: OsNotificacao[] = []
    public displayedColumns: string[] = [];

     @Input() set os(os: Os) {
        this._os = os;
    }

    constructor(
        private osService: OsService,
        private _notification: NotificationService,
        private spinner: SpinnerService,
        protected _modalVisualizarCorpo: OsModalService
    ) {
        this.displayedColumns = this.configDisplayedColumns().map(({ chave }) => chave);
    }

    ngOnInit() {
        this.getNotificacao();
    }

    private getNotificacao() {
        this.spinner.show();
        this.osService.findNoficao(this._os.id).subscribe((data) => {
            this.notificacoes = data.data;
            this.spinner.hide();
        })
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'selecionar', label: 'assunto' },
            { chave: 'assunto', label: 'Assunto' },
            { chave: 'destino', label: 'Destino' },
            { chave: 'canal', label: 'Canal' },
            { chave: 'enviado', label: 'Enviado' },
            { chave: 'informacao', label: 'Tipo' },
            { chave: 'corpo', label: 'Corpo' }
        ];
    }

    selecionar(menu: string) {
        this.menuSelecionado = menu;
        this.selecionado.emit(menu);
    }

    alterarReenvio(id: number): void {
        if (this.reenvio.includes(id)) {
            this.reenvio = [...this.reenvio.filter(item => id !== item)];
            return;
        }
        this.reenvio = [...this.reenvio, id];
    }

    visualizarCorpo(element: string, id: number) {
        const data = {
            corpo: element,
            id
        }
            this._modalVisualizarCorpo
                .visualizarCorpo(data)
                .subscribe(() => { });

    }

    reenviar (): void {
        this.spinner.show();

        const payload = {
            enviarParaOBanco: this.notificacoes.some(notificacao => this.reenvio.includes(notificacao.id) && notificacao.informacao === 'BANCO'),
            enviarParaOCliente: this.notificacoes.some(notificacao => this.reenvio.includes(notificacao.id) && notificacao.informacao === 'EMPRESA'),
            notificacoes: this.reenvio,
        }

        this.osService.resendNotifications(this._os.id, payload)
            .subscribe(() => {
                this.getNotificacao();
            },
            ({ error }) => {
                Object.keys(error.errors)
                .forEach((key) => error.errors[key].forEach((error) => this._notification.error(error)))
                this.spinner.hide();
            });

        //this.getNotificacao();
    }
}