import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StatusReport } from '../interfaces/status-report';
import { StatusReportService } from '../services/status-report.service';

@Injectable()
export class StatusReportListResolver implements Resolve<StatusReport> {

    constructor(private _service: StatusReportService) { };

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._service.find().pipe(map((result) => result.data));
    }
}


