import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { ChecklistDsnameConfigTipo } from '../interfaces/checklist-dsname-config.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistDsnameConfigTipoService extends ServiceBase<ChecklistDsnameConfigTipo> {
    private path: string = `${environment.server}/checklist`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(): Observable<Response<Array<ChecklistDsnameConfigTipo>>> {
        const url = `${this.path}/dsnameCampoTipo`;
        return this._http.get<Response<Array<ChecklistDsnameConfigTipo>>>(url);
    }
}
