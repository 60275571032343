import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'time-detalhe-page',
  templateUrl: './time-detalhe-page.component.html',
  styleUrls: ['./time-detalhe-page.component.scss']
})
export class TimeDetalhePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
