import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MatPaginatorModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
} from '@angular/material';

import { TimeRoutingModule } from './time-routing.module';

import { TimeFormComponent } from './components/time-form/time-form.component';
import { TimeGridComponent } from './components/time-grid/time-grid.component';
import { TimeDetalheComponent } from './components/time-detalhe/time-detalhe.component';

import { TimeCadastroPageComponent } from './pages/time-cadastro-page/time-cadastro-page.component';
import { TimeDetalhePageComponent } from './pages/time-detalhe-page/time-detalhe-page.component';
import { TimeGridPageComponent } from './pages/time-grid-page/time-grid-page.component';

import { TimeService } from './shareds/services/time.service';
import { TimeModalService } from './shareds/services/time-modal.service';
import { ModalTimeDetalheComponent } from './modal/modal-time-detalhe/modal-time-detalhe.component';
import { TimeDetalheResolver } from './shareds/resolvers/time-detalhe.resolver';

import { TimeGuard } from './time.guard';
import { ModalTimePesquisaComponent } from './modal/modal-time-pesquisa/modal-time-pesquisa.component';
import { TimePesquisaComponent } from './components/time-pesquisa/time-pesquisa.component';
import { UsuarioModule } from '../usuario/usuario.module';
import { ProdutoModule } from '../produto/produto.module';
import { TimeListaResolver } from './shareds/resolvers/time-lista.resolver';

@NgModule({
    declarations: [
        TimeFormComponent,
        TimeDetalheComponent,
        TimeGridComponent,
        TimeCadastroPageComponent,
        TimeGridPageComponent,
        TimeDetalhePageComponent,
        ModalTimeDetalheComponent,
        ModalTimePesquisaComponent,
        TimePesquisaComponent,
    ],

    imports: [
        CommonModule,
        TimeRoutingModule,
        UtilsModule,

        //TABLE
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,

        //FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatSlideToggleModule,

        //MODULOS
        UsuarioModule,
        ProdutoModule,
    ],

    exports: [TimeGridComponent],
    providers: [
        TimeService,
        TimeModalService,
        TimeDetalheResolver,
        TimeGuard,
        TimeListaResolver,
    ],

    entryComponents: [ModalTimeDetalheComponent, ModalTimePesquisaComponent],
})
export class TimeModule { }
