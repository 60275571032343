import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { ContratoTipoReajuste } from '../interfaces/contrato-tipo-reajuste.interface';

@Injectable({
    providedIn: 'root',
})
export class ContratoTipoReajusteService extends ServiceBase<ContratoTipoReajuste> {
    private path = `${environment.server}/tabelareajuste`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(): Observable<Response<Array<ContratoTipoReajuste>>> {
        const url = `${this.path}`;
        return this._http.get<Response<Array<ContratoTipoReajuste>>>(url);
    }
}
