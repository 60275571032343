import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Empresa } from '../../shareds/interfaces/empresa.interface';

@Component({
    selector: 'modal-empresa-cadastro',
    templateUrl: './modal-empresa-cadastro.component.html',
    styleUrls: ['./modal-empresa-cadastro.component.scss'],
})
export class ModalEmpresaCadastroComponent implements OnInit {
    tituloModal: string = 'Cadastro de empresa';

    constructor(
        public dialogRef: MatDialogRef<ModalEmpresaCadastroComponent>,
        @Inject(MAT_DIALOG_DATA) public empresa: Empresa
    ) {}

    ngOnInit() {}

    fechar(): void {
        this.dialogRef.close();
    }
}
