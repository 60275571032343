import { IAuthResponseLoginData } from './../interfaces/auth-response-login.interface';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { IAuth } from '../interfaces/auth.interface';
import { environment } from 'environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { Response } from '@utils/interfaces/http/response.interface';
import { AuthRedefinirSenhaCliente } from '../interfaces/auth-redefinir-senha-cliente.interface';
import { CotacaoClienteService } from 'app/main/cotacao/shared/services/cotacao-cliente.service';
import { catchError, switchMap } from 'rxjs/operators';
import { CotacaoCliente } from 'app/main/cotacao/shared/interfaces/faturamento-cliente.interface';
// import { NotificacaoWebsocketService } from 'app/main/notificacao/shareds/services/notificacao-websocket.service';

@Injectable()
export class AuthService {
    private path = `${environment.server}/auth`;

    constructor(
        private _http: HttpClient,
        private _router: Router,
        private _authLocalStorage: AuthLocalStorageService,
        private _notification: NotificationService,
        private _spinner: NgxSpinnerService,
        private _cotacaoClienteService: CotacaoClienteService
    ) {}

    registrar(): void {}

    login(auth: IAuth): void {
        const url = `${this.path}/login`;
        this._spinner.show();
        this._http.post<Response<IAuthResponseLoginData>>(url, auth).subscribe(
            (authResponse) => {
                this._spinner.hide();
                this._authLocalStorage.deleteSession();
                this._authLocalStorage.setUsuario(
                    authResponse.data.dadosUsuario
                );
                this._authLocalStorage.setToken(authResponse.data.token);
                this._router.navigate(['home']);
            },
            (error: any) => {
                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
    }

    loginCliente(auth: IAuth): void {
        const url = `${this.path}/login/cliente`;
        this._spinner.show();
        this._http.post<Response<IAuthResponseLoginData>>(url, auth).subscribe(
            (authResponse) => {
                this._spinner.hide();
                this._authLocalStorage.deleteSession();
                this._authLocalStorage.setToken(authResponse.data.token);
                this._authLocalStorage.setUsuarioCliente(
                    authResponse.data.dadosUsuario
                );
                this.direcionaAcessoLoginCliente();
            },
            (error: any) => {
                this._spinner.hide();
                this._notification.error(error.error.message);
            }
        );
    }

    alterarSenhaLogado(senha: string): Observable<Response<any>> {
        const url = `${environment.server}/usuario/alterarsenhalogado`;
        return this._http.put<Response<any>>(url, { senha: senha });
    }

    validaTokenEsqueceuSenhaCliente(
        esqueceuSenha: AuthRedefinirSenhaCliente
    ): Observable<Response<any>> {
        return of({ data: { valido: true } });
    }

    recuperarSenha(email: string, recaptcha: any[]): void {
        const url = `${environment.server}/usuario/alterarsenha`;
        this._spinner.show();
        this._http
            .post<Response<any>>(url, { email: email, recaptcha: recaptcha })
            .subscribe(
                () => {
                    this._spinner.hide();
                    this._notification.sucess(
                        'Email de recuperação enviado com sucesso'
                    );
                    this._router.navigateByUrl(
                        `/auth/email-confirmacao/recupera-senha/${email}`
                    );
                },
                (error: any) => {
                    this._spinner.hide();
                    this._notification.error(error.error.message);
                }
            );
    }

    redefinirSenha(
        rederifirSenha: AuthRedefinirSenhaCliente
    ): Observable<Response<any>> {
        const url = `${environment.server}/usuario/redefinirsenha`;
        return this._http.put<Response<any>>(url, rederifirSenha);
    }

    isLogado(): Observable<boolean> {
        // const isLogado = this._authLocalStorage.isLoggedIn()
        // if (!isLogado) return of(false);
        // return this.refrashToken();
        return of(this._authLocalStorage.isLoggedIn());
    }

    refrashToken(): Observable<boolean> {
        const url = `${environment.server}/auth/atualizar-token`;
        return this._http.get<Response<any>>(url).pipe(
            catchError(() => of(false)),
            switchMap((response: any) => {
                this._authLocalStorage.deleteToken();
                this._authLocalStorage.setToken(response.data.token);

                return of(true);
            })
        );
    }

    deslogar(): Observable<boolean> {
        const url = `${this.path}/logout`;
        return new Observable((obs) => {
            this._authLocalStorage.deleteSession();
            this._router.navigate(['auth', 'login']);
        });
    }

    deslogarAdmin(): Observable<boolean> {
        const url = `${this.path}/logout`;
        return new Observable((obs) => {
            this._authLocalStorage.deleteSession();
            this._router.navigate(['auth', 'admin', 'login']);
        });
    }

    validaSenha(senha: string): boolean {
        let isValid = false;
        if (/[0-9a-zA-Z$*&@#]{8,}/.test(senha)) {
            throw new Error(
                'A senha deve conter ao menos 8 dos caracteres mencionados'
            );
        } else if (/(?=.*\d)/.test(senha)) {
            throw new Error('A senha deve conter ao menos uma letra minúscula');
        } else if (/(?=.*\d)/.test(senha)) {
            throw new Error('A senha deve conter ao menos uma letra maiúscula');
        } else if (/(?=.*\d)/.test(senha)) {
            throw new Error(
                'A senha deve conter ao menos um caractere especial'
            );
        } else {
            isValid = true;
        }
        return isValid;
    }

    private direcionaAcessoLoginCliente(): void {
        this._cotacaoClienteService.find().subscribe((result) => {
            let isAcessou = false;
            result.data.forEach((cotacao) => {
                if (cotacao.acessoUsuario === 'acessado') {
                    isAcessou = true;
                }
            });

            if (!isAcessou) {
                if (result.data.length === 1) {
                    const cotacao: CotacaoCliente = result.data[0];
                    if (cotacao.idChecklist) {
                        this._router.navigate([
                            'checklist',
                            cotacao.idChecklist,
                            'inicio',
                        ]);
                    } else {
                        this._router.navigate(['checklist']);
                    }
                } else {
                    this._router.navigate(['checklist']);
                }
            } else {
                this._router.navigate(['home', 'cliente']);
            }
        });
    }
}
