import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { Os } from '../../shareds/interfaces/os.interface';
import { OsService } from '../../shareds/services/os.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OsContato } from '../../shareds/interfaces/os-contato.interface';
import { OsContatoTipo } from '../../shareds/enum/os-contato-tipo.enum';

@Component({
    selector: 'os-contato',
    templateUrl: './os-contato.component.html',
    styleUrls: ['./os-contato.component.scss'],
})
export class OsContatoComponent implements OnInit {
    @Output() selecionado = new EventEmitter();
    menuSelecionado: string = 'CONTATO';

    private _os: Os;

    public isEdit: OsContatoTipo[] = [];
    public form: {
        [OsContatoTipo.BANCO]?: FormGroup;
        [OsContatoTipo.EMPRESA]?: FormGroup;
    } = {};
    public contatosBanco: OsContato[] = [];
    public contatosEmpresa: OsContato[] = [];
    public displayedColumns: string[] = [];

    @Input() set os(os: Os) {
        this._os = os;
    }

    CELLPHONE = '(00) 0 0000-0000';
    LANDLINE = '(00) 0000-0000';

    phoneMask = this.LANDLINE;
    phoneNumber = '';
    previusLength = 0;

    constructor(
        private osService: OsService,
        private _notification: NotificationService,
        private spinner: SpinnerService,
        private formBuilder: FormBuilder
    ) {
        this.displayedColumns = this.configDisplayedColumns().map(
            ({ chave }) => chave
        );
        [OsContatoTipo.BANCO, OsContatoTipo.EMPRESA].forEach(
            (tipo) =>
                (this.form[tipo] = formBuilder.group({
                    id: [''],
                    nome: ['', Validators.required],
                    email: ['', [Validators.required, Validators.email]],
                    telefone: ['', Validators.required],
                }))
        );
    }

    ngOnInit() {
        this.getContatos();
    }

    onPhoneChanged() {
        this.phoneMask = this.LANDLINE;
        if (
            this.phoneNumber &&
            this.phoneNumber.length >= 10 &&
            this.phoneMask >= this.LANDLINE &&
            this.previusLength >= 10
        ) {
            this.phoneMask = this.CELLPHONE;
        }

        this.previusLength = this.phoneNumber ? this.phoneNumber.length : 0;
    }

    private getContatos() {
        this.spinner.show();
        this.osService.getContatos(this._os).subscribe((data) => {
            this.contatosBanco = data.data.filter(
                ({ tipo }) => OsContatoTipo.BANCO === tipo
            );
            this.contatosEmpresa = data.data.filter(
                ({ tipo }) => OsContatoTipo.EMPRESA === tipo
            );
            this.spinner.hide();
        });
    }

    limpar(tipo: OsContatoTipo) {
        this.isEdit = this.isEdit.filter(item => item === tipo);
        this.form[tipo].reset();
    }

    salvar(tipo: OsContatoTipo) {
        const form = this.form[tipo]
        const id = form.get('id').value;
        const payload: OsContato = {
            nome: form.get('nome').value,
            email: form.get('email').value,
            telefone: form.get('telefone').value,
            tipo,
            id: id || undefined,
        };
        this.spinner.show();
        this.osService[id ? 'atualizarContato' : 'criarContato'](
            this._os,
            payload
        ).subscribe(
            () => {
                this.limpar(tipo);
                this.getContatos();
                this.spinner.hide();
            },
            (error) => {
                this._notification.error(
                    error.error.message,
                    'Não foi possível salvar.'
                );
                this.spinner.hide();
            }
        );
    }

    editar(tipo: OsContatoTipo, element: OsContato) {
        this.isEdit.push(tipo);
        this.form[tipo].patchValue({
            id: element.id,
            nome: element.nome,
            email: element.email,
            telefone: element.telefone,
        });
    }

    excluir(id: number) {
        this.spinner.show();
        this.osService.excluirContato(this._os, id).subscribe(() => {
            this.getContatos();
        });
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'acoes', label: 'Ações' },
            { chave: 'nome', label: 'Nome' },
            { chave: 'email', label: 'Email' },
            { chave: 'telefone', label: 'Telefone' },
        ];
    }

    selecionar(menu: string) {
        this.menuSelecionado = menu;
        this.selecionado.emit(menu);
    }
}
