import { Component, Injector, Input, OnInit } from '@angular/core';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { ChecklistDadosEmpresa } from '../../shared/interfaces/checklist-dados-empresa.interface';
import { ChecklistDadosEmpresaRelacionamentoService } from '../../shared/services/checklist-dados-empresa-relacionamento.service';

@Component({
    selector: 'checklist-relacionamento-detalhe',
    templateUrl: './checklist-relacionamento-detalhe.component.html',
    styleUrls: ['./checklist-relacionamento-detalhe.component.scss'],
})
export class ChecklistRelacionamentoDetalheComponent extends ComponentBase implements OnInit {
    @Input() data: any;
    relacionamentos: Array<ChecklistDadosEmpresa> = [];
    relacionamentosFull: Array<ChecklistDadosEmpresa> = [];

    constructor(
        private _checklistDadosEmpresaRelacionamentoService: ChecklistDadosEmpresaRelacionamentoService,
        private _injector: Injector
    ) {
        super(_injector);
    }

    ngOnInit() {
        this.getRelacionamentos();
    }

    getRelacionamentos(): void {
        this._spinner.show();
        this._checklistDadosEmpresaRelacionamentoService
            .findRelacionamento(this.data.checklist.id)
            .subscribe(
                (dadosEmpresa) => {
                    this._spinner.hide();
                    this.relacionamentosFull = [];
                    dadosEmpresa.data.forEach((d) => {
                        d.banco = this.data.bancos.find(
                            (b) => b.id === d.idBanco
                        );
                        d.empresa = this.data.empresas.find(
                            (e) => e.id === d.idEmpresa
                        );
                        this.relacionamentosFull.push(d);
                    });
                    if (!('origen' in this.data.relacionamento) || (this.data.relacionamento.origen != 'produto-financeiro')) {
                        this.data.relacionamento.checklistRelacionamentosIds.forEach(
                            (idRelacionamento) => {
                                this.relacionamentosFull.forEach((rel, idxFull) => {
                                    if (
                                        rel.idChecklistRelacionamento ===
                                        idRelacionamento
                                    ) {
                                        this.relacionamentos.push(rel);
                                    }
                                });
                            }
                        );
                    } else {
                        this.data.relacionamento.contas.forEach(
                            (idRelacionamento) => {
                                this.relacionamentosFull.forEach((rel, idxFull) => {
                                    if (
                                        rel.idChecklistRelacionamento ===
                                        idRelacionamento
                                    ) {
                                        this.relacionamentos.push(rel);
                                    } else if (
                                        rel.id ===
                                        idRelacionamento.id
                                    ) {
                                        this.relacionamentos.push(rel);
                                    }

                                });
                            }
                        );
                    }


                },
                (error) => this.error(error)
            );
    }
}
