import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChecklistConfiguracao } from '../interfaces/checklist-configuracao.interface';
import { ChecklistConfiguracaoService } from '../services/checklist-configuracao.service';

@Injectable()
export class ChecklistCondiguracaoDetalheResolver
    implements Resolve<ChecklistConfiguracao> {
    constructor(
        private _checklistConfiguracaoService: ChecklistConfiguracaoService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const idChecklist = route.params['id'];
        return this._checklistConfiguracaoService
            .find({ idChecklist: idChecklist })
            .pipe(
                map((result: any) =>
                    result.data ? result.data.checklistConfiguracaos : null
                )
            );
    }
}
