import { ChangeDetectorRef, Component, EventEmitter, Injector, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Time } from '../../shareds/interfaces/time.interface';
import { TimeService } from '../../shareds/services/time.service';

@Component({
    selector: 'time-pesquisa',
    templateUrl: './time-pesquisa.component.html',
    styleUrls: ['./time-pesquisa.component.scss'],
})
export class TimePesquisaComponent
    extends GridBase<Time, FiltroBase, TimeService>
    implements OnChanges, OnInit {
    @Output() selecionado = new EventEmitter();

    constructor(
        protected _service: TimeService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {});
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'nome', label: '' },
            { chave: 'produto', label: '' },
        ];
    }

    selecionar(time: Time) {
        this.selecionado.emit(time);
    }
}

