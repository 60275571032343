import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RelacionamentoAberturaGuard } from './relacionamento-abertura.guard';
import { RelacionamentoAberturaGridPageComponent } from './pages/relacionamento-abertura-grid-page/relacionamento-abertura-grid-page.component';
import { RelacionamentoAberturaDetalhePageComponent } from './pages/relacionamento-abertura-detalhe-page/relacionamento-abertura-detalhe-page.component';
import { ProjetoStatusListaResolver } from '../projeto/shareds/resolvers/projeto-status-lista.resolver';
import { BancoListaResolver } from '../banco/shareds/resolvers/banco-lista.resolver';
import { TimeListaResolver } from '../time/shareds/resolvers/time-lista.resolver';
import { UsuarioListaResolver } from '../usuario/resolvers/usuario-lista.resolver';
import { OsStatusListaResolver } from '../os/shareds/resolvers/os-status.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [RelacionamentoAberturaGuard],
        canLoad: [RelacionamentoAberturaGuard],
        resolve: {
            listaStatusOs: OsStatusListaResolver,
            listaProjetoStatus: ProjetoStatusListaResolver,
            listaUsuarios: UsuarioListaResolver,
            listaTimes: TimeListaResolver,
            listaBancos: BancoListaResolver,
        },
        component: RelacionamentoAberturaGridPageComponent,
    },
    {
        path: ':id',
        canActivate: [RelacionamentoAberturaGuard],
        canLoad: [RelacionamentoAberturaGuard],
        resolve: {
        },
        component: RelacionamentoAberturaDetalhePageComponent,
    },

    { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RelacionamentoAberturaRoutingModule { }
