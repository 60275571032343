import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { ChecklistRelacionamentoProdutoFinancerio } from '../interfaces/checklist-relacionamento.interface';
import { FiltroChecklistRelacionamento } from '../interfaces/filtro-checklist-relacionamento.interface';
import { ChecklistRelacionamentoProdutoFinancerioRequest, ChecklistRelacionamentoRequest } from '../interfaces/checklist-relacionamentoRequest.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistRelacionamentoProdutoFinanceiroService extends ServiceBase<ChecklistRelacionamentoProdutoFinancerio> {
    private path = `${environment.server}/checklist`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroChecklistRelacionamento
    ): Observable<Response<Array<ChecklistRelacionamentoProdutoFinancerio>>> {
        const idChecklist = filtro.idChecklist;
        delete filtro.modulo;
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/${idChecklist}/produto-financeiro${params}`;
        return this._http.get<Response<Array<ChecklistRelacionamentoProdutoFinancerio>>>(url);
    }

    findListProducts(
        filtro?: FiltroChecklistRelacionamento
    ): Observable<Response<Array<ChecklistRelacionamentoProdutoFinancerio>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/produto-financeiro${params}`;
        return this._http.get<Response<Array<ChecklistRelacionamentoProdutoFinancerio>>>(url);
    }

    findByChecklistAndId(idChecklist: number, idProdutoFinanceiroItem: number) {
        const url = `${this.path}/${idChecklist}/produto-financeiro/${idProdutoFinanceiroItem}`;
        return this._http.get<Response<ChecklistRelacionamentoProdutoFinancerio>>(url);
    }

    add(relacionamento: ChecklistRelacionamentoProdutoFinancerioRequest): Observable<Response<ChecklistRelacionamentoRequest>> {
        const url = `${this.path}/${relacionamento.checklist}/produto-financeiro`;
        return this._http.post<Response<ChecklistRelacionamentoRequest>>(url, relacionamento);
    }

    update(relacionamento: ChecklistRelacionamentoProdutoFinancerioRequest): Observable<Response<ChecklistRelacionamentoRequest>> {
        const url = `${this.path}/${relacionamento.checklist}/produto-financeiro/${relacionamento.id}`;
        return this._http.put<Response<ChecklistRelacionamentoRequest>>(url, relacionamento);
    }

    download(): Observable<any> {
        const url = `${this.path}/modelo-produto-financeiro`;
        return this._http.get(url);
    }

    upload(id: number, file: File): Observable<any> {
        const url = `${this.path}/${id}/importacao-produto-financeiro`;
        const formData = new FormData();
        formData.set('arquivo', file, file.name);

        return this._http.post(url, formData, {
            observe: 'events',
            reportProgress: true,
        });
    }

    report(id: number): Observable<any> {
        const url = `${this.path}/relatorio/checklist-produto-financeiro?id=${id}`;
        return this._http.get(url);
    }

    delete(checklistId, produtoFinanceiroId): Observable<any> {
        const url = `${this.path}/${checklistId}/produto-financeiro/${produtoFinanceiroId}`;
        return this._http.delete(url);
    }
}
