import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Contrato } from '../../shared/interfaces/contrato.interface';

@Component({
  selector: 'modal-contrato-add-aditivo',
  templateUrl: './modal-contrato-add-aditivo.component.html',
  styleUrls: ['./modal-contrato-add-aditivo.component.scss']
})
export class ModalContratoAddAditivoComponent implements OnInit {
  tituloModal: string = 'Aditivo ao Contrato';

  constructor(
    public dialogRef: MatDialogRef<ModalContratoAddAditivoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) { }

  ngOnInit() { }

  fechar(): void {
    this.dialogRef.close();
  }

  selecionado(contrato: Contrato) {
    this.dialogRef.close(contrato);
  }

}
