import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { FaturamentoHistorico } from '../../shared/interfaces/faturamento-historico.interface';
import { FaturamentoHistoricoService } from '../../shared/services/faturamento-historico.service';

@Component({
    selector: 'faturamento-historico-detalhe',
    templateUrl: './faturamento-historico-detalhe.component.html',
    styleUrls: ['./faturamento-historico-detalhe.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class FaturamentoHistoricoDetalheComponent implements OnChanges, OnInit {
    @Input() historico: FaturamentoHistorico = {};

    constructor(
        protected _service: FaturamentoHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) {}

    ngOnInit(): void {}

    ngOnChanges(): void {}
}
