import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Contrato } from '../interfaces/contrato.interface';
import { FiltroContrato } from '../interfaces/filtro-contrato.interface';

@Injectable({
  providedIn: 'root'
})
export class PreContratoService extends ServiceGridBase<Contrato, FiltroContrato>{

  private path = `${environment.server}/pre-contrato`;

  constructor(private _http: HttpClient) {
    super();
  }

  find(filtro: FiltroContrato): Observable<Response<Array<Contrato>>> {
    const params: string = this.toGetParams(filtro);
    const url = `${this.path}${params}`;
    return this._http.get<Response<Array<Contrato>>>(url);
  }

  findById(id: number | string): Observable<Response<Contrato>> {
    const url = `${this.path}/${id}`;
    return this._http.get<Response<Contrato>>(url);
  }

  update(contrato: Contrato): Observable<Response<Contrato>> {
    const url = `${this.path}/${contrato.id}`;
    return this._http.put<Response<Contrato>>(url, contrato);
  }

  addAditivo(contrato: { id: any; preContratoId: number; }) {
    const url = `${environment.server}/contrato/${contrato.id}/adicionar-aditivo`;
    delete contrato.id;
    return this._http.post<Response<any>>(url, contrato);
  }
}
