import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FiltroProjetoHistorico } from '../../shareds/interfaces/filtro-projeto-historico.interface';
import { ProjetoHistorico } from '../../shareds/interfaces/projeto-historico.interface';
import { Projeto } from '../../shareds/interfaces/projeto.interface';
import { ProjetoHistoricoService } from '../../shareds/services/projeto-historico.service';
import { ProjetoModalService } from '../../shareds/services/projeto-modal.service';

@Component({
    selector: 'projeto-historico',
    templateUrl: './projeto-historico.component.html',
    styleUrls: ['./projeto-historico.component.scss'],
})
export class ProjetoHistoricoComponent
    extends GridBase<
    ProjetoHistorico,
    FiltroProjetoHistorico,
    ProjetoHistoricoService
    >
    implements OnChanges, OnInit {
    private _projeto: Projeto;
    get projeto(): Projeto {
        return this._projeto;
    }
    @Input() set projeto(projeto: Projeto) {
        this._projeto = projeto;
        this.filtro.idProjeto = projeto.id;
        this.initGrid(this.filtro || {});
    }

    constructor(
        protected _service: ProjetoHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _modalProjetoHistoricoService: ProjetoModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void { }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'data', label: '' },
            { chave: 'item', label: '' },
            { chave: 'acao', label: '' },
            { chave: 'usuario', label: '' },
        ];
    }

    selecionar(projetoHistorico: ProjetoHistorico) {
        this._modalProjetoHistoricoService
            .historicoDetalhe(projetoHistorico)
            .subscribe(() => { });
    }
}
