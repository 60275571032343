import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class ContratoGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [
            { path: '/contrato', role: Role.CONTRATO_LISTAR },
            { path: '/contrato/[0-9]+', role: Role.CONTRATO_EXIBIR },
            { path: '/contrato/pre-contrato/[0-9]+', role: Role.CONTRATO_EXIBIR },
        ];
    }
}
