import { Component, Injector, OnInit } from '@angular/core';
import { SimNao } from '@utils/interfaces/shareds/sim-nao.interface';
import { ChecklistConectividadeBase } from '../checklist-conectividade-base';
import { ChecklistConectividadesSfpt } from '../shareds/interfaces/checklist-conectividades-sfpt.interface';

@Component({
  selector: 'checklist-conectividades-sfpt',
  templateUrl: './checklist-conectividades-sfpt.component.html',
  styleUrls: ['./checklist-conectividades-sfpt.component.scss']
})
export class ChecklistConectividadesSfptComponent extends ChecklistConectividadeBase<ChecklistConectividadesSfpt> implements OnInit {
  public servidorFinnetSftp: string = null;

  public PORT_MASK = '99990000';

  public listaSimNao: Array<SimNao> = [
    { codigo: 'SERVIDOR_FINNET', descricao: 'Servidor Finnet' },
    { codigo: 'SERVIDOR_PROPRIO', descricao: 'Servidor próprio' }
  ]

  constructor(
    protected _injector: Injector
  ) {
    super(_injector)
  }

  ngOnInit() {
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      servidorFinnetSftp: [],
      caixaPostal: [],
      caixaPostalSenha: [],
      sftpHost: [],
      sftpPorta: [],
      sftpUsuario: [],
      sftpSenha: [],
      sftpDiretorioRetorno: [],
      sftpDiretorioRemessa: [],
      sftpNomenclaturaRetorno: [],
    }
  }

  protected setForm(data: ChecklistConectividadesSfpt) {
    const servidorFinnetSftp = this.listaSimNao.find(val => val.codigo === data.servidorFinnetSftp)
    this.servidorFinnetSftp = data.servidorFinnetSftp;
    this.form.patchValue({
      servidorFinnetSftp: servidorFinnetSftp,
      caixaPostal: data.caixaPostal,
      caixaPostalSenha: data.caixaPostalSenha,
      sftpHost: data.sftpHost,
      sftpPorta: data.sftpPorta,
      sftpUsuario: data.sftpUsuario,
      sftpSenha: data.sftpSenha,
      sftpDiretorioRetorno: data.sftpDiretorioRetorno,
      sftpDiretorioRemessa: data.sftpDiretorioRemessa,
      sftpNomenclaturaRetorno: data.sftpNomenclaturaRetorno,
    })
  }

  private getForm() {
    const servidorFinnetSftpVal = this.form.get('servidorFinnetSftp').value
    const data: ChecklistConectividadesSfpt = {
      servidorFinnetSftp: servidorFinnetSftpVal.codigo,
      caixaPostal: this.form.get('caixaPostal').value ? this.form.get('caixaPostal').value : undefined,
      caixaPostalSenha: this.form.get('caixaPostalSenha').value ? this.form.get('caixaPostalSenha').value : undefined,
      sftpHost: this.form.get('sftpHost').value,
      sftpPorta: this.form.get('sftpPorta').value,
      sftpUsuario: this.form.get('sftpUsuario').value,
      sftpSenha: this.form.get('sftpSenha').value,
      sftpDiretorioRetorno: this.form.get('sftpDiretorioRetorno').value,
      sftpDiretorioRemessa: this.form.get('sftpDiretorioRemessa').value,
      sftpNomenclaturaRetorno: this.form.get('sftpNomenclaturaRetorno').value,
    }

    this.values.emit(data);
  }

  changeServidorFinnetSftp() {
    const servidorFinnetSftp = this.form.get('servidorFinnetSftp').value
    this.servidorFinnetSftp = servidorFinnetSftp.codigo;
    this.limpaSubForm();
    this.getForm();
  }

  limpaSubForm() {
    this.form.get('caixaPostal').reset();
    this.form.get('caixaPostalSenha').reset();
    this.form.get('sftpHost').reset();
    this.form.get('sftpPorta').reset();
    this.form.get('sftpUsuario').reset();
    this.form.get('sftpSenha').reset();
    this.form.get('sftpDiretorioRetorno').reset();
    this.form.get('sftpDiretorioRemessa').reset();
    this.form.get('sftpNomenclaturaRetorno').reset();
  }

}
