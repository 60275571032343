import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ModalChecklistPesquisaComponent } from '../../modal/modal-pesquisa-checklist/modal-checklist-pesquisa.component';
import { ChecklistPesquisa } from '../interfaces/checklist-pesquisa.interface';

@Injectable({
    providedIn: "root",
})
export class ModalChecklistPesquisaService {
    constructor(public dialog: MatDialog) {}

    checklistPesquisa(data: ChecklistPesquisa) {
        const dialogRef = this.dialog.open(ModalChecklistPesquisaComponent, {
            width: "90%",
            data: data,
        });

        return dialogRef.afterClosed();
    }
}
