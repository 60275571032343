import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { Projeto } from 'app/main/projeto/shareds/interfaces/projeto.interface';
import { OsProjetoRequest } from '../interfaces/os-projeto-request.interface';

@Injectable({
    providedIn: 'root',
})
export class OsProjetoService extends ServiceBase<Projeto> {
    private path = `${environment.server}/os`;

    constructor(private _http: HttpClient) {
        super();
    }

    findByIdOs(idOs: number) {
        const url = `${this.path}/${idOs}/projetos`;
        return this._http.get<Response<Array<Projeto>>>(url);
    }

    save(osProjeto: OsProjetoRequest): Observable<Response<OsProjetoRequest>> {
        if (osProjeto.os && osProjeto.os.id) {
            return this.saveProjeto(osProjeto);
        } else if (osProjeto.idsOs && osProjeto.idsOs.length > 0) {
            return this.saveListaProjeto(osProjeto);
        }
    }

    saveProjeto(
        osProjeto: OsProjetoRequest
    ): Observable<Response<OsProjetoRequest>> {
        const url = `${this.path}/${osProjeto.os.id}/vincular-projetos`;
        return this._http.put<Response<OsProjetoRequest>>(url, osProjeto);
    }

    saveListaProjeto(
        osProjeto: OsProjetoRequest
    ): Observable<Response<OsProjetoRequest>> {
        const url = `${this.path}/vincular-lote-projetos`;
        const oss = [];
        osProjeto.idsOs.forEach((id) => {
            oss.push({ id: id });
        });
        const projetos = [];
        osProjeto.projetos.forEach((projeto) => {
            projetos.push({ id: projeto.id });
        });
        return this._http.put<Response<OsProjetoRequest>>(url, {
            projetos: projetos,
            oss: oss,
        });
    }
}
