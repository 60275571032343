import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class FaturamentoGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [
            { path: '/faturamento', role: Role.FATURAMENTO_LISTAR },
            { path: '/faturamento/cadastro/[0-9]+', role: Role.FATURAMENTO_CADASTRO },
            { path: '/faturamento/cadastro/[0-9]+/visualizar', role: Role.FATURAMENTO_VISUALIZAR },
            { path: '/faturamento/recorrente/[0-9]+', role: Role.FATURAMENTO_CADASTRO },
            { path: '/faturamento/recorrente/[0-9]+/visualizar', role: Role.FATURAMENTO_VISUALIZAR },
        ];
    }
}
