import { Component, OnInit } from '@angular/core';
import { FiltroFaturamento } from '../../shared/interfaces/filtro-faturamento.interface';

@Component({
    selector: 'faturamento-recorrente-detalhe-page',
    templateUrl: './faturamento-recorrente-detalhe-page.component.html',
    styleUrls: ['./faturamento-recorrente-detalhe-page.component.scss'],
})
export class FaturamentoRecorrenteDetalhePageComponent implements OnInit {
    filtro: FiltroFaturamento;

    constructor() { }

    ngOnInit() { }

    filtrar(filtro: FiltroFaturamento) {
        this.filtro = Object.assign({}, filtro);
    }
}
