import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BancoHistorico } from '../interfaces/banco-historico.interface';
import { FiltroBancoHistorico } from '../interfaces/filtro-banco-historico.interface';

@Injectable({
  providedIn: 'root'
})
export class BancoHistoricoService extends ServiceGridBase<
BancoHistorico,
FiltroBancoHistorico
> {
  private path: string = `${environment.server}/banco/historico`;

  constructor(private _http: HttpClient) {
    super();
  }

  find(
    filtro?: FiltroBancoHistorico
  ): Observable<Response<Array<BancoHistorico>>> {
    const params: string = filtro
      ? this.toGetParams(filtro, ['idBanco'])
      : '';
    const url = `${this.path}/${filtro.idBanco}${params}`;
    return this._http.get<Response<Array<BancoHistorico>>>(url);
  }
}
