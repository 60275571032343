import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OsCategoria } from '../interfaces/os-categoria.interface';
import { OsCategoriaService } from '../services/os-categoria.service';

@Injectable()
export class OsCategoriaListaResolver implements Resolve<OsCategoria> {
    constructor(private _osCategoriaService: OsCategoriaService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._osCategoriaService
            .find({})
            .pipe(map((result) => result.data));
    }
}
