import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { CotacaoDocumentoDownload } from '../interfaces/cotacao-documento-download.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';

@Injectable({
    providedIn: 'root',
})
export class CotacaoDocumentoDownloadService extends ServiceBase<CotacaoDocumentoDownload> {
    private path: string = `${environment.server}/cotacaodocumento`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        idCotacao: number
    ): Observable<Response<Array<CotacaoDocumentoDownload>>> {
        const url = `${environment.server}/cotacao/${idCotacao}/documento`;
        return this._http.get<Response<Array<CotacaoDocumentoDownload>>>(url);
    }

    download(idDocumento: number): Observable<any> {
        const url = `${this.path}/${idDocumento}`;
        return this._http.get<Response<CotacaoDocumentoDownload>>(url);
    }

    delete(idDocumento): Observable<any> {
        const url = `${this.path}/${idDocumento}`;
        return this._http.delete(url);
    }
}
