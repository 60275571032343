import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { Perfil } from 'app/main/acl/perfil/shareds/interfaces/perfil.interface';
import { PerfilService } from 'app/main/acl/perfil/shareds/services/perfil.service';
import { Usuario } from '../../shared/interfaces/usuario.interface';
import { UsuarioService } from '../../shared/services/usuario.service';

@Component({
    selector: 'usuario-form-perfil',
    templateUrl: './usuario-form-perfil.component.html',
    styleUrls: ['./usuario-form-perfil.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UsuarioFormPerfilComponent extends ComponentBase implements OnInit, OnChanges {
    @Input() usuario: Usuario;
    @Output() saved = new EventEmitter<Usuario>();
    perfisFull: Array<Perfil>;
    perfis: Array<Perfil>;
    perfil: Perfil;

    constructor(
        private _usuarioService: UsuarioService,
        private _perfilService: PerfilService,
        private _injector: Injector
    ) {
        super(_injector);
        this.perfis = [];
        this.perfisFull = [];
        this.perfil = {};
    }

    ngOnInit(): void { }

    ngOnChanges(): void {
        if (this.usuario) {
            if (this.usuario && this.usuario.tipo) {
                this._perfilService
                    .find({ tipo: this.usuario.tipo.id })
                    .subscribe(
                        (perfis) => {
                            this.perfisFull = perfis.data;
                        },
                        (error) => {
                            console.error(
                                'Erro ao retornar os dados do usuário'
                            );
                        }
                    );
            }
            this.atualizaListaPerfil(this.usuario.perfis || []);
        }
    }

    addPerfil() {
        if (this.perfil && this.perfil.id) {
            const perfil: Perfil = this.perfil;
            if (this.validaPerfilAdicionado(perfil)) {
                this._notification.info('Perfil já inserido');
                this.perfil = null;
                return;
            }
            if (
                perfil.tipo &&
                this.usuario.tipo &&
                perfil.tipo.id != this.usuario.tipo.id
            ) {
                this._notification.warning(
                    'Não é possíve adicionar o perfil diferente do usuário'
                );
                this.perfil = null;
                return;
            }
            this._confirmation
                .confirm({
                    titulo: `Deseja adicionar o perfil ${perfil.nome}?`,
                })
                .subscribe((result) => {
                    if (result) {
                        this.usuario.perfis.push(perfil);
                        this.perfil = null;
                        this.atualizaListaPerfil(this.usuario.perfis);
                    }
                });
        }
    }

    removerPerfil(perfil): void {
        this._confirmation
            .confirm({ titulo: `Deseja remover o perfil ${perfil.nome}?` })
            .subscribe((result) => {
                if (result) {
                    this.usuario.perfis.splice(
                        this.usuario.perfis.findIndex(
                            (p) => p.id === perfil.id
                        ),
                        1
                    );
                    this.atualizaListaPerfil(this.usuario.perfis);
                }
            });
    }

    salvar(): void {
        this._usuarioService.alterarPerfil(this.usuario).subscribe(
            (usuario) => {
                this.sucess(
                    `Perfis do usuário ${this.usuario.nome} foram salvos com sucesso!`
                );
                this.saved.emit(usuario.data);
            },
            (error) => {
                this.error(error);
            }
        );
    }

    private atualizaListaPerfil(perfis: Array<Perfil> = []): void {
        this.perfis = [];
        this.perfisFull.forEach((p) => {
            let isPerfil: boolean = !(perfis.length > 0);
            perfis.forEach((pf) => {
                if (p.id === pf.id) {
                    isPerfil = true;
                }
            });

            if (!isPerfil) {
                this.perfis.push(p);
            }
        });
    }

    private validaPerfilAdicionado(perfil: Perfil): boolean {
        return !!this.usuario.perfis.find((p) => p.id == perfil.id);
    }
}
