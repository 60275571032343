import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChecklistConfiguracaoDsname } from '../interfaces/checklist-configuracao-dsname.interface';
import { ChecklistConfiguracaoDsnameService } from '../services/checklist-configuracao-dsname.service';

@Injectable()
export class ChecklistCondiguracaoDsnameLimiteResolver
    implements Resolve<ChecklistConfiguracaoDsname> {
    constructor(
        private _checklistConfiguracaoDsnameService: ChecklistConfiguracaoDsnameService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._checklistConfiguracaoDsnameService
            .findLimite()
            .pipe(map((result: any) => result.data));
    }
}
