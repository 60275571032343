import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { ConfirmationService } from '@utils/utils/confirmation/confirmation.service';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { GmudDocumentoDownload } from '../../shared/interfaces/gmud-documento-download.interface';
import { GmudDocumentoUpload } from '../../shared/interfaces/gmud-documento-upload.interface';
import { Gmud } from '../../shared/interfaces/gmud.interface';
import { GmudDocumentoDownloadService } from '../../shared/services/gmud-documento-download.service';
import { GmudDocumentoUploadService } from '../../shared/services/gmud-documento-upload.service';

@Component({
  selector: 'gmud-aba-documentacao',
  templateUrl: './gmud-aba-documentacao.component.html',
  styleUrls: ['./gmud-aba-documentacao.component.scss']
})
export class GmudAbaDocumentacaoComponent extends ComponentBase implements OnInit {

  arquivosDownloads: Array<GmudDocumentoDownload> = [];
  gmud: Gmud;

  arquivo: GmudDocumentoUpload;
  @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
  files: Array<any>;
  uploaded: Boolean;

  constructor(
    private _gmudDocumentoUploadService: GmudDocumentoUploadService,
    private _gmudDocumentoDownloadService: GmudDocumentoDownloadService,
    private _injector: Injector
  ) {
    super(_injector);
  }

  ngOnInit() {
    this._route.data.subscribe(data => {
      if (data.gmud) {
        this.gmud = data.gmud;
        this.atualizaListaDownloads();
      }
    })

  }

  // ==========================================
  //                  UPLOAD
  // ==========================================
  onFileDropped($event): void {
    this.prepareFile($event);
  }

  fileBrowseHandler(files): void {
    this.prepareFile(files);
  }

  deleteFile(file, i): void {
    if (file.progress > 0 && file.progress < 100) {
      return;
    }
    this.files.splice(i, 1);
  }

  deleteFiles(): void {
    this.files = null
  }

  upload(): void {
    this.uploaded = true
    if (this.gmud.id) {
      this.files.forEach((file, i) => {
        this._gmudDocumentoUploadService
          .upload(this.gmud.id, file)
          .subscribe(
            (event: HttpEvent<any>) => {
              if (event.type === HttpEventType.Response) {
                this.sucess('Upload realizado com sucesso!');

                if (this.files) {
                  if (this.files.length >= i + 1) {
                    this.uploaded = false
                    this.deleteFiles();
                    this.atualizaListaDownloads();
                  }
                }
              } else if (
                event.type === HttpEventType.UploadProgress
              ) {
                file.progress = Math.round(
                  (event.loaded * 100) / event.total
                );
              }
            },
            (error) => {
              this._notification.error('Erro no upload do arquivo!');
            }
          );
      });
    }
  }

  prepareFile(files: FileList): void {
    this.uploaded = false
    this.files = Array.from(files);
    this.files.forEach(file => {
      file.progress = 0;
    })

    this.fileDropEl.nativeElement.value = '';
  }

  formatBytes(bytes, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (
      parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
    );
  }
  // ==========================================
  //                  DOWNLOAD
  // ==========================================
  download(arquivoDownload: GmudDocumentoDownload): void {
    this._spinner.show();
    this._gmudDocumentoDownloadService.download(arquivoDownload.id).subscribe(
      (result) => {
        DownloadUtil.base64(
          result.data.arquivo,
          result.data.name,
          result.data.type
        );
        this._spinner.hide();
      },
      (error) => this.error(error)
    );
  }

  remover(arquivoDownload: GmudDocumentoDownload): void {
    this._confirmation
      .confirm({ titulo: 'Deseja excluir esse arquivo?' })
      .subscribe((result) => {
        if (result) {
          this._spinner.show();
          this._gmudDocumentoDownloadService
            .delete(arquivoDownload.id)
            .subscribe(
              () => {
                this._spinner.hide();
                this.sucess('Arquivo removido com sucesso!');
                this.atualizaListaDownloads();
              },
              (error) => this.error(error)
            );
        }
      });
  }

  private atualizaListaDownloads() {
    this._spinner.show();
    this._gmudDocumentoDownloadService.find(this.gmud.id).subscribe(
      (arquivos) => {
        this.arquivosDownloads = arquivos.data;
        this._spinner.hide();
      },
      (error) => this.error(error)
    );
  }
}

