import { Component, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { FaturamentoParcela, FaturamentoParcelaRequest } from '../../shared/interfaces/faturamento-parcela.interface';
import { FaturamentoProduto } from '../../shared/interfaces/faturamento-produto.interface';
import { Faturamento, FaturamentoRequest } from '../../shared/interfaces/faturamento.interface';
import { FaturamentoService } from '../../shared/services/faturamento.service';

@Component({
    selector: 'faturamento-faturar-aba',
    templateUrl: './faturamento-faturar-aba.component.html',
    styleUrls: ['./faturamento-faturar-aba.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class FaturamentoFaturarAbaComponent extends FormBase implements OnInit {
    public faturamento: Faturamento = {};
    public faturamentoProdutos: Array<FaturamentoProduto> = [];
    public faturamentoParcelas: Array<FaturamentoParcelaRequest> = [];
    public itensValores: any = {}
    public totalFaturamento: number = 0;
    public isEdit: boolean = true;
    public isView: boolean = false;

    public listaFaturamentoItemNota: Array<FaturamentoParcela> = [];
    public saldoEmitir: number = 0;
    public faturamentoRequest: FaturamentoRequest;

    constructor(
        injector: Injector,
        protected _faturamentoService: FaturamentoService
    ) {
        super(injector, FaturamentoFaturarAbaCombos.combos());
    }

    ngOnInit() {
        this._route.url.subscribe(params => {
            let isView = false;
            params.forEach(param => {
                if (param.path === 'visualizar') this.isView = true;
            })

            this.isEdit = !isView

        })

        this._route.data.subscribe((data) => {
            this.faturamento = data.faturamento;
            this.faturamentoProdutos = data.faturamentoProdutos;
            this.listaFaturamentoItemNota = data.faturamentoParcelas;

            this.optionList.listaStatus = data.listaStatus;
            this.optionList.listaStatusFinanceiro = data.listaStatusFinanceiro;
            this.optionList.listaEmissor = data.listaEmissor;
            this.optionList.listaTipoCobranca = data.listaTipoCobranca;
            this.optionList.listaParcelar = data.listaParcelar;
            this.optionList.listaTipoFaturamento = data.listaTipoFaturamento;
            this.optionList.listaStatusNota = data.listaStatusNota;

            if (this.faturamento) {
                this.setForm(this.faturamento);
            }

            this.refreshOptionsConfig();
        });
    }

    protected crateForm(): { [key: string]: any } {
        return {
            contrato: [],
            status: [],
            parcelamento: [],
            statusFinanceiro: ['', Validators.required],
            emissor: ['', Validators.required],
            tipoCobranca: ['', Validators.required],
            parcelar: ['', Validators.required],
            saldoEmitir: ['', Validators.required],
            valorSetup: ['', Validators.required],
            tipoFaturamento: [],
        };
    }

    getForm(): FaturamentoRequest {
        const statusFinanceiro = this.form.get('statusFinanceiro').value;
        const emissor = this.form.get('emissor').value;
        const tipoCobranca = this.form.get('tipoCobranca').value;
        const parcelar = this.form.get('parcelar').value;
        const tipoFaturamento = this.form.get('tipoFaturamento').value;


        const faturamentoRequest = Object.assign({}, {
            idStatusFinanceiro: statusFinanceiro ? statusFinanceiro.id : undefined,
            idEmissor: emissor ? emissor.id : undefined,
            idTipoCobranca: tipoCobranca ? tipoCobranca.id : undefined,
            parcelar: parcelar ? parcelar.id : undefined,
            idTipoFaturamento: this.faturamento.tipoFaturamento.id,
            parcelas: this.faturamentoParcelas
        })

        return faturamentoRequest;
    }

    getIsEdit(parcela: FaturamentoParcela) {
        if (this.isView) {
            return false;
        }

        if (parcela && parcela.faturamentoParcelaStatus) {
            const idStatus = parcela.faturamentoParcelaStatus.id
            if ([2, 3, 5].find(id => id === idStatus)) {
                return true;
            }
        }
    }

    getFormParcela(val: any) {
        if (val) {
            this.faturamentoParcelas[val.chave] = val.parcela;
            this.somaValorEmitido();
        }
    }

    verificaAlteracaoParcela(nova, antiga): boolean {
        return JSON.stringify(nova) === JSON.stringify(antiga);
    }

    setForm(faturamento: Faturamento) {
        this.form.patchValue({
            contrato: faturamento.contrato ? faturamento.contrato.numeroContrato : undefined,
            // status: faturamento.cotacao && faturamento.cotacao.status ? faturamento.cotacao.status.descricao : undefined,
            parcelamento: faturamento.qtdeParcelas,
            statusFinanceiro: faturamento.cotacao && faturamento.cotacao.statusFinanceiro ? faturamento.cotacao.statusFinanceiro.id : undefined,
            emissor: faturamento.emissor ? faturamento.emissor.id : undefined,
            tipoCobranca: faturamento.cotacao && faturamento.cotacao.tipoCobranca ? faturamento.cotacao.tipoCobranca.id : undefined,
            parcelar: faturamento.parcelar ? faturamento.parcelar.id : undefined,
            saldoEmitir: this.saldoEmitir,
            valorSetup: faturamento.cotacao ? faturamento.cotacao.valorSetup : undefined,
            tipoFaturamento: faturamento.tipoFaturamento ? faturamento.tipoFaturamento.descricao : undefined,
        })
    }

    atualizaQuantidadeParcelas() {
        const parcelar = this.form.get('parcelar').value;
        let qtdeParcela = parcelar.value || 1;
        qtdeParcela = qtdeParcela === 'n' ? 1 : parseInt(qtdeParcela);

        const qtdeItemNota = this.listaFaturamentoItemNota.length;

        if (qtdeParcela > qtdeItemNota) {
            for (let index = qtdeItemNota; index < qtdeParcela; index++) {
                this.listaFaturamentoItemNota.push({
                    diasAVencer: 7,
                    dataEmissao: new Date(),
                    faturamentoParcelaStatus: this.optionList.listaStatusNota.find(status => status.descricao === "A emitir")
                });
            }
        }
        else if (qtdeParcela < qtdeItemNota) {
            for (let index = qtdeItemNota; index > qtdeParcela; index--) {
                const parcela = this.listaFaturamentoItemNota[(index - 1)];
                let isDelete = false

                if (!parcela || !parcela.faturamentoParcelaStatus) {
                    isDelete = true
                }
                else if (parcela.faturamentoParcelaStatus.id === 2 || parcela.faturamentoParcelaStatus.id === 3 || parcela.faturamentoParcelaStatus.id === 5) {
                    isDelete = true
                }

                if (isDelete) {
                    this.listaFaturamentoItemNota.splice((index - 1), 1);
                    this.faturamentoParcelas.splice((index - 1), 1);
                }
                else {

                    this._notification.error(`A parcela ${index} não pode ser excluída`)
                }
            }
        }
        this.somaValorEmitido();
    }

    somaValorEmitido() {
        let saldoTotal = 0;
        let itensValores = {};
        this.faturamentoParcelas.forEach(parcela => {
            parcela.itens.forEach(item => {
                const id = item.faturamentoItemId;
                if (id) {
                    itensValores[id] = itensValores[id] ? itensValores[id] : 0;
                    itensValores[id] = itensValores[id] + item.valor;
                    saldoTotal += item.valor;
                }
            })
        })
        this.itensValores = Object.assign({}, itensValores)
        this.form.get('saldoEmitir').setValue(saldoTotal);
        this.validaItensLinhas();
    }

    validaValorFaturamento(): boolean {
        const saldoEmitir = this.form.get('saldoEmitir').value;
        const valorSetup = this.form.get('valorSetup').value;
        const isValido = saldoEmitir === valorSetup;
        if (!isValido) {
            this._notification.error(`Saldo a emitir diferente do valor total do setup`)
        }
        return isValido
    }

    validaItensLinhas(): boolean {
        let isValido = true;
        this.faturamentoProdutos.forEach(produto => {
            const item = produto.cotacaoItemCotacao;
            // const idItemCotacao = item.itemCotacao.id;
            // const totalValorFaturar = this.itensValores[idItemCotacao];
            // if (totalValorFaturar > item.valorTotal) {
            //     this._notification.error(`O item ${item.itemCotacao.descricao} ultrapassou o valor total!`)
            //     isValido = false;
            // }
        })
        return isValido;
    }

    validaParcelaZerada(): boolean {
        let isValido = true;
        this.faturamentoParcelas.forEach(parcela => {
            if (parcela.valorNota === 0 || parcela.valorNota === undefined) {
                isValido = false
            }
        })
        if (!isValido) {
            this._notification.error('Nota com o valor zerado')
        }
        return isValido
    }


    salvar() {
        if (
            this.validaValorFaturamento() &&
            this.validaItensLinhas() &&
            this.validaParcelaZerada()
        ) {

            const faturamento: FaturamentoRequest = this.getForm()
            this._spinner.show();
            this._faturamentoService.updateFaturamento(this.faturamento.id, faturamento).subscribe(
                result => {
                    this.sucess('Faturamento salvo com sucesso')
                },
                error => this.error(error))
        }
    }

    private setFormSaldoEmitir() {
        this.form.patchValue({
            saldoEmitir: this.saldoEmitir,
        })
    }

}

export class FaturamentoFaturarAbaCombos {
    static combos() {
        return [
            {
                listName: 'listaStatus',
                filterName: 'filterStatus',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaStatusFinanceiro',
                filterName: 'filterStatusFinanceiro',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaEmissor',
                filterName: 'filterEmissor',
                fieldValue: 'descricao'
            },
            {
                listName: 'listaTipoCobranca',
                filterName: 'filterTipoCobranca',
                fieldValue: 'descricao'
            },
            {
                listName: 'listaParcelar',
                filterName: 'filterParcelar',
                fieldValue: 'descricao'
            },
            {
                listName: 'listaTipoFaturamento',
                filterName: 'filterTipoFaturamento',
                fieldValue: 'descricao'
            },
        ]
    }
}