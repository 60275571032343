import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PerfilGuard } from './perfil/perfil.guard';

const routes: Routes = [
    {
        path: 'perfil',
        canActivate: [PerfilGuard],
        canLoad: [PerfilGuard],
        loadChildren: 'app/main/acl/perfil/perfil.module#PerfilModule',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class AclRoutingModule {}
