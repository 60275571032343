import {
    ChangeDetectorRef,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { SelectionModel } from '@angular/cdk/collections';
import { Gmud } from '../../shared/interfaces/gmud.interface';
import { FiltroGmud } from '../../shared/interfaces/filtro-gmud.interface';
import { GmudService } from '../../shared/services/gmud.service';
import { GmudModalService } from '../../shared/services/gmud-modal.service';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';

@Component({
    selector: 'gmud-grid',
    templateUrl: './gmud-grid.component.html',
    styleUrls: ['./gmud-grid.component.scss'],
})
export class GmudGridComponent extends GridBase<Gmud, FiltroGmud, GmudService> implements OnInit, OnChanges {
    selection = new SelectionModel<Gmud>(true, []);

    constructor(
        protected _gmudService: GmudService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalGmud: GmudModalService,
    ) {
        super(_gmudService, _injector, _changeDetectorRefs, 'GmudGrid');
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {});
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'statusImp', label: 'Status IMP' },
            { chave: 'statusGmud', label: 'Status GMUD' },
            { chave: 'numeroImp', label: 'N. IMP' },
            { chave: 'numeroGmud', label: 'N. GMUD' },
            { chave: 'produto', label: 'Produto' },
            { chave: 'ambiente', label: 'Ambiente' },
            { chave: 'responsavelProducao', label: 'Responsável Produção' },
            { chave: 'responsavelDesenvolvimento', label: 'Responsável Desenvolvimento' },
            { chave: 'dataHoraExecucao', label: 'Data/Hora Execução' },
            { chave: 'dataHoraCriacao', label: 'Data/Hora Criação' },
        ];
    }

    cadastrar() {
        this._router.navigateByUrl('gmud/cadastro')
    }

    configurarAprovacao() {
        this._router.navigateByUrl('/gmud/configurar-aprovacao')

    }

    editar(gmud: Gmud) {
        this._router.navigate(['gmud', 'cadastro', gmud.id])
    }

    visualizar() {

    }

    historico(gmud: Gmud) {
        this._modalGmud.historico(gmud);
    }


}
