import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CotacaoCliente } from '../interfaces/faturamento-cliente.interface';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';

@Injectable({
    providedIn: 'root',
})
export class CotacaoClienteService extends ServiceBase<CotacaoCliente> {
    private path = `${environment.server}/cotacao-listar-cliente`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(): Observable<Response<Array<CotacaoCliente>>> {
        const url = `${this.path}`;
        return this._http.get<Response<Array<CotacaoCliente>>>(url);
    }
}
