import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CotacaoModulo } from 'app/main/cotacao/shared/interfaces/cotacao-modulo.interface';
import { ChecklistConfiguracaoLayout } from '../../shared/interfaces/checklist-configuracao.interface';
import { ChecklistConfiguracaoService } from '../../shared/services/checklist-configuracao.service';

interface ChecklistMenuConfiguracao {
    modulo: CotacaoModulo;
    isValido?: boolean;
}

@Component({
    selector: 'checklist-configuracao-modulo',
    templateUrl: './checklist-configuracao-modulo.component.html',
    styleUrls: ['./checklist-configuracao-modulo.component.scss'],
})
export class ChecklistConfiguracaoModuloComponent implements OnInit {
    modulos: Array<CotacaoModulo> = [];
    itensMenu: Array<ChecklistMenuConfiguracao> = [];
    configuracoes: Array<ChecklistConfiguracaoLayout> = [];
    @Output() selecionado = new EventEmitter();
    private _recarrega: any;

    get recarrega(): any {
        return this._recarrega;
    }
    @Input() set recarrega(recarrega: any) {
        if (recarrega && recarrega.isRecarrega) {
            this._recarrega = recarrega;
            this.atualizaConfiguracao();
        }
    }

    constructor(
        private _route: ActivatedRoute,
        private _checklistConfiguracaoService: ChecklistConfiguracaoService
    ) {}

    ngOnInit() {
        this._route.data.subscribe((data) => {
            this.modulos = data.modulos;
            this.configuracoes = data.configuracoes;
            this.atualizarMenu();
        });
    }

    selecionar(modulo: CotacaoModulo) {
        this.selecionado.emit(modulo);
    }

    atualizaConfiguracao() {
        const idChecklist = parseInt(this._route.snapshot.paramMap.get('id'));
        this._checklistConfiguracaoService
            .find({ idChecklist: idChecklist })
            .subscribe((c) => {
                this.configuracoes = c.data.checklistConfiguracaos;
                this.atualizarMenu();
            });
    }
    atualizarMenu() {
        this.itensMenu = [];
        this.modulos.forEach((m) => {
            let isValido = false;

            this.configuracoes.forEach((c) => {
                if (c.moduloId === m.id) {
                    isValido = c.isValid;
                }
            });

            this.itensMenu.push({
                modulo: m,
                isValido: isValido,
            });
        });
    }
}
