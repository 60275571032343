import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Usuario } from '../../shared/interfaces/usuario.interface';

@Component({
    selector: 'usuario-detalhe-perfil',
    templateUrl: './usuario-detalhe-perfil.component.html',
    styleUrls: ['./usuario-detalhe-perfil.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UsuarioDetalhePerfilComponent {
    @Input() usuario: Usuario;

    constructor() {}
}
