import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { OsEmpresa } from '../../shareds/interfaces/os-empresa.interface';
import { OsEmpresaService } from '../../shareds/services/os-empresa.service';

@Component({
    selector: 'os-empresa-cadastro',
    templateUrl: './os-empresa-cadastro.component.html',
    styleUrls: ['./os-empresa-cadastro.component.scss'],
})
export class OsEmpresaCadastroComponent extends ComponentBase implements OnInit {
    @Input() empresa: OsEmpresa;
    @Output() salvo = new EventEmitter<boolean>();
    form: FormGroup;

    constructor(
        private _formBuilder: FormBuilder,
        private _empresaService: OsEmpresaService,
        private _injector: Injector
    ) {
        super(_injector);
    }

    ngOnInit() {
        this.form = this.createForm();
    }

    salvar() {
        const inscricao: string = this.form.get('inscricao').value;
        const empresa: OsEmpresa = {
            inscricao: inscricao,
            nome: this.form.get('nome').value,
            idTipoInscricao: inscricao.length === 11 ? 1 : 2,
        };
        this._empresaService.save(empresa).subscribe(
            () => {
                this.sucess(
                    'Dados do empresa atualizados com sucesso'
                );
                this.salvo.emit(true);
            },
            (error) => this.error(error)
        );
    }

    createForm() {
        return this._formBuilder.group({
            inscricao: [this.empresa.inscricao, Validators.required],
            nome: [this.empresa.nome, Validators.required],
        });
    }
}
