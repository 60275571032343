import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { ContratoItem } from '../../shared/interfaces/contrato-item.interface';
import { ContratoSituacao } from '../../shared/interfaces/contrato-situacao.interface';
import { FiltroContratoItem } from '../../shared/interfaces/filtro-contrato-item.interface';
import { ContratoItemService } from '../../shared/services/contrato-item.service';
import { ContratoModalService } from '../../shared/services/contrato-modal.service';
import { ContratoService } from '../../shared/services/contrato.service';

@Component({
    selector: 'contrato-detalhe-item-grid',
    templateUrl: './contrato-detalhe-item-grid.component.html',
    styleUrls: ['./contrato-detalhe-item-grid.component.scss']
})
export class ContratoDetalheItemGridComponent extends GridBase<ContratoItem, FiltroContratoItem, ContratoItemService> implements OnChanges, OnInit {

    selection = new SelectionModel<ContratoItem>(true, []);
    desableAtivoInativo: boolean = false;
    listaSituacao: Array<ContratoSituacao> = []

    @Input() set atualizaGrid(isAtualiza: boolean) {
        this.atualizarGrid();
    }

    constructor(
        protected _service: ContratoItemService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalContrato: ContratoModalService
    ) {
        super(_service, _injector, _changeDetectorRefs)
    }

    ngOnInit(): void {
        this._route.data.subscribe((data) => {
            this.filtro = this.filtro ? this.filtro : {}
            this.filtro.idContrato = data.contrato.id
            this.initGrid(this.filtro || {})
            this.listaSituacao = data.situacoes
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {}, () => this.selection.clear());
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'select' },
            { chave: 'seleciona' },
            { chave: 'situacao', label: '' },
            { chave: 'produto', label: '' },
            { chave: 'modulo', label: '' },
            { chave: 'vigencia', label: '' },
            { chave: 'tipoContrato', label: '' },
            { chave: 'quantidade', label: '' },
            { chave: 'valorTotalItem', label: '' },
            { chave: 'dataBiller', label: '' },
        ]
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows =
            this.paginacao.pageSize <= this.paginacao.length
                ? this.paginacao.pageSize
                : this.paginacao.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.listaDataSource.data.forEach((row) =>
                this.selection.select(row)
            );
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: ContratoItem): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    historico(row: ContratoItem) {
        this._modalContrato.historicoItem(row).subscribe(() => {

        })
    }

    ajustarValor() {
        const idItensContrato = this.selection.selected.map((v) => v.id);
        this._modalContrato.ajustarValor(idItensContrato).subscribe((result) => {
            if (result) {
                this.atualizarGrid(this.filtro)
            }
            this.selection.clear();
        })
    }

    atualizarReajuste() {
        const idItensContrato = this.selection.selected.map((v) => v.id);
        this._modalContrato.atualizarReajuste(idItensContrato).subscribe((result) => {
            if (result) {
                this.atualizarGrid(this.filtro)
            }
            this.selection.clear();
        })
    }

    ativarInativar() {
        const situacaoAtivo = this.listaSituacao.find(situacao => situacao.descricao === 'Ativo')
        let ativarInativar = situacaoAtivo.id;
        let comparaSituacao = null;
        let situacoesIguais = true;
        const situacoes: Array<any> = []

        if (!this.selection || this.selection.selected.length === 0) {
            this._notification.warning("Selecione ao menos um item do contrato");
            return
        }
        this.selection.selected.forEach((item: ContratoItem) => {
            if (comparaSituacao === null || comparaSituacao === item.situacao.id) {
                comparaSituacao = item.situacao.id
                if (item.situacao.descricao === "Ativo" || item.situacao.descricao === "Não aplicado") {
                    const situacaoInativo = this.listaSituacao.find(situacao => situacao.descricao === 'Inativo')
                    ativarInativar = situacaoInativo.id;
                }
                situacoes.push({
                    id: item.id,
                    situacao: {
                        id: ativarInativar
                    }
                })
            }
            else {
                situacoesIguais = false;
            }
        })

        if (!situacoesIguais) {
            this._notification.warning("Todas as situalções devem ser do mesmo tipo");
            return
        }

        this._spinner.show();
        this._service.updateStatus(this.filtro.idContrato, { itens: situacoes }).subscribe(() => {
            this._notification.sucess("Status dos itens atualizados com sucesso!")
            this.atualizarGrid(this.filtro);
            this.selection.clear();
            this._spinner.hide();
        },
            error => this.error(error))
    }

    validaItemAtivarInativar() {
        let isPreCadastrado = false;
        this.selection.selected.map((item: ContratoItem) => {
            if (item.situacao.descricao === 'Pré-Cadastrado') {
                this.desableAtivoInativo = true;
                isPreCadastrado = true;
            }
        })

        if (!isPreCadastrado) {
            this.desableAtivoInativo = true;
        }
    }

}
