import {
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
} from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import * as moment from 'moment';
import { ContratoSituacao } from '../../shared/interfaces/contrato-situacao.interface';
import { ContratoStatus } from '../../shared/interfaces/contrato-status.interface';
import { FiltroContrato } from '../../shared/interfaces/filtro-contrato.interface';

@Component({
    selector: 'contrato-grid-filtro',
    templateUrl: './contrato-grid-filtro.component.html',
    styleUrls: ['./contrato-grid-filtro.component.scss'],
})
export class ContratoGridFiltroComponent
    extends FormFiltroBase<FiltroContrato>
    implements OnInit {
    @Output() filtrar = new EventEmitter<FiltroContrato>();

    desabilitarDataFinal: boolean = false

    listaStatus: Array<ContratoStatus> = []
    listaSituacao: Array<ContratoSituacao> = []

    constructor(injector: Injector) {
        super('ContratoGrid', injector, ContratoGridFiltroCombos.combos());
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            //this.listaStatus = data.statuss
            //this.listaSituacao = data.situacoes
            this.optionList.listaStatus = data.statuss;
            this.optionList.listaSituacao = data.situacoes;
            this.optionList.listaProdutos = data.produtos;
            this.optionList.listaPrioridades = data.prioridades;
            this.optionList.listaSegmento = data.segmentos;
            this.optionList.listaStatusPrimario = data.statusPrimario;
            this.optionList.listaStatusSecundario = data.statusSecundario;
            this.optionList.listaTipoMinuta = data.tipoMinuta;

            this.refreshOptionsConfig();
        });
    }

    camposFiltro(): string[] {
        return [
            'numeroContrato',
            'grupo',
            'nomeEmpresa',
            'inscricao',
            'numeroCotacao',
            'dataInicial',
            'dataFinal',
            'dataFinalVigenciaIndeterminado',
            'situacao',
            'status',
            'statusPrimario',
            'statusSecundario',
            'tipoMinuta',
        ];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            numeroContrato: [],
            grupo: [],
            nomeEmpresa: [],
            inscricao: [],
            numeroCotacao: [],
            tipoData: ['dataCriacao'],
            dataInicial: [],
            dataFinal: [],
            dataFinalVigenciaIndeterminado: [],
            situacao: [],
            produto: [],
            segmento: [],
            status: [],
            prioridade: [],
            tipoMinuta: [],
            statusPrimario: [],
            statusSecundario: [],
        };
    }

    limpar(): void {
        this.form.reset();
        this.form.patchValue({ tipoData: 'dataCriacao' })
        this.filtro = Object.assign({});
        this.filtrar.emit(this.filtro);
        this.filtrarExec();
    }

    filtrarExecEnter(event): void {
        if (event.keyCode === 13) {
            this.filtrarExec();
        }
    }

    filtrarExec(): void {
        const numeroContrato = this.form.get('numeroContrato').value;
        const grupo = this.form.get('grupo').value;
        const nomeEmpresa = this.form.get('nomeEmpresa').value;
        let inscricao = this.form.get('inscricao').value;
        const numeroCotacao = this.form.get('numeroCotacao').value;
        const tipoData = this.form.get('tipoData').value;
        const dataInicial = this.form.get('dataInicial').value;
        const dataFinal = this.form.get('dataFinal').value;
        const dataFinalVigenciaIndeterminado = this.form.get('dataFinalVigenciaIndeterminado').value;
        const situacao = this.form.get('situacao').value;
        const status = this.form.get('status').value;
        const produto = this.form.get('produto').value;
        const segmento = this.form.get('segmento').value;
        const prioridade = this.form.get('prioridade').value;
        const tipoMinuta = this.form.get('tipoMinuta').value;
        const statusPrimario = this.form.get('statusPrimario').value;
        const statusSecundario = this.form.get('statusSecundario').value;
        inscricao = inscricao ? inscricao.replace(/[\-\.\/]/g, '') : '';

        const filtro: FiltroContrato = {
            contratoNumero: numeroContrato,
            grupo: grupo,
            nomeEmpresa: nomeEmpresa,
            inscricaoEmpresa: inscricao,
            numeroCotacao: numeroCotacao,
            dataAceiteInicial: dataInicial && tipoData == 'dataAceite' ? moment(dataInicial, 'yyyy-MM-dd').toDate() : null,
            dataAceiteFinal: dataFinal && tipoData == 'dataAceite' ? moment(dataFinal, 'yyyy-MM-dd').toDate() : null,
            dataVigenciaInicial: dataInicial && tipoData == 'dataVigencia' ? moment(dataInicial, 'yyyy-MM-dd').toDate() : null,
            dataVigenciaFinal: dataFinal && tipoData == 'dataVigencia' ? moment(dataFinal, 'yyyy-MM-dd').toDate() : null,
            dataCriacaoInicial: dataInicial && tipoData == 'dataCriacao' ? moment(dataInicial, 'yyyy-MM-dd').toDate() : null,
            dataCriacaoFinal: dataFinal && tipoData == 'dataCriacao' ? moment(dataFinal, 'yyyy-MM-dd').toDate() : null,
            dataFinalVigenciaIndeterminado: dataFinalVigenciaIndeterminado ? '1' : null,
            situacaoId: situacao ? situacao.id : null,
            statusId: status ? status.id : null,
            produto: produto ? produto.id : null,
            segmentoId: segmento ? segmento.id : null,
            prioridade: prioridade ? prioridade.id : null,
            tipoMinuta: tipoMinuta ? tipoMinuta.id : null,
            statusPrimarioId: statusPrimario ? statusPrimario.id : null,
            statusSecundarioId: statusSecundario ? statusSecundario.id : null,
        };

        this.filtrar.emit(filtro);
    }

    inativarDataFinalIndeterminada() {
        const tipoData = this.form.get('tipoData').value;
        const isDataCriacao = tipoData === 'dataCriacao'
        if (isDataCriacao) {
            this.desabilitarDataFinal = false
            this.form.get('dataFinalVigenciaIndeterminado').setValue(false)
        }
        return isDataCriacao
    }

    desabilitarDataFinalIndeterminada(indeterminado) {
        const tipoData = this.form.get('tipoData').value;

        if (tipoData === 'dataVigencia' && indeterminado.checked) {
            this.form.get('dataFinal').setValue('');
            this.desabilitarDataFinal = true
        }
        else {
            this.desabilitarDataFinal = false
        }
    }

    editar() { }

}

export class ContratoGridFiltroCombos {
    static combos() {
        return [
            {
                listName: 'listaSituacao',
                filterName: 'filterSituacao',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaStatus',
                filterName: 'filterStatus',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaProdutos',
                filterName: 'filterProduto',
                fieldValue: 'nome',
            },
            {
                listName: 'listaSegmento',
                filterName: 'filterSegmento',
                fieldValue: 'nome',
            },
            {
                listName: 'listaPrioridades',
                filterName: 'filterPrioridade',
                fieldValue: 'nome',
            },
            {
                listName: 'listaStatusPrimario',
                filterName: 'filterStatusPrimario',
                fieldValue: 'nome',
            },
            {
                listName: 'listaStatusSecundario',
                filterName: 'filterStatusSecundario',
                fieldValue: 'nome',
            },
            {
                listName: 'listaTipoMinuta',
                filterName: 'filterTipoMinuta',
                fieldValue: 'nome',
            },
        ];
    }
}