import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { StatusReport } from '../interfaces/status-report';

@Injectable({
    providedIn: 'root',
})
export class StatusReportService extends ServiceGridBase<StatusReport, FiltroBase> {
    private path: string = `${environment.server}/projeto-status-report`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroBase): Observable<Response<Array<StatusReport>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<StatusReport>>>(url);
    }
}
