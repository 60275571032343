import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FiltroImportacaoCotacaoDetalhe } from '../../shared/interfaces/filtro-importacao-cotacao-detalhe.interface';
import { ImportacaoCotacaoDetalhe } from '../../shared/interfaces/importacao-cotacao-detalhe.interface';
import { ImportacaoCotacao } from '../../shared/interfaces/importacao-cotacao.interface';
import { ImportacaoCotacaoDetalheService } from '../../shared/services/importacao-cotacao-detalhe.service';

@Component({
    selector: 'importacao-cotacao-detalhe',
    templateUrl: './importacao-cotacao-detalhe.component.html',
    styleUrls: ['./importacao-cotacao-detalhe.component.scss'],
})
export class ImportacaoCotacaoDetalheComponent
    extends GridBase<
    ImportacaoCotacaoDetalhe,
    FiltroImportacaoCotacaoDetalhe,
    ImportacaoCotacaoDetalheService
    >
    implements OnInit {
    @Input() importacao: ImportacaoCotacao = {};

    constructor(
        service: ImportacaoCotacaoDetalheService,
        injector: Injector,
        changeDetectorRefs: ChangeDetectorRef
    ) {
        super(service, injector, changeDetectorRefs);
    }

    ngOnInit(): void {
        this.filtro.idArquivo = this.importacao.id;
        this.initGrid(this.filtro);
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'nome', label: '' },
            { chave: 'empresaRazaoSocial', label: '' },
            { chave: 'cotacaoNumero', label: '' },
            { chave: 'dataAceite', label: '' },
            { chave: 'valorTotal', label: '' },
        ];
    }
}
