import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmpresaDetalheResolver } from './shareds/resolvers/empresa-detalhe.resolver';
import { EmpresaGuard } from './empresa.guard';
import { ModalEmpresaDetalheComponent } from './modal/modal-empresa-detalhe/modal-empresa-detalhe.component';
import { ModalEmpresaGridComponent } from './modal/modal-empresa-grid/modal-empresa-grid.component';
import { ModalEmpresaCadastroComponent } from './modal/modal-empresa-cadastro/modal-empresa-cadastro.component';



const routes: Routes = [
  {
    path: '', 
    canActivate: [EmpresaGuard],
    canLoad: [EmpresaGuard],
    component: ModalEmpresaGridComponent
  },
  {
    path: ':id/visualizar', 
    canActivate: [EmpresaGuard],
    canLoad: [EmpresaGuard],
    resolve:{
      empresaDetalheResolver : EmpresaDetalheResolver 
    },
    component: ModalEmpresaDetalheComponent
  },

  {
    path: ':id/editar', 
    canActivate: [EmpresaGuard],
    canLoad: [EmpresaGuard],
    resolve:{
      empresaDetalheResolver: EmpresaDetalheResolver
    },
    component: ModalEmpresaCadastroComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmpresaRoutingModule { }
