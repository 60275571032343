import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { ConfirmationService } from '@utils/utils/confirmation/confirmation.service';
import * as moment from 'moment';
import { GmudAmbiente } from '../../shared/interfaces/gmud-ambiente.interface';
import { GmudFluxo } from '../../shared/interfaces/gmud-fluxo.interface';
import { GmudImpRequest } from '../../shared/interfaces/gmud-imp.interface';
import { GmudProduto } from '../../shared/interfaces/gmud-produto.interface';
import { GmudStatusImp } from '../../shared/interfaces/gmud-status-imp.interface';
import { Gmud } from '../../shared/interfaces/gmud.interface';
import { GmudImpService } from '../../shared/services/gmud-imp.service';

@Component({
  selector: 'gmud-aba-imp',
  templateUrl: './gmud-aba-imp.component.html',
  styleUrls: ['./gmud-aba-imp.component.scss']
})
export class GmudAbaImpComponent extends FormBase implements OnInit {

  gmudFluxo: GmudFluxo = {}
  gmud: Gmud = {};
  isEdit: boolean = false;

  listaStatusImp: Array<GmudStatusImp> = []
  listaAmbientes: Array<GmudAmbiente> = []
  listaProdutos: Array<GmudProduto> = []


  constructor(
    protected _gmudImpService: GmudImpService,
    protected _injector: Injector,
    protected _changeDetectorRef: ChangeDetectorRef
  ) {
    super(_injector);
  }

  ngOnInit() {
    this._route.data.subscribe(data => {
      this.listaStatusImp = data.impStatuss;
      this.listaAmbientes = data.ambientes;
      this.listaProdutos = data.produtos;
      if (data.gmudFluxo) {
        this.gmudFluxo = data.gmudFluxo
      }
      if (data.gmud) {
        this.gmud = data.gmud;
        this.isEdit = this.gmud.podeEditarImp;
        this.setForm(data.gmud);
      }
      else {
        this.isEdit = true;
        this.gmud.podeEditarImp = true;
      }
    })
  }

  protected crateForm(): { [key: string]: any } {
    return {
      id: [],
      gmudAmbiente: ['', Validators.required],
      versaoAtual: ['', Validators.required],
      versaoImplantada: ['', Validators.required],
      caminho: ['', Validators.required],
      gmudProduto: ['', Validators.required],
      planoRetorno: ['', Validators.required],
    };
  }

  limparForm() {
    this.form.reset();
  }

  enviar() {
    const id = this.form.get('id').value;
    const ambiente = this.form.get('gmudAmbiente').value;
    const caminho = this.form.get('caminho').value;
    const produto = this.form.get('gmudProduto').value;
    const planoRetorno = this.form.get('planoRetorno').value;
    const versaoAtual: string = this.form.get('versaoAtual').value;
    const versaoImplantada: string = this.form.get('versaoImplantada').value;

    const versaoAtualStr = versaoAtual.replace(/([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})/ig, 'v$1.$2-$3.$4');
    const versaoImplantadaStr = versaoImplantada.replace(/([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})/ig, 'v$1.$2-$3.$4');

    const imp: GmudImpRequest = {
      id: id,
      gmudId: this.gmud && this.gmud.id ? this.gmud.id : undefined,
      ambiente: ambiente.id,
      caminho,
      produto: produto.id,
      planoRetorno,
      versaoAtual: versaoAtualStr,
      versaoImplantada: versaoImplantadaStr,
    }

    this._spinner.show();
    this._gmudImpService.save(imp).subscribe(result => {
      this._spinner.hide();
      this._notification.sucess('IMP enviado com sucesso!')
      this._router.navigate(['gmud', 'cadastro', result.id])
    },
      error => this.error(error))
  }

  aprovarReprovar(aprovacao: boolean) {

    if (!this.gmud || !this.gmud.id) {
      this._notification.error("GMUD não definida")
      return
    }

    const acao = aprovacao ? 'aprova' : 'reprova';

    this._confirmation.confirm({ titulo: `Deseja ${acao}r o IMP?` }).subscribe(result => {
      if (result) {
        this._spinner.show();
        this._gmudImpService.aprovacao(aprovacao, this.gmud.id).subscribe(
          () => {
            this._spinner.hide();
            this._notification.sucess(`IMP ${acao}do realizado com sucesso`)
            if (aprovacao) {
              this.redirectTo(`/gmud/cadastro/${this.gmud.id}`)
            } else {
              this._router.navigateByUrl(`gmud`)
            }
          },
          error => this.error(error)
        )
      }
    })
  }


  private setForm(imp: Gmud) {
    this.form.patchValue({
      id: imp.id,
      numero: imp.impNumero,
      gmudAmbiente: imp.gmudAmbiente,
      versaoAtual: imp.versaoAtual,
      versaoImplantada: imp.versaoImplantada,
      caminho: imp.caminho,
      gmudProduto: imp.gmudProduto,
      dataCriacao: imp.dataCriacao ? moment(imp.dataCriacao).format('DD/MM/YYYY') : '',
      planoRetorno: imp.planoRetorno,
    })
  }

  private redirectTo(uri: string) {
    this._router.navigateByUrl('/gmud', { skipLocationChange: true }).then(() =>
      this._router.navigate([uri]));
  }
}
