import { ChangeDetectorRef, Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { Contrato } from '../../shared/interfaces/contrato.interface';
import { ContratoModalService } from '../../shared/services/contrato-modal.service';
import { PreContratoService } from '../../shared/services/pre-contrato.service';

@Component({
  selector: 'pre-contrato-detalhe',
  templateUrl: './pre-contrato-detalhe.component.html',
  styleUrls: ['./pre-contrato-detalhe.component.scss']
})
export class PreContratoDetalheComponent extends FormBase implements OnInit {

  isEdit: boolean = false;
  public form: FormGroup;
  public preContrato: Contrato = {};

  @Output() atualizaGrid = new EventEmitter<boolean>();
  @Output() contratoData = new EventEmitter<Contrato>();

  public validadoRF: { validado: boolean; naoValidado: boolean; naoEncontrado: boolean; texto: string; };
  public contrato: Contrato;

  constructor(
    protected _injector: Injector,
    protected _service: PreContratoService,
    protected _changeDetectorRefs: ChangeDetectorRef,
    protected _formBuilder: FormBuilder,
    private _modalContrato: ContratoModalService
  ) {
    super(_injector, preContratoCombos.combos());
  }

  ngOnInit() {
    this.crateForm();
    this._route.data.subscribe((data) => {
      this.preContrato = data.contrato;
      this.optionList.listaEstado = data.estados;
      this.optionList.listaRepresentante = data.representantes;
      this.optionList.listaSituacaoLegal = data.situacoesLegal;
      this.optionList.listaPrioridade = data.prioridades;
      this.optionList.listaSegmento = data.segmentos;

      this.contratoData.emit(this.preContrato);
      this.setForm(this.preContrato);
      //this.refreshOptionsConfig();
    })
  }

  setForm(preContrato: Contrato) {
    this.form.get('cnpj').setValue(preContrato.cotacao.inscricao);
    this.form.get('nome').setValue(preContrato.cotacao.empresa);
    this.form.get('inscricao').setValue(preContrato.cotacao.situacao.inscricaoEstadual);
    this.form.get('representante').setValue(preContrato.representanteLegal);
    this.form.get('situacao').setValue(preContrato.cotacao.situacao.id)
    this.form.get('contato').setValue(preContrato.cotacao.contato);
    this.form.get('telefone').setValue(preContrato.cotacao.telefone);
    this.form.get('email').setValue(preContrato.cotacao.email);
    this.form.get('cep').setValue(preContrato.cotacao.situacao.cep);
    this.form.get('logradouro').setValue(preContrato.cotacao.situacao.logradouro);
    this.form.get('numero').setValue(preContrato.cotacao.situacao.numero);
    this.form.get('complemento').setValue(preContrato.cotacao.situacao.complemento);
    this.form.get('bairro').setValue(preContrato.cotacao.situacao.bairro);
    this.form.get('municipio').setValue(preContrato.cotacao.situacao.municipio);
    this.form.get('estado').setValue(preContrato.cotacao.situacao.estado);
    this.form.get('prioridade').setValue(preContrato.prioridade.id);
    this.form.get('situacao').setValue(preContrato.situacao.id);


    if (preContrato.situacao.situacaoCadastralLabel == 'Validado na Receita Federal') {
      this.validadoRF = { validado: true, naoValidado: false, naoEncontrado: false, texto: preContrato.situacao.situacaoCadastralLabel };
    } else if (preContrato.situacao.situacaoCadastralLabel != 'Validado na Receita Federal') {
      this.validadoRF = { validado: false, naoValidado: true, naoEncontrado: false, texto: preContrato.situacao.situacaoCadastralLabel };
    }

    if (preContrato.situacao.situacaoCadastralLabel == undefined ||
      preContrato.situacao.situacaoCadastralLabel == null) {
      this.validadoRF = { validado: false, naoValidado: false, naoEncontrado: true, texto: 'Não foi possível obter Situacao Cadastral' };
    }
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      cnpj: [],
      nome: [],
      inscricao: [],
      representante: [],
      situacao: [],
      contato: [],
      telefone: [],
      email: [],
      cep: [],
      logradouro: [],
      numero: [],
      complemento: [],
      bairro: [],
      municipio: [],
      estado: [],
      prioridade: [],
      segmento: [],
    };
  }
}

export class preContratoCombos {
  static combos() {
    return [
      {
        listName: 'listaRepresentante',
        filterName: 'filterRepresentante',
        fieldValue: 'nome',
      },
      {
        listName: 'listaSituacaoLegal',
        filterName: 'filterSituacaoLegal',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaEstado',
        filterName: 'filterEstado',
        fieldValue: 'nome',
      },
      {
        listName: 'listaPrioridade',
        filterName: 'filterPrioridade',
        fieldValue: 'nome',
      },
      {
        listName: 'listaSegmento',
        filterName: 'filterSegmento',
        fieldValue: 'nome',
      }

    ];
  }
}