import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Contrato } from '../../shared/interfaces/contrato.interface';

@Component({
    selector: 'modal-historico-contrato',
    templateUrl: './modal-historico-contrato.component.html',
    styleUrls: ['./modal-historico-contrato.component.scss'],
})
export class ModalHistoricoContratoComponent implements OnInit {
    tituloModal: string = 'Histórico';

    constructor(
        public dialogRef: MatDialogRef<ModalHistoricoContratoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Contrato
    ) {}
    ngOnInit() {}

    selecionado(contrato: Contrato) {
        this.dialogRef.close(contrato);
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
