import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { ProjetoDocumentoDownload } from '../interfaces/projeto-documento-download.interface';

@Injectable({
  providedIn: 'root'
})
export class ProjetoDocumentoDownloadService extends ServiceBase<any> {
  private path: string = `${environment.server}/projeto-documento`;

  constructor(private _http: HttpClient) {
    super();
  }

  find(idProjeto: number): Observable<Response<Array<ProjetoDocumentoDownload>>> {
    const url = `${this.path}/${idProjeto}`;
    return this._http.get<Response<Array<ProjetoDocumentoDownload>>>(url);
  }

  download(idDocumento: number): Observable<any> {
    const url = `${this.path}/download/${idDocumento}`;
    return this._http.get<Response<ProjetoDocumentoDownload>>(url);
  }

  delete(idDocumento: number): Observable<any> {
    const url = `${this.path}/${idDocumento}`;
    return this._http.delete(url);
  }
}
