import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { FiltroChecklistContatoEmpresa } from '../interfaces/filtro-checklist-empresa-contato.interface';
import { ChecklistContatoEmpresa } from '../interfaces/checklist-contato-empresa.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistContatoEmpresaService extends ServiceBase<ChecklistContatoEmpresa> {
    private path = `${environment.server}/checklist`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroChecklistContatoEmpresa
    ): Observable<Response<Array<ChecklistContatoEmpresa>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/${params}`;
        return this._http.get<Response<Array<ChecklistContatoEmpresa>>>(url);
    }

    findByChecklist(
        idChecklist: number,
        filtro?: FiltroChecklistContatoEmpresa
    ): Observable<Response<Array<ChecklistContatoEmpresa>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/${idChecklist}/contato/${params}`;
        return this._http.get<Response<Array<ChecklistContatoEmpresa>>>(url);
    }

    save(
        idChecklist: number,
        contatos: Array<ChecklistContatoEmpresa>
    ): Observable<Response<Array<ChecklistContatoEmpresa>>> {
        const url = `${this.path}/${idChecklist}/contato`;
        return this._http.put<Response<Array<ChecklistContatoEmpresa>>>(url, {
            contatos: contatos,
        });
    }
}
