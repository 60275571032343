import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Injector,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { FiltroOsFase } from '../../shareds/interfaces/filtro-os-fase.interface';
import { OsFaseNome } from '../../shareds/interfaces/os-fase-nome.interface';
import { OsFases } from '../../shareds/interfaces/os-fases.interface';
import { Os } from '../../shareds/interfaces/os.interface';
import { OsFaseNomeService } from '../../shareds/services/os-fase-nome.service';
import { OsFasesService } from '../../shareds/services/os-fases.service';
import { OsFaseStatusService } from '../../shareds/services/os-fase-status.service';
import { OsDocumentoUpload } from '../../shareds/interfaces/os-documento-upload.interface';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { UsuarioModalService } from 'app/main/usuario/shared/services/usuario-modal.service';
import { OsFaseEvidencia } from '../../shareds/interfaces/os-fase-evidencia.interface';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';

@Component({
    selector: 'os-fases',
    templateUrl: './os-fases.component.html',
    styleUrls: ['./os-fases.component.scss'],
})
export class OsFasesComponent
    extends GridBase<OsFases, FiltroOsFase, OsFasesService>
    implements OnInit {
    private _os: Os;
    fasesNomes: Array<OsFaseNome> = [];
    statuss: Array<OsFaseNome> = [];
    responsavelFase: Usuario;
    osFaseEvidencia: OsFaseEvidencia;

    arquivo: OsDocumentoUpload;
    @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
    file: any;

    get os(): Os {
        return this._os;
    }
    @Input() set os(os: Os) {
        this._os = os;
        this.filtro.idOs = os.id;
        this.initGrid(this.filtro);
    }

    isEdit: boolean = false;
    public form: FormGroup;

    constructor(
        protected _osFaseService: OsFasesService,
        protected _injector: Injector,
        protected _changeDetectorRef: ChangeDetectorRef,
        private _faseNomeService: OsFaseNomeService,
        private _faseStatusService: OsFaseStatusService,
        private _modalUsuario: UsuarioModalService
    ) {
        super(_osFaseService, _injector, _changeDetectorRef);
        this.form = this._formBuilder.group(this.crateForm() || null);
    }

    ngOnInit() {
        this._spinner.show();
        this._faseNomeService.find({}).subscribe(
            (result) => {
                this.fasesNomes = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );

        this._faseStatusService.find({}).subscribe(
            (result) => {
                this.statuss = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'fase', label: '' },
            { chave: 'status', label: '' },
            { chave: 'responsavelFase', label: '' },
            { chave: 'evidencias', label: '' },
            { chave: 'comentario', label: '' },
        ];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            id: [],
            fase: ['', Validators.required],
            status: ['', Validators.required],
            comentario: [''],
        };
    }
    private getForm(): OsFases {
        const fase = this.form.get('fase').value;
        const id = this.form.get('id').value;
        const status = this.form.get('status').value;
        const comentario = this.form.get('comentario').value;

        if (!this.responsavelFase || !this.responsavelFase.id) {
            this._notification.error('Seleciona o responsável da fase!');
            this._spinner.hide();
            return;
        }

        const osFases: OsFases = {
            id: id,
            comentario: comentario,
            idOs: this.os.id,
            idOsFaseNome: fase ? fase.id : null,
            idOsFaseStatus: status ? status.id : null,
            idUsuario: this.responsavelFase ? this.responsavelFase.id : null,
        };

        return osFases;
    }

    limparForm() {
        this.isEdit = false;
        this.responsavelFase = null;
        this.osFaseEvidencia = null;
        this.form.reset();
    }

    salvar() {
        const OsFase = this.getForm();
        const msg = this.form.get('id').value
            ? 'Fase atualizada com sucesso'
            : 'Fase cadastrada com sucesso!';

        this._spinner.show();
        this._osFaseService.save(OsFase, this.file).subscribe(
            (event: HttpEvent<any>) => {
                if (event.type === HttpEventType.Response) {
                    this._spinner.hide();
                    this._notification.sucess(msg);
                    this.atualizarGrid();
                    this.limparForm();
                    this.deleteFile();
                } else if (
                    event.type === HttpEventType.UploadProgress &&
                    this.file
                ) {
                    this.file.progress = Math.round(
                        (event.loaded * 100) / event.total
                    );
                }
            },
            (error) => this.error(error)
        );
    }

    remover(osFases: OsFases) {
        this._confirmation
            .confirm({
                titulo: 'Deseja excluir a fase?',
            })
            .subscribe((result) => {
                if (result) {
                    this._osFaseService.delete(osFases.id).subscribe(
                        () => {
                            this.atualizarGrid();
                            this.sucess(
                                'Fase removida com sucesso!'
                            );
                        },
                        (error) => {
                            this.error(error);
                        }
                    );
                }
            });
    }

    editar(osFases: OsFases) {
        this.isEdit = true;
        this.responsavelFase = osFases.usuario;
        this.osFaseEvidencia = osFases.osFaseEvidencia;
        this.form.patchValue({
            id: osFases.id,
            fase: osFases.osFaseNome,
            status: osFases.osFaseStatus,
            comentario: osFases.comentario,
        });
    }

    selectedOption(o1: any, o2: any) {
        return o1.id == o2.id;
    }

    // ==========================================
    //                  UPLOAD
    // ==========================================
    onFileDropped($event): void {
        this.prepareFile($event);
    }

    fileBrowseHandler(files): void {
        this.prepareFile(files);
    }

    deleteFile(): void {
        if (this.file.progress > 0 && this.file.progress < 100) {
            return;
        }
        this.file = null;
    }

    prepareFile(files: Array<any>): void {
        console.log(files);
        this.file = files[0] || {};
        this.file.progress = 0;

        this.fileDropEl.nativeElement.value = '';
    }

    formatBytes(bytes, decimals = 2): string {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    }

    download(osFases: OsFases): void {
        this._spinner.show();
        this._osFaseService.download(osFases).subscribe(
            (result) => {
                DownloadUtil.base64(
                    result.data.arquivo,
                    result.data.name,
                    result.data.type
                );
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
    }

    pesquisaUsuario() {
        this._modalUsuario.usuarioPesquisa({}).subscribe((usuario) => {
            if (usuario) {
                this.responsavelFase = usuario;
            }
        });
    }
}
