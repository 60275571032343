import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class CotacaoGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [
            { path: '/cotacao', role: Role.COTACAO_LISTAR },
            { path: '/cotacao/[0-9]+', role: Role.COTACAO_EXIBIR },
        ];
    }
}
