import { Component, Injector, OnInit } from '@angular/core';
import { ChecklistConectividadeBase } from '../checklist-conectividade-base';
import { ChecklistConectividadesApiOpenBanking } from '../shareds/interfaces/checklist-conectividades-api-open-banking.interface';
import { ChecklistConectividadesWebDav } from '../shareds/interfaces/checklist-conectividades-web-dav.interface';

@Component({
  selector: 'checklist-conectividades-web-dav',
  templateUrl: './checklist-conectividades-web-dav.component.html',
  styleUrls: ['./checklist-conectividades-web-dav.component.scss']
})
export class ChecklistConectividadesWebDavComponent extends ChecklistConectividadeBase<ChecklistConectividadesWebDav> implements OnInit {

  constructor(
    protected _injector: Injector
  ) {
    super(_injector)
  }

  ngOnInit() {
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      caixaPostal: [],
      caixaPostalSenha: [],
    }
  }

  protected setForm(data: ChecklistConectividadesWebDav) {
    this.form.patchValue({
      caixaPostal: data.caixaPostal,
      caixaPostalSenha: data.caixaPostalSenha,
    })
  }

  public getForm() {
    const data: ChecklistConectividadesWebDav = {
      caixaPostal: this.form.get('caixaPostal').value,
      caixaPostalSenha: this.form.get('caixaPostalSenha').value,
    }

    this.values.emit(data);
  }

}
