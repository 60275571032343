import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ProfileRoutingModule } from './profile-routing.module';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileGuard } from './profile.guard';
import { UtilsModule } from '@utils/utils.module';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
} from '@angular/material';

@NgModule({
    declarations: [ProfilePageComponent, ProfileComponent],
    imports: [
        CommonModule,
        ProfileRoutingModule,

        UtilsModule,

        // FORMULARIO
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
    ],
    providers: [ProfileGuard],
})
export class ProfileModule {}
