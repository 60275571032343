import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { ProjetoHistorico } from '../../shareds/interfaces/projeto-historico.interface';
import { ProjetoHistoricoService } from '../../shareds/services/projeto-historico.service';

@Component({
    selector: 'projeto-historico-detalhe',
    templateUrl: './projeto-historico-detalhe.component.html',
    styleUrls: ['./projeto-historico-detalhe.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProjetoHistoricoDetalheComponent implements OnChanges, OnInit {
    @Input() historico: ProjetoHistorico = {};

    constructor(
        protected _service: ProjetoHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) {}

    ngOnInit(): void {}

    ngOnChanges(): void {}
}
