import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GmudStatus } from '../interfaces/gmud-status.interface';
import { GmudStatusService } from '../services/gmud-status.service';

@Injectable()
export class GmudStatusListaResolver implements Resolve<GmudStatus> {
    constructor(private _service: GmudStatusService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._service.find({}).pipe(map((result) => result.data));
    }
}
