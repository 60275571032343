import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FiltroSalvarDados } from './shareds/interfaces/fintro.interface';

@Injectable({
    providedIn: 'root'
})
export class FiltroService {
    private path = `${environment.server}/usuario-configuracao-tela`;

    constructor(private _http: HttpClient) { }

    save(dados: FiltroSalvarDados): Observable<Response<FiltroSalvarDados>> {
        const url = `${this.path}`;
        return this._http.post<Response<FiltroSalvarDados>>(url, dados);
    }

    findByChave(chave: string): Observable<Response<FiltroSalvarDados>> {
        const url = `${this.path}/${chave}`;
        return this._http.get<Response<FiltroSalvarDados>>(url)
    }
}
