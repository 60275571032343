import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const confirmaSenhaValidator: ValidatorFn = (
    control: AbstractControl
): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const senha = control.parent.get('senha');
    const senhaConfirm = control.parent.get('senhaConfirm');

    if (!senha || !senhaConfirm) {
        return null;
    }

    if (senhaConfirm.value === '') {
        return null;
    }

    if (senha.value === senhaConfirm.value) {
        return null;
    }

    return { senhasNotMatching: true };
};
