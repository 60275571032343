import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { FiltroContratoItem } from '../interfaces/filtro-contrato-item.interface';
import { ContratoItem } from '../interfaces/contrato-item.interface';

@Injectable({
    providedIn: 'root',
})
export class ContratoItemService extends ServiceGridBase<ContratoItem, FiltroContratoItem> {
    private path = `${environment.server}/contrato`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroContratoItem): Observable<Response<Array<ContratoItem>>> {
        const params: string = this.toGetParams(filtro, ['idContrato']);
        const url = `${this.path}/${filtro.idContrato}/itens${params}`;
        return this._http.get<Response<Array<ContratoItem>>>(url);
    }

    findById(id: number | string): Observable<Response<ContratoItem>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<ContratoItem>>(url);
    }

    updateStatus(idContrato: number, data: any): Observable<Response<any>> {
        const url = `${this.path}/${idContrato}/itens`;
        return this._http.put<Response<any>>(url, data);
    }

}
