import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Gmud } from '../../shared/interfaces/gmud.interface';



@Component({
  selector: 'gmud-cadastro',
  templateUrl: './gmud-cadastro.component.html',
  styleUrls: ['./gmud-cadastro.component.scss']
})
export class GmudCadastroComponent implements OnInit {

  menuSelecionado: string;
  menuDesabilitado = {
    imp: false,
    gmud: true,
    documento: true,
    aprovacao: true
  }

  constructor(
    private _router: ActivatedRoute,
  ) {

  }

  ngOnInit() {
    this._router.data.subscribe(data => {
      const idGmud = this._router.snapshot.params.id;
      if (idGmud) {
        this.menuDesabilitado.documento = false;
        this.menuDesabilitado.aprovacao = false;
      }

      if (data.gmud) {
        const gmud: Gmud = data.gmud
        if (gmud.impStatus.id === 5) {
          this.menuDesabilitado.gmud = false;
        }
      }

      if (data.gmudFluxo) {
        const gmudFluxo = data.gmudFluxo
      }
    })

    this.selecionar('IMP');
  }

  selecionar(menu: string) {
    this.menuSelecionado = menu;
  }

  private habilitaMenu() {
    const idGmud = this._router.snapshot.params.id;
  }
}
