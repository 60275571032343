import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class TimeGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [
            { path: '/time', role: Role.TIME_LISTAR },
            { path: '/time/cadastro', role: Role.TIME_CADASTRAR },
            { path: '/time/[0-9]+/visualizar', role: Role.TIME_BUSCAR },
            { path: '/time/[0-9]+/editar', role: Role.TIME_ALTERAR },
        ];
    }
}
