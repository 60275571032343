import { Component, Input, OnInit } from '@angular/core';
import { Empresa } from '../../shareds/interfaces/empresa.interface';

@Component({
    selector: 'empresa-detalhe',
    templateUrl: './empresa-detalhe.component.html',
    styleUrls: ['./empresa-detalhe.component.scss']
})
export class EmpresaDetalheComponent implements OnInit {

    @Input() empresa: Empresa
    
    constructor() { }
    
    ngOnInit() {
        
    }
}
