import { ThemeConfig } from '../../@utils/interfaces/theme/theme-config.interface';

export const themeConfig: ThemeConfig = {
    logoSVG: 'assets/temas/finnet-project/logo/project-desk-white.svg',
    logoWhiteSVG: 'assets/temas/finnet-project/logo/project-desk-white.svg',
    logoColorSVG: 'assets/temas/finnet-project/logo/project.svg',
    logoTexto: 'Finnet Project',
    avatar: 'assets/images/avatars/profile.jpg',
    pagePublicBackgroundImage: 'assets/temas/finnet-project/background/bg.jpg',
    pagePublicBackgroundImageCliente:
        'assets/temas/finnet-project/background/bg-cliente.jpg',
    footerText: 'Finnet Project - Powered by Finnet',
};
