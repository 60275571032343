import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Gmud } from '../../shared/interfaces/gmud.interface';

@Component({
    selector: 'modal-historico-gmud',
    templateUrl: './modal-historico-gmud.component.html',
    styleUrls: ['./modal-historico-gmud.component.scss'],
})
export class ModalHistoricoGmudComponent implements OnInit {
    tituloModal: string = 'Histórico';

    constructor(
        public dialogRef: MatDialogRef<ModalHistoricoGmudComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Gmud
    ) { }
    ngOnInit() { }

    selecionado(gmud: Gmud) {
        this.dialogRef.close(gmud);
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
