import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'os-visualizar-corpo',
    templateUrl: './os-visualizar-corpo.component.html',
    styleUrls: ['./os-visualizar-corpo.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OsVisualizarCorpoComponent implements OnChanges, OnInit {
    @Input() corpo: any = 'string';

    constructor(
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
    }
        
    ngOnChanges(): void {}
}
