import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'projeto-detalhe-page',
  templateUrl: './projeto-detalhe-page.component.html',
  styleUrls: ['./projeto-detalhe-page.component.scss']
})
export class ProjetoDetalhePageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
