import { Component, OnInit } from '@angular/core';
import { FiltroCotacao } from '../../shared/interfaces/filtro-cotacao.interface';

@Component({
    selector: 'cotacao-grid-page',
    templateUrl: './cotacao-grid-page.component.html',
    styleUrls: ['./cotacao-grid-page.component.scss'],
})
export class CotacaoGridPageComponent implements OnInit {
    filtro: FiltroCotacao;

    constructor() {}

    ngOnInit() {}

    filtrar(filtro: FiltroCotacao) {
        this.filtro = Object.assign({}, filtro);
    }
}
