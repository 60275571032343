import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroRelatorio } from '../interfaces/filtro-relatorio.interface';
import { Relatorio } from '../interfaces/relatorio.interface';

@Injectable({
    providedIn: 'root'
})
export class RelatorioService extends ServiceGridBase<Relatorio, FiltroRelatorio> {
    private path: string = `${environment.server}/relatorio`

    constructor(private _http: HttpClient) {
        super()
    }

    find(filtro?: FiltroRelatorio): Observable<Response<Array<Relatorio>>> {
        const params: string = filtro ? this.toGetParams(filtro) : ''
        const url = `${this.path}/geracao${params}`
        return this._http.get<Response<Array<Relatorio>>>(url)
    }

    gerarRelatorio(relatorio: Relatorio) {
        const url = `${this.path}/${relatorio.id}/download`;
        return this._http.get<Response<any>>(url);
    }
}
