import { Component, OnInit } from '@angular/core';
import { FiltroRelacionamentoAbertura } from '../../shared/interfaces/filtro-relacionamento-abertura.interface';

@Component({
    selector: 'relacionamento-abertura-grid-page',
    templateUrl: './relacionamento-abertura-grid-page.component.html',
    styleUrls: ['./relacionamento-abertura-grid-page.component.scss'],
})
export class RelacionamentoAberturaGridPageComponent implements OnInit {
    filtro: FiltroRelacionamentoAbertura;

    constructor() { }

    ngOnInit() { }

    filtrar(filtro: FiltroRelacionamentoAbertura) {
        this.filtro = Object.assign({}, filtro);
    }
}
