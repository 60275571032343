import {
    AfterViewInit,
    ChangeDetectorRef,
    Injector,
    Input,
    ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GridBaseDatasource } from './grid-base-data-source';
import { ModelBase } from '../model-base/model-base';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
    MatPaginator,
    MatPaginatorIntl,
    PageEvent,
} from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FiltroBase } from '../form-filtro-base/interfaces/filtro-base.interface';
import { ServiceGridBase } from '../service-base/service-grid-base';
import { ComponentBase } from '../component-base/component-base';
import { FiltroService } from '@utils/utils/filtro/filtro.service';
import { FiltroModalService } from '@utils/utils/filtro/shareds/services/filtro-modal.service';
import { GridCollumn } from './grid-column.interface';

export abstract class GridBase<T extends ModelBase, F extends FiltroBase, S extends ServiceGridBase<T, F>> extends ComponentBase implements AfterViewInit {
    protected _formBuilder: FormBuilder;
    protected _changeDetectorRefs: ChangeDetectorRef;
    protected _service: S;
    private filtroService: FiltroService;
    private filtroModalService: FiltroModalService;
    public chaveFiltro: string;

    form: FormGroup;
    campoPesquisa: string;
    @Input() filtro: F;

    // Tabela

    listaDataSource: GridBaseDatasource<T, F, S>;
    displayedColumns: Array<string> = [];

    // Paginação e ordenação

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    pageEvent: PageEvent;
    paginacao: any = {
        length: 0,
        pageSize: 10,
    };

    constructor(
        service: S,
        injector: Injector,
        changeDetectorRefs: ChangeDetectorRef,
        chaveFiltro?: string,
    ) {
        super(injector)
        this._formBuilder = injector.get(FormBuilder);
        this._changeDetectorRefs = changeDetectorRefs;
        this._service = service;
        this.filtroService = injector.get(FiltroService);
        this.filtroModalService = injector.get(FiltroModalService);
        this.chaveFiltro = chaveFiltro;

        this.filtro = Object.apply({});
        this.displayedColumns = [];

        this.carregaConfiguracoesFiltro();
    }

    abstract configDisplayedColumns(): Array<GridCollumn>;

    // GRID

    pesquisarPorTexto(): void {
        if (this.campoPesquisa) {
            this.filtro = this.filtro ? this.filtro : Object.apply({});;
            this.filtro.filter = this.campoPesquisa;
            this.atualizarGrid();
        } else {
            this.limparCampoPesquisa();
        }
    }

    limparCampoPesquisa(): void {
        this.campoPesquisa = '';
        if (this.filtro) {
            this.filtro.filter = '';
        }
        this.atualizarGrid();
    }

    protected initGrid(filtro?: F): void {
        this.pageEvent = new PageEvent();
        this.sort = new MatSort();
        this.paginator = new MatPaginator(
            new MatPaginatorIntl(),
            this._changeDetectorRefs
        );
        this.listaDataSource = new GridBaseDatasource<T, F, S>(
            this._service,
            this._changeDetectorRefs,
            this._spinner,
            this._notification
        );
        this.atualizarGrid(filtro);
    }

    ngAfterViewInit(): void {
        this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(tap(() => this.atualizarGrid()))
            .subscribe();
    }

    protected atualizarGrid(filtro?: F, callback?: Function): void {
        if (callback) callback();

        if (this.sort && this.paginator) {
            filtro = filtro
                ? filtro
                : this.filtro
                    ? this.filtro
                    : Object.assign({});
            const filter = filtro.filter
                ? filtro.filter
                : this.campoPesquisa || '';
            const sortDirection = this.sort.direction || 'asc';
            const sortField = this.sort.active
                ? this.sort.active
                : filtro.sortField;
            const pageNumber = this.paginator.pageIndex + 1;
            const pageSize = this.paginator.pageSize;
            const callback = (result) => {
                this.paginacao.length =
                    result &&
                        result.metadata &&
                        result.metadata.pagination &&
                        result.metadata.pagination.total
                        ? result.metadata.pagination.total
                        : 0;
            };
            this.listaDataSource.load(
                filtro,
                filter,
                sortField,
                sortDirection,
                pageNumber,
                pageSize,
                callback
            );
        }
    }

    private setCamposHabilitados(campos: any) {
        if (campos) {
            const lista: Array<string> = [];
            const listaCampos: Array<any> = this.configDisplayedColumns();

            listaCampos.forEach(c => {
                if (!c.label && c.chave) {
                    if (!lista[c.chave]) {
                        lista.push(c.chave)
                    }
                } else {
                    Object.keys(campos).forEach((chave) => {
                        if (campos[chave] && c.chave === chave) {
                            lista.push(chave)
                        }
                    });
                }
            })


            this.displayedColumns = lista;
        }
        else {
            this.displayedColumns = this.getDisplayedColumns();
        }

    }

    private carregaConfiguracoesFiltro() {
        if (this.chaveFiltro) {
            this._spinner.show();
            this.filtroService.findByChave(this.chaveFiltro).subscribe(
                result => {
                    this._spinner.hide();
                    const filtroSalvarDados = result.data
                    const colunas = filtroSalvarDados.grid ? JSON.parse(filtroSalvarDados.grid) : undefined
                    this.setCamposHabilitados(colunas)
                },
                error => {
                    this._spinner.hide();
                }
            )
        }
        else {
            this.setCamposHabilitados(undefined);
        }

    }

    configurarGrid() {
        if (this.chaveFiltro) {
            this.filtroModalService.configuraGrid({ campos: this.configDisplayedColumns(), chave: this.chaveFiltro }).subscribe(result => {
                if (result) {
                    this.carregaConfiguracoesFiltro();
                }
            })
        }
    }

    private getDisplayedColumns(): Array<string> {
        const lista: Array<string> = [];
        const colunas = this.configDisplayedColumns();

        colunas.forEach(col => lista.push(col.chave));
        return lista
    }


}
