import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ChecklistPesquisa } from '../../shared/interfaces/checklist-pesquisa.interface';

@Component({
    selector: 'modal-checklist-pesquisa',
    templateUrl: './modal-checklist-pesquisa.component.html',
    styleUrls: ['./modal-checklist-pesquisa.component.scss'],
})
export class ModalChecklistPesquisaComponent implements OnInit {
    tituloModal: string = 'Selecione o checklist';

    constructor(
        public dialogRef: MatDialogRef<ModalChecklistPesquisaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
    ngOnInit() {}

    selecionado(checklist: ChecklistPesquisa) {
        this.dialogRef.close(checklist);
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
