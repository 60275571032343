import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { ContratoHistorico } from '../interfaces/contrato-historico.interface';
import { FiltroContratoHistorico } from '../interfaces/filtro-contrato-historico.interface';

@Injectable({
    providedIn: 'root',
})
export class ContratoHistoricoService extends ServiceGridBase<
    ContratoHistorico,
    FiltroContratoHistorico
> {
    private path: string = `${environment.server}/contrato/historico`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroContratoHistorico
    ): Observable<Response<Array<ContratoHistorico>>> {
        const params: string = filtro
            ? this.toGetParams(filtro, ['idContrato'])
            : '';
        const url = `${this.path}/${filtro.idContrato}${params}`;
        return this._http.get<Response<Array<ContratoHistorico>>>(url);
    }
}
