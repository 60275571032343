import { Component, OnInit } from '@angular/core';
import { FiltroFaturamento } from '../../shared/interfaces/filtro-faturamento.interface';

@Component({
    selector: 'faturamento-grid-page',
    templateUrl: './faturamento-grid-page.component.html',
    styleUrls: ['./faturamento-grid-page.component.scss'],
})
export class FaturamentoGridPageComponent implements OnInit {
    filtroSetup: FiltroFaturamento;
    filtroRecorrente: FiltroFaturamento;

    constructor() { }

    ngOnInit() { }

    filtrarSetup(filtroSetup: FiltroFaturamento) {
        this.filtroSetup = Object.assign({}, filtroSetup);
    }

    filtrarRecorrente(filtroRecorrente: FiltroFaturamento) {
        this.filtroRecorrente = Object.assign({}, filtroRecorrente);
    }
}
