import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ContratoItem } from '../../shared/interfaces/contrato-item.interface';

@Component({
    selector: 'modal-contrato-atualizar-vigencia',
    templateUrl: './modal-contrato-atualizar-vigencia.component.html',
    styleUrls: ['./modal-contrato-atualizar-vigencia.component.scss'],
})
export class ModalContratoAtualizarVigenciaComponent implements OnInit {
    tituloModal: string = 'Atualizar vigência';

    constructor(
        public dialogRef: MatDialogRef<ModalContratoAtualizarVigenciaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: number
    ) { }
    ngOnInit() { }

    fechar(): void {
        this.dialogRef.close();
    }

    atualizado(val) {
        this.dialogRef.close(val);
    }
}
