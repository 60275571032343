import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Empresa } from '../interfaces/empresa.interface';
import { EmpresaService } from '../services/empresa.service';

@Injectable()
export class EmpresaListaResolver implements Resolve<Empresa> {
    constructor(private _empresaService: EmpresaService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        return this._empresaService
            .find({ idChecklist: id })
            .pipe(map((result) => result.data));
    }
}
