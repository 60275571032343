import { Component, Input, OnInit } from '@angular/core';
import { ChecklistConfiguracaoArquivo } from '../../shared/interfaces/checklist-configuracao-arquivo.interface';

@Component({
    selector: 'checklist-configuracao-arquivo',
    templateUrl: './checklist-configuracao-arquivo.component.html',
    styleUrls: ['./checklist-configuracao-arquivo.component.scss'],
})
export class ChecklistConfiguracaoArquivoComponent implements OnInit {
    @Input() arquivo: ChecklistConfiguracaoArquivo;
    @Input() isView: boolean = false;
    atualizarDownloads: any;

    constructor() { }

    ngOnInit() { }

    uploadFinalizado(isFinalizado) {
        if (isFinalizado) {
            this.atualizarDownloads = Object.assign({}, { isAtualizar: true });
        }
    }
}
