import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CotacaoItemCotacao } from '../../shared/interfaces/cotacao-item-cotacao.interface';

@Component({
    selector: 'modal-cotacao-banco-contratado',
    templateUrl: './modal-cotacao-banco-contratado.component.html',
    styleUrls: ['./modal-cotacao-banco-contratado.component.scss',],
})
export class ModalCotacaoBancoContratadoComponent
    implements OnInit {
    tituloModal: string = 'Bancos contratados';

    constructor(
        public dialogRef: MatDialogRef<ModalCotacaoBancoContratadoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: CotacaoItemCotacao
    ) { }
    ngOnInit() { }

    fechar(): void {
        this.dialogRef.close();
    }
}
