import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OsHistorico } from '../../shareds/interfaces/os-historico.interface';

@Component({
    selector: 'modal-os-historico',
    templateUrl: './modal-os-historico.component.html',
    styleUrls: ['./modal-os-historico.component.scss'],
})
export class ModalOsHistoricoComponent implements OnInit {
    tituloModal: string = 'Historico da OS';

    constructor(
        public dialogRef: MatDialogRef<ModalOsHistoricoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: OsHistorico
    ) {}
    ngOnInit() {}

    fechar(): void {
        this.dialogRef.close();
    }

    selecionar(osHistorico: OsHistorico) {
        this.dialogRef.close(osHistorico);
    }
}
