import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Response } from '@utils/interfaces/http/response.interface';
import * as internal from 'assert';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { FiltroDocumentoArquivo } from '../interfaces/filtro-documento-arquivo.interface';

@Injectable({
    providedIn: 'root',
})
export class DocumentImportService extends ServiceBase<any> {
    private path = `${environment.server}/moduloDocumentoArquivo`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(
        filtro?: FiltroDocumentoArquivo
    ): Observable<Response<Array<FiltroDocumentoArquivo>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/${filtro.arquivo_chave}`;
        return this._http.get<Response<Array<FiltroDocumentoArquivo>>>(url);
    }

    findAll(
        chaveDocumentoArquivo: string
    ): Observable<Response<Array<FiltroDocumentoArquivo>>> {
        const url = `${this.path}/${chaveDocumentoArquivo}`;
        return this._http.get<Response<Array<FiltroDocumentoArquivo>>>(url);
    }

    download(idDocumentoArquivo: number): Observable<Response<any>> {
        const url = `${this.path}/download/${idDocumentoArquivo}`;
        return this._http.get<Response<Array<any>>>(url);
    }

    upload(file: any, origem: string, data: any): Observable<any> {
        let url = '';
        switch (origem) {
            case 'CHECKLIST_DADOS_EMPRESA':
                url = `${environment.server}/checklist/${data.checklist.id}/importacao-dados-empresas`;
                break;
            case 'CHECKLIST_EMPRESA':
                url = `${environment.server}/checklist/${data.checklist.id}/importacao-empresas`;
                break;
        }

        const formData = new FormData();
        formData.set('arquivo', file, file.name);

        return this._http.post(url, formData, {
            observe: 'events',
            reportProgress: true,
        });
    }
}
