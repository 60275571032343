import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { CotacaoSquad, FiltroCotacaoSquad } from '../../shared/interfaces/cotacao.squad.interface';
import { CotacaoSquadService } from '../../shared/services/cotacao-squad.service';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';

let ELEMENT_DATA: CotacaoSquad[] = []

@Component({
  selector: 'cotacao-squad-grid',
  templateUrl: './cotacao-squad-grid.component.html',
  styleUrls: ['./cotacao-squad-grid.component.scss']
})
export class CotacaoSquadGridComponent implements OnInit {

  @Input() squad: Array<any> = [];
  displayedColumns: string[] = ['nome', 'bloqueado'];
  dataSource: CotacaoSquad[];



  constructor(
    protected _service: CotacaoSquadService,
    protected _injector: Injector,
    protected _changeDetectorRefs: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    this.dataSource = ELEMENT_DATA = this.squad;
    console.log(this.dataSource)
  }

}
