import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@utils/utils.module';
import { ConfiguradorService } from './shareds/services/configurador.service';
import { ConfiguradorListaResolver } from './shareds/resolvers/configurador-lista.resolver';
import { ConfiguradorLayoutListaResolver } from './shareds/resolvers/configurador-layout-lista.resolver';
import { ConfiguradorCustoTraficoListaResolver } from './shareds/resolvers/configurador-custo-trafico-lista.resolver';
import { ConfiguradorDefaultListaResolver } from './shareds/resolvers/configurador-default-list.resolver';
import { ConfiguradorFormatoListaResolver } from './shareds/resolvers/configurador-formato-list.resolver';
import { ConfiguradorTimestampRXListaResolver } from './shareds/resolvers/configurador-timestamp-rx-lista-resolver';
import { ConfiguradorTimestampTXListaResolver } from './shareds/resolvers/configurador-timestamp-tx-lista-resolver';
import { ConfiguradorInsertTimestampListaResolver } from './shareds/resolvers/configurador-insert-timestamp-lista.resolver';
import { ConfiguradorInsertSeqTypeListaResolver } from './shareds/resolvers/configurador-insert-seq-type-lista.resolver';
import { ConfiguradorCRLFListaResolver } from './shareds/resolvers/configurador-crlf-lista.resolver';
import { ConfiguradorInsertSeqListaResolver } from './shareds/resolvers/configurador-insert-seq-lista.resolver';
import { ConfiguradorRemoveAcentosListaResolver } from './shareds/resolvers/configurador-remove-acentos-lista.resolver';

@NgModule({
    declarations: [],

    imports: [CommonModule, UtilsModule],
    providers: [
        ConfiguradorService,
        ConfiguradorListaResolver,
        ConfiguradorLayoutListaResolver,
        ConfiguradorCustoTraficoListaResolver,
        ConfiguradorDefaultListaResolver,
        ConfiguradorFormatoListaResolver,
        ConfiguradorTimestampRXListaResolver,
        ConfiguradorTimestampTXListaResolver,
        ConfiguradorInsertTimestampListaResolver,
        ConfiguradorInsertSeqTypeListaResolver,
        ConfiguradorCRLFListaResolver,
        ConfiguradorInsertSeqListaResolver,
        ConfiguradorRemoveAcentosListaResolver,
    ]
})
export class ConfiguradorModule { }
