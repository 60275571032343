import {
    Component,
    EventEmitter,
    Injector,
    OnInit,
    Output,
} from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { CpfCnpjUtil } from '@utils/utils/class/cpfCnpj.util';
import { DataUtil } from '@utils/utils/class/data.util';
import { FaturamentoStatusFinanceiro } from '../../shared/interfaces/faturamento-status-financeiro.interface';
import { FaturamentoStatus } from '../../shared/interfaces/faturamento-status.interface';
import { FaturamentoTipoCobranca } from '../../shared/interfaces/faturamento-tipo-cobranca.interface';
import { FaturamentoTipoFaturamento } from '../../shared/interfaces/faturamento-tipo-faturamento.interface';
import { FiltroFaturamento } from '../../shared/interfaces/filtro-faturamento.interface';
import { FaturamentoRecorrenteService } from '../../shared/services/faturamento-recorrente.service';

@Component({
    selector: 'faturamento-grid-filtro-recorrente',
    templateUrl: './faturamento-grid-filtro-recorrente.component.html',
    styleUrls: ['./faturamento-grid-filtro-recorrente.component.scss'],
})
export class FaturamentoGridFiltroRecorrenteComponent
    extends FormFiltroBase<FiltroFaturamento>
    implements OnInit {

    listaStatus: Array<FaturamentoStatus> = [];
    listaStatusFinanceiro: Array<FaturamentoStatusFinanceiro> = [];
    listaTipoFaturamento: Array<FaturamentoTipoFaturamento> = [];
    listaTipoCobranca: Array<FaturamentoTipoCobranca> = [];

    @Output() filtrar = new EventEmitter<FiltroFaturamento>();

    constructor(
        injector: Injector,
        protected _faturamentoService: FaturamentoRecorrenteService
    ) {
        super('FaturamentoGridRecorrente', injector, FaturamentoGridRecorrenteFiltroCombos.combos());
    }

    ngOnInit() {
        this._route.data.subscribe((data) => {
            console.log(data);
            this.optionList.listaStatus = data.listaStatusRecorrente;
            this.optionList.listaStatusFinanceiro = data.listaStatusFinanceiro;
            this.optionList.listaTipoFaturamento = data.listaTipoFaturamento;
            this.optionList.listaTipoCobranca = data.listaTipoCobranca;

            this.refreshOptionsConfig();
        });
    }

    camposFiltro(): string[] {
        return [
            'status',
            'statusFinanceiro',
            'tipoFaturamento',
            'tipoCobranca',
            'empresa',
            'inscricao',
            'numeroContrato',
            'valorNota',
            'dataInicio',
            'dataFim',
            'numeroCotacao',
        ];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            status: [],
            statusFinanceiro: [],
            tipoFaturamento: [],
            tipoCobranca: [],
            empresa: [],
            inscricao: [],
            numeroContrato: [],
            valorNota: [],
            tipoData: ['data-emissao'],
            dataInicio: [],
            dataFim: [],
            numeroCotacao: [],
        };
    }

    filtrarExecEnter(event): void {
        if (event.keyCode === 13) {
            this.filtrarExec();
        }
    }

    filtrarExec(): void {
        const status = this.form.get('status').value;
        const statusFinanceiro = this.form.get('statusFinanceiro').value;
        const tipoFaturamento = this.form.get('tipoFaturamento').value;
        const tipoCobranca = this.form.get('tipoCobranca').value;
        const nomeEmpresa = this.form.get('empresa').value;
        const inscricaoEmpresa = this.form.get('inscricao').value;
        const numeroContrato = this.form.get('numeroContrato').value;
        const valorNota = this.form.get('valorNota').value;
        const tipoData = this.form.get('tipoData').value;
        const dataInicio = this.form.get('dataInicio').value;
        const dataFim = this.form.get('dataFim').value;
        const numeroCotacao = this.form.get('numeroCotacao').value;

        let dataInicioEmissao;
        let dataFimEmissao;
        let dataInicioPagamento;
        let dataFimPagamento;
        let dataInicioVencimento;
        let dataFimVencimento;

        if (tipoData === 'data-emissao') {
            dataInicioEmissao = dataInicio ? DataUtil.toDateBackend(dataInicio) : '';
            dataFimEmissao = dataInicio ? DataUtil.toDateBackend(dataFim) : '';
        }
        else if (tipoData === 'data-pagamento') {
            dataInicioPagamento = dataInicio ? DataUtil.toDateBackend(dataInicio) : '';
            dataFimPagamento = dataInicio ? DataUtil.toDateBackend(dataFim) : '';
        }
        else if (tipoData === 'data-vencimento') {
            dataInicioVencimento = dataInicio ? DataUtil.toDateBackend(dataInicio) : '';
            dataFimVencimento = dataInicio ? DataUtil.toDateBackend(dataFim) : '';
        }

        const filtro: FiltroFaturamento = {
            status,
            statusFinanceiro,
            tipoFaturamento,
            tipoCobranca,
            nomeEmpresa,
            valorNota,
            inscricaoEmpresa: CpfCnpjUtil.removeMask(inscricaoEmpresa),
            numeroContrato,
            dataInicioEmissao,
            dataFimEmissao,
            dataInicioPagamento,
            dataFimPagamento,
            dataInicioVencimento,
            dataFimVencimento,
            numeroCotacao
        };

        this.filtrar.emit(filtro);
    }
}

export class FaturamentoGridRecorrenteFiltroCombos {
    static combos() {
        return [
            {
                listName: 'listaStatus',
                filterName: 'filterStatus',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaStatusFinanceiro',
                filterName: 'filterStatusFinanceiro',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaTipoFaturamento',
                filterName: 'filterStatusFinanceiro',
                fieldValue: 'descricao',
            },
            {
                listName: 'listaTipoCobranca',
                filterName: 'filterStatusFinanceiro',
                fieldValue: 'descricao',
            },

        ];
    }
}