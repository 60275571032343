import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { ContratoHistoricoItem } from '../../shared/interfaces/contrato-historico-item.interface';
import { ContratoItem } from '../../shared/interfaces/contrato-item.interface';
import { FiltroContratoHistoricoItem } from '../../shared/interfaces/filtro-contrato-historico-item.interface';
import { ContratoHistoricoItemService } from '../../shared/services/contrato-historico-item.service';
import { ContratoModalService } from '../../shared/services/contrato-modal.service';

@Component({
    selector: 'contrato-historico-item',
    templateUrl: './contrato-historico-item.component.html',
    styleUrls: ['./contrato-historico-item.component.scss'],
})
export class ContratoHistoricoItemComponent
    extends GridBase<
    ContratoHistoricoItem,
    FiltroContratoHistoricoItem,
    ContratoHistoricoItemService
    >
    implements OnChanges, OnInit {
    private _contratoItem: ContratoItem;
    get contratoItem(): ContratoItem {
        return this._contratoItem;
    }
    @Input() set contratoItem(contratoItem: ContratoItem) {
        this._contratoItem = contratoItem;
        this.filtro.idContratoItem = contratoItem.id;
        this.initGrid(this.filtro || {});
    }

    constructor(
        protected _service: ContratoHistoricoItemService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _modalContratoHistoricoItemService: ContratoModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void {
        this.initGrid(this.filtro)
    }

    ngOnChanges(changes: SimpleChanges): void { }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'data', label: '' },
            { chave: 'item', label: '' },
            { chave: 'acao', label: '' },
            { chave: 'usuario', label: '' },
        ];
    }

    selecionar(contratoItemHistoricoItem: ContratoHistoricoItem) {
        this._modalContratoHistoricoItemService
            .historicoDetalhe(contratoItemHistoricoItem)
            .subscribe(() => { });
    }
}
