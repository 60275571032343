import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bi-jbs',
  templateUrl: './bi-jbs.component.html',
  styleUrls: ['./bi-jbs.component.scss']
})
export class BiJBSComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
