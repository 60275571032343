import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { GmudDocumentoDownload } from '../interfaces/gmud-documento-download.interface';

@Injectable({
    providedIn: 'root',
})
export class GmudDocumentoDownloadService extends ServiceBase<GmudDocumentoDownload> {
    private path: string = `${environment.server}/gmud`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(idGmud: number): Observable<Response<Array<GmudDocumentoDownload>>> {
        const url = `${this.path}/${idGmud}/documento`;
        return this._http.get<Response<Array<GmudDocumentoDownload>>>(url);
    }

    download(idDocumento: number): Observable<any> {
        const url = `${this.path}/documento/${idDocumento}/download`;
        return this._http.get<Response<GmudDocumentoDownload>>(url);
    }

    delete(idDocumento): Observable<any> {
        const url = `${this.path}/documento/${idDocumento}`;
        return this._http.delete(url);
    }
}
