import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { OsNotificacao } from '../../shareds/interfaces/os-notificacao.interface';

@Component({
    selector: 'modal-visualizar-corpo',
    templateUrl: './modal-visualizar-corpo.component.html',
    styleUrls: ['./modal-visualizar-corpo.component.scss'],
})
export class ModalVisualizarCorpoComponent extends ComponentBase implements OnInit {
    corpo: string = '';
    tituloModal: string = 'visualizar-corpo';

    constructor(
        public dialogRef: MatDialogRef<ModalVisualizarCorpoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _Injector: Injector
    ) {
        super(_Injector);
    this.tituloModal = `Notificação nº ${data.id}`;
    this.corpo = data.corpo;
    }
    ngOnInit() { }

    fechar(): void {
        this.dialogRef.close();
    }
}
