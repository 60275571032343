import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FaturamentoParcelaStatus } from '../interfaces/faturamento-parcela-status.interface';
import { FaturamentoParcelaStatusService } from '../services/faturamento-parcela-status.service';

@Injectable()
export class FaturamentoParcelaStatusResolver
    implements Resolve<Array<FaturamentoParcelaStatus>> {
    constructor(private _service: FaturamentoParcelaStatusService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._service
            .find()
            .pipe(map((result) => result.data));
    }
}
