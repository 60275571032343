import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalSalvarConfiguracaoFiltroComponent } from '../../components/modal-salvar-configuracao-filtro/modal-salvar-configuracao-filtro.component';
import { ModalSalvarConfiguracaoGridComponent } from '../../components/modal-salvar-configuracao-grid/modal-salvar-configuracao-grid.component';


@Injectable({
    providedIn: 'root',
})
export class FiltroModalService {
    constructor(public dialog: MatDialog) { }

    configuraFiltro(data: any) {
        const dialogRef = this.dialog.open(ModalSalvarConfiguracaoFiltroComponent, {
            width: '50%',
            data: data,
        });

        return dialogRef.afterClosed();
    }


    configuraGrid(data: any) {
        const dialogRef = this.dialog.open(ModalSalvarConfiguracaoGridComponent, {
            width: '50%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

}
