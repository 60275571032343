import { Component, OnInit } from '@angular/core';
import { FiltroContrato } from '../../shared/interfaces/filtro-contrato.interface';

@Component({
    selector: 'contrato-grid-page',
    templateUrl: './contrato-grid-page.component.html',
    styleUrls: ['./contrato-grid-page.component.scss'],
})
export class ContratoGridPageComponent implements OnInit {
    filtro: FiltroContrato;
    filtroPreContrato: FiltroContrato;

    constructor() { }

    ngOnInit() { }

    filtrar(filtro: FiltroContrato) {
        this.filtro = Object.assign({}, filtro);
    }

    filtrarPreContrato(filtroPreContrato: FiltroContrato) {
        this.filtroPreContrato = Object.assign({}, filtroPreContrato);
    }
}
