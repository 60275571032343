import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FaturamentoRecorrenteItemNota } from '../interfaces/faturamento-recorrente.interface';
import { FaturamentoRecorrenteItemService } from '../services/faturamento-recorrente-item.service';

@Injectable()
export class FaturamentoRecorrenteItemListaResolver implements Resolve<Array<FaturamentoRecorrenteItemNota>> {
    constructor(private _service: FaturamentoRecorrenteItemService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let id = route.params['id']
        return this._service.find({ idFaturamentoRecorrente: id }).pipe(map(result => result.data))
    }
}
