import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GmudHistorico } from '../../shared/interfaces/gmud-historico.interface';
import { Gmud } from '../../shared/interfaces/gmud.interface';
import { GmudHistoricoService } from '../../shared/services/gmud-historico.service';
import { FiltroGmudHistorico } from '../../shared/interfaces/filtro-gmud-historico.interface';
import { GmudModalService } from '../../shared/services/gmud-modal.service';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';

@Component({
    selector: 'gmud-historico',
    templateUrl: './gmud-historico.component.html',
    styleUrls: ['./gmud-historico.component.scss'],
})
export class GmudHistoricoComponent
    extends GridBase<
    GmudHistorico,
    FiltroGmudHistorico,
    GmudHistoricoService
    >
    implements OnChanges, OnInit {
    private _gmud: Gmud;
    get gmud(): Gmud {
        return this._gmud;
    }
    @Input() set gmud(gmud: Gmud) {
        this._gmud = gmud;
        this.filtro.idGmud = gmud.id;
        this.initGrid(this.filtro || {});
    }

    constructor(
        protected _service: GmudHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _modalGmudHistoricoService: GmudModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void { }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'data', label: '' },
            { chave: 'item', label: '' },
            { chave: 'acao', label: '' },
            { chave: 'usuario', label: '' },
        ];
    }

    selecionar(gmudHistorico: GmudHistorico) {
        this._modalGmudHistoricoService
            .historicoDetalhe(gmudHistorico)
            .subscribe(() => { });
    }
}
