import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { FaturamentoEmissor } from '../interfaces/faturamento-emissor.interface';

@Injectable({
    providedIn: 'root',
})
export class EmissorService extends ServiceBase<FaturamentoEmissor> {
    private path: string = `${environment.server}/tabelaDominio/FaturamentoEmissor`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroBase): Observable<Response<Array<FaturamentoEmissor>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<FaturamentoEmissor>>>(url);
    }
}
