import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalChecklistDsnameComponent } from '../../modal/modal-checklist-dsname/modal-checklist-dsname.component';
import { ChecklistDsnameModal } from '../interfaces/checklist-dsname-modal.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistDsnameModalService {
    constructor(public dialog: MatDialog) {}

    checklistDsname(data: ChecklistDsnameModal) {
        const dialogRef = this.dialog.open(ModalChecklistDsnameComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }
}
