import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bi-painel-suporte',
  templateUrl: './bi-painel-suporte.component.html',
  styleUrls: ['./bi-painel-suporte.component.scss']
})
export class BiPainelSuporteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
