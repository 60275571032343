import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { ContratoAtualizarValor } from '../interfaces/contrato-atuallizar-valor.interface';

@Injectable({
    providedIn: 'root',
})
export class ContratoAtualizarValorService extends ServiceBase<ContratoAtualizarValor> {
    private path = `${environment.server}/contratoatualizacaovalor`;

    constructor(private _http: HttpClient) {
        super();
    }

    update(atualizarValor: ContratoAtualizarValor): Observable<Response<ContratoAtualizarValor>> {
        const url = `${this.path}`;
        return this._http.put<Response<ContratoAtualizarValor>>(url, atualizarValor);
    }

}

