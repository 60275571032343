import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ImportacaoCotacaoDetalheModalComponent } from '../../modal/importacao-cotacao-detalhe-modal/importacao-cotacao-detalhe-modal.component';
import { ImportacaoCotacaoErroModalComponent } from '../../modal/importacao-cotacao-erro-modal/importacao-cotacao-erro-modal.component';
import { ImportacaoCotacaoUploadModalComponent } from '../../modal/importacao-cotacao-upload-modal/importacao-cotacao-upload-modal.component';
import { ImportacaoCotacao } from '../interfaces/importacao-cotacao.interface';

@Injectable({
    providedIn: 'root',
})
export class ImportacaoCotacaoModalService {
    constructor(public dialog: MatDialog) {}

    importacaoErro(data: ImportacaoCotacao): Observable<any> {
        const dialogRef = this.dialog.open(
            ImportacaoCotacaoErroModalComponent,
            {
                minWidth: '500px',
                width: '80%',
                minHeight: '200px',
                direction: 'ltr',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }

    importacaoDetalhe(data: ImportacaoCotacao): Observable<any> {
        const dialogRef = this.dialog.open(
            ImportacaoCotacaoDetalheModalComponent,
            {
                minWidth: '500px',
                width: '80%',
                minHeight: '200px',
                direction: 'ltr',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }

    upload(): Observable<any> {
        const dialogRef = this.dialog.open(
            ImportacaoCotacaoUploadModalComponent,
            {
                minWidth: '500px',
                width: '80%',
                minHeight: '200px',
                direction: 'ltr',
            }
        );

        return dialogRef.afterClosed();
    }
}
