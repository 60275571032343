import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { FiltroOsHistorico } from '../../shareds/interfaces/filtro-os-historico.interface';
import { OsHistorico } from '../../shareds/interfaces/os-historico.interface';
import { Os } from '../../shareds/interfaces/os.interface';
import { OsHistoricoService } from '../../shareds/services/os-historico.service';
import { OsHistoricoModalService } from '../../shareds/services/os-historico-modal.service';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';

@Component({
    selector: 'os-historico',
    templateUrl: './os-historico.component.html',
    styleUrls: ['./os-historico.component.scss'],
})
export class OsHistoricoComponent
    extends GridBase<OsHistorico, FiltroOsHistorico, OsHistoricoService>
    implements OnChanges, OnInit {
    private _os: Os;
    get os(): Os {
        return this._os;
    }
    @Input() set os(os: Os) {
        this._os = os;
        this.filtro.idOs = os.id;
        this.initGrid(this.filtro || {});
    }

    constructor(
        protected _service: OsHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _modalOsHistoricoService: OsHistoricoModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void { }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'data', label: '' },
            { chave: 'item', label: '' },
            { chave: 'acao', label: '' },
            { chave: 'usuario', label: '' },
        ];
    }

    selecionar(osHistorico: OsHistorico) {
        this._modalOsHistoricoService
            .historico(osHistorico)
            .subscribe(() => { });
    }
}
