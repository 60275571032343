import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@utils/utils.module';

import { ModuloService } from './shareds/services/modulo.service';
import { ModuloListaResolver } from './shareds/resolvers/modulo-lista.resolver';

@NgModule({
    declarations: [],

    imports: [CommonModule, UtilsModule],

    providers: [ModuloService, ModuloListaResolver],
})
export class ModuloModule { }
