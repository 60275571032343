import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Checklist } from '../../shared/interfaces/checklist.interface';

@Component({
    selector: 'modal-checklist-relacionamento-empresa-detalhe',
    templateUrl:
        './modal-checklist-relacionamento-empresa-detalhe.component.html',
    styleUrls: [
        './modal-checklist-relacionamento-empresa-detalhe.component.scss',
    ],
})
export class ModalChecklistRelacionamentoEmpresaDetalheComponent
    implements OnInit {
    tituloModal: string = 'Dados da Empresa';

    constructor(
        public dialogRef: MatDialogRef<ModalChecklistRelacionamentoEmpresaDetalheComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
    ngOnInit() {}

    fechar(): void {
        this.dialogRef.close();
    }
}
