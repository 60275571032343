import {
    Component,
    Inject,
    Injector,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalBase } from '@utils/base/modal-base/modal-base';
import { Usuario } from '../../shared/interfaces/usuario.interface';
import { UsuarioService } from '../../shared/services/usuario.service';

@Component({
    selector: 'usuario-detalhes-modal',
    templateUrl: './usuario-detalhes-modal.component.html',
    styleUrls: ['./usuario-detalhes-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UsuarioDetalhesModalComponent extends ModalBase implements OnInit {
    tituloModal = 'Dados do usuário';
    usuario: Usuario;

    constructor(
        public dialogRef: MatDialogRef<UsuarioDetalhesModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Usuario,
        private _usuarioService: UsuarioService,
        private _injector: Injector
    ) {
        super(_injector);
        this.usuario = {};
    }

    ngOnInit(): void {
        this._spinner.show();
        if (!this.data) {
            this._notification.error('Erro ao retornar os dados do usuário');
            console.log('Erro ao retornar os dados do usuário');
        }
        this._usuarioService.findById(this.data.id).subscribe(
            (usuario) => {
                this._spinner.hide();
                this.usuario = usuario.data;
            },
            (error) => {
                this._spinner.hide();
                this._notification.error(
                    'Erro ao retornar os dados do usuário'
                );
            }
        );
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
