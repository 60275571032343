import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OsMenu } from '../../shareds/enum/os-menu.enum';
import { Os } from '../../shareds/interfaces/os.interface';
import { FormBase } from '@utils/base/form-base/form-base';

@Component({
    selector: 'os-cadastro',
    templateUrl: './os-cadastro.component.html',
    styleUrls: ['./os-cadastro.component.scss'],
})
export class OsCadastroComponent implements OnInit {
    menuSelecionado: OsMenu;
    os: Os;

    constructor(private _route: ActivatedRoute) {
        this.os = {};
    }

    ngOnInit() {
        this.menuSelecionado = OsMenu.ABERTURA;
        this.os = {};
        this._route.data.subscribe((data) => {
            this.os = data.os;
        });
    }

    selecionarMenu(osMenu: OsMenu) {
        this.menuSelecionado = osMenu;
    }

    salvandoOs(os: Os) {
        if (os) {
            this.os = Object.assign({}, os);
        }
    }
}
