import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { ConfirmationService } from '@utils/utils/confirmation/confirmation.service';
import * as moment from 'moment';
import { Validators } from '@angular/forms';
import { ContratoAtualizarValorService } from '../../shared/services/contrato-atualizar-valor.service';
import { ContratoAtualizarValor } from '../../shared/interfaces/contrato-atuallizar-valor.interface';
import { DataUtil } from '@utils/utils/class/data.util';

@Component({
    selector: 'contrato-ajustar-valor',
    templateUrl: './contrato-ajustar-valor.component.html',
    styleUrls: ['./contrato-ajustar-valor.component.scss'],
})
export class ContratoAjustarValorComponent
    extends FormBase
    implements OnInit {

    isView: boolean = false;

    @Output() atualizado = new EventEmitter();

    private _idsContratoAtualizarValor: Array<number> = []

    get idsContratoAtualizarValor() {
        return this._idsContratoAtualizarValor;
    }

    @Input() set idsContratoAtualizarValor(idsContratoAtualizarValor: Array<number>) {
        const idsContrato = idsContratoAtualizarValor;
        this._idsContratoAtualizarValor = idsContratoAtualizarValor;
        if (idsContrato && idsContrato.length > 0) {
            this.isView = true;
        }
    }

    constructor(
        protected _injector: Injector,
        private _contratoAtualizarValorService: ContratoAtualizarValorService
    ) {

        super(_injector);

    }

    ngOnInit() {

    }

    protected crateForm(): { [key: string]: any } {
        return {
            valor: ['', Validators.required],
            dataBiller: ['', Validators.required],
        };
    }

    editar() {
        const dataBiller = this.form.get('dataBiller').value
        const valorAtualizado = this.trataValorFaturado(this.form.get('valor').value)
        const contratoReajuste: ContratoAtualizarValor = {
            valorAtualizado: valorAtualizado + '',
            dataBiller: dataBiller ? DataUtil.toDateBackend(dataBiller) : '',
            contratoItemId: this._idsContratoAtualizarValor

        };
        this._confirmation
            .confirm({
                titulo:
                    'Tem certeza que deseja realizar o reajuste?',
                mensagem:
                    'Esta alteração impacta nos Projetos relacionados.',
            })
            .subscribe((result) => {
                if (result) {
                    this.editarExec(contratoReajuste);
                }
            });
    }

    private editarExec(contratoReajuste) {
        this._spinner.show();
        this._contratoAtualizarValorService.update(contratoReajuste).subscribe(
            () => {
                this.sucess('Reajuste realizado com sucesso!');
                this.atualizado.emit('true');
            },
            (error) => this.error(error)
        );
    }

    private trataValorFaturado(valorFaturado: number) {
        let valor = valorFaturado + '';
        valor = valor.replace(',', '.');

        if (/[0-9]+\.[0-9]{2}/g.test(valor)) {
            return valor;
        } else if (/[0-9]+\.[0-9]{1}/g.test(valor)) {
            return valor + '0';
        } else if (/[0-9]+/g.test(valor)) {
            return valor + '.00';
        }
    }
}
