import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of} from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { ProjetoProcessoStatus } from '../interfaces/projeto-processo-status.interface';

@Injectable({
    providedIn: 'root'
})
export class ProjetoProcessoStatusService extends ServiceBase<ProjetoProcessoStatus> {
    private path: string = `${environment.server}/projeto-processo-status`
    
    constructor(private _http: HttpClient) {
        super()
    }
    
    find(filtro?: FiltroBase): Observable<Response<Array<ProjetoProcessoStatus>>>{
        const params: string = filtro ? this.toGetParams(filtro) : ''
        const url = `${this.path}${params}`
        return this._http.get<Response<Array<ProjetoProcessoStatus>>>(url)
        
    }
    
    
            
    
}
