import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroProjeto } from '../interfaces/filtro-projeto.interface';
import { Projeto, ProjetoRequest } from '../interfaces/projeto.interface';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable({
    providedIn: 'root',
})
export class ProjetoService extends ServiceGridBase<Projeto, FiltroProjeto> {
    private path: string = `${environment.server}/projeto`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroProjeto): Observable<Response<Array<Projeto>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/busca${params}`;
        return this._http.get<Response<Array<Projeto>>>(url);
    }

    findById(id: number | string): Observable<Response<Projeto>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Projeto>>(url);
    }

    findByIdCotacao(idCotacao: number): Observable<Response<Array<Projeto>>> {
        const url = `${this.path}/buscar-vinculo?idCotacao=${idCotacao}`;
        return this._http.get<Response<Array<Projeto>>>(url);
    }

    update(projeto: ProjetoRequest): Observable<Response<Projeto>> {
        const url = `${this.path}/${projeto.id}`;
        return this._http.put<Response<Projeto>>(url, projeto);
    }

    batchUpdate(projetos: ProjetoRequest): Observable<Response<Projeto>> {
        const url = `${this.path}/alterar-em-lote`;
        return this._http.put<Response<Projeto>>(url, projetos);
    }

    gerarRelatorio(filtro: FiltroProjeto) {
        console.log(filtro);
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${environment.server}/relatorio/projeto${params}`;
        return this._http.get<Response<any>>(url);
    }
}
