import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { FiltroChecklist } from '../interfaces/filtro-checklist.interface';
import { Response } from '@utils/interfaces/http/response.interface';
import { ChecklistStatus } from '../interfaces/checklist-status.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { ChecklistConfiguracaoDsname } from '../interfaces/checklist-configuracao-dsname.interface';

@Injectable({
    providedIn: 'root',
})
export class ChecklistConfiguracaoDsnameService extends ServiceBase<ChecklistConfiguracaoDsname> {
    private path = `${environment.server}/checklist/checklistConfiguracaoDsname`;

    constructor(private _http: HttpClient) {
        super();
    }

    findLimite(): Observable<Response<ChecklistConfiguracaoDsname>> {
        const url = `${this.path}/limite`;
        return this._http.get<Response<ChecklistConfiguracaoDsname>>(url);
    }
}
