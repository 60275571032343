import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { OsEmpresa } from '../interfaces/os-empresa.interface';
import { FiltroOsEmpresa } from '../interfaces/filtro-os-empresa.interface';

@Injectable({
    providedIn: 'root',
})
export class OsEmpresaService extends ServiceGridBase<
    OsEmpresa,
    FiltroOsEmpresa
> {
    private path: string = `${environment.server}/osempresa`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroOsEmpresa): Observable<Response<Array<OsEmpresa>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<OsEmpresa>>>(url);
    }

    save(empresa: OsEmpresa): Observable<Response<OsEmpresa>> {
        const url = `${this.path}`;
        return this._http.post<Response<OsEmpresa>>(url, empresa);
    }
}
