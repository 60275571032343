import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Usuario } from '../../shared/interfaces/usuario.interface';

@Component({
  selector: 'modal-pesquisa-usuario',
  templateUrl: './modal-pesquisa-usuario.component.html',
  styleUrls: ['./modal-pesquisa-usuario.component.scss']
})
export class ModalPesquisaUsuarioComponent implements OnInit {

  tituloModal: string = 'Selecione o usuário'

  constructor(
    public dialogRef: MatDialogRef<ModalPesquisaUsuarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    
  }
  ngOnInit() {
  }

  selecionado(usuario: Usuario) {
    this.dialogRef.close(usuario);
}

  fechar(): void {
    this.dialogRef.close();
  }
  
}