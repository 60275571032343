import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalEditarContratoComponent } from '../../modal/modal-editar-contrato/modal-editar-contrato.component';
import { ModalHistoricoContratoItemComponent } from '../../modal/modal-historico-contrato-item/modal-historico-contrato-item.component';
import { ModalHistoricoContratoComponent } from '../../modal/modal-historico-contrato/modal-historico-contrato.component';
import { ModalHistoricoDetalheContratoItemComponent } from '../../modal/modal-historico-detalhe-contrato-item/modal-historico-detalhe-contrato-item.component';
import { ModalHistoricoDetalheContratoComponent } from '../../modal/modal-historico-detalhe-contrato/modal-historico-detalhe-contrato.component';
import { ContratoEditar } from '../interfaces/contrato-editar.interface';
import { ContratoHistorico } from '../interfaces/contrato-historico.interface';
import { ModalContratoAtualizarReajusteComponent } from '../../modal/modal-contrato-atualizar-reajuste/modal-contrato-atualizar-reajuste.component';
import { ModalContratoAtualizarVigenciaComponent } from '../../modal/modal-contrato-atualizar-vigencia/modal-contrato-atualizar-vigencia.component';
import { Contrato } from '../interfaces/contrato.interface';
import { ContratoItem } from '../interfaces/contrato-item.interface';
import { ModalContratoAjustarValorComponent } from '../../modal/modal-contrato-ajustar-valor/modal-contrato-ajustar-valor.component';
import { ModalDocumentoContratoComponent } from '../../modal/modal-documento-contrato/modal-documento-contrato.component';
import { ModalContratoAddAditivoComponent } from '../../modal/modal-contrato-add-aditivo/modal-contrato-add-aditivo.component';

@Injectable({
    providedIn: 'root',
})
export class ContratoModalService {
    constructor(public dialog: MatDialog) { }

    cotacaoEditar(data: Array<number>) {
        const dialogRef = this.dialog.open(ModalEditarContratoComponent, {
            width: '50%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    atualizarReajuste(data: Array<number>) {
        const dialogRef = this.dialog.open(ModalContratoAtualizarReajusteComponent, {
            width: '400px',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    atualizarVigencia(data: number) {
        const dialogRef = this.dialog.open(ModalContratoAtualizarVigenciaComponent, {
            width: '400px',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    ajustarValor(data: Array<number>) {
        const dialogRef = this.dialog.open(ModalContratoAjustarValorComponent, {
            width: '400px',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    documento(data: Contrato) {
        const dialogRef = this.dialog.open(ModalDocumentoContratoComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }


    historico(data: Contrato) {
        const dialogRef = this.dialog.open(ModalHistoricoContratoComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    historicoDetalhe(data: ContratoHistorico) {
        const dialogRef = this.dialog.open(
            ModalHistoricoDetalheContratoComponent,
            {
                width: '90%',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }

    historicoItem(data: Contrato) {
        const dialogRef = this.dialog.open(ModalHistoricoContratoItemComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    historicoDetalheItem(data: ContratoHistorico) {
        const dialogRef = this.dialog.open(
            ModalHistoricoDetalheContratoItemComponent,
            {
                width: '90%',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }

    addAtivoContrato(data: number) {
        const dialogRef = this.dialog.open(
            ModalContratoAddAditivoComponent,
            {
                width: '90%',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }
}
