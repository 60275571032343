import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Produto } from '../interfaces/produto.interface';
import { ProdutoService } from '../services/produto.service';

@Injectable()
export class ProdutoListaResolver implements Resolve<Produto> {
    constructor(private _produtoService: ProdutoService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._produtoService.find({ naoMigrado: 1 }).pipe(map((result) => result.data));
    }
}
