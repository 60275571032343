import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Time } from '../interfaces/time.interface';
import { TimeService } from '../services/time.service';

@Injectable()
export class TimeListaResolver implements Resolve<Time> {
    constructor(private _osStatusService: TimeService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._osStatusService
            .find({})
            .pipe(map((result) => result.data));
    }
}
