import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConfiguracaoGuard } from './configuracao.guard';
import { ConfiguracaoPageComponent } from './pages/configuracao-page/configuracao-page.component';
import { ConfiguracaoDetalheResolver } from './shareds/resolvers/configuracao-detalhe.resolver';


const routes: Routes = [
  {
    path: '',
    canActivate: [ConfiguracaoGuard],
    canLoad: [ConfiguracaoGuard],
    resolve: {
      listaConfiguracoes: ConfiguracaoDetalheResolver
    },
    component: ConfiguracaoPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfiguracaoRoutingModule { }
