import { ChangeDetectorRef, Component, Injector, Input, ViewChild } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { FiltroRelatorio } from '../../shareds/interfaces/filtro-relatorio.interface';
import { Relatorio } from '../../shareds/interfaces/relatorio.interface';
import { RelatorioService } from '../../shareds/services/relatorio.service';

@Component({
  selector: 'relatorio-grid',
  templateUrl: './relatorio-grid.component.html',
  styleUrls: ['./relatorio-grid.component.scss']
})
export class RelatorioGridComponent extends GridBase<Relatorio, FiltroRelatorio, RelatorioService> {

  constructor(
    protected _service: RelatorioService,
    protected _injector: Injector,
    protected _changeDetectorRefs: ChangeDetectorRef,
  ) {
    super(_service, _injector, _changeDetectorRefs)
  }

  ngOnInit(): void {
    this.initGrid(this.filtro || {})
  }

  ngOnChanges(): void {
    this.atualizarGrid(this.filtro || {})
  }

  configDisplayedColumns(): Array<GridCollumn> {
    return [
      { chave: 'seleciona' },
      { chave: 'dataSolicitacao', label: '' },
      { chave: 'relatorio', label: '' },
      { chave: 'relatorioGeracaoStatus', label: '' },
    ]
  }

  download(relatorio: Relatorio): void {
    this._spinner.show();
    this._service.gerarRelatorio(relatorio).subscribe(
      (result) => {
        DownloadUtil.base64(
          result.data.arquivo,
          result.data.name,
          result.data.type
        );
        this._spinner.hide();
      },
      (error) => this.error(error)
    );
  }
}
