import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AclRoutingModule } from './acl-routing.module';
import { PerfilModule } from './perfil/perfil.module';
import { PermissaoModule } from './permissao/permissao.module';
import { AclGuard } from './acl.guard';
import { UtilsModule } from '@utils/utils.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        AclRoutingModule,
        PerfilModule,
        PermissaoModule,
        UtilsModule,
    ],
    providers: [AclGuard],
})
export class AclModule {}
