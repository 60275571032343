import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TabelaDominio } from 'app/main/tabela-dominio/shareds/interfaces/tabela-dominio.interface';
import { ContratoDominioService } from '../services/contrato-dominio.service';

@Injectable()
export class ContratoCnpjFinnetResolver implements Resolve<TabelaDominio> {
    constructor(private _contratoService: ContratoDominioService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._contratoService
            .findFinnetCNPJ()
            .pipe(map((result) => result.data));
    }
}
