import {
    ChangeDetectorRef,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { SelectionModel } from '@angular/cdk/collections';
import { Faturamento } from '../../shared/interfaces/faturamento.interface';
import { FiltroFaturamento } from '../../shared/interfaces/filtro-faturamento.interface';
import { FaturamentoModalService } from '../../shared/services/faturamento-modal.service';
import { FaturamentoStatus } from '../../shared/interfaces/faturamento-status.interface';
import { ModelBase } from '@utils/base/model-base/model-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FaturamentoRecorrenteService } from '../../shared/services/faturamento-recorrente.service';
import { FaturamentoRecorrenteStatus } from '../../shared/interfaces/faturamento-recorrente-status.interface';
import { TipoCobrancaRecorrente } from '../../shared/interfaces/faturamento-recorrente-tipo-cobranca.interface';
import { EmissorRecorrente } from '../../shared/interfaces/faturamento-recorrente-emissor.interface';
import { ContratoRecorrente } from '../../shared/interfaces/faturamento-recorrente-contrato.interface';
import { FaturamentoGridRecorrente } from '../../shared/interfaces/faturamento-recorrente-grid-interface';
import { FaturamentoModalRecorrenteService } from '../../shared/services/faturamento-modal-recorrente.service';

@Component({
    selector: 'faturamento-grid-recorrente',
    templateUrl: './faturamento-grid-recorrente.component.html',
    styleUrls: ['./faturamento-grid-recorrente.component.scss'],
})
export class FaturamentoGridRecorrenteComponent extends GridBase<FaturamentoGridRecorrente, FiltroFaturamento, FaturamentoRecorrenteService> implements OnInit, OnChanges {

    selection = new SelectionModel<FaturamentoGridRecorrente>(true, []);

    constructor(
        protected _faturamentoService: FaturamentoRecorrenteService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalFaturamento: FaturamentoModalService,
        private _modalFaturamentoEditar: FaturamentoModalRecorrenteService,
    ) {
        super(_faturamentoService, _injector, _changeDetectorRefs, 'FaturamentoGridRecorrente');
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {}, () => this.selection.clear());
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows =
            this.paginacao.pageSize <= this.paginacao.length
                ? this.paginacao.pageSize
                : this.paginacao.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.listaDataSource.data.forEach((row) =>
                this.selection.select(row)
            );
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: FaturamentoGridRecorrente): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
            }`;
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'select' },
            { chave: 'seleciona' },
            { chave: 'status', label: 'Status' },
            { chave: 'empresa', label: 'Empresa' },
            { chave: 'inscricao', label: 'Inscrição' },
            { chave: 'responsavel', label: 'Responsavel' },
            { chave: 'valorRecorrencia', label: 'Valor Recorrência' },
            { chave: 'valorNota', label: 'Valor da Nota' },
            { chave: 'dataPagamento', label: 'Data de Pagamento' },
            { chave: 'numeroContrato', label: 'Número de Contrato' },
            { chave: 'numeroCotacao', label: 'Cotacao' },
            { chave: 'dataEmissao', label: 'Data de Emissão' },
            { chave: 'vencimento', label: 'Vencimento' }
        ];
    }


    editarLote() {
        const faturamentosSelecionadas = this.selection.selected.map((v) => v.id);
        this._modalFaturamentoEditar
            .faturamentoEditar({ idFaturamentoRecorrente: faturamentosSelecionadas })
            .subscribe((result) => {
                if (result) {
                    this.selection.clear()
                    this.atualizarGrid(this.filtro);
                }
            });
    }

    editar(faturamentoGrid: FaturamentoGridRecorrente) {
        this._router.navigateByUrl(`faturamento/recorrente/${faturamentoGrid.id}`)
    }

    visualizar(faturamentoGrid: FaturamentoGridRecorrente) {
        this._router.navigateByUrl(`faturamento/recorrente/${faturamentoGrid.id}/visualizar`)
    }

    historico(faturamento: Faturamento) {
        this._modalFaturamento.historico(faturamento);
    }

    emitirNota() {
        const faturamentosSelecionadas = this.selection.selected.map((v) => v.id);
        let emt = this._faturamentoService.emitirNota(faturamentosSelecionadas)
            .subscribe((result) => {
                if (result) {
                    this.selection.clear();
                    this._notification.sucess('Emissão de notas', 'Notas Emitidas com sucesso');
                }
            }, (error) => this.error(error));
    }

    gerarRelatorio() {
        this._spinner.show();
        this._faturamentoService.gerarRelatorio(this.filtro).subscribe(
            (result) => {
                this.sucess('Seu relatório está em processamento, acompanhe a tela de retirada de relatórios para download');
            },
            (error) => this.error(error)
        );
    }

}
