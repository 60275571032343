import {
    ChangeDetectorRef,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { environment } from 'environments/environment';
import { ChecklistGrid } from '../../shared/interfaces/checklist-grid.interface';
import { Checklist } from '../../shared/interfaces/checklist.interface';
import { FiltroChecklist } from '../../shared/interfaces/filtro-checklist.interface';
import { ChecklistModalService } from '../../shared/services/checklist-modal.service';
import { ChecklistService } from '../../shared/services/checklist.service';

@Component({
    selector: 'checklist-grid',
    templateUrl: './checklist-grid.component.html',
    styleUrls: ['./checklist-grid.component.scss'],
})
export class ChecklistGridComponent extends GridBase<ChecklistGrid, FiltroChecklist, ChecklistService> implements OnChanges, OnInit {

    constructor(
        protected _service: ChecklistService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalChecklist: ChecklistModalService
    ) {
        super(_service, _injector, _changeDetectorRefs, 'ChecklistGrid');
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {});
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona', label: '' },
            { chave: 'status', label: 'Status' },
            { chave: 'cotacao', label: 'N. da cotação' },
            { chave: 'nomeEmpresa', label: 'Empresa' },
            { chave: 'qtdeProjeto', label: 'Quantidade de Projetos' },
            { chave: 'dataCriacao', label: 'Data de criação' },
            { chave: 'dataPagamentoSetup', label: 'Data de pagamento setup' },
        ];
    }

    visualizar(checklist: Checklist): void {
        //this._router.navigate(['checklist', checklist.id, 'visualizar']);
        this._router.navigate(['checklist', checklist.id, 'visualizar', 'contato']);
    }

    editar(checklist: Checklist): void {
        this._router.navigate(['checklist', checklist.id, 'contato']);
    }

    historico(checklist: Checklist) {
        this._modalChecklist.historico(checklist);
    }

    gerarRelatorio(): void {
        this._spinner.show();
        this._service.gerarRelatorio(this.filtro).subscribe(
            (result) => {
                // DownloadUtil.base64(
                //     result.data.arquivo,
                //     result.data.name,
                //     result.data.type
                // );
                this.sucess('Seu relatório está em processamento, acompanhe a tela de retirada de relatórios para download');
            },
            (error) => this.error(error)
        );
    }

    visualizarCotacao(idCotacao) {
        const link = `${environment.host}/cotacao/${idCotacao}`;
        this._router.navigate([]).then((result) => {
            window.open(link, '_blank');
        });
    }
}
