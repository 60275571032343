import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Contrato } from '../../shared/interfaces/contrato.interface';
import { FiltroAdendoContrato } from '../../shared/interfaces/filtro-adendo.interface';
import { ContratoAdendoService } from '../../shared/services/contrato-adendo.service';
import { ContratoModalService } from '../../shared/services/contrato-modal.service';

@Component({
  selector: 'contrato-adendo-grid',
  templateUrl: './contrato-adendo-grid.component.html',
  styleUrls: ['./contrato-adendo-grid.component.scss']
})
export class ContratoAdendoGridComponent extends GridBase<Contrato, FiltroAdendoContrato, ContratoAdendoService> implements OnInit, OnChanges {

  selection = new SelectionModel<Contrato>(true, []);
  @Input() filtroAditivo: any;
  @Input() set atualizarAditivoGrid(isAtualiza: boolean) {
    this.atualizarGrid();
  }

  constructor(
    protected _service: ContratoAdendoService,
    protected _injector: Injector,
    protected _changeDetectorRefs: ChangeDetectorRef,
    private _modalContrato: ContratoModalService,
  ) {
    super(_service, _injector, _changeDetectorRefs, 'ContratoAdendoGrid')
  }

  async ngOnInit() {
    this._route.data.subscribe((data) => {
      this.filtro = this.filtro ? this.filtro : {};
      this.filtro.idContrato = data.contrato.id
      this.filtroAditivo = this.filtro;
      this.initGrid(this.filtroAditivo);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.atualizarGrid(this.filtroAditivo || {}, () => this.selection.clear());
  }

  configDisplayedColumns(): GridCollumn[] {
    return [
      { chave: 'select' },
      { chave: 'seleciona' },
      { chave: 'status', label: 'Status do Contrato' },
      { chave: 'statusSeg', label: 'Status Secundário' },
      { chave: 'situacao', label: 'Situação' },
      { chave: 'empresa', label: 'Nome da Empresa' },
      { chave: 'inscricao', label: 'inscrição' },
      { chave: 'numeroContrato', label: 'N° Contrato' },
      { chave: 'dataCriacao', label: 'Data Criação' },
      { chave: 'dataVigencia', label: 'Data Vigência' },
      { chave: 'dataAssinatura', label: 'Data Assinatura' },
      { chave: 'numeroCotacao', label: 'N° Cotação' },
    ]
  }

  visualizar(contrato: Contrato) {
    this._router.navigateByUrl(`contrato/${contrato.id}`);
  }

  historico(contrato: Contrato) {
    this._modalContrato.historico(contrato);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows =
      this.paginacao.pageSize <= this.paginacao.length
        ? this.paginacao.pageSize
        : this.paginacao.length;
    return numSelected === numRows && numSelected != 0 && numRows != 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listaDataSource.data.forEach((row) =>
        this.selection.select(row)
      );
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Contrato): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }
}
