import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, ElementRef, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { OsDocumentoDownload } from '../../shareds/interfaces/os-documento-download.interface';
import { OsDocumentoUpload } from '../../shareds/interfaces/os-documento-upload.interface';
import { Os } from '../../shareds/interfaces/os.interface';
import { OsDocumentoDownloadService } from '../../shareds/services/os-documento-download.service';
import { OsDocumentoUploadService } from '../../shareds/services/os-documento-upload.service';

@Component({
    selector: 'os-documentacao',
    templateUrl: './os-documentacao.component.html',
    styleUrls: ['./os-documentacao.component.scss'],
})
export class OsDocumentacaoComponent extends ComponentBase implements OnInit {
    arquivosDownloads: Array<OsDocumentoDownload> = [];
    @Input() os: Os;

    arquivo: OsDocumentoUpload;
    @ViewChild('fileDropRef', { static: false }) fileDropEl: ElementRef;
    files: Array<any>;
    uploaded: Boolean;

    constructor(
        private _osDocumentoUploadService: OsDocumentoUploadService,
        private _osDocumentoDownloadService: OsDocumentoDownloadService,
        private _injector: Injector
    ) {
        super(_injector);
        this.os = {};
    }

    ngOnInit() {
        this.atualizaListaDownloads();
    }

    // ==========================================
    //                  UPLOAD
    // ==========================================
    onFileDropped($event): void {
        this.prepareFile($event);
    }

    fileBrowseHandler(files): void {
        this.prepareFile(files);
    }

    deleteFile(file, i): void {
        if (file.progress > 0 && file.progress < 100) {
            return;
        }
        this.files.splice(i, 1);
    }

    deleteFiles(): void {
        this.files = null
    }

    upload(): void {
        this.uploaded = true
        if (this.os.id) {
            this.files.forEach((file, i) => {
                this._osDocumentoUploadService
                    .upload(this.os.id, file)
                    .subscribe(
                        (event: HttpEvent<any>) => {
                            if (event.type === HttpEventType.Response) {
                                this.sucess(
                                    'Upload realizado com sucesso!'
                                );

                                if (this.files) {
                                    if (this.files.length >= i + 1) {
                                        this.uploaded = false
                                        this.deleteFiles();
                                        this.atualizaListaDownloads();
                                    }
                                }
                            } else if (
                                event.type === HttpEventType.UploadProgress
                            ) {
                                file.progress = Math.round(
                                    (event.loaded * 100) / event.total
                                );
                            }
                        },
                        (error) => {
                            //this.error('Erro no upload do arquivo!');
                            this._notification.error(error.error.errors.documento[0]);
                        }
                    );
            });
        }
    }

    prepareFile(files: FileList): void {
        this.uploaded = false
        this.files = Array.from(files);
        this.files.forEach(file => {
            file.progress = 0;
        })

        this.fileDropEl.nativeElement.value = '';
    }

    formatBytes(bytes, decimals = 2): string {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return (
            parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
        );
    }
    // ==========================================
    //                  DOWNLOAD
    // ==========================================
    download(arquivoDownload: OsDocumentoDownload): void {
        this._spinner.show();
        this._osDocumentoDownloadService.download(arquivoDownload.id).subscribe(
            (result) => {
                DownloadUtil.base64(
                    result.data.arquivo,
                    result.data.name,
                    result.data.type
                );
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
    }

    remover(arquivoDownload: OsDocumentoDownload): void {
        this._confirmation
            .confirm({ titulo: 'Deseja excluir esse arquivo?' })
            .subscribe((result) => {
                if (result) {
                    this._spinner.show();
                    this._osDocumentoDownloadService
                        .delete(arquivoDownload.id)
                        .subscribe(
                            () => {
                                this.sucess(
                                    'Arquivo removido com sucesso!'
                                );
                                this.atualizaListaDownloads();
                            },
                            (error) => this.error(error)
                        );
                }
            });
    }

    private atualizaListaDownloads() {
        this._spinner.show();
        this._osDocumentoDownloadService.find(this.os.id).subscribe(
            (arquivos) => {
                this.arquivosDownloads = arquivos.data;
                this._spinner.hide();
            },
            (error) => {
                this.error(error);
            }
        );
    }
}
