import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class RelacionamentoAberturaGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [
            { path: '/relacionamento-abertura', role: Role.RELACIONAMENTO_ABERTURA_LISTAR },
            { path: '/relacionamento-abertura/[0-9]+', role: Role.RELACIONAMENTO_ABERTURA_EXIBIR },
        ];
    }
}
