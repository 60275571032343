import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { CotacaoEditar } from '../interfaces/cotacao-editar.interface';

@Injectable({
    providedIn: 'root',
})
export class CotacaoEdicaoService extends ServiceBase<CotacaoEditar> {
    private path = `${environment.server}/cotacao`;

    constructor(private _http: HttpClient) {
        super();
    }

    update(cotacaoEdicao: CotacaoEditar): Observable<Response<CotacaoEditar>> {
        const url = `${this.path}`;
        return this._http.put<Response<CotacaoEditar>>(url, cotacaoEdicao);
    }
}
