import { ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FiltroImportacaoOsDetalhe } from '../../shared/interfaces/filtro-importacao-os-detalhe.interfaces';
import { ImportacaoOsDetalhe } from '../../shared/interfaces/importacao-os-detalhe.interfaces';
import { ImportacaoOs } from '../../shared/interfaces/importacao-os.interfaces';
import { ImportacaoOsDetalheService } from '../../shared/services/importacao-os-detalhe.service';

@Component({
  selector: 'importacao-os-detalhe',
  templateUrl: './importacao-os-detalhe.component.html',
  styleUrls: ['./importacao-os-detalhe.component.scss']
})
export class ImportacaoOsDetalheComponent
  extends GridBase<
  ImportacaoOsDetalhe,
  FiltroImportacaoOsDetalhe,
  ImportacaoOsDetalheService
  >
  implements OnInit {
  @Input() importacao: ImportacaoOs = {};

  constructor(
    service: ImportacaoOsDetalheService,
    injector: Injector,
    changeDetectorRefs: ChangeDetectorRef
  ) {
    super(service, injector, changeDetectorRefs);
  }

  ngOnInit(): void {
    this.filtro.idArquivo = this.importacao.id;
    this.initGrid(this.filtro);
  }

  configDisplayedColumns(): Array<GridCollumn> {
    return [
      { chave: 'nome', label: '' },
      { chave: 'empresaRazaoSocial', label: '' },
      { chave: 'osNumero', label: '' },
      { chave: 'dataAceite', label: '' },
      { chave: 'valorTotal', label: '' },
    ];
  }
}
