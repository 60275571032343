import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentImportInterface } from '../interfaces/document-import.interface';
import { DocumentImportService } from '../services/document-import.service';

@Injectable()
export class DocumentoImporteChecklistDadosEmpresaListaResolver
    implements Resolve<DocumentImportInterface> {
    constructor(
        private _service: DocumentImportService
    ) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._service
            .find({ arquivo_chave: 'checklist-empresas' })
            .pipe(
                map((result) => result.data || [])
            );
    }
}
