import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalHistoricoBancoDetalheComponent } from '../../modal/modal-historico-banco-detalhe/modal-historico-banco-detalhe.component';
import { ModalHistoricoBancoComponent } from '../../modal/modal-historico-banco/modal-historico-banco.component';
import { BancoHistorico } from '../interfaces/banco-historico.interface';
import { Banco } from '../interfaces/banco.interface';

@Injectable({
  providedIn: 'root'
})
export class BancoModalService {

  constructor(public dialog: MatDialog) { }

  historic(data: Banco) {
    const dialogRef = this.dialog.open(ModalHistoricoBancoComponent, {
      width: '90%',
      data: data,
    });

    return dialogRef.afterClosed();
  }

  historicoDetalhe(data: BancoHistorico) {
    const dialogRef = this.dialog.open(
      ModalHistoricoBancoDetalheComponent,
      {
        width: '90%',
        data: data,
      }
    );

    return dialogRef.afterClosed();
  }
}
