import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class OsGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [
            { path: '/os', role: Role.CHECKLIST_LISTAR },
            { path: '/os/cadastro', role: Role.CHECKLIST_LISTAR },
            { path: '/os/[0-9]+/editar', role: Role.CHECKLIST_LISTAR },
        ];
    }
}
