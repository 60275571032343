import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Checklist } from '../interfaces/checklist.interface';
import { FiltroChecklist } from '../interfaces/filtro-checklist.interface';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { ChecklistGrid } from '../interfaces/checklist-grid.interface';
import { ChecklistProdutoPortal } from '../interfaces/checklist-produto-portal.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ChecklistService extends ServiceGridBase<
ChecklistGrid,
FiltroChecklist
> {
    private path = `${environment.server}/checklist`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroChecklist): Observable<Response<Array<ChecklistGrid>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<ChecklistGrid>>>(url);
    }

    findById(id: number | string): Observable<Response<Checklist>> {
        const url = `${this.path}/${id}`;
        return this._http.get<Response<Checklist>>(url);
    }

    finalizar(idChecklist: number): Observable<any> {
        const url = `${this.path}/${idChecklist}/finalizar`;
        return this._http.put(url, {});
    }

    finalizarStep(idChecklist: number): Observable<any> {
        const url = `${this.path}/${idChecklist}/finalizar-steps`;
        return this._http.put(url, {});
    }

    gerarRelatorio(filtro: FiltroChecklist) {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${environment.server}/relatorio/checklist${params}`;
        return this._http.get<Response<any>>(url);
    }

    produtosPortal(
        idChecklist: number
    ): Observable<Response<Array<ChecklistProdutoPortal>>> {
        const url = `${this.path}/${idChecklist}/checklist-produto-portal`;
        return this._http.get<Response<Array<ChecklistProdutoPortal>>>(url);
    }
}
