import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { ContratoHistoricoItem } from '../../shared/interfaces/contrato-historico-item.interface';
import { ContratoHistoricoItemService } from '../../shared/services/contrato-historico-item.service';

@Component({
    selector: 'contrato-historico-detalhe-item',
    templateUrl: './contrato-historico-detalhe-item.component.html',
    styleUrls: ['./contrato-historico-detalhe-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ContratoHistoricoDetalheItemComponent implements OnChanges, OnInit {
    @Input() historico: ContratoHistoricoItem = {};

    constructor(
        protected _service: ContratoHistoricoItemService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) {}

    ngOnInit(): void {}

    ngOnChanges(): void {}
}
