import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OsEmpresa } from '../../shareds/interfaces/os-empresa.interface';

@Component({
    selector: 'modal-os-empresa-cadastro',
    templateUrl: './modal-os-empresa-cadastro.component.html',
    styleUrls: ['./modal-os-empresa-cadastro.component.scss'],
})
export class ModalOsEmpresaCadastroComponent implements OnInit {
    tituloModal: string = 'Cadastro de empresa';

    constructor(
        public dialogRef: MatDialogRef<ModalOsEmpresaCadastroComponent>,
        @Inject(MAT_DIALOG_DATA) public empresa: OsEmpresa
    ) {}

    ngOnInit() {}

    fechar(): void {
        this.dialogRef.close();
    }
}
