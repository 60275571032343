import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { Gmud } from '../interfaces/gmud.interface';
import { FiltroGmud } from '../interfaces/filtro-gmud.interface';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { FiltroGmudConfiguracaoAprovacao } from '../interfaces/filtro-gmud-configuracao-aprovacao.interface';
import { GmudConfiguracaoAprovacao, GmudConfiguracaoAprovacaoRequest } from '../interfaces/gmud-configuracao-aprovacao.interface';

@Injectable({
    providedIn: 'root',
})
export class GmudConfiguracaoAprovacaoService extends ServiceGridBase<Gmud, FiltroGmud> {
    private path = `${environment.server}/gmudAprovacao`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroGmudConfiguracaoAprovacao): Observable<Response<Array<GmudConfiguracaoAprovacao>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<GmudConfiguracaoAprovacao>>>(url);
    }

    save(gmudConfiguracaoAprovacao: GmudConfiguracaoAprovacaoRequest): Observable<Response<GmudConfiguracaoAprovacao>> {
        if (gmudConfiguracaoAprovacao.id) {
            return this.update(gmudConfiguracaoAprovacao);
        }
        else {
            return this.create(gmudConfiguracaoAprovacao);
        }
    }

    create(gmudConfiguracaoAprovacao: GmudConfiguracaoAprovacaoRequest): Observable<Response<GmudConfiguracaoAprovacao>> {
        const url = `${this.path}`;
        return this._http.post<Response<GmudConfiguracaoAprovacao>>(url, gmudConfiguracaoAprovacao);
    }

    update(gmudConfiguracaoAprovacao: GmudConfiguracaoAprovacaoRequest): Observable<Response<GmudConfiguracaoAprovacao>> {
        const url = `${this.path}/${gmudConfiguracaoAprovacao.id}`;
        return this._http.put<Response<GmudConfiguracaoAprovacao>>(url, gmudConfiguracaoAprovacao);
    }

    delete(gmudConfiguracaoAprovacao: GmudConfiguracaoAprovacao): Observable<Response<void>> {
        const url = `${this.path}/${gmudConfiguracaoAprovacao.id}`;
        return this._http.delete<Response<void>>(url);
    }
}
