import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import { AuthLocalStorageService } from '@utils/services/auth-local-storage.service';
import { PermissaoUtil } from '@utils/utils/class/permissao.utl';

@Directive({
    selector: '[role]',
})
export class RoleDirective implements OnInit {
    usuarioLogado: UsuarioLogado;

    @Input('role') roleStr: string;

    constructor(
        private container: ViewContainerRef,
        private template: TemplateRef<any>,
        private _authLocalStorage: AuthLocalStorageService
    ) {
        this.usuarioLogado = _authLocalStorage.getUsuario();
    }

    ngOnInit(): void {
        let isAccess: boolean;
        if (!this.roleStr) {
            console.log('É necessário passa um parâmetro do tipo string!');
        }
        isAccess = PermissaoUtil.isAccess(this.usuarioLogado, this.roleStr);
        if (!isAccess) {
            this.container.remove();
        } else {
            this.container.createEmbeddedView(this.template);
        }
    }
}
