import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { ProjetoAberturaHistorico } from '../../shareds/interfaces/projeto-abertura-historico.interface';
import { ProjetoHistoricoService } from '../../shareds/services/projeto-historico.service';

@Component({
    selector: 'projeto-abertura-historico-detalhe',
    templateUrl: './projeto-abertura-historico-detalhe.component.html',
    styleUrls: ['./projeto-abertura-historico-detalhe.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ProjetoAberturaHistoricoDetalheComponent implements OnChanges, OnInit {
    @Input() historico: ProjetoAberturaHistorico = {};

    constructor(
        protected _service: ProjetoHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) { }

    ngOnInit(): void { }

    ngOnChanges(): void { }
}
