import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroOsHistorico } from '../interfaces/filtro-os-historico.interface';
import { OsHistorico } from '../interfaces/os-historico.interface';

@Injectable({
    providedIn: 'root',
})
export class OsHistoricoService extends ServiceGridBase<
    OsHistorico,
    FiltroOsHistorico
> {
    private path: string = `${environment.server}/os/historico`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroOsHistorico): Observable<Response<Array<OsHistorico>>> {
        const params: string = filtro ? this.toGetParams(filtro, ['idOs']) : '';
        const url = `${this.path}/${filtro.idOs}${params}`;
        return this._http.get<Response<Array<OsHistorico>>>(url);
    }
}
