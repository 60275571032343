import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { Time } from 'app/main/time/shareds/interfaces/time.interface';
import { TimeService } from 'app/main/time/shareds/services/time.service';
import { Usuario } from 'app/main/usuario/shared/interfaces/usuario.interface';
import { UsuarioService } from 'app/main/usuario/shared/services/usuario.service';
import { OsCategoria } from '../../shareds/interfaces/os-categoria.interface';
import { OsEditar } from '../../shareds/interfaces/os-editar.interface';
import { OsStatus } from '../../shareds/interfaces/os-status.interface';
import { OsCategoriaService } from '../../shareds/services/os-categoria.service';
import { OsEdicaoService } from '../../shareds/services/os-edicao.service';
import { OsStatusService } from '../../shareds/services/os-status.service';
import { OsAlerta } from '../../shareds/interfaces/os-alerta.interface';

@Component({
    selector: 'editar-os',
    templateUrl: './editar-os.component.html',
    styleUrls: ['./editar-os.component.scss'],
})
export class EditarOsComponent extends FormBase implements OnInit {
    private _osEditar: OsEditar;
    enviarBanco = true;
    enviarCliente = true;
    listaAlerta: OsAlerta[] = [];
    isView: boolean = false;
    isViewOsSemAbertura: boolean = false;
    needsWarning = false;
    osSemAberturas: Array<number> = [];
    listaStatusOs: Array<OsStatus> = [];
    listaCategorias: Array<OsCategoria> = [];
    listaResponsavel: Array<Usuario> = [];
    listaTime: Array<Time> = [];

    checkStatusOs: boolean = false;
    checkCategoria: boolean = false;
    checkResponsavel: boolean = false;
    checkTime: boolean = false;
    todosFull: boolean = false;
    checkPlannedDate: boolean = false;

    @Output() atualizado = new EventEmitter<boolean>();

    get idsOs() {
        return this._osEditar;
    }

    @Input() set idsOs(osEditar: OsEditar) {
        const ids = osEditar.oss.map(({ id }) => id);
        this._osEditar = osEditar;
        if (ids && ids.length > 0) {
            this.isView = true;
        }
    }

    constructor(
        private _injector: Injector,
        private _statusService: OsStatusService,
        private _categoriaService: OsCategoriaService,
        private _usuarioService: UsuarioService,
        private _timeService: TimeService,
        private _service: OsEdicaoService
    ) {
        super(_injector);
    }

    ngOnInit() {
        this.enviarBanco = true;
        this.enviarCliente = true;
        this._spinner.show();
        this._statusService.find({}).subscribe(
            (result) => {
                this.listaStatusOs = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
        this._categoriaService.find({}).subscribe(
            (result) => {
                this.listaCategorias = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
        this._usuarioService.find({ativo: 1,  tipo: 1 }).subscribe(
            (result) => {
                this.listaResponsavel = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
        this._timeService.find({}).subscribe(
            (result) => {
                this.listaTime = result.data;
                this._spinner.hide();
            },
            (error) => this.error(error)
        );
        this.hasNotOsAbertura();
    }

    hasNotOsAbertura(): void {
        if (this._osEditar.oss.every(({ categoria }) => "Mapeamento" === categoria.nome)) { // REGRA DE MAPEAMENTO
            return;
        }
        const ids = this._osEditar.oss.filter(({ categoria }) => "Mapeamento" !== categoria.nome).map(({ id }) => id);
        if (ids && ids.length > 0) {
            this._spinner.show();
            this._service.validaOsAbertura(ids).subscribe(
                (result) => {
                    this._spinner.hide();
                    const osSemAberturas: Array<any> = result.data;
                    this.isViewOsSemAbertura = false;

                    osSemAberturas.forEach((result) => {
                        for (var v in result) {
                            if (!result[v]) {
                                this.isViewOsSemAbertura = true;
                                this.osSemAberturas.push(parseInt(v));
                            }
                        }
                    });
                },
                (error) => this.error(error)
            );
        }
    }

    selecionaCheckStatusOs(check) {
        this.checkStatusOs = check;
        if (!check) {
            this.form.get('statusOs').setValue('');
        }
    }
    selecionaCheckCategoria(check) {
        this.checkCategoria = check;
        if (!check) {
            this.form.get('categoria').setValue('');
        }
    }
    selecionaCheckResponsavel(check) {
        this.checkResponsavel = check;
        if (!check) {
            this.form.get('responsavel').setValue('');
        }
    }
    selecionaCheckTime(check) {
        this.checkTime = check;
        if (!check) {
            this.form.get('time').setValue('');
        }
    }
    selecionaCheckPlannedDate(check) {
        this.checkPlannedDate = check;
        if (!check) {
            this.form.get('dataPlanejada').setValue('');
        }
    }

    selecionaTodos(check) {
        if (!check) {
            this.form.reset();
        }
        this.checkStatusOs = check;
        this.checkCategoria = check;
        this.checkResponsavel = check;
        this.checkTime = check;
        this.checkPlannedDate = check;
    }

    isDisabled(chave: string) {
        switch (chave) {
            case 'checkStatusOs':
                return !this.checkStatusOs;
                break;
            case 'checkCategoria':
                return !this.checkCategoria;
                break;
            case 'checkResponsavel':
                return !this.checkResponsavel;
                break;
            case 'checkTime':
                return !this.checkTime;
                break;
            case 'checkPlannedDate':
                return !this.checkPlannedDate;
                break;
        }
    }

    alterar() {
        const idOsStatus = this.form.get('statusOs').value;

        this._osEditar = Object.assign({}, this._osEditar, {
            enviarParaOBanco: this.enviarBanco,
            enviarParaOCliente: this.enviarCliente,
            idOsStatus,
            idCategoria: this.form.get('categoria').value,
            idResponsavel: this.form.get('responsavel').value,
            idTime: this.form.get('time').value,
            dataPlanejada: this.form.get('dataPlanejada').value,
        });

        if (!this.needsWarning && 8 === Number(idOsStatus)) {
            this._service.getListaAlerta(this._osEditar.oss.map(({ id }) => id)).subscribe(({ data }) => this.listaAlerta = data);
            this.needsWarning = true;
            return;
        }

        if (this._osEditar.oss) {
            this._service.update(this._osEditar).subscribe(
                () => {
                    this.sucess('Os alteradas com sucesso!');
                    this.atualizado.emit(true);
                    this.needsWarning = false;
                    this.enviarBanco = false;
                    this.enviarCliente = false;
                },
                (error) => this.error(error)
            );
        }
    }

    selecionarEnviarParaOBanco(checked: boolean) {
        this.enviarBanco = !!checked;
    }

    selecionarEnviarParaOCliente(checked: boolean) {
        this.enviarCliente = !!checked;
    }

    protected crateForm(): { [key: string]: any } {
        return {
            statusOs: [],
            categoria: [],
            responsavel: [],
            time: [],
            dataPlanejada: [],
        };
    }
}
