import { Component, OnInit } from '@angular/core';
import { Contrato } from '../../shared/interfaces/contrato.interface';
import { FiltroContrato } from '../../shared/interfaces/filtro-contrato.interface';
import { Location } from '@angular/common';
@Component({
  selector: 'app-pre-contrato-detalhe-page',
  templateUrl: './pre-contrato-detalhe-page.component.html',
  styleUrls: ['./pre-contrato-detalhe-page.component.scss']
})
export class PreContratoDetalhePageComponent implements OnInit {
  filtro: FiltroContrato;
  atualizarGrid: boolean;
  preContratoData: Contrato;

  constructor(private _location: Location) { }

  ngOnInit() {
  }

  filtrar(filtro: FiltroContrato) {
    this.filtro = Object.assign({}, filtro);
  }

  atualizaGrid(isAtualizar: boolean) {
    this.atualizarGrid = Object.assign({});
  }

  contratoData(contrato: Contrato) {
    this.preContratoData = contrato;
  }

  voltar() {
    this._location.back();
}
}
