import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FiltroPerfil } from '../../shareds/interfaces/filtro-perfil.interface';
import { Perfil } from '../../shareds/interfaces/perfil.interface';
import { PerfilModalService } from '../../shareds/services/perfil-modal.service';
import { PerfilService } from '../../shareds/services/perfil.service';

@Component({
    selector: 'perfil-grid',
    templateUrl: './perfil-grid.component.html',
    styleUrls: ['./perfil-grid.component.scss'],
})
export class PerfilGridComponent
    extends GridBase<Perfil, FiltroPerfil, PerfilService>
    implements OnInit {
    constructor(
        service: PerfilService,
        injector: Injector,
        changeDetectorRefs: ChangeDetectorRef,
        private _perfilService: PerfilService,
        private _perfilModalService: PerfilModalService
    ) {
        super(service, injector, changeDetectorRefs);
    }

    ngOnInit(): void {
        this.initGrid({ sortField: 'nome' });
    }

    visualizar(perfil: Perfil): void {
        this._perfilModalService.detalhes(perfil).subscribe(() => { });
    }

    editar(perfil: Perfil): void {
        this._router.navigate(['acl', 'perfil', 'cadastro', perfil.id]);
    }

    excluir(perfil: Perfil): void {
        this._confirmation
            .confirm({
                titulo: 'Deseja excluir o perfil?',
                mensagem:
                    'Ao remover o perfil o mesmo não ficará mais disponível',
            })
            .subscribe((result) => {
                if (result) {
                    this._perfilService.delete(perfil).subscribe(
                        () => {
                            this.atualizarGrid();
                            this._notification.sucess(
                                'Perfil removido com sucesso!'
                            );
                        },
                        (error) => {
                            this._notification.error(error.error.message);
                        }
                    );
                }
            });
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'nome', label: 'Nome' },
            { chave: 'tipo', label: 'Tipo' },
        ];
    }
}
