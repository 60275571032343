import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RelatorioGridPageComponent } from './pages/relatorio-grid-page/relatorio-grid-page.component';
import { RelatorioGuard } from './relatorio.guard';
import { RelatorioTipoListaResolver } from './shareds/resolvers/relatorio-tipo-lista.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [RelatorioGuard],
        canLoad: [RelatorioGuard],
        resolve: {
            listaTipoRelatorios: RelatorioTipoListaResolver,
        },
        component: RelatorioGridPageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RelatorioRoutingModule { }
