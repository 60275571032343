import { ChangeDetectorRef, Component, Injector, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Cotacao } from 'app/main/cotacao/shared/interfaces/cotacao.interface';
import { CotacaoService } from 'app/main/cotacao/shared/services/cotacao.service';

@Component({
    selector: 'pesquisa-cotacao',
    templateUrl: './pesquisa-cotacao.component.html',
    styleUrls: ['./pesquisa-cotacao.component.scss'],
})
export class PesquisaCotacaoComponent
    extends GridBase<Cotacao, FiltroBase, CotacaoService>
    implements OnChanges, OnInit {
    @Output() selecionado = new EventEmitter();

    constructor(
        protected _service: CotacaoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {});
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'nome', label: '' },
            { chave: 'numeroCotacao', label: '' },
            { chave: 'empresa', label: '' },
            { chave: 'valorTotal', label: '' },
        ];
    }

    selecionar(cotacao: Cotacao) {
        this.selecionado.emit(cotacao);
    }
}
