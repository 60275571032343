import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeIndexPageComponent } from './pages/home-index-page/home-index-page.component';
import { HomeGuard } from './home.guard';
import { UtilsModule } from '@utils/utils.module';

@NgModule({
    declarations: [HomeIndexPageComponent],
    imports: [CommonModule, HomeRoutingModule, UtilsModule],
    providers: [HomeGuard],
})
export class HomeModule {}
