import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FiltroUsuario } from '../interfaces/filtro-usuario.interface';
import { Usuario } from '../interfaces/usuario.interface';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';

@Injectable()
export class UsuarioEditarService extends ServiceGridBase<Usuario, FiltroUsuario> {
    private path = `${environment.server}/usuario-edicao`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro: FiltroUsuario): Observable<Response<Array<Usuario>>> {
        const params = this.toGetParams(filtro);
        const url = `${this.path}${params}`;
        return this._http.get<Response<Array<Usuario>>>(url);
    }
}
