import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RelacionamentoAberturaEditar } from '../../shared/interfaces/relacionamento-abertura-editar.interface';

@Component({
  selector: 'modal-editar-lote-relacionamento-abertura',
  templateUrl: './modal-editar-lote-relacionamento-abertura.component.html',
  styleUrls: ['./modal-editar-lote-relacionamento-abertura.component.scss']
})
export class ModalEditarLoteRelacionamentoAberturaComponent implements OnInit {
  tituloModal: string = 'Editar relacionamento abertura em Lote';

  constructor(
    public dialogRef: MatDialogRef<ModalEditarLoteRelacionamentoAberturaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RelacionamentoAberturaEditar
  ) { }

  ngOnInit() { }

  fechar(): void {
    this.dialogRef.close();
  }

  atualizado(result: boolean) {
    this.dialogRef.close(result);
  }

}
