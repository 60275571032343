import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Usuario } from '../interfaces/usuario.interface';
import { UsuarioDetalhesModalComponent } from '../../modal/usuario-detalhes-modal/usuario-detalhes-modal.component';
import { UsuarioAlterarPerfilModalComponent } from '../../modal/usuario-alterar-perfil-modal/usuario-alterar-perfil-modal.component';
import { Observable } from 'rxjs';
import { UsuarioService } from './usuario.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { ModalPesquisaUsuarioComponent } from '../../modal/modal-pesquisa-usuario/modal-pesquisa-usuario.component';

@Injectable()
export class UsuarioModalService {
    constructor(
        private _dialog: MatDialog,
        private _usuarioService: UsuarioService,
        private _spinner: SpinnerService
    ) {}

    detalhes(data: Usuario): Observable<any> {
        const dialogRef = this._dialog.open(UsuarioDetalhesModalComponent, {
            minWidth: '500px',
            width: '80%',
            minHeight: '200px',
            direction: 'ltr',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    alterarPerfil(data: Usuario): Observable<any> {
        const dialogRef = this._dialog.open(
            UsuarioAlterarPerfilModalComponent,
            {
                minWidth: '500px',
                width: '80%',
                minHeight: '200px',
                direction: 'ltr',
                data: data,
            }
        );

        return dialogRef.afterClosed();
    }

    usuarioPesquisa(data: Usuario) {
        const dialogRef = this._dialog.open(ModalPesquisaUsuarioComponent, {
            width: "90%",
            data: data,
        });

        return dialogRef.afterClosed();
    }
}
