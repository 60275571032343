import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { OsAlerta } from '../../shareds/interfaces/os-alerta.interface';
import { ComponentBase } from '@utils/base/component-base/component-base';

@Component({
    selector: 'modal-notificacao',
    templateUrl: './modal-notificacao.component.html',
    styleUrls: ['./modal-notificacao.component.scss'],
})
export class ModalNotificacaoComponent extends ComponentBase implements OnInit {
    tituloModal: string = 'Finalização de OS';
    listaAlerta: Array<OsAlerta> = [];
    enviarParaOBanco = true;
    enviarParaOCliente = true;

    constructor(
        private _Injector: Injector,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            atualizarOS: (os: any) => void;
            lista: Array<OsAlerta>;
            categoria: { id: number, nome: string },
            fechar: () => void;
            os: any;
            salvar: () => void;
        }
    ) {
        super(_Injector);
    }

    ngOnInit() {
        this.listaAlerta = this.data.lista;
    }

    fechar(): void {
        this.data.fechar();
    }

    temNumeroAbertura () {
        return this.listaAlerta.some(item => !!item.numeroAberturas)
    }

    salvar() {
        if (!this.temNumeroAbertura() && this.data.categoria.nome !== 'Mapeamento') {
            this._notification.error('OS sem abertura, por favor corrigir!');
            return
        }

        this.data.atualizarOS({
            enviarParaOBanco: this.enviarParaOBanco,
            enviarParaOCliente: this.enviarParaOCliente,
        });
        this.data.salvar();
    }

    toggle(campo: string, value: boolean) {
        this[campo] = !!value;
    }
}
