import { Component, Injector, OnInit } from '@angular/core';
import { ChacklistPageBase } from '../../shared/base/checklist-page-base';

@Component({
    selector: 'checklist-contato-empresa-page',
    templateUrl: './checklist-contato-empresa-page.component.html',
    styleUrls: ['./checklist-contato-empresa-page.component.scss'],
})
export class ChecklistContatoEmpresaPageComponent
    extends ChacklistPageBase
    implements OnInit {
    carregarMenu: boolean;
    constructor(protected _injector: Injector) {
        super(_injector);
    }

    atualizarMenu(result) {
        this.carregarMenu = Object.assign({});
    }
    ngOnInit(): void {}
}
