import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ChecklistDsnameModal } from '../../shared/interfaces/checklist-dsname-modal.interface';
import { ChecklistDsname } from '../../shared/interfaces/checklist-dsname.interface';

@Component({
    selector: 'modal-checklist-dsname',
    templateUrl: './modal-checklist-dsname.component.html',
    styleUrls: ['./modal-checklist-dsname.component.scss'],
})
export class ModalChecklistDsnameComponent implements OnInit {
    tituloModal: string = 'Dsname';

    constructor(
        public dialogRef: MatDialogRef<ModalChecklistDsnameComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ChecklistDsnameModal
    ) {}
    ngOnInit() {}

    adicionandoDsname(dsnamesRequest: Array<ChecklistDsname>) {
        this.dialogRef.close(dsnamesRequest);
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
