import { FuseConfigService } from '@fuse/services/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Injector } from '@angular/core';
import { AuthService } from './services/auth.service';
import { ThemeConfig } from '@utils/interfaces/theme/theme-config.interface';
import { themeConfig } from 'app/fuse-config/theme.config';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';

export abstract class AuthBaseController {
    protected _fuseConfigService: FuseConfigService;
    protected _formBuilder: FormBuilder;
    protected _router: Router;
    protected _route: ActivatedRoute;
    protected _authService: AuthService;
    protected _notification: NotificationService;
    protected _spinner: NgxSpinnerService;

    themeConfig: ThemeConfig;

    // Constructor
    constructor(protected injector: Injector) {
        this._fuseConfigService = this.injector.get(FuseConfigService);
        this._formBuilder = this.injector.get(FormBuilder);
        this._router = this.injector.get(Router);
        this._route = this.injector.get(ActivatedRoute);
        this._authService = this.injector.get(AuthService);
        this._notification = this.injector.get(NotificationService);
        this._spinner = this.injector.get(NgxSpinnerService);

        // Configuração do tema
        this.themeConfig = themeConfig;

        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };
    }
}
