import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Projeto } from '../../shareds/interfaces/projeto.interface';

@Component({
    selector: 'modal-pesquisa-projeto',
    templateUrl: './modal-pesquisa-projeto.component.html',
    styleUrls: ['./modal-pesquisa-projeto.component.scss'],
})
export class ModalPesquisaProjetoComponent implements OnInit {
    tituloModal: string = 'Selecione a projeto';

    constructor(
        public dialogRef: MatDialogRef<ModalPesquisaProjetoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
    ngOnInit() {}

    selecionado(checklist: Projeto) {
        this.dialogRef.close(checklist);
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
