import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Faturamento } from '../../shared/interfaces/faturamento.interface';

@Component({
    selector: 'modal-historico-faturamento',
    templateUrl: './modal-historico-faturamento.component.html',
    styleUrls: ['./modal-historico-faturamento.component.scss'],
})
export class ModalHistoricoFaturamentoComponent implements OnInit {
    tituloModal: string = 'Histórico';

    constructor(
        public dialogRef: MatDialogRef<ModalHistoricoFaturamentoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Faturamento
    ) {}
    ngOnInit() {}

    selecionado(faturamento: Faturamento) {
        this.dialogRef.close(faturamento);
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
