import { Injectable } from '@angular/core';
import { BaseGuard, GuardConfigPermission } from '@utils/guard/base.guard';
import { Role } from 'app/fuse-config/role';

@Injectable()
export class ImportacaoOsGuard extends BaseGuard {
    protected configPermissions(): Array<GuardConfigPermission> {
        return [{ path: '/importacao/os', role: Role.OS_IMPOTACAO_XLS }];
    }
}
