import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    EventEmitter,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Checklist } from 'app/main/checklist/shared/interfaces/checklist.interface';
import { FiltroOsEmpresa } from '../../shareds/interfaces/filtro-os-empresa.interface';
import { OsEmpresa } from '../../shareds/interfaces/os-empresa.interface';
import { OsEmpresaModalService } from '../../shareds/services/os-empresa-modal.service';
import { OsEmpresaService } from '../../shareds/services/os-empresa.service';

@Component({
    selector: 'os-empresa-grid',
    templateUrl: './os-empresa-grid.component.html',
    styleUrls: ['./os-empresa-grid.component.scss'],
})
export class OsEmpresaGridComponent
    extends GridBase<OsEmpresa, FiltroOsEmpresa, OsEmpresaService>
    implements OnChanges, OnInit {
    @Input() checklist: Checklist;
    @Output() selecionado = new EventEmitter<OsEmpresa>();

    constructor(
        protected _service: OsEmpresaService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalEmpresa: OsEmpresaModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    pesquisarPorTexto(): void {
        const campoPesquisa = this.campoPesquisa
            .toLocaleLowerCase()
            .replace(/\./g, '')
            .replace(/\-/g, '')
            .replace(/\//g, '')
            .replace(/ /g, '');

        if (campoPesquisa) {
            this.filtro.filter = campoPesquisa;
            this.atualizarGrid(this.filtro);
        }
    }

    ngOnInit(): void {
        this.initGrid(this.getFiltro());
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.getFiltro());
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'inscricao', label: '' },
            { chave: 'nome', label: '' },
        ];
    }

    cadastrar() {
        this._modalEmpresa.empresaCadastro({}).subscribe(() => {
            this.atualizarGrid(this.getFiltro());
        });
    }

    selecionar(empresa: OsEmpresa) {
        this.selecionado.emit(empresa);
    }

    private getFiltro(): FiltroOsEmpresa {
        this.filtro = this.filtro ? this.filtro : {};
        return this.filtro;
    }
}
