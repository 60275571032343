import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RelacionamentoAbertura } from '../../shared/interfaces/relacionamento-abertura.interface';

@Component({
    selector: 'modal-historico-relacionamento-abertura',
    templateUrl: './modal-historico-relacionamento-abertura.component.html',
    styleUrls: ['./modal-historico-relacionamento-abertura.component.scss'],
})
export class ModalHistoricoRelacionamentoAberturaComponent implements OnInit {
    tituloModal: string = 'Histórico';

    constructor(
        public dialogRef: MatDialogRef<ModalHistoricoRelacionamentoAberturaComponent>,
        @Inject(MAT_DIALOG_DATA) public data: RelacionamentoAbertura
    ) { }
    ngOnInit() { }

    selecionado(relacionamentoAbertura: RelacionamentoAbertura) {
        this.dialogRef.close(relacionamentoAbertura);
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
