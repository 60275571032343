import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { FaturamentoRecorrenteDadosComplementares } from '../interfaces/faturamento-recorrente-dados-complementares.interface';

@Injectable({
    providedIn: 'root',
})
export class FaturamentoRecorrenteDadosComplementaresService extends ServiceBase<FaturamentoRecorrenteDadosComplementares> {
    private path = `${environment.server}/faturamento-recorrente`;

    constructor(private _http: HttpClient) {
        super();
    }

    findById(id: number | string): Observable<Response<FaturamentoRecorrenteDadosComplementares>> {
        const url = `${this.path}/${id}/dadosComplementares`;
        return this._http.get<Response<FaturamentoRecorrenteDadosComplementares>>(url);
    }

    updateDadosComplementares(idFaturamentoRecorrente: number, dadosComplementares: string): Observable<Response<void>> {
        const url = `${environment.server}/faturamento-recorrente/${idFaturamentoRecorrente}/dadosComplementares`;
        return this._http.put<Response<void>>(url, { dadosComplementares });
    }


}
