import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { OsHistorico } from '../../shareds/interfaces/os-historico.interface';
import { OsHistoricoService } from '../../shareds/services/os-historico.service';

@Component({
    selector: 'os-historico-detalhe',
    templateUrl: './os-historico-detalhe.component.html',
    styleUrls: ['./os-historico-detalhe.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class OsHistoricoDetalheComponent implements OnChanges, OnInit {
    @Input() historico: OsHistorico = {};

    constructor(
        protected _service: OsHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) {}

    ngOnInit(): void {}

    ngOnChanges(): void {}
}
