import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { Usuario } from '../shared/interfaces/usuario.interface';
import { UsuarioService } from '../shared/services/usuario.service';
import { map } from 'rxjs/operators';

@Injectable()
export class UsuarioFinnetAtivoResolver implements Resolve<Usuario> {
    constructor(private _usuarioService: UsuarioService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._usuarioService
            .find({ ativo: 1,  tipo: 1})
            .pipe(map((result) => result.data));
    }
}
