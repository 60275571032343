import {
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FiltroRelacionamentoAberturaHistorico } from '../../shared/interfaces/filtro-relacionamento-abertura-historico.interface';
import { RelacionamentoAberturaHistorico } from '../../shared/interfaces/relacionamento-abertura-historico.interface';
import { RelacionamentoAbertura } from '../../shared/interfaces/relacionamento-abertura.interface';
import { RelacionamentoAberturaHistoricoService } from '../../shared/services/relacionamento-abertura-historico.service';
import { RelacionamentoAberturaModalService } from '../../shared/services/relacionamento-abertura-modal.service';

@Component({
    selector: 'relacionamento-abertura-historico',
    templateUrl: './relacionamento-abertura-historico.component.html',
    styleUrls: ['./relacionamento-abertura-historico.component.scss'],
})
export class RelacionamentoAberturaHistoricoComponent
    extends GridBase<
    RelacionamentoAberturaHistorico,
    FiltroRelacionamentoAberturaHistorico,
    RelacionamentoAberturaHistoricoService
    >
    implements OnChanges, OnInit {
    private _relacionamentoAbertura: RelacionamentoAbertura;
    get relacionamentoAbertura(): RelacionamentoAbertura {
        return this._relacionamentoAbertura;
    }
    @Input() set relacionamentoAbertura(relacionamentoAbertura: RelacionamentoAbertura) {
        this._relacionamentoAbertura = relacionamentoAbertura;
        this.filtro.idOsAbertura = relacionamentoAbertura.idOsAbertura;
        this.initGrid(this.filtro || {});
    }

    constructor(
        protected _service: RelacionamentoAberturaHistoricoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        protected _modalRelacionamentoAberturaService: RelacionamentoAberturaModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void { }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'data', label: '' },
            { chave: 'item', label: '' },
            { chave: 'acao', label: '' },
            { chave: 'usuario', label: '' },
        ];
    }

    selecionar(relacionamentoAberturaHistorico: RelacionamentoAberturaHistorico) {
        this._modalRelacionamentoAberturaService
            .historicoDetalhe(relacionamentoAberturaHistorico)
            .subscribe(() => { });
    }
}
