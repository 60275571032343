import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TipoCobranca } from '../interfaces/tipo-cobranca.interface';
import { TipoCobrancaService } from '../../shared/services/tipo-cobranca.service';

@Injectable()
export class TipoCobrancaListaResolver implements Resolve<TipoCobranca> {
    constructor(private _tipoCobrancaService: TipoCobrancaService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        return this._tipoCobrancaService
            .find({})
            .pipe(map((result) => result.data));
    }
}
