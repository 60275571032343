import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ImportacaoRoutingModule } from './importacao-routing.module';
import { ImportacaoCotacaoModule } from './importacao-cotacao/importacao-cotacao.module';
import { ImportacaoGuard } from './importacao.guard';
import { UtilsModule } from '@utils/utils.module';
import { ImportacaoOsModule } from './importacao-os/importacao-os.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        ImportacaoRoutingModule,
        ImportacaoCotacaoModule,
        ImportacaoOsModule,
        UtilsModule,
    ],
    providers: [ImportacaoGuard],
})
export class ImportacaoModule { }
