import {
    ChangeDetectorRef,
    Component,
    Injector,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { FiltroTime } from '../../shareds/interfaces/filtro-time.interface';
import { Time } from '../../shareds/interfaces/time.interface';
import { TimeModalService } from '../../shareds/services/time-modal.service';
import { TimeService } from '../../shareds/services/time.service';

@Component({
    selector: 'time-grid',
    templateUrl: './time-grid.component.html',
    styleUrls: ['./time-grid.component.scss'],
})
export class TimeGridComponent
    extends GridBase<Time, FiltroTime, TimeService>
    implements OnChanges, OnInit {
    constructor(
        protected _service: TimeService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef,
        private _modalTime: TimeModalService
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || { sortField: 'nome' });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || { sortField: 'nome' });
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'nome', label: '' },
            { chave: 'produto', label: '' },
            { chave: 'quantidadeUsuarios', label: '' },
        ];
    }

    visualizar(time: Time) {
        this._modalTime.timeDetalhe(time);
    }

    editar(time: Time) {
        this._router.navigateByUrl(`time/${time.id}/editar`);
    }

    excluir(time: Time) {
        this._confirmation
            .confirm({ titulo: 'Deseja realmente excluir esse item?' })
            .subscribe((result) => {
                if (result) {
                    this._service.delete(time.id).subscribe(() => {
                        this.atualizarGrid();
                    });
                }
            });
    }
}
