import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ChecklistConfiguracaoArquivoData } from '../../shared/interfaces/checklist-configuracao-arquivo.interface';

@Component({
  selector: 'app-modal-produto-financeiro-upload',
  templateUrl: './modal-produto-financeiro-upload.component.html',
  styleUrls: ['./modal-produto-financeiro-upload.component.scss']
})
export class ModalProdutoFinanceiroUploadComponent implements OnInit {
  tituloModal: string = 'Importar Relação Produto Financeiro';

  constructor(
    public dialogRef: MatDialogRef<ModalProdutoFinanceiroUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChecklistConfiguracaoArquivoData
  ) { }

  ngOnInit() {
  }

  fechar(): void {
    this.dialogRef.close();
  }

  modeloDocumentoArquivo(): void {
  }
}
