import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { FiltroOsFase } from '../interfaces/filtro-os-fase.interface';
import { OsFaseEvidencia } from '../interfaces/os-fase-evidencia.interface';
import { OsFases } from '../interfaces/os-fases.interface';

@Injectable({
    providedIn: 'root',
})
export class OsFasesService extends ServiceGridBase<OsFases, FiltroOsFase> {
    private path: string = `${environment.server}/os`;

    constructor(private _http: HttpClient) {
        super();
    }

    find(filtro?: FiltroOsFase): Observable<Response<Array<OsFases>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/fase/${filtro.idOs}${params}`;
        return this._http.get<Response<Array<OsFases>>>(url);
    }

    save(osFases: OsFases, file: File): Observable<any> {
        if (osFases.id) {
            return this.update(osFases, file);
        } else {
            return this.create(osFases, file);
        }
    }

    create(osFases: OsFases, file: File): Observable<any> {
        const url = `${this.path}/fasemanual`;
        const formData = new FormData();
        if (file && file.name) {
            formData.set('evidencia', file, file.name);
        }
        formData.set('comentario', osFases.comentario ? osFases.comentario : '');
        formData.set('idOsFaseNome', osFases.idOsFaseNome.toString());
        formData.set('idOsFaseStatus', osFases.idOsFaseStatus.toString());
        formData.set('idOs', osFases.idOs.toString());
        formData.set('idUsuario', osFases.idUsuario.toString());

        return this._http.post(url, formData, {
            observe: 'events',
            reportProgress: true,
        });
    }

    update(osFases: OsFases, file: File): Observable<any> {
        const url = `${this.path}/fase/${osFases.id}`;
        const formData = new FormData();
        if (file && file.name) {
            formData.set('evidencia', file, file.name);
        }
        formData.set('comentario', osFases.comentario);
        formData.set('idOsFaseNome', osFases.idOsFaseNome.toString());
        formData.set('idOsFaseStatus', osFases.idOsFaseStatus.toString());
        formData.set('idOs', osFases.idOs.toString());
        formData.set('idUsuario', osFases.idUsuario.toString());

        return this._http.post(url, formData, {
            observe: 'events',
            reportProgress: true,
        });
    }

    delete(idOsFase: number): Observable<Response<OsFases>> {
        const url = `${this.path}/fase/${idOsFase}`;
        return this._http.delete<Response<OsFases>>(url);
    }

    download(osFases: OsFases): Observable<any> {
        const idDocumento = osFases.osFaseEvidencia.id;
        const url = `${this.path}/fase/evidencia/${idDocumento}`;
        return this._http.get<Response<any>>(url);
    }
}
