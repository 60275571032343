import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Response } from '@utils/interfaces/http/response.interface';
import { ContratoEditar } from '../interfaces/contrato-editar.interface';
import { ServiceBase } from '@utils/base/service-base/service-base';

@Injectable({
    providedIn: 'root',
})
export class ContratoEdicaoService extends ServiceBase<ContratoEditar> {
    private path = `${environment.server}/atualizarcontratoemlote`;

    constructor(private _http: HttpClient) {
        super();
    }

    update(cotacaoEdicao: ContratoEditar): Observable<Response<ContratoEditar>> {
        const url = `${this.path}`;
        return this._http.put<Response<ContratoEditar>>(url, cotacaoEdicao);
    }

}
