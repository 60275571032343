import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { Cotacao } from 'app/main/cotacao/shared/interfaces/cotacao.interface';
import { Projeto } from 'app/main/projeto/shareds/interfaces/projeto.interface';
import { Os } from '../../shareds/interfaces/os.interface';
import { CotacaoModalService } from '../../../cotacao/shared/services/cotacao-modal.service';
import { ProjetoService } from 'app/main/projeto/shareds/services/projeto.service';
import { OsProjetoService } from '../../shareds/services/os-projeto.service';
import { OsProjetoRequest } from '../../shareds/interfaces/os-projeto-request.interface';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute } from '@angular/router';
import { OsService } from '../../shareds/services/os.service';
import { ComponentBase } from '@utils/base/component-base/component-base';

@Component({
    selector: 'os-projeto',
    templateUrl: './os-projeto.component.html',
    styleUrls: ['./os-projeto.component.scss'],
})
export class OsProjetoComponent extends ComponentBase implements OnInit {
    @Input() os: Os;
    @Input() idsOs: Array<number>;
    @Output() atualizado = new EventEmitter<boolean>();

    cotacao: Cotacao = {};

    //--------------------
    campoPesquisa: string;
    projetosFull: Array<Projeto> = [];
    projetosFullTotal: Array<Projeto> = [];
    projetosSelecionados: Array<Projeto> = [];
    projetosFullCheckbox: Array<Projeto> = [];
    projetosSelecionadosCheckbox: Array<Projeto> = [];
    todosProjetosFull: boolean = false;
    todosProjetosSelecionado: boolean = false;
    private campoPesquisaOld: string;

    constructor(
        private _modalPesquisaCotacao: CotacaoModalService,
        private _projetoService: ProjetoService,
        private _osProjetoService: OsProjetoService,
        private _osService: OsService,
        private _injector: Injector
    ) {
        super(_injector);
    }

    ngOnInit() {

        if (this.os && this.os.id) {
            this._spinner.show();
            this._osService.findCotacao(this.os.id).subscribe(result => {
                this._spinner.hide();
                this.cotacao = result.data;
                this.inicializaListas(true);
            })

            this._spinner.show();
            this._osProjetoService
                .findByIdOs(this.os.id)
                .subscribe(
                    (projetosVinculados) => {
                        this.projetosSelecionados = projetosVinculados.data,
                            this._spinner.hide();
                    },
                    error => this.error(error)
                );
        }
    }

    pesquisarCotacao() {
        this._modalPesquisaCotacao.cotacaoPesquisa({}).subscribe((cotacao) => {
            this.cotacao = cotacao;
            this.inicializaListas();
        });
    }

    salvar() {
        const osVincular: OsProjetoRequest = {
            projetos: this.projetosSelecionados,
            os: this.os,
            idsOs: this.idsOs,
        };
        this._spinner.show();
        this._osProjetoService.save(osVincular).subscribe(
            (result) => {
                this.sucess('Projetos vinculados com sucesso!');
                this.atualizado.emit(true);
            },
            (error) => this.error(error)
        );
    }

    selectedOption(o1: any, o2: any) {
        return o1.id == o2.id;
    }
    exibeBtoPesquisaCotacao() {
        return !this.cotacao || !this.cotacao.id || this.projetosSelecionados.length === 0
    }


    protected crateForm(): { [key: string]: any } {
        return {
            nome: ['', Validators.required],
            produto: [],
        };
    }

    //-----------------------------------
    pesquisarPorTexto(): void {
        this.projetosFull = this.projetosFullTotal.filter((projeto) => {
            const regex = new RegExp(this.campoPesquisa.toLocaleLowerCase());
            return (
                projeto.id.toString().toLowerCase().match(regex) ||
                projeto.produto.nome.toLowerCase().match(regex) ||
                projeto.modulo.nome.toLowerCase().match(regex)
            );
        });
    }

    limparCampoPesquisa(): void {
        this.campoPesquisa = '';
        this.projetosFull = this.projetosFullTotal;
    }

    // Projetos Full
    addFullCheckbox(): void {
        const projetosFullCheckbox = Array.from(this.projetosFullCheckbox);

        projetosFullCheckbox.forEach((r: Projeto, i: number) => {
            const index = this.projetosFull.findIndex((rel) => rel.id === r.id);
            r.selecionado = false;
            this.adicionarSelecionado(r, index);
        });

        this.todosProjetosFull = false;
        this.projetosFullCheckbox = [];
    }

    removeFullCheckbox(projeto: Projeto): void {
        const index = this.projetosFullCheckbox.findIndex(
            (r) => r.id === projeto.id
        );
        this.projetosFullCheckbox.splice(index, 1);
    }

    adicionarFull(projeto: Projeto, index: number): void {
        projeto.selecionado = false;
        this.projetosFull.push(projeto);
        this.projetosFullTotal = this.projetosFull;
        this.removeSelecionadoCheckboxArray(projeto);
        this.removeProjetoSelecionado(index);
    }

    removeProjetoFull(index: number): void {
        this.projetosFull.splice(index, 1);
        this.projetosFullTotal = this.projetosFull;
    }

    selecionaFull(projeto: Projeto, checked: boolean): void {
        if (checked) {
            this.projetosFullCheckbox.push(projeto);
        } else {
            this.removeFullCheckbox(projeto);
        }
    }

    setTodosProjetosFull(selecionado: boolean) {
        this.todosProjetosFull = selecionado;
        if (this.projetosFull === null) {
            return;
        }
        if (selecionado) {
            this.projetosFullCheckbox = Array.from(this.projetosFull);
        } else {
            this.projetosFullCheckbox = [];
        }
        this.projetosFull.forEach((r) => (r.selecionado = selecionado));
    }

    // Projetos Selecionados
    removeSelecionadosCheckbox(): void {
        const projetosSelecionadosCheckbox = Array.from(
            this.projetosSelecionadosCheckbox
        );

        projetosSelecionadosCheckbox.forEach((r: Projeto, i: number) => {
            const index = this.projetosSelecionados.findIndex(
                (rel) => rel.id === r.id
            );
            r.selecionado = false;
            this.adicionarFull(r, index);
        });

        this.todosProjetosSelecionado = false;
        this.projetosSelecionadosCheckbox = [];
    }

    adicionarSelecionado(projeto: Projeto, index: number): void {
        projeto.selecionado = false;
        this.projetosSelecionados.push(projeto);
        this.removeFullCheckbox(projeto);
        this.removeProjetoFull(index);
    }

    removeSelecionadoCheckboxArray(projeto: Projeto): void {
        const index = this.projetosFullCheckbox.findIndex(
            (r) => r.id === projeto.id
        );
        this.projetosFullCheckbox.splice(index, 1);
    }

    removeProjetoSelecionado(index: number): void {
        this.projetosSelecionados.splice(index, 1);
    }

    selecionaSelecionado(projeto: Projeto, checked: boolean): void {
        if (checked) {
            this.projetosSelecionadosCheckbox.push(projeto);
        } else {
            this.removeSelecionadoCheckboxArray(projeto);
        }
    }

    setTodosProjetosSelecionado(selecionado: boolean) {
        this.todosProjetosSelecionado = selecionado;
        if (this.projetosSelecionados === null) {
            return;
        }
        if (selecionado) {
            this.projetosSelecionadosCheckbox = Array.from(
                this.projetosSelecionados
            );
        } else {
            this.projetosSelecionadosCheckbox = [];
        }
        this.projetosSelecionados.forEach((r) => (r.selecionado = selecionado));
    }

    private inicializaListas(inicializacao?: boolean) {
        if (this.cotacao) {
            if (this.cotacao.id !== undefined) {
                this._spinner.show();
                this._projetoService.findByIdCotacao(this.cotacao.id).subscribe(
                    (projetosCotacao) => {
                        this._spinner.hide();
                        this.projetosFull = [];
                        projetosCotacao.data.forEach((d) => {
                            if (inicializacao) {
                                const isSelecionado = this.projetosSelecionados.findIndex(p => d.id === p.id) > -1
                                if (!isSelecionado) {
                                    this.projetosFull.push(d);
                                }
                            }
                            else {
                                this.projetosFull.push(d);
                            }
                        });
                        this.projetosFullTotal = this.projetosFull;
                    },
                    (error) => this.error(error)
                );
            }
        }
    }

    //-----------------------------------
}
