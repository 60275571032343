import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bi-diretoria',
  templateUrl: './bi-diretoria.component.html',
  styleUrls: ['./bi-diretoria.component.scss']
})
export class BiDiretoriaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
