import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RelacionamentoAbertura } from '../../shared/interfaces/relacionamento-abertura.interface';

@Component({
    selector: 'relacionamento-abertura-detalhe-page',
    templateUrl: './relacionamento-abertura-detalhe-page.component.html',
    styleUrls: ['./relacionamento-abertura-detalhe-page.component.scss'],
})
export class RelacionamentoAberturaDetalhePageComponent implements OnInit {
    relacionamentoAbertura: RelacionamentoAbertura = {};

    constructor(private _route: ActivatedRoute) {
        this._route.data.subscribe((data) => {
            this.relacionamentoAbertura = data.relacionamentoAbertura || {};
        });
    }

    ngOnInit(): void { }
}
