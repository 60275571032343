import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { DownloadUtil } from '@utils/utils/class/download.util';
import { FiltroImportacaoOs } from '../../shared/interfaces/filtro-importacao-os.interfaces';
import { ImportacaoOs } from '../../shared/interfaces/importacao-os.interfaces';
import { ImportacaoOsModalService } from '../../shared/services/importacao-os-modal.service';
import { ImportacaoOsService } from '../../shared/services/importacao-os.service';

@Component({
  selector: 'importacao-os-grid',
  templateUrl: './importacao-os-grid.component.html',
  styleUrls: ['./importacao-os-grid.component.scss']
})
export class ImportacaoOsGridComponent extends GridBase<ImportacaoOs, FiltroImportacaoOs, ImportacaoOsService> implements OnInit {


  constructor(
    service: ImportacaoOsService,
    injector: Injector,
    changeDetectorRefs: ChangeDetectorRef,
    private _modalImportacaoOs: ImportacaoOsModalService,
    private _importacaoOsService: ImportacaoOsService
  ) {
    super(service, injector, changeDetectorRefs);
  }

  ngOnInit(): void {
    this.initGrid();
  }

  configDisplayedColumns(): Array<GridCollumn> {
    return [
      { chave: 'seleciona' },
      { chave: 'arquivo', label: '' },
      { chave: 'status', label: '' },
      { chave: 'dataUpload', label: '' },
    //   { chave: 'usuario', label: '' },
    ];
  }

  upload(): void {
    this._modalImportacaoOs.upload().subscribe((result) => {
      this.atualizarGrid();
    });
  }

  visualizarErro(importacaoOs: ImportacaoOs): void {
    this._modalImportacaoOs
      .importacaoErro(importacaoOs)
      .subscribe((result) => { });
  }

  visualizarDetalhes(importacaoOs: ImportacaoOs): void {
    this._modalImportacaoOs
      .importacaoDetalhe(importacaoOs)
      .subscribe((result) => { });
  }

  download(importacaoOs: ImportacaoOs): void {
    this._spinner.show();
    this._importacaoOsService
      .download(importacaoOs)
      .subscribe((result) => {
        this._spinner.hide();
        DownloadUtil.base64(
          result.data.arquivo,
          result.data.name,
          result.data.type
        );
      });
  }

  modeloDocumentoArquivo(): void {
    this._spinner.show();
    this._importacaoOsService
      .modeloDocumentoArquivo()
      .subscribe((result) => {
        this._spinner.hide();
        DownloadUtil.base64(
          result.data.arquivo,
          result.data.name,
          result.data.type
        );
      })
  }
}
