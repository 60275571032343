import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Banco } from '../../shareds/interfaces/banco.interface';

@Component({
  selector: 'modal-historico-banco',
  templateUrl: './modal-historico-banco.component.html',
  styleUrls: ['./modal-historico-banco.component.scss']
})
export class ModalHistoricoBancoComponent {
  tituloModal: string = 'Histórico';

  constructor(
    public dialogRef: MatDialogRef<ModalHistoricoBancoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Banco
  ) { }

  selecionado(banco: Banco) {
    this.dialogRef.close(banco);
  }

  fechar(): void {
    this.dialogRef.close();
  }

}
