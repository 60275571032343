import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBase } from '@utils/base/form-base/form-base';
import { NotificationService } from '@utils/utils/notification/notification.service';
import { SpinnerService } from '@utils/utils/spinner/spinner.service';
import { FiltroService } from '../../filtro.service';
import { FiltroSalvarDados } from '../../shareds/interfaces/fintro.interface';

@Component({
    selector: 'modal-salvar-configuracao-filtro',
    templateUrl: './modal-salvar-configuracao-filtro.component.html',
    styleUrls: ['./modal-salvar-configuracao-filtro.component.scss']
})
export class ModalSalvarConfiguracaoFiltroComponent implements OnInit {

    public form: FormGroup;
    tituloModal: string = 'Configurar visibilidade dos filtros de pesquisa';
    fields: any = {}

    constructor(
        public dialogRef: MatDialogRef<ModalSalvarConfiguracaoFiltroComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private filtroService: FiltroService,
        private _spinner: SpinnerService,
        private _notification: NotificationService,
    ) { }

    ngOnInit() {
        this.filtroService.findByChave(this.data.chave).subscribe(result => {
            const filtroSalvarDados = result.data
            const campos = filtroSalvarDados.filtroCampos ? JSON.parse(filtroSalvarDados.filtroCampos) : undefined
            const listaCampos: Array<any> = this.data.campos;

            if (campos) {
                Object.keys(campos).forEach((chave) => {
                    if (campos[chave]) {
                        this.fields[chave] = true;
                    }
                });
            }
            else {
                listaCampos.forEach(campo => {
                    if (campo) {
                        this.fields[campo] = true;
                    }
                })
            }
        })
    }

    salvar() {
        let i = Object.keys(this.fields).length
        if (i > 0) {
            i = 0
            Object.keys(this.fields).forEach(field => {
                this.fields[field] ? i++ : ''
            });
        }

        if (i == 0) {
            this._notification.warning("Selecione ao menos um campo para visualizar");
            return
        }

        const filtroSalvarDados: FiltroSalvarDados = {
            tela: this.data.chave,
            filtroCampos: this.fields ? JSON.stringify(this.fields) : ''
        }

        this._spinner.show();
        this.filtroService.save(filtroSalvarDados).subscribe(
            result => {
                this._notification.sucess('Dados salvos com sucesso!')
                this._spinner.hide();
                this.dialogRef.close(true);
            },
            error => {
                this._notification.error(error.error.message)
            })
    }


    fechar(): void {
        this.dialogRef.close();
    }

    label(campo): string {
        const el = <HTMLElement>document.querySelector("[formControlName='" + campo + "']")
        let campoLabel: string = ""
        if (el) {
            const div = el.closest("div")
            campoLabel = div.querySelector("mat-label").textContent
        }
        if (campoLabel) {
            return campoLabel
        }
        return campo
    }

    selecionarTodos(): void {
        this.data.campos.forEach(campo => {
            this.fields[campo] = true
        });
    }

    limparSelecao(): void {
        this.data.campos.forEach(campo => {
            this.fields[campo] = false
        });
    }
}
