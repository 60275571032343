import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FaturamentoRecorrenteEmpresa } from '../interfaces/faturamento-recorrente-empresa.interface';
import { Faturamento } from '../interfaces/faturamento.interface';
import { FaturamentoRecorrenteEmpresaService } from '../services/faturamento-recorrente-empresa.service';
import { FaturamentoService } from '../services/faturamento.service';

@Injectable()
export class FaturamentoRecorrenteEmpresaResolver implements Resolve<FaturamentoRecorrenteEmpresa> {
    constructor(private _service: FaturamentoRecorrenteEmpresaService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        let id = route.params['id']
        return this._service.findById(id).pipe(map(result => result.data))
    }
}
