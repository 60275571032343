import { Component, Injector, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBase } from '@utils/base/form-base/form-base';
import { Layout } from 'app/main/os/shareds/interfaces/layout.interface';
import { CustoTrafegoService } from 'app/main/os/shareds/services/custo-trafego.service';
import { LayoutService } from 'app/main/os/shareds/services/layout.service';
import { RelacionamentoAberturaEditar } from '../../shared/interfaces/relacionamento-abertura-editar.interface';
import { RelacionamentoAberturaEditarService } from '../../shared/services/relacionamento-abertura-editar.service';

@Component({
  selector: 'editar-lote-relacionamento-abertura',
  templateUrl: './editar-lote-relacionamento-abertura.component.html',
  styleUrls: ['./editar-lote-relacionamento-abertura.component.scss']
})
export class EditarLoteRelacionamentoAberturaComponent extends FormBase implements OnInit {

  isView: boolean = false;
  custoTrafegoRemessa: boolean = false;
  dsnameRemessa: boolean = false;
  renameRemessa: boolean = false;
  layoutRetorno: boolean = false;
  custoTrafegoRetorno: boolean = false;
  dsnameRetorno: boolean = false;
  renameRetorno: boolean = false;
  todosFull: boolean = false;

  @Input() idsRelacionamentoAbertura: RelacionamentoAberturaEditar;
  @Output() atualizado = new EventEmitter();

  listaLayoutRetorno: Array<Layout> = []

  constructor(
    private _injector: Injector,
    private _service: RelacionamentoAberturaEditarService,
    private _serviceLayout: LayoutService,
    private _serviceCustoTrafigo: CustoTrafegoService) {
    super(_injector, EditarLoteRelacionamentoAberturaCombos.combos());
  }

  ngOnInit() {
    this._spinner.show();
    let ids: any = this.idsRelacionamentoAbertura;
    if (ids.length > 0) {
      this.isView = true;


    }
    this._spinner.hide();
  }

  protected crateForm(): { [key: string]: any; } {
    return {
      remessa_idAbertura: [],
      remessa_layout: [],
      remessa_custoTrafego: [],
      remessa_dsName: [],
      remessa_caixaPostalOrigem: [],
      remessa_rename: [],
      remessa_caixaPostalDestino: [],
      remessa_formato: [],
      remessa_maxRecSize: [],
      remessa_CRLF: [],
      remessa_timestampTX: [],
      remessa_timestampTR: [],
      remessa_removeAcentos: [],
      remessa_insertTimestamp: [],
      remessa_insertSeqType: [],
      remessa_insertSeq: [],
      remessa_insertSeqNumberLen: [],
      remessa_insertSeqNumberMax: [],
      remessa_insertSeqNumbermin: [],
      remessa_mapas: [],
      remessa_fileRegexEBCDIC: [],
      remessa_maxRecSizeEBCDIC: [],
      remessa_configuradorEnvioDsNameStatus: [],

      retorno_idAbertura: [],
      retorno_layout: [],
      retorno_custoTrafego: [],
      retorno_Dsname: [],
      retorno_caixaPostalOrigem: [],
      retorno_rename: [],
      retorno_caixaPostalDestino: [],
      retorno_formato: [],
      retorno_maxRecSize: [],
      retorno_CRLF: [],
      retorno_timestampTX: [],
      retorno_timestampTR: [],
      retorno_removeAcentos: [],
      retorno_insertTimestamp: [],
      retorno_insertSeqType: [],
      retorno_insertSeq: [],
      retorno_insertSeqNumberLen: [],
      retorno_insertSeqNumberMax: [],
      retorno_insertSeqNumbermin: [],
      retorno_mapas: [],
      retorno_fileRegexEBCDIC: [],
      retorno_maxRecSizeEBCDIC: [],
      retorno_configuradorEnvioDsNameStatus: [],

      portal_idAbertura: [],
      portal_layout: [],
      portal_custoTrafego: [],
      portal_Dsname: [],
      portal_caixaPostalOrigem: [],
      portal_rename: [],
      portal_caixaPostalDestino: [],
      portal_formato: [],
      portal_maxRecSize: [],
      portal_CRLF: [],
      portal_timestampTX: [],
      portal_timestampTR: [],
      portal_removeAcentos: [],
      portal_insertTimestamp: [],
      portal_insertSeqType: [],
      portal_insertSeq: [],
      portal_insertSeqNumberLen: [],
      portal_insertSeqNumberMax: [],
      portal_insertSeqNumbermin: [],
      portal_mapas: [],
      portal_fileRegexEBCDIC: [],
      portal_maxRecSizeEBCDIC: [],
      portal_configuradorEnvioDsNameStatus: [],
    };
  }



  alterar() {
    this.idsRelacionamentoAbertura = Object.assign({}, this.idsRelacionamentoAbertura, {
      custoTrafegoRemessa: this.form.get('custoTrafegoRemessa').value,
      dsnameRemessa: this.form.get('dsnameRemessa').value,
      renameRemessa: this.form.get('renameRemessa').value,
      layoutRetorno: this.form.get('layoutRetorno').value,
      custoTrafegoRetorno: this.form.get('custoTrafegoRetorno').value,
      dsnameRetorno: this.form.get('dsnameRetorno').value,
      renameRetorno: this.form.get('renameRetorno').value,
    });

    if (this.idsRelacionamentoAbertura) {
      this._confirmation
        .confirm({
          titulo:
            'Tem certeza que deseja continuar com a Alteração do Status da Cotação?',
          mensagem:
            'Esta alteração impacta nos Projetos relacionados.',
        })
        .subscribe((result) => {
          if (result) {
            this.alterarExec();
          }
        });
    } else {
      this.alterarExec();
    }
  }

  alterarExec() {
    this._service.alteracaoEmLote(this.idsRelacionamentoAbertura)
      .subscribe(() => {
        this.sucess('Relacionamento Abertura alteradas com sucesso!');
        this.atualizado.emit(true);
      },
        (error) => this.error(error)
      );
  }


}

export class EditarLoteRelacionamentoAberturaCombos {
  static combos() {
    return [
      {
        listName: 'listaLayouts',
        filterName: 'filterLayouts',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaCustoTrafego',
        filterName: 'filterCustoTrafego',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaFormatos',
        filterName: 'filterFormato',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaTimestampTX',
        filterName: 'filterTimestampTX',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaTimestampTR',
        filterName: 'filterTimestampTR',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaInsertTimestamp',
        filterName: 'filterInsertTimestamp',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaInsertSeq',
        filterName: 'filterInsertSeq',
        fieldValue: 'descricao',
      },

      {
        listName: 'listaInsertSeqType',
        filterName: 'filterInsertSeqType',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaCRLF',
        filterName: 'filterCRLF',
        fieldValue: 'descricao',
      },
      {
        listName: 'listaRemoveAcentos',
        filterName: 'filterRemoveAcentos',
        fieldValue: 'descricao',
      },
    ];
  }
}