import {
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormBase } from '@utils/base/form-base/form-base';
import { ContratoTipoReajuste } from '../../shared/interfaces/contrato-tipo-reajuste.interface';
import { ContratoTipoReajusteService } from '../../shared/services/contrato-tipo-reajuste.service';
import { ContratoReajuste } from '../../shared/interfaces/contrato-reajuste.interface';
import { Validators } from '@angular/forms';
import { ContratoReajusteService } from '../../shared/services/contrato-reajuste.service';
import { DataUtil } from '@utils/utils/class/data.util';
import { ContratoAtualizarValorService } from '../../shared/services/contrato-atualizar-valor.service';
import { ContratoAtualizarValor } from '../../shared/interfaces/contrato-atuallizar-valor.interface';

@Component({
    selector: 'contrato-atualizar-reajuste',
    templateUrl: './contrato-atualizar-reajuste.component.html',
    styleUrls: ['./contrato-atualizar-reajuste.component.scss'],
})
export class ContratoAtualizarReajusteComponent
    extends FormBase
    implements OnInit {

    isView: boolean = false;
    listaTipoReajustes: Array<ContratoTipoReajuste> = [];

    @Output() atualizado = new EventEmitter();

    private _idsContratoReajuste: Array<number> = []

    get idsContratoReajuste() {
        return this._idsContratoReajuste;
    }

    @Input() set idsContratoReajuste(idsContratoReajuste: Array<number>) {
        const idsContrato = idsContratoReajuste;
        this._idsContratoReajuste = idsContratoReajuste;
        if (idsContrato && idsContrato.length > 0) {
            this.isView = true;
        }
    }

    constructor(
        protected _injector: Injector,
        private _contratoAtualizarReajusteService: ContratoTipoReajusteService,
        private _contratoReajusteService: ContratoReajusteService,
        private _contratoAtualizarValorService: ContratoAtualizarValorService,
    ) {

        super(_injector);

    }

    ngOnInit() {
        this._contratoAtualizarReajusteService.find().subscribe(result => {
            this.listaTipoReajustes = result.data
        })
    }

    protected crateForm(): { [key: string]: any } {
        return {
            tipoReajuste: ['', Validators.required],
            valor: [''],
            valorReais: [''],
            dataBiller: ['', Validators.required],
        };
    }

    editar() {
        const tipoReajuste = this.form.get('tipoReajuste').value;
        if (tipoReajuste == '000') {
            this.atualizarValor();
        } else {
            this.atualizarReajuste();
        }

    }

    private atualizarReajuste() {
        const dataBiller = this.form.get('dataBiller').value;
        const tipoReajuste = this.form.get('tipoReajuste').value;
        const porcentagemReajuste = this.form.get('valor').value;
        if (porcentagemReajuste == undefined || porcentagemReajuste == '') {
            this.error({ error: { message: 'Você deve informar a porcentagem do ajustes' } });
            return;
        }
        const contratoReajuste: ContratoReajuste = {
            tabelaReajusteId: tipoReajuste,
            porcentagemReajuste: porcentagemReajuste,
            dataBiller: dataBiller ? DataUtil.toDateBackend(dataBiller) : '',
            contratoItemId: this._idsContratoReajuste

        };
        this._confirmation
            .confirm({
                titulo:
                    'Tem certeza que deseja realizar o reajuste?',
                mensagem:
                    'Esta alteração impacta nos Projetos relacionados.',
            })
            .subscribe((result) => {
                if (result) {
                    this.editarExec(contratoReajuste);
                }
            });
    }

    private atualizarValor() {
        const dataBiller = this.form.get('dataBiller').value
        const valorAtualizado = this.trataValorFaturado(this.form.get('valorReais').value)
        if (valorAtualizado == undefined || valorAtualizado == '') {
            this.error({ error: { message: 'Você deve informar o valor do ajustes' } });
            return;
        }
        const contratoReajuste: ContratoAtualizarValor = {
            valorAtualizado: valorAtualizado + '',
            dataBiller: dataBiller ? DataUtil.toDateBackend(dataBiller) : '',
            contratoItemId: this._idsContratoReajuste

        };
        this._confirmation
            .confirm({
                titulo:
                    'Tem certeza que deseja realizar o reajuste?',
                mensagem:
                    'Esta alteração impacta nos Projetos relacionados.',
            })
            .subscribe((result) => {
                if (result) {
                    this.editarExecValor(contratoReajuste);
                }
            });
    }

    private trataValorFaturado(valorFaturado: number) {
        let valor = valorFaturado + '';
        valor = valor.replace(',', '.');

        if (/[0-9]+\.[0-9]{2}/g.test(valor)) {
            return valor;
        } else if (/[0-9]+\.[0-9]{1}/g.test(valor)) {
            return valor + '0';
        } else if (/[0-9]+/g.test(valor)) {
            return valor + '.00';
        }
    }

    private editarExecValor(contratoReajuste) {
        this._spinner.show();
        this._contratoAtualizarValorService.update(contratoReajuste).subscribe(
            () => {
                this.sucess('Reajuste realizado com sucesso!');
                this.atualizado.emit('true');
            },
            (error) => this.error(error)
        );
    }


    private editarExec(contratoReajuste) {
        this._spinner.show();
        this._contratoReajusteService.update(contratoReajuste).subscribe(
            () => {
                this.sucess('Reajuste realizado com sucesso!');
                this.atualizado.emit('true');
            },
            (error) => this.error(error)
        );
    }
}
