import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Projeto } from '../../shareds/interfaces/projeto.interface';

@Component({
    selector: 'modal-alteracao-lote-projeto',
    templateUrl: './modal-alteracao-lote-projeto.component.html',
    styleUrls: ['./modal-alteracao-lote-projeto.component.scss']
})
export class ModalAlteracaoLoteProjetoComponent implements OnInit {
    tituloModal: string = 'Editar Projeto';

    constructor(
        public dialogRef: MatDialogRef<ModalAlteracaoLoteProjetoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

    ngOnInit() {

    }

    fechar(): void {
        this.dialogRef.close();
    }

    atualizar(result: any) {
        this.dialogRef.close(result);
    }

}
