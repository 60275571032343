import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeGuard } from './home.guard';
import { HomeIndexPageComponent } from './pages/home-index-page/home-index-page.component';

const routes: Routes = [
    {
        path: '',
        canActivate: [HomeGuard],
        component: HomeIndexPageComponent,
    },

    {
        path: 'cliente',
        canActivate: [HomeGuard],
        component: HomeIndexPageComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class HomeRoutingModule {}
