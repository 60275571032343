import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProdutoListaResolver } from '../produto/shareds/resolvers/produto-lista.resolver';
import { ContratoGuard } from './contrato.guard';
import { ContratoDetalhePageComponent } from './pages/contrato-detalhe-page/contrato-detalhe-page.component';
import { ContratoGridPageComponent } from './pages/contrato-grid-page/contrato-grid-page.component';
import { PreContratoDetalhePageComponent } from './pages/pre-contrato-detalhe-page/pre-contrato-detalhe-page.component';
import { ContratoCnpjFinnetResolver } from './shared/resolvers/contrato-cnpj-finnet.resolver';
import { ContratoDetalheResolver } from './shared/resolvers/contrato-detalhe.resolver';
import { ContratoEstadoResolver } from './shared/resolvers/contrato-estado.resolver';
import { ContratoIndiceReajusteResolver } from './shared/resolvers/contrato-indice-reajuste.resolver';
import { ContratoPrioridadeListaResolver } from './shared/resolvers/contrato-prioridade-lista.resolver';
import { ContratoSegmentoListaResolver } from './shared/resolvers/contrato-segmento-lista.resolver';
import { ContratoSituacaoListaResolver } from './shared/resolvers/contrato-situacao-lista.resolver';
import { ContratoStatusListaResolver } from './shared/resolvers/contrato-status-lista.resolver';
import { ContratoStatusPrimarioResolver } from './shared/resolvers/contrato-status-primario.resolver';
import { ContratoStatusSecundarioResolver } from './shared/resolvers/contrato-status-secundario.resolver';
import { ContratoTipoMinutaResolver } from './shared/resolvers/contrato-tipo-minuta.resolver';
import { PreContratoDetalheResolver } from './shared/resolvers/pre-contrato-detalhe.resolver';
import { ContratoAutorizacaoUsoMarcaResolver } from './shared/resolvers/contrato-autorizacao-uso-marca.resolver';
import { ContratoAutorizacaoBloqueioResolver } from './shared/resolvers/contrato-autorizacao-bloqueio.resolver';
import { ContratoTipoCobrancaResolver } from './shared/resolvers/contrato-tipo-cobranca.resolver';
import { ContratoLimitacaoResponsabilidadeResolver } from './shared/resolvers/contrato-limitacao-responsabilidade.resolver';
import { ContratoMultaRescisoriaResolver } from './shared/resolvers/contrato-multa-rescisoria.resolver';
import { ContratoAutorizacaoProtestoResolver } from './shared/resolvers/contrato-autorizacao-protesto.resolver';

const routes: Routes = [
    {
        path: '',
        canActivate: [ContratoGuard],
        canLoad: [ContratoGuard],
        resolve: {
            statuss: ContratoStatusListaResolver,
            situacoes: ContratoSituacaoListaResolver,
            prioridades: ContratoPrioridadeListaResolver,
            segmentos: ContratoSegmentoListaResolver,
            statusPrimario: ContratoStatusPrimarioResolver,
            statusSecundario: ContratoStatusSecundarioResolver,
            tipoMinuta: ContratoTipoMinutaResolver,
            produtos: ProdutoListaResolver,
        },
        component: ContratoGridPageComponent,
    },
    {
        path: ':id',
        canActivate: [ContratoGuard],
        canLoad: [ContratoGuard],
        resolve: {
            autorizacaoBloqueio: ContratoAutorizacaoBloqueioResolver,
            autorizacaoProtesto: ContratoAutorizacaoProtestoResolver,
            autorizacaoUsoMarca: ContratoAutorizacaoUsoMarcaResolver,
            contrato: ContratoDetalheResolver,
            contratoProdutosLista: ProdutoListaResolver,
            situacoes: ContratoSituacaoListaResolver,
            statuss: ContratoStatusListaResolver,
            finnetCNPJ: ContratoCnpjFinnetResolver,
            prioridades: ContratoPrioridadeListaResolver,
            segmentos: ContratoSegmentoListaResolver,
            statusPrimario: ContratoStatusPrimarioResolver,
            statusSecundario: ContratoStatusSecundarioResolver,
            tipoCobranca: ContratoTipoCobrancaResolver,
            tipoMinuta: ContratoTipoMinutaResolver,
            indiceReajuste: ContratoIndiceReajusteResolver,
            estados: ContratoEstadoResolver,
            limitacaoResponsabilidade: ContratoLimitacaoResponsabilidadeResolver,
            multaRescisoria: ContratoMultaRescisoriaResolver,
        },
        component: ContratoDetalhePageComponent,
    },
    {
        path: 'contratosituacao',
        canActivate: [ContratoGuard],
        canLoad: [ContratoGuard],
        resolve: {},
        component: ContratoGridPageComponent,
    },
    {
        path: 'pre-contrato/:id',
        canActivate: [ContratoGuard],
        canLoad: [ContratoGuard],
        resolve: {
            contrato: PreContratoDetalheResolver,
            contratoProdutosLista: ProdutoListaResolver,
            segmentos: ContratoSegmentoListaResolver,
            prioridades: ContratoPrioridadeListaResolver,
            statusPrimario: ContratoStatusPrimarioResolver,
            statusSecundario: ContratoStatusSecundarioResolver,
            tipoMinuta: ContratoTipoMinutaResolver,
            produtos: ProdutoListaResolver,
            estados: ContratoEstadoResolver,
        },
        component: PreContratoDetalhePageComponent,
    },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ContratoRoutingModule {}
