import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceGridBase } from '@utils/base/service-base/service-grid-base';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Response } from '@utils/interfaces/http/response.interface';
import { ProjetoObservacaoHistorico } from '../interfaces/projeto-observacao-historico.interface';
import { UsuarioLogado } from '@utils/interfaces/usuario/usuario-logado.interface';
import { FiltroProjetoObservacaoHistorico } from '../interfaces/filtro-projeto-observacao-historico.interface';

@Injectable({
    providedIn: 'root',
})
export class ProjetoObservacaoHistoricoService extends ServiceGridBase<ProjetoObservacaoHistorico, FiltroProjetoObservacaoHistorico> {
    private path: string = `${environment.server}/projeto`;

    constructor(
        private _http: HttpClient,
    ) {
        super();
    }

    find(filtro?: FiltroProjetoObservacaoHistorico): Observable<Response<Array<ProjetoObservacaoHistorico>>> {
        const params: string = filtro ? this.toGetParams(filtro) : '';
        const url = `${this.path}/${filtro.idProjeto}/historico-observacao${params}`;
        return this._http.get<Response<Array<ProjetoObservacaoHistorico>>>(url);
    }

    create(observacao: ProjetoObservacaoHistorico): Observable<Response<ProjetoObservacaoHistorico>> {
        const url = `${this.path}/${observacao.idProjeto}/historico-observacao`;
        return this._http.post<Response<ProjetoObservacaoHistorico>>(url, observacao);
    }

}
