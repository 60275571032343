import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { ContratoItem } from '../../shared/interfaces/contrato-item.interface';
import { Contrato } from '../../shared/interfaces/contrato.interface';
import { FiltroContrato } from '../../shared/interfaces/filtro-contrato.interface';
import { ContratoItemService } from '../../shared/services/contrato-item.service';
import { ContratoModalService } from '../../shared/services/contrato-modal.service';
import { PreContratoService } from '../../shared/services/pre-contrato.service';

@Component({
  selector: 'pre-contrato-grid',
  templateUrl: './pre-contrato-grid.component.html',
  styleUrls: ['./pre-contrato-grid.component.scss'],
})
export class PreContratoGridComponent
  extends GridBase<Contrato, FiltroContrato, PreContratoService>
  implements OnChanges, OnInit {
  private cache: Map<number, string> = new Map();

  selection = new SelectionModel<Contrato>(true, []);
  produtos: ContratoItem[] = [];

  get filtroPreContrato(): any {
    return this.filtro;
  }
  @Input() set filtroPreContrato(filtro: any) {
    this.filtro = filtro;
  }

  constructor(
    protected _service: PreContratoService,
    private contratoItemService: ContratoItemService,
    protected _injector: Injector,
    protected _changeDetectorRefs: ChangeDetectorRef,
    private _modalContrato: ContratoModalService
  ) {
    super(_service, _injector, _changeDetectorRefs, 'PreContratoGrid');
  }

  async ngOnInit() {
    this.filtro = this.filtro ? this.filtro : {};
    this.filtro.sortField = this.filtro.sortField
      ? this.filtro.sortField
      : 'dataCriacao';
    this.filtro.sortOrder = this.filtro.sortOrder
      ? this.filtro.sortOrder
      : 'desc';
    this.filtro;
    this.initGrid(this.filtro);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.atualizarGrid(this.filtro || {}, () =>
      this.selection.clear()
    );
  }

  configDisplayedColumns(): GridCollumn[] {
    return [
      { chave: 'select' },
      { chave: 'seleciona' },
      { chave: 'empresa', label: 'Nome da empresa' },
      { chave: 'inscricaoEmpresa', label: 'CNPJ' },
      { chave: 'segmento', label: 'Segmento' },
      { chave: 'prioridade', label: 'Prioridade' },
      { chave: 'dataAceite', label: 'Aceite da proposta' },
      { chave: 'dataCriacao', label: 'Data de criação' },
      { chave: 'numeroCotacao', label: 'Numero de cotação' },
      { chave: 'produto', label: 'Produto' },
    ];
  }

  visualizar(preContrato: Contrato) {
    this._router.navigateByUrl(`contrato/pre-contrato/${preContrato.id}`);
  }

  editar() {
    const contratosSelecionadas = this.selection.selected.map((v) => v.id);
    this._modalContrato
      .cotacaoEditar(contratosSelecionadas)
      .subscribe((result) => {
        if (result) {
          this.selection.clear();
          this.atualizarGrid(this.filtro);
        }
      });
  }

  historico(preContrato: Contrato) {
    this._modalContrato.historico(preContrato);
  }

  documentos(preContrato: Contrato) {
    this._modalContrato.documento(preContrato);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows =
      this.paginacao.pageSize <= this.paginacao.length
        ? this.paginacao.pageSize
        : this.paginacao.length;
    return numSelected === numRows && numSelected != 0 && numRows != 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.listaDataSource.data.forEach((row) =>
        this.selection.select(row)
      );
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Contrato): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1
      }`;
  }

  getProdutoTooltip(idContrato: number) {
    return this.cache.get(idContrato);
  }

  visualizarProduto(idContrato: any) {
    if (this.cache.get(idContrato)) {
      return;
    }

    this.contratoItemService.find({ idContrato }).subscribe((result) => {
      const tmp: string[] = [];
      for (let j = 0; j < result.data.length; j++) {
        const elementIten = result.data[j];
        if (elementIten.produto.nome) {
          tmp.push(elementIten.produto.nome);
        }

      }
      this.cache.set(idContrato, tmp.join(" | "));
    });
  }

  criarContrato(contrato: Contrato): void {
    let data = {
      id: contrato.id,
      status: { id: 1 },
    };
    this._service.update(data).subscribe(
      (data) => {
        this._notification.sucess('Contrato gerado com sucesso');
        this.atualizarGrid(this.filtro);
      },
      (error) => this.error(error)
    );
  }

  addAditivoContrato(contrato: Contrato): void {
    let id = contrato.id;
    this._modalContrato.addAtivoContrato(contrato.id).subscribe((data) => {
      let contrato = {
        id: data[0].id,
        preContratoId: id,
      };
      this._service.addAditivo(contrato).subscribe(
        (data: any) => {
          this._notification.sucess('Aditivo vinculado com sucesso');
          this.atualizarGrid(this.filtro);
        },
        (error) => this.error(error)
      );
    });
  }
}
