import { Component, Injector, OnInit } from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { FiltroEmpresa } from '../../shareds/interfaces/filtro-empresa.interface';

@Component({
    selector: 'empresa-grid-filtro',
    templateUrl: './empresa-grid-filtro.component.html',
    styleUrls: ['./empresa-grid-filtro.component.scss'],
})
export class EmpresaGridFiltroComponent
    extends FormFiltroBase<FiltroEmpresa>
    implements OnInit {
    listaCombo: Array<string> = ['ATIVO', 'INATIVO'];

    constructor(injector: Injector) {
        super('EmpresaGrid', injector);
    }

    ngOnInit() { }

    camposFiltro(): string[] {
        return [];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            inscricao: [],
            nome: [],
            seleciona: [],
        };
    }
}
