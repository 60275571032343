import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Projeto } from '../../shareds/interfaces/projeto.interface';

@Component({
  selector: 'app-modal-download-projeto',
  templateUrl: './modal-download-projeto.component.html',
  styleUrls: ['./modal-download-projeto.component.scss']
})
export class ModalDownloadProjetoComponent implements OnInit {
  tituloModal: string = 'Documentação';

  constructor(
    public dialogRef: MatDialogRef<ModalDownloadProjetoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Projeto) { }

  ngOnInit() {
  }

  fechar(): void {
    this.dialogRef.close();
  }

}
