import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormFiltroBase } from '@utils/base/form-filtro-base/form-filtro-base';
import { Faturamento } from '../../shared/interfaces/faturamento.interface';
import { FiltroFaturamento } from '../../shared/interfaces/filtro-faturamento.interface';
import { FaturamentoRecorrenteDadosComplementaresService } from '../../shared/services/faturamento-recorrente-dados-complementares.service';
import { FaturamentoService } from '../../shared/services/faturamento.service';

@Component({
    selector: 'faturamento-dados-complementares-aba',
    templateUrl: './faturamento-dados-complementares-aba.component.html',
    styleUrls: ['./faturamento-dados-complementares-aba.component.scss']
})
export class FaturamentoDadosComplementaresAbaComponent extends FormFiltroBase<FiltroFaturamento> implements OnInit {

    public faturamento: Faturamento = {};
    public isEdit: boolean = true;

    @Input() isRecorrente: boolean;

    constructor(
        injector: Injector,
        protected _faturamentoService: FaturamentoService,
        protected _faturamentoRecorrenteDadosComplementaresService: FaturamentoRecorrenteDadosComplementaresService
    ) {
        super('FaturamentoDadosComplementaresAba', injector, FaturamentoDadosComplementaresAbaCombos.combos());
    }

    ngOnInit() {
        this._route.url.subscribe(params => {
            let isView = false;
            params.forEach(param => {
                if (param.path === 'visualizar') isView = true;
            })
            this.isEdit = !isView
        })

        this._route.data.subscribe((data) => {
            if (this.isRecorrente) {
                this.faturamento = data.faturamento;
                const faturamentoDadosComplementares = data.faturamentoRecorrenteDadosComplementares;
                this.faturamento.dadosComplementares = faturamentoDadosComplementares.dadosComplementares;
            }
            else {
                this.faturamento = data.faturamento;
            }

            this.setForm(this.faturamento)
        });
    }

    camposFiltro(): string[] {
        return [];
    }

    protected crateForm(): { [key: string]: any } {
        return {
            id: [],
            dadosComplementares: [],
        };
    }

    salvar() {
        const dadosComplementares = this.form.get('dadosComplementares').value

        if (this.isRecorrente) {
            this._spinner.show();
            this._faturamentoRecorrenteDadosComplementaresService.updateDadosComplementares(this.faturamento.id, dadosComplementares).subscribe(() => {
                this._notification.sucess('Dados atualizados com sucesso');
                this._spinner.hide();
            },
                error => {
                    this._notification.error('Erro ao atualizar os dados');
                    this._spinner.hide();
                }
            )
        }
        else {

            this._spinner.show();
            this._faturamentoService.updateDadosComplementares(this.faturamento.id, dadosComplementares).subscribe(() => {
                this._notification.sucess('Dados atualizados com sucesso');
                this._spinner.hide();
            },
                error => {
                    this._notification.error('Erro ao atualizar os dados');
                    this._spinner.hide();
                }
            )
        }
    }

    private setForm(faturamento: Faturamento) {
        this.form.patchValue({
            id: faturamento.id,
            dadosComplementares: faturamento.dadosComplementares
        })
    }

}

export class FaturamentoDadosComplementaresAbaCombos {
    static combos() {
        return [
            {
                listName: 'listaProdutos',
                filterName: 'filterProdutos',
                fieldValue: 'nome',
            },
        ];
    }
}
