import { Component, Input, OnInit } from '@angular/core';
import { Perfil } from '../../shareds/interfaces/perfil.interface';

@Component({
    selector: 'perfil-detalhe',
    templateUrl: './perfil-detalhe.component.html',
    styleUrls: ['./perfil-detalhe.component.scss'],
})
export class PerfilDetalheComponent implements OnInit {
    @Input() perfil: Perfil = {};

    constructor() {
        this.perfil = {};
    }

    ngOnInit(): void {}
}
