import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ChecklistConfiguracaoArquivoData } from '../../shared/interfaces/checklist-configuracao-arquivo.interface';

@Component({
    selector: 'modal-checklist-configuracao-arquivo',
    templateUrl: './modal-checklist-configuracao-arquivo.component.html',
    styleUrls: ['./modal-checklist-configuracao-arquivo.component.scss'],
})
export class ModalChecklistConfiguracaoArquivoComponent implements OnInit {
    tituloModal: string = 'Anexos';

    constructor(
        public dialogRef: MatDialogRef<ModalChecklistConfiguracaoArquivoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ChecklistConfiguracaoArquivoData
    ) {}
    ngOnInit() {}

    fechar(): void {
        this.dialogRef.close();
    }
}
