import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Cotacao } from '../interfaces/cotacao.interface';
import { CotacaoService } from '../services/cotacao.service';

@Injectable()
export class CotacaoDetalheResolver implements Resolve<Cotacao> {
    constructor(private _cotacaoService: CotacaoService) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        const id = route.params['id'];
        return this._cotacaoService
            .findById(id)
            .pipe(map((result) => result.data));
    }
}
