import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ComponentBase } from '@utils/base/component-base/component-base';
import { Time } from '../../shareds/interfaces/time.interface';
import { TimeService } from '../../shareds/services/time.service';

@Component({
    selector: 'modal-time-detalhe',
    templateUrl: './modal-time-detalhe.component.html',
    styleUrls: ['./modal-time-detalhe.component.scss'],
})
export class ModalTimeDetalheComponent extends ComponentBase implements OnInit {
    time: Time;
    tituloModal: string = 'Time';

    constructor(
        public dialogRef: MatDialogRef<ModalTimeDetalheComponent>,
        @Inject(MAT_DIALOG_DATA) public data: Time,
        private _timeService: TimeService,
        private _Injector: Injector
    ) {
        super(_Injector);
        this._spinner.show();
        this._timeService.findById(data.id).subscribe(
            (time) => {
                this._spinner.hide();
                this.time = time.data;
            },
            (error) => this.error(error)
        );
    }
    ngOnInit() { }

    fechar(): void {
        this.dialogRef.close();
    }
}
