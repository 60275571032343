import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ValidacaoDigitoAgenciaConta } from './validacao-digito-agencia-conta.interface';

@Injectable({
  providedIn: 'root'
})
export class ValidacaoDigitoAgenciaContaService extends ServiceBase<ValidacaoDigitoAgenciaConta>{
  private path: string = `${environment.server}/validarBanco`;

  constructor(private _http: HttpClient) {
    super()
  }

  validate(data: ValidacaoDigitoAgenciaConta): Observable<boolean> {
    const params: string = data ? this.toGetParams(data) : '';
    const url = `${this.path}${params}`;
    return this._http.get<any>(url).pipe(map(result => {
      return true
    }));
  }
}
