import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceBase } from '@utils/base/service-base/service-base';
import { Response } from '@utils/interfaces/http/response.interface';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ProjetoDocumentoUpload } from '../interfaces/projeto-documento-upload.interface';

@Injectable({
  providedIn: 'root'
})
export class ProjetoDocumentoUploadService extends ServiceBase<any>{
  private path: string = `${environment.server}/projeto-documento`;

  constructor(private _http: HttpClient) {
    super();
  }

  find(idProjeto: number): Observable<Response<Array<ProjetoDocumentoUpload>>> {
    const url = `${this.path}/${idProjeto}`;
    return this._http.get<Response<Array<ProjetoDocumentoUpload>>>(url);
  }

  upload(idProjeto: any, file: File): Observable<any> {
    const url = `${this.path}/${idProjeto}`;
    const formData = new FormData();
    formData.set('documento', file, file.name);
    formData.set('idProjeto', idProjeto);

    return this._http.post(url, formData, {
      observe: 'events',
      reportProgress: true,
    });
  }

}
