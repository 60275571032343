import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsuarioGridPageComponent } from './pages/usuario-grid-page/usuario-grid-page.component';
import { UsuarioGuard } from './usuario.guard';

const routes: Routes = [
    {
        path: '',
        canActivate: [UsuarioGuard],
        canLoad: [UsuarioGuard],
        component: UsuarioGridPageComponent,
    },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class UsuarioRoutingModule {}
