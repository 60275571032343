import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CotacaoPesquisa } from '../../shared/interfaces/cotacao-pesquisa.interface';

@Component({
    selector: 'modal-pesquisa-cotacao',
    templateUrl: './modal-pesquisa-cotacao.component.html',
    styleUrls: ['./modal-pesquisa-cotacao.component.scss']
})
export class ModalPesquisaCotacaoComponent implements OnInit {
    tituloModal: string = 'Selecione a cotacao';

    constructor(
        public dialogRef: MatDialogRef<ModalPesquisaCotacaoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }
    ngOnInit() { }

    selecionado(checklist: CotacaoPesquisa) {
        this.dialogRef.close(checklist);
    }

    fechar(): void {
        this.dialogRef.close();
    }
}
