import { ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FiltroBase } from '@utils/base/form-filtro-base/interfaces/filtro-base.interface';
import { GridBase } from '@utils/base/grid-base/grid-base';
import { GridCollumn } from '@utils/base/grid-base/grid-column.interface';
import { Contrato } from 'app/main/contrato/shared/interfaces/contrato.interface';
import { ContratoService } from 'app/main/contrato/shared/services/contrato.service';
import { Cotacao } from '../../shared/interfaces/cotacao.interface';

@Component({
    selector: 'cotacao-vincular-contrato',
    templateUrl: './cotacao-vincular-contrato.component.html',
    styleUrls: ['./cotacao-vincular-contrato.component.scss'],
})
export class CotacaoContratoVincularComponent
    extends GridBase<Contrato, FiltroBase, ContratoService>
    implements OnChanges, OnInit {

    @Input() cotacao: Cotacao;
    @Output() selecionado = new EventEmitter();

    constructor(
        protected _service: ContratoService,
        protected _injector: Injector,
        protected _changeDetectorRefs: ChangeDetectorRef
    ) {
        super(_service, _injector, _changeDetectorRefs);
    }

    ngOnInit(): void {
        this.initGrid(this.filtro || {});
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.atualizarGrid(this.filtro || {});
    }

    configDisplayedColumns(): Array<GridCollumn> {
        return [
            { chave: 'seleciona' },
            { chave: 'nome', label: '' },
            { chave: 'numeroContrato', label: '' },
            { chave: 'empresa', label: '' },
            { chave: 'incricao', label: '' },
        ];
    }

    selecionar(contrato: Contrato) {

        this._confirmation.confirm({ titulo: `Deseja realmente vincular a cotação ao contrato selecionado. Essa ação não poderá ser desfeita` }).subscribe(result => {
            if (result) {
                this._spinner.show();
                this._service.vincularCotacaoContrato(contrato.id, this.cotacao).subscribe(
                    () => {
                        this.selecionado.emit(true);
                        this.sucess("Contrato vinculado com sucesso!");
                    },
                    error => this.error(error)
                )
            }
        })
    }
}

