import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ModalOsEmpresaCadastroComponent } from '../../modal/modal-os-empresa-cadastro/modal-os-empresa-cadastro.component';
import { ModalOsEmpresaGridComponent } from '../../modal/modal-os-empresa-grid/modal-os-empresa-grid.component';
import { OsEmpresa } from '../interfaces/os-empresa.interface';
import { Os } from '../interfaces/os.interface';

@Injectable({
    providedIn: 'root',
})
export class OsEmpresaModalService {
    constructor(public dialog: MatDialog) {}

    empresaGrid(data: Os) {
        const dialogRef = this.dialog.open(ModalOsEmpresaGridComponent, {
            width: '90%',
            data: data,
        });

        return dialogRef.afterClosed();
    }

    empresaCadastro(data: OsEmpresa) {
        const dialogRef = this.dialog.open(ModalOsEmpresaCadastroComponent, {
            width: '95%',
            data: data,
        });

        return dialogRef.afterClosed();
    }
}
